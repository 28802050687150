.personal_menu__mobile {
   position: fixed;
   right: -999px;
   width: 320px;
   padding: 21px 15px;
   border: 1px solid @color-gray-light;
   box-sizing: border-box;
   
   background-color: white;
   z-index: 500;
   
   .column();
   .justify-start();
   
   height: 60vh;
   
   .from( 1215px, {
      display: none;
      right: -99999999px;
   });
   .xxs-block({
      width: 100%;
      height: 100vh;
   });
   
}
.personal_menu__header {
   .flex();
   .justify-between();
   .items-center();
   
   padding-bottom: 21px;
   margin-bottom: 20px;
   box-sizing: border-box;
}
.personal_menu__title {

}
#open_menu {
   cursor: default;
   .to( 1215px, {
      cursor: pointer;
   });
}

.personal_menu__link,
.personal__out {
   .montserrat-semibold();
   font-size: 14px;
   line-height: 120%;
   color: @color-gray;
}
.personal_link_current {
   color: @color-blue;
}
.personal__out {
   margin-top: auto;
   cursor: pointer;
}
