.one_image__holder {
    .slick-track:before, .slick-track:after {
      position: absolute;
      background-color: @color-back-main;
   }
   button.slick-arrow {
      color: transparent;
      font-size: 0;
   }
   .slick-prev.slick-arrow,
   .slick-next.slick-arrow {
      position: absolute;
      background-color: @color-back-main;
      transform: none;
      z-index: 100;
      top: -1px;
      bottom: -1px;
      width: 51px;
      height: calc(100% + 2px);
      color: transparent;
      font-size: 1px;
      line-height: 0;
   }
   .slick-prev.slick-arrow {
      left: -2px;
      background-image: url("../images/svg/icons/b-angle-l.svg");
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: center;
   }
   .slick-next.slick-arrow {
      right: -2px;
      background-image: url("../images/svg/icons/b-angle-r.svg");
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: center;
   }
   .middle-block({
      .slick-prev.slick-arrow,
      .slick-next.slick-arrow {
         width: 30%;
      }
       .slick-track:before, .slick-track:after {
         position: absolute;
         background-color: transparent;
      }
      .slick-prev.slick-arrow {
         left: -2px;
         background-size: 0;
         background-color: transparent;
      }
      .slick-next.slick-arrow {
         right: -2px;
         background-size: 0;
         background-color: transparent;
      }
   });
}
.one_image__main {
   margin-bottom: 20px;
   .middle-block({
      height: 400px;
      border-radius: 10px;
      overflow: hidden;
   })
}
.one_image__wrapper,
.one_image__main.slick-initialized.slick-slider.slick-dotted,
.one_image__main .slick-list.draggable,
.one_image__main img {
   .middle-block({
      height: 400px;
      border-radius: 10px;
      overflow: hidden;
   });
   .sm-block({
      height: 300px;
      .slick-track {
         top: -10vw;
      }
   });
   .xs-block({
      height: 250px;
   })
}
.one_image__main,
.one_image__main .slick-list,
.one_image__main img {
   border-radius: 10px;
   overflow: hidden;
}
.one_item__small img {
   border-radius: 10px;
   overflow: hidden;
   width: 82px;
   height: 82px;
   object-fit: cover;
   border: 1px solid transparent;
   box-sizing: border-box;
}
.one_item__small .slick-slide.slick-current.slick-active.slick-center {
   border-color: @color-blue;
}
.one_item__small {
   .middle-block({
      .none();
   })
}
.catalog_main__container .slick-track:before, .catalog_main__container .slick-track:after {

}
