.basket__caption_top {
   .flex();
   .items-center();
   .justify-start();
   .to( 710px, {
      margin-bottom: 20px;
   });
}
.basket__caption_top #amount_basket {
   position: relative;
   top: unset;
   right: unset;
   width: 36px;
   height: 36px;
   border-radius: 50px;
   
   .montserrat-semibold();
   font-size: 18px;
   line-height: 145%;
   
   background-color: @color-yellow-2;
   color: @color-blue;
   box-sizing: border-box;
   padding: 0 0 1px 1px;
   
   margin-left: 20px;
   .lg-block({
      margin-left: 16px;
      padding: 0;
   });
   .xs-block({
      width: 24px;
      height: 24px;
      margin-left: 8px;
      font-size: 12px;
   });
}


.basket_order__steps {
   .flex();
   margin-top: 40px;
   margin-bottom: 20px;
   border-radius: 8px;
   overflow: hidden;
   background-color: @color-blue-gray;
   .lg-block({
      margin-top: 20px;
   });
   .to( 710px, {
      .none();
   });
}
.basket_order__step {
   .flex();
   .justify-start();
   .items-center();
   width: 25%;
   padding: 18px 20px;
   box-sizing: border-box;
   color: @color-gray;
   .montserrat-medium();
   font-size: 14px;
   cursor: default;
   .tr();
   &:first-of-type,
   &:nth-of-type(2) {
      border-right: 1px solid @color-gray-light;
   }
   &:last-of-type {
      border-left: 1px solid @color-gray-light;
   }
   .xl-block({
      &:nth-of-type(2) {
         width: 32%;
      }
   });
   .lg-block({
      padding: 12px 20px;
      &:nth-of-type(2) {
         min-width: 250px;
      }
   });
   .to( 760px, {
      padding: 8px 10px;
      &:nth-of-type(2) {
         min-width: 240px;
      }
   });
}
.basket_order__number {
   .flex-center();
   background-color: white;
   width: 24px;
   height: 24px;
   border-radius: 50px;
   margin-right: 8px;
   box-sizing: border-box;
   padding: 0 0 1px 1px;
   .tr();
   flex-shrink: 0;
}
.basket_order__title {
   .tr();
   text-align: left;
   white-space: nowrap;
}
.basket_order__step.progress {
   cursor: pointer;
   background-color: white;
   .basket_order__number {
      background-color: @color-blue;
      color: white;
   }
   .basket_order__title {
      color: @color-blue;
   }
   &[disable="true"] {
      cursor: default;
   }
}


#basket_wrapper {
   .to(485px, {
      padding-left: 5px;
      padding-right: 5px !important;
   });
   .xxs-block({
      padding-left: 5px !important;
      padding-right: 5px !important;
   });
   
   .slick-list {
      .to(470px, {
         padding-left: 0 !important;
         padding-right: 130px !important;
         margin-left: 25px !important;
      });
      .xxs-block({
         padding-left: 0 !important;
         padding-right: 110px !important;
         margin-left: 16px !important;
      });
   }
   
   .section__caption {
      .to(470px, {
         margin-left: 23px !important;
      });
      .xxs-block({
         margin-left: 16px !important;
      });
      .sm-block({
         padding-right: 0;
      });
   }
   .product__purchase {
      .md-block({
         grid-template-columns: auto 48px;
         grid-template-rows: auto auto;
         grid-row-gap: 8px;
         .product__amount {
            grid-column: ~"1 / 3";
            grid-row: ~"1 / 2";
         }
         .product__buy {
            grid-column: ~"1 / 2";
            grid-row: ~"2 / 3";
         }
         .product__button {
            grid-column: ~"2 / 3";
            grid-row: ~"2 / 3";
            justify-self: flex-end;
         }
         .product__button {
            width: 40px;
            height: 40px;
         }
      });
   }
   
   
   
   
   
.similar_carousel__holder {
   .from(@break_sm, {
      .slick-arrow {
         position: absolute;
         z-index: 70;
         top: -40px;
         transform: translateY(-100%);
         color: transparent;
         font-size: 0;
         line-height: 0;
         .xl-block({
            top: -30px;
         });
         .ll-block({
            top: -30px;
         });
      
      }
   });
   .from(@break_sm, {
      .slick-prev[aria-disabled="false"] {
         left: calc(100% - 66px);
         .arrow-prev-active();
      };
      .slick-next[aria-disabled="false"] {
         left: calc(100% - 28px);
         .arrow-next-active();
      };
      .slick-next[aria-disabled="true"] {
         left: calc(100% - 28px);
         .arrow-next()
      };
      .slick-prev[aria-disabled="true"] {
         left: calc(100% - 66px);
         .arrow-prev()
      };
   });
   .to( 1555px, {
      .catalog_main__products {
         //min-width: 265px;
      }
   });

   .sm-block({
      .good-friendly-arrows();
   });
}
}

.basket_order__wrapper .section_holder {
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: auto;
   grid-column-gap: 20px;
   grid-row-gap: 20px;
   .xl-block({
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-column-gap: 0;
      grid-row-gap: 40px;
   });
   
   .none();
}
.basket_order__wrapper .section_holder.show {
   .gr();
}
.basket_order__wrapper .section_main {
   grid-column: ~"1 / 9";
   .xl-block({
      grid-column: span 1;
   });
}
.basket_order__wrapper .section_second {
   grid-column: ~"9 / 13";
   .xl-block({
      grid-column: span 1;
   });
}
.basket_order__wrapper .section_block {
   background-color: white;
   border-radius: 10px;
   padding: 20px;
   box-sizing: border-box;
   .sm-block({
      padding: 15px;
   })
}
.basket_order__main {
   .gr();
   grid-row-gap: 20px;
   align-items: start;
}



.basket_order__important {
   .flex();
   border-radius: 10px;
   overflow: hidden;
   margin-bottom: 20px;
   border: 1px solid @color-gray-light;
   border-left: unset;
   
   .ico__box {
      margin-right: 12px;
   }
}
.line_blue {
   width: 6px;
   min-height: 100%;
   background-color: @color-blue;
}
.order__important {
   .flex();
   width: 100%;
   background-color: white;
   box-sizing: border-box;
   padding: 12px 16px;
}


.basket_order_total__promo {
   .gr();
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: 48px;
   grid-column-gap: 16px;
   padding-bottom: 20px;
   border-bottom: 1px solid @color-gray-light;
   .md-block({
      grid-template-rows: 48px 48px;
      grid-row-gap: 5px;
   });
   
   .input {
      height: unset;
      border: unset;
      color: @color-blue;
   }
}
.order_total__input,
.order_total__button {
   height: 48px;
   border-radius: 10px;
   .md-block({
      grid-column: ~"1 / 13";
      grid-row: span 1;
   });
}
.order_total__input {
   grid-column: ~"1 / 9";
   border: 1px solid #DDE1E6;
   .xl-block({
      grid-column: ~"1 / 7";
      min-width: 50%;
      grid-row: ~"1 / 2";
   });
}
.order_total__button {
   grid-column: ~"9 / 13";
   min-width: 117px;
   .xl-block({
      grid-column: ~"7 / 13";
      min-width: 50%;
      grid-row: ~"1 / 2";
   });
}
.basket_order_total__sum {
   padding: 20px 0;
   border-bottom: 1px solid @color-gray-light;
}

.order_total__sum,
.total_sum__text,
.total__sum,
.order_total__discount,
.total__discount,
.basket_order__total,
.order__total {
   .flex();
}
.order_total__sum,
.order_total__discount,
.basket_order__total {
   .justify-between();
}
.order_total__sum {
   margin-bottom: 16px;
}
.total_sum__text,
.total_discount__text,
.total__sum,
.total__discount,
.order_total__text {
   font-size: 14px;
   line-height: 110%;
}
.total_sum__text,
.total_discount__text,
.order_total__text {
   .montserrat-medium();
}
.basket_order__total {
   padding: 20px 0;
   flex-wrap: wrap;
}
.total__sum,
.total__discount,
.order__total{
   .montserrat-semibold();
}
.order__total {
   font-size: 16px;
   line-height: 120%;
}
.basket_order__total .text-dark {
   width: 100%;
}


#basket_order_code {
   .gr();
   grid-template-columns: 191px 200px 182px;
   grid-template-rows: 1fr 48px;
   grid-column-gap: 10px;
   .middle-block({
      grid-template-columns: 191px 200px;
      grid-template-rows: 1fr 48px auto;
      grid-row-gap: 10px;
   });
   .xs-block({
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      grid-column-gap: 0;
   });
   .text-small {
      grid-column: ~"1 / 3";
      grid-row: ~"1 / 2";
      width: 100%;
   }
   label {
      position: relative;
      width: 191px;
      grid-row: ~"2 / 3";
      .xs-block({
         grid-column: ~"1 / 3";
          width: 100%;
      });
   }
   input {
      height: 48px;
   }
   #order_code_button {
      width: 200px;
      grid-column: ~"2 / 3";
      grid-row: ~"2 / 3";
      .sm-block({
         height: 48px;
      });
      .xs-block({
         grid-column: ~"1 / 3";
         grid-row: ~"3 / 4";
          width: 100%;
         max-width: unset;
      });
   }
   
   display: none;
}
.order_button__again {
   .flex();
   .items-center();
   grid-column: ~"3 / 4";
   grid-row: ~"2 / 3";
   .middle-block({
      grid-column: ~"1 / 3";
      grid-row: ~"4 / 5";
   });
}

.options_popup {
   position: relative;
   z-index: 70;
   padding: 16px;
   box-sizing: border-box;
   background-color: white;
   border-radius: 8px;
   box-shadow: 0 0 18px 0 @opacity-blue-16;
   white-space: nowrap;
   
   &:after {
      .pseudo();
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 12px;
      .options-pointer();
   }
}

