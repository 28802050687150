.choose__container {
      margin-top: 80px;
      margin-bottom: 80px;
   .lg-block({
      margin-top: 80px;
      margin-bottom: 40px;
   });
   .middle-block({
      margin-top: 82px;
   });
   .sm-block({
      margin-top: 40px;
      margin-bottom: 40px;
   });
}
.choose__wrapper {
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: repeat(2, auto);
   grid-column-gap: 20px;
   
   background-color: @color-white;
   border-radius: 20px;
   padding: 40px 40px 10px;
   .lg-block({
      padding: 30px;
   });
   .sm-block({
      padding: 15px;
   });
   h2.caption.text-blue {
      margin-bottom: 20px;
      .lg-block({
         margin-bottom: 10px;
      })
   }
}
.choose__block_text {
   grid-column: ~"1 / 6";
   grid-row: ~"1 / 2";
   
   min-width: 312px;
}
.choose__container .button-yellow {
    grid-column: ~"1 / 6";
    grid-row: ~"2 / 3";
    margin-top: auto;
   margin-bottom: 30px;
   .sm-block({
      width: 100%;
   });
   .xs-block({
      height: 41px;
   })
 }
.choose__block_photo {
   grid-column: ~"6 / 13";
   grid-row: ~"1 / 3";
}
.choose__block_photo {
   .u-row-flex(28px);
   .justify-end();
   .lg-block({
      .u-row-flex(20px);
   });
   .middle-block({
   });
   .sm-block({
      .u-row-flex(15px);
   })
}
.choose__manager {
   .u-col(28px);
   .u-size(6, 24, 28px);
   margin-bottom: 30px;
   .lg-block({
      .u-col(20px);
      .u-size(8, 24, 20px);
        margin-bottom: 20px;
   });
   .sm-block({
      .u-col(15px);
      .u-size(5, 24, 15px);
        margin-bottom: 15px;
   });
   min-width: 100px;
   min-height: 100px;
}
.choose__manager.first-of-type .choose__manager_name {
   margin-top: 8px;
}
.choose__manager.first-of-type .choose__manager_image img {
   position: relative;
   z-index: 52;
}
.choose__manager.first-of-type .choose__manager_image {
   position: relative;
   z-index: 50;
   .flex-center();
   .xs-block({
      padding-right: 17px;
      padding-top: 6px;
   })
}
.choose__manager.first-of-type .choose__manager_image:before {
   .pseudo();
   z-index: 50;
   top: -6px;
   right: -6px;
   bottom: -6px;
   left: -6px;
   height: calc(100% + 12px);
   width: calc(100% + 12px);
   border-radius: 100px;
   .stroke-img();
   .middle-block({
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      height: calc(100% + 8px);
      width: calc(100% + 8px);
   });
   .xs-block({
      // top: -6px;
      //right: -6px;
      //bottom: -6px;
      //left: -6px;
      //height: calc(100% + 12px);
      //width: calc(100% + 12px);
   });
   
}
.choose__manager_name {
   text-align: center;
   .montserrat-semibold();
   color: @color-blue;
   font-size: 16px;
   line-height: 120%;
   margin-top: 5px;
   .lg-block({
      margin-top: 5px;
      font-size: 14px;
   });
   .sm-block({
      margin-top: 10px;
   })
}

@media screen and (max-width: @break_middle) {
   .choose__wrapper {
      padding: 30px 10px 10px 30px;
   }
   .choose__block_photo {
      .justify-center();
   }
   .choose__manager {
      .u-size(8, 48, 10px);
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
      .column-center();
      img {
         max-width: 87px;
         width: 87px;
         height: 87px;
      }
         min-width: 90px;
         min-height: 90px;
   }
   .choose__block_text {
      min-width: unset;
      width: 290px;
      .text-14 {
         line-height: 130%;
      }
   }
   .choose__manager_name {
      margin-top: 3px;
   }
   .choose__container .button-yellow {
      width: 235px;
      margin-bottom: 20px;
   }
}

@media screen and (max-width: 760px) {
.choose__manager {
      max-width: unset;
   }
   .choose__wrapper {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: repeat(3, auto);
      grid-column-gap: 0;
      grid-row-gap: 25px;
      padding: 40px;
   }
   .choose__block_text {
      grid-column: ~"1 / 2";
      grid-row: ~"1 / 2";
      
      min-width: unset;
      width: unset;
      margin-bottom: 20px;
   }
   .choose__container .button-yellow {
       grid-column: ~"1 / 2";
       grid-row: ~"3 / 4";
       margin-top: auto;
      margin-bottom: 0;
      justify-self: center;
    }
   .choose__block_photo {
      grid-column: ~"1 / 2";
      grid-row: ~"2 / 3";
   }
   .choose__block_photo {
      .justify-center();
   }
   .choose__manager {
      .u-col(20px);
      .u-size(5, 24, 20px);
        margin-bottom: 20px;
   }
}
@media screen and (max-width: 591px) {
   .choose__wrapper {
      display: block;
      max-width: 100%;
      overflow: hidden;
   }
   .choose__manager {
      max-width: unset;
   }
   .choose__manager_image {
      .flex();
      .justify-center();
      max-height: 100%;
      max-width: unset;
      width: auto;
   }
   .choose__block_photo {
      //.column-center();
      max-height: unset;
      overflow: hidden;
   }
   .choose__block_photo .choose__manager_image {
      .flex();
      .justify-center();
      padding-top: 10px;
   }
    .choose__block_photo .choose__manager_image img {
      width: 128px;
      height: 128px;
      max-width: unset;
   }
   .choose__block_photo .choose__manager.first-of-type .choose__manager_image img{
      width: 128px;
   }
   
   .choose__block_photo .choose__manager.first-of-type .choose__manager_image:before {
      width: 166px;
      height: 140px;
      top: unset;
      right: unset;
      bottom: -6px;
      left: unset;
   }
   .choose__block_text {
      margin-bottom: 10px;
   }
   .choose__container .button-yellow {
      width: 100%;
      margin-top: 10px;
      .xs-block({
      
      });
    }
}
.choose__container .slick-arrow {
   position: absolute;
   z-index: 70;
   top: 50%;
   transform: translateY(-74%);
   color: transparent;
   font-size: 0;
   line-height: 0;
}
.choose__container .slick-prev[aria-disabled="false"] {
   left: 6px;
   .arrow-prev-active();
}
.choose__container .slick-next[aria-disabled="false"] {
   right: 6px;
   .arrow-next-active();
}

.choose__container .slick-next[aria-disabled="true"] {
   right: 6px;
   .arrow-next()
}
.choose__container .slick-prev[aria-disabled="true"] {
   left: 6px;
   .arrow-prev()
}
