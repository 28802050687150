.social__holder {
   .flex();
}

.social__icon {
   cursor: pointer;
}

.header__social {
   margin-right: 20px;
}


.header__contact { // del

}
.contact__number {
   .montserrat-bold();
   font-size: 18px;
   margin-bottom: 0;
   color: @color-blue;
}
.contacts__text {
   .montserrat-semibold();
   font-size: 10px;
   line-height: 100%;
   letter-spacing: 1px;
   color: @color-gray;
   text-transform: uppercase;
   cursor: pointer;
}

