.personal_account__setting {

}
.personal_account__setting .account__title {
   margin-bottom: 20px;
   .sm-block({
      margin-bottom: 10px;
   });
}
.personal_account__setting .personal_account__checkbox.mb {
   margin-bottom: 16px;
   .sm-block({
      margin-bottom: 8px;
   });
}
.personal_account__setting .title-mt {
   margin-top: 30px;
   .sm-block({
      margin-top: 20px;
   });
}
.personal_account__bottom {
   border-bottom: 1px solid @color-gray-light;
   padding-bottom: 30px;
   .sm-block({
      padding-bottom: 20px;
   });
}
.personal_account__password {
   .gr();
   grid-template-columns: repeat(2, 1fr);
   grid-column-gap: 10px;
   .sm-block({
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 10px;
   })
   
}
