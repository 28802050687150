/* компания предлогает и всякое такое */
.variety_products__container {
   margin-top: 80px;
   margin-bottom: 80px;
   .sm-block({
      margin-top: 40px;
      margin-bottom: 40px;
   });
   .sm-block({
       margin-top: 32px;
      br {
         display: none !important;
      }
   });
   
   .wrapper {
      .to( 485px, {
         padding-left: 5px;
         padding-right: 5px !important;
      });
      .xxs-block({
          padding-left: 15px !important;
         
         padding-right: 5px !important;
      });
   }
   .slick-list {
      .to( 485px, {
         //padding-left: 30px !important;
         //padding-right: 30px !important;
         padding-left: 0 !important;
         padding-right: 130px !important;
         margin-left: 25px !important;
      });
      .xxs-block({
         padding-left: 0 !important;
         padding-right: 110px !important;
         margin-left: 15px !important;
      });
   }
   .section__caption {
      .to(485px, {
         padding-left: 30px;
         margin-left: 23px !important;
      });
      .xxs-block({
         margin-left: 15px !important;
      });
   }
   
   br {
      .none()
   }
   .variety_products_image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
   }
}
.variety_products__container {
   .relation_content {
      border-radius: 10px;
      overflow: hidden;
   }
   .text-18-title {
      margin-top: 16px;
      .lg-block({
         margin-top: 10px;
      })
   }
   .text-14 {
      display: inline-block;
      width: 90%;
      margin-top: 6px;
      .md-block({
         display: none;
      })
   }
}
.variety_products__container .variety_products__list {
   display: grid;
   margin-top: 40px;
   grid-column-gap: 20px;
   grid-row-gap: 28px;
   
   grid-template-columns: repeat(4, 1fr);
   grid-template-rows: 1fr auto auto 1fr;
   
  & > article {
      .to( 485px, {
         max-width: 475px;
      });
  }
    .xs-block({
        //max-width: 215px;
        //max-height: 193px;
     });

}
.variety_products__block-square {
   .from( @break_ll, {
      grid-column: span 1;
      grid-row: span 1;
   });
   .xs-block({
         /*max-width: 215px;
         max-height: 193px;
      [class^='image__'] {
         max-width: 215px;
         max-height: 193px;
      }*/
   });
}
.variety_products__block-rectangle {
   .from( @break_ll, {
      grid-column: span 2;
      grid-row: span 1;
   });
   .xs-block({
        /* max-width: 215px;
         max-height: 193px;
      [class^='image__'] {
         max-width: 215px;
         max-height: 193px;
      }*/
   });
}
.variety_products__container h3.caption.text-blue.second {
    .none();
}
.variety_product-7,
.variety_product-8 {
   margin-top: 10px;
   .xs-block({
      margin-top: 0;
   });
}

.variety_product-9,
.variety_product-10,
.variety_product-11,
.variety_product-12  {
   margin-top: 12px;
}
@media screen and (max-width: @break_ll) {
   .variety_products__container .variety_products__list {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(6, auto);
      
       .variety_products_image {
         border-radius: 10px;
         overflow: hidden;
      }
      .relation-rectangle {
         //padding-top: 47.6%;
         padding-top: 46.3%;
      }
   }
   .variety_products__block-square {
      &.variety_product-1 {  grid-column: ~"1 / 2";  }
      &.variety_product-2 {  grid-column: ~"2 / 3";  }
      &.variety_product-3 { grid-column: ~"3 / 4"; }
      &.variety_product-1,
      &.variety_product-2,
      &.variety_product-3 {
         grid-column: span 1;
         grid-row: ~"2 / 3";
      }
      &.variety_product-4 {
         grid-column: span 1;
         grid-row: ~"3 / 4";
      }
      
      &.variety_product-9 {
         grid-column: span 1;
         grid-row: ~"6 / 7";
         margin-top: 10px;
         .relation-square {
            padding-top: 96.4%;
         }
      }
      &.variety_product-10,
      &.variety_product-11 {
         grid-column: span 1;
         grid-row: ~"5 / 6";
      }
      &.variety_product-12 {
         grid-column: span 1;
         grid-row: ~"5 / 6";
      }
   }

   .variety_products__block-rectangle {
      grid-column: unset;
      grid-row:unset;
 
      &.variety_product-5 {
         grid-column: ~"1 / 3";
         grid-row: ~"3 / 4";
      }
      &.variety_product-6 {
         grid-column: ~"1 / 4";
         grid-row: ~"4 / 5";
      }
      &.variety_product-7 {
         grid-column: ~"1 / 4";
         grid-row: ~"7 / 8";
      }
      &.variety_product-8 {
         grid-column: ~"1 / 3";
         grid-row: ~"6 / 7";
      }
      &.variety_product-6,
      &.variety_product-7 {
         .relation-rectangle {
            padding-top: 31.4%;
         }
      }

   }
}
@media screen and (max-width: @break_middle) {
   .variety_products__container .variety_products__list {
      margin-top: 0;
      grid-row-gap: 10px;
   }
   .variety_products__container br {
      .block()
   }
   .variety_products__container .section__caption {
      margin-bottom: 20px;
   }
   .variety_products__block-square {
      &.variety_product-9 {
         .relation-square {
            padding-top: 97.4%;
         }
      }
   }
}
@media screen and (max-width: 760px) {
   .variety_products__container h3.caption.text-blue {
      display: none;
   }
   .variety_products__container h3.caption.text-blue.second {
      display: block;
   }
}
@media screen and (max-width: @break_md) {
   .variety_products__block-square {
      &.variety_product-9 {
         .relation-square {
            padding-top: 100%;
         }
      }
   }
}
@media screen and (max-width: @break_sm) {
   .variety_products__container .variety_products__list {
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(10, auto);
      margin-top: 20px;
      
      .variety_products_image {
         border-radius: 10px;
         overflow: hidden;
      }
      div[class^='variety_product-'] {
         display: block;
      }
   }
    .variety_products__block-square {
      &.variety_product-1 { grid-column: ~"1 / 2"; }
      &.variety_product-3 { grid-column: ~"2 / 3"; }
      &.variety_product-1,
      &.variety_product-3 {
         grid-row: ~"2 / 3";
      }
      &.variety_product-2 { grid-column: ~"1 / 2"; }
      &.variety_product-2 { grid-column: ~"2 / 3"; }
      &.variety_product-2,
      &.variety_product-4 {
         grid-row: ~"3 / 4";
      }
      
      &.variety_product-9 { grid-column: ~"1 / 2"; }
      &.variety_product-10 { grid-column: ~"2 / 3"; }
      &.variety_product-9,
      &.variety_product-10 {
         grid-row: ~"6 / 7";
      }
      &.variety_product-11 { grid-column: ~"1 / 2"; }
      &.variety_product-12 { grid-column: ~"2 / 3"; }
      &.variety_product-11,
      &.variety_product-12 {
         grid-row: ~"7 / 8";
      }
   }

   .variety_products__block-rectangle {
      &.variety_product-5 {
         grid-column: ~"1 / 3";
         grid-row: ~"1 / 2";
      }
      &.variety_product-6 {
         grid-column: ~"1 / 3";
         grid-row: ~"4 / 5";
      }
      &.variety_product-7 {
         grid-column: ~"1 / 3";
         grid-row: ~"5 / 6";
      }
      &.variety_product-8 {
         grid-column: ~"1 / 3";
         grid-row: ~"8 / 9";
      }
   }
   .variety_products__container .variety_products__list {
      .good-friendly-arrows();
   }
}

@media screen and (max-width: 485px) {
   .variety_products__container .variety_products__list {
      display: block;
      .relation-rectangle,
      .relation-square {
         padding-top: 89.8%;
      }
   }
   .variety_products__container .section__caption {
      padding-left: 30px;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: repeat( 2, auto);
      .section-title-text-14 {
         margin-bottom: 6px;
         grid-column: span 1;
         grid-row: ~"1 / 2";
      }
      .caption {
         grid-column: span 1;
         grid-row: ~"2 / 3";
      }
   }
   .variety_product-9, .variety_product-10, .variety_product-11, .variety_product-12 {
      margin-top: 0;
   }
}

@media screen and (max-width: @break_xs) {
   .variety_products__container .variety_products__list {
      display: block;
      .relation-rectangle,
      .relation-square {
         padding-top: 90.8%;
         height: 25px;
         min-height: 25px;
         min-width: 140px;
      }
   }
   .variety_products__container .section__caption {
      padding-left: 0;
   }
}
