@columns: 24;
@atom: (100% / @columns);

@break_xxl: 1235px;
@break_xl: 1190px;
@break_ll: 970px;
@break_lg: 890px;
@break_middle: 768px;
@break_md: 660px;
@break_sm: 590px;
@break_xs: 470px;
@break_xxs: 370px;
@break_tiny: 320px;

@offset: 20px;
@offset_xxl: @offset;
@offset_xl: @offset;
@offset_ll: @offset;
@offset_lg: @offset;
@offset_middle: @offset;
@offset_md: @offset;
@offset_sm: @offset;
@offset_xs: @offset;
@offset_xxs: 10px;
@offset_tiny: @offset_xxs;

@offset_one_side: (@offset / 2);
@offset_xxl_one_side: (@offset_xxl / 2);
@offset_xl_one_side: (@offset_xl / 2);
@offset_ll_one_side: (@offset_ll / 2);
@offset_lg_one_side: (@offset_lg / 2);
@offset_middle_one_side: (@offset_middle / 2);
@offset_md_one_side: (@offset_md / 2);
@offset_sm_one_side: (@offset_sm / 2);
@offset_xs_one_side: (@offset_xs / 2);
@offset_xxs_one_side: (@offset_xxs / 2);
@offset_tiny_one_side: (@offset_tiny / 2);

@fields: 0;
@fields_xxl: 60px;
@fields_middle: 42px;
@fields_md: 30px;
@fields_xxs: 16px;

.wrapper-full(){
    padding-left: @fields;
    padding-right: @fields;

    .xxl-block({
        padding-left: @fields_xxl;
        padding-right: @fields_xxl;
    });

    .middle-block({
        padding-left: @fields_middle;
        padding-right: @fields_middle;
    });

    .md-block({
        padding-left: @fields_md;
        padding-right: @fields_md;
    });

    .xxs-block({
        padding-left: @fields_xxs;
        padding-right: @fields_xxs;
    });
}

.row-offsets(){
    margin-left: (@offset_one_side * -1);
    margin-right: (@offset_one_side * -1);

    .xxs-block({
        margin-left: (@offset_xxs_one_side * -1);
        margin-right: (@offset_xxs_one_side * -1);
    });
}

.col-offsets(@type){
    @{type}-left: @offset_one_side;
    @{type}-right: @offset_one_side;

    .xxs-block({
        @{type}-left: @offset_xxs_one_side;
        @{type}-right: @offset_xxs_one_side;
    });
}

.wrapper(){
    max-width: 1120px;
    margin: 0 auto;
    .wrapper-full();
}

.row-flex(){
    display: flex;
    flex-wrap: wrap;
    .row-offsets();
}

.row-float(){
    .row-offsets();
    .clearfix();
}

.row-ib(){
    .row-offsets();
}

.col(){
    box-sizing: border-box;
    word-wrap: break-word;
    .col-offsets(margin);
}

.col-float(){
    float: left;
}

.col-ib(){
    display: inline-block;
    vertical-align: top;
}

.col-padding(){
    word-wrap: break-word;
    .col-offsets(padding);
}

.size(@n){
    @val: 100% / @columns * @n;
    width: ~"calc(@{val} - @{offset})";
}

.size-xxl(@n){
    @val: 100% / @columns * @n;
    .xxl(width, ~"calc(@{val} - @{offset_xxl})");
}

.size-xl(@n){
    @val: 100% / @columns * @n;
    .xl(width, ~"calc(@{val} - @{offset_xl})");
}

.size-ll(@n){
    @val: 100% / @columns * @n;
    .ll(width, ~"calc(@{val} - @{offset_ll})");
}

.size-lg(@n){
    @val: 100% / @columns * @n;
    .lg(width, ~"calc(@{val} - @{offset_lg})");
}

.size-middle(@n){
    @val: 100% / @columns * @n;
    .middle(width, ~"calc(@{val} - @{offset_middle})");
}

.size-md(@n){
    @val: 100% / @columns * @n;
    .md(width, ~"calc(@{val} - @{offset_md})");
}

.size-sm(@n){
    @val: 100% / @columns * @n;
    .sm(width, ~"calc(@{val} - @{offset_sm})");
}

.size-xs(@n){
    @val: 100% / @columns * @n;
    .xs(width, ~"calc(@{val} - @{offset_xs})");
}

.size-xxs(@n){
    @val: 100% / @columns * @n;
    .xxs(width, ~"calc(@{val} - @{offset_xxs})");
}

.size-tiny(@n){
    @val: 100% / @columns * @n;
    .tiny(width, ~"calc(@{val} - @{offset_tiny})");
}

.shift(@n){
    @val: 100% / @columns * @n;
    margin-left: ~"calc(@{val} + @{offset_one_side})";
    margin-right: ~"calc(@{val} + @{offset_one_side})";
}

.shift-left(@n){
    @val: 100% / @columns * @n;
    margin-left: ~"calc(@{val} + @{offset_one_side})";
}

.shift-right(@n){
    @val: 100% / @columns * @n;
    margin-right: ~"calc(@{val} + @{offset_one_side})";
}

.shift-padding(@n){
    @val: 100% / @columns * @n;
    padding-left: ~"calc(@{val} + @{offset_one_side})";
    padding-right: ~"calc(@{val} + @{offset_one_side})";
}

.shift-padding-left(@n){
    @val: 100% / @columns * @n;
    padding-left: ~"calc(@{val} + @{offset_one_side})";
}

.shift-padding-right(@n){
    @val: 100% / @columns * @n;
    padding-right: ~"calc(@{val} + @{offset_one_side})";
}

.shift-xxl(@n){
    @val: 100% / @columns * @n;
    .xxl(margin-left, ~"calc(@{val} + @{offset_xxl_one_side})");
    .xxl(margin-right, ~"calc(@{val} + @{offset_xxl_one_side})");
}

.shift-xxl-left(@n){
    @val: 100% / @columns * @n;
    .xxl(margin-left, ~"calc(@{val} + @{offset_xxl_one_side})");
}

.shift-xxl-right(@n){
    @val: 100% / @columns * @n;
    .xxl(margin-right, ~"calc(@{val} + @{offset_xxl_one_side})");
}

.shift-xxl-padding(@n){
    @val: 100% / @columns * @n;
    .xxl(padding-left, ~"calc(@{val} + @{offset_xxl_one_side})");
    .xxl(padding-right, ~"calc(@{val} + @{offset_xxl_one_side})");
}

.shift-xxl-padding-left(@n){
    @val: 100% / @columns * @n;
    .xxl(padding-left, ~"calc(@{val} + @{offset_xxl_one_side})");
}

.shift-xxl-padding-right(@n){
    @val: 100% / @columns * @n;
    .xxl(padding-right, ~"calc(@{val} + @{offset_xxl_one_side})");
}

.shift-xl(@n){
    @val: 100% / @columns * @n;
    .xl(margin-left, ~"calc(@{val} + @{offset_xl_one_side})");
    .xl(margin-right, ~"calc(@{val} + @{offset_xl_one_side})");
}

.shift-xl-left(@n){
    @val: 100% / @columns * @n;
    .xl(margin-left, ~"calc(@{val} + @{offset_xl_one_side})");
}

.shift-xl-right(@n){
    @val: 100% / @columns * @n;
    .xl(margin-right, ~"calc(@{val} + @{offset_xl_one_side})");
}

.shift-xl-padding(@n){
    @val: 100% / @columns * @n;
    .xl(padding-left, ~"calc(@{val} + @{offset_xl_one_side})");
    .xl(padding-right, ~"calc(@{val} + @{offset_xl_one_side})");
}

.shift-xl-padding-left(@n){
    @val: 100% / @columns * @n;
    .xl(padding-left, ~"calc(@{val} + @{offset_xl_one_side})");
}

.shift-xl-padding-right(@n){
    @val: 100% / @columns * @n;
    .xl(padding-right, ~"calc(@{val} + @{offset_xl_one_side})");
}

.shift-ll(@n){
    @val: 100% / @columns * @n;
    .ll(margin-left, ~"calc(@{val} + @{offset_ll_one_side})");
    .ll(margin-right, ~"calc(@{val} + @{offset_ll_one_side})");
}

.shift-ll-left(@n){
    @val: 100% / @columns * @n;
    .ll(margin-left, ~"calc(@{val} + @{offset_ll_one_side})");
}

.shift-ll-right(@n){
    @val: 100% / @columns * @n;
    .ll(margin-right, ~"calc(@{val} + @{offset_ll_one_side})");
}

.shift-ll-padding(@n){
    @val: 100% / @columns * @n;
    .ll(padding-left, ~"calc(@{val} + @{offset_ll_one_side})");
    .ll(padding-right, ~"calc(@{val} + @{offset_ll_one_side})");
}

.shift-ll-padding-left(@n){
    @val: 100% / @columns * @n;
    .ll(padding-left, ~"calc(@{val} + @{offset_ll_one_side})");
}

.shift-ll-padding-right(@n){
    @val: 100% / @columns * @n;
    .ll(padding-right, ~"calc(@{val} + @{offset_ll_one_side})");
}

.shift-lg(@n){
    @val: 100% / @columns * @n;
    .lg(margin-left, ~"calc(@{val} + @{offset_lg_one_side})");
    .lg(margin-right, ~"calc(@{val} + @{offset_lg_one_side})");
}

.shift-lg-left(@n){
    @val: 100% / @columns * @n;
    .lg(margin-left, ~"calc(@{val} + @{offset_lg_one_side})");
}

.shift-lg-right(@n){
    @val: 100% / @columns * @n;
    .lg(margin-right, ~"calc(@{val} + @{offset_lg_one_side})");
}

.shift-lg-padding(@n){
    @val: 100% / @columns * @n;
    .lg(padding-left, ~"calc(@{val} + @{offset_lg_one_side})");
    .lg(padding-right, ~"calc(@{val} + @{offset_lg_one_side})");
}

.shift-lg-padding-left(@n){
    @val: 100% / @columns * @n;
    .lg(padding-left, ~"calc(@{val} + @{offset_lg_one_side})");
}

.shift-lg-padding-right(@n){
    @val: 100% / @columns * @n;
    .lg(padding-right, ~"calc(@{val} + @{offset_lg_one_side})");
}

.shift-middle(@n){
    @val: 100% / @columns * @n;
    .middle(margin-left, ~"calc(@{val} + @{offset_middle_one_side})");
    .middle(margin-right, ~"calc(@{val} + @{offset_middle_one_side})");
}

.shift-middle-left(@n){
    @val: 100% / @columns * @n;
    .middle(margin-left, ~"calc(@{val} + @{offset_middle_one_side})");
}

.shift-middle-right(@n){
    @val: 100% / @columns * @n;
    .middle(margin-right, ~"calc(@{val} + @{offset_middle_one_side})");
}

.shift-middle-padding(@n){
    @val: 100% / @columns * @n;
    .middle(padding-left, ~"calc(@{val} + @{offset_middle_one_side})");
    .middle(padding-right, ~"calc(@{val} + @{offset_middle_one_side})");
}

.shift-middle-padding-left(@n){
    @val: 100% / @columns * @n;
    .middle(padding-left, ~"calc(@{val} + @{offset_middle_one_side})");
}

.shift-middle-padding-right(@n){
    @val: 100% / @columns * @n;
    .middle(padding-right, ~"calc(@{val} + @{offset_middle_one_side})");
}

.shift-md(@n){
    @val: 100% / @columns * @n;
    .md(margin-left, ~"calc(@{val} + @{offset_md_one_side})");
    .md(margin-right, ~"calc(@{val} + @{offset_md_one_side})");
}

.shift-md-left(@n){
    @val: 100% / @columns * @n;
    .md(margin-left, ~"calc(@{val} + @{offset_md_one_side})");
}

.shift-md-right(@n){
    @val: 100% / @columns * @n;
    .md(margin-right, ~"calc(@{val} + @{offset_md_one_side})");
}

.shift-md-padding(@n){
    @val: 100% / @columns * @n;
    .md(padding-left, ~"calc(@{val} + @{offset_md_one_side})");
    .md(padding-right, ~"calc(@{val} + @{offset_md_one_side})");
}

.shift-md-padding-left(@n){
    @val: 100% / @columns * @n;
    .md(padding-left, ~"calc(@{val} + @{offset_md_one_side})");
}

.shift-md-padding-right(@n){
    @val: 100% / @columns * @n;
    .md(padding-right, ~"calc(@{val} + @{offset_md_one_side})");
}

.shift-sm(@n){
    @val: 100% / @columns * @n;
    .sm(margin-left, ~"calc(@{val} + @{offset_sm_one_side})");
    .sm(margin-right, ~"calc(@{val} + @{offset_sm_one_side})");
}

.shift-sm-left(@n){
    @val: 100% / @columns * @n;
    .sm(margin-left, ~"calc(@{val} + @{offset_sm_one_side})");
}

.shift-sm-right(@n){
    @val: 100% / @columns * @n;
    .sm(margin-right, ~"calc(@{val} + @{offset_sm_one_side})");
}

.shift-sm-padding(@n){
    @val: 100% / @columns * @n;
    .sm(padding-left, ~"calc(@{val} + @{offset_sm_one_side})");
    .sm(padding-right, ~"calc(@{val} + @{offset_sm_one_side})");
}

.shift-sm-padding-left(@n){
    @val: 100% / @columns * @n;
    .sm(padding-left, ~"calc(@{val} + @{offset_sm_one_side})");
}

.shift-sm-padding-right(@n){
    @val: 100% / @columns * @n;
    .sm(padding-right, ~"calc(@{val} + @{offset_sm_one_side})");
}

.shift-xs(@n){
    @val: 100% / @columns * @n;
    .xs(margin-left, ~"calc(@{val} + @{offset_xs_one_side})");
    .xs(margin-right, ~"calc(@{val} + @{offset_xs_one_side})");
}

.shift-xs-left(@n){
    @val: 100% / @columns * @n;
    .xs(margin-left, ~"calc(@{val} + @{offset_xs_one_side})");
}

.shift-xs-right(@n){
    @val: 100% / @columns * @n;
    .xs(margin-right, ~"calc(@{val} + @{offset_xs_one_side})");
}

.shift-xs-padding(@n){
    @val: 100% / @columns * @n;
    .xs(padding-left, ~"calc(@{val} + @{offset_xs_one_side})");
    .xs(padding-right, ~"calc(@{val} + @{offset_xs_one_side})");
}

.shift-xs-padding-left(@n){
    @val: 100% / @columns * @n;
    .xs(padding-left, ~"calc(@{val} + @{offset_xs_one_side})");
}

.shift-xs-padding-right(@n){
    @val: 100% / @columns * @n;
    .xs(padding-right, ~"calc(@{val} + @{offset_xs_one_side})");
}

.shift-xxs(@n){
    @val: 100% / @columns * @n;
    .xxs(margin-left, ~"calc(@{val} + @{offset_xxs_one_side})");
    .xxs(margin-right, ~"calc(@{val} + @{offset_xxs_one_side})");
}

.shift-xxs-left(@n){
    @val: 100% / @columns * @n;
    .xxs(margin-left, ~"calc(@{val} + @{offset_xxs_one_side})");
}

.shift-xxs-right(@n){
    @val: 100% / @columns * @n;
    .xxs(margin-right, ~"calc(@{val} + @{offset_xxs_one_side})");
}

.shift-xxs-padding(@n){
    @val: 100% / @columns * @n;
    .xxs(padding-left, ~"calc(@{val} + @{offset_xxs_one_side})");
    .xxs(padding-right, ~"calc(@{val} + @{offset_xxs_one_side})");
}

.shift-xxs-padding-left(@n){
    @val: 100% / @columns * @n;
    .xxs(padding-left, ~"calc(@{val} + @{offset_xxs_one_side})");
}

.shift-xxs-padding-right(@n){
    @val: 100% / @columns * @n;
    .xxs(padding-right, ~"calc(@{val} + @{offset_xxs_one_side})");
}

.shift-tiny(@n){
    @val: 100% / @columns * @n;
    .tiny(margin-left, ~"calc(@{val} + @{offset_tiny_one_side})");
    .tiny(margin-right, ~"calc(@{val} + @{offset_tiny_one_side})");
}

.shift-tiny-left(@n){
    @val: 100% / @columns * @n;
    .tiny(margin-left, ~"calc(@{val} + @{offset_tiny_one_side})");
}

.shift-tiny-right(@n){
    @val: 100% / @columns * @n;
    .tiny(margin-right, ~"calc(@{val} + @{offset_tiny_one_side})");
}

.shift-tiny-padding(@n){
    @val: 100% / @columns * @n;
    .tiny(padding-left, ~"calc(@{val} + @{offset_tiny_one_side})");
    .tiny(padding-right, ~"calc(@{val} + @{offset_tiny_one_side})");
}

.shift-tiny-padding-left(@n){
    @val: 100% / @columns * @n;
    .tiny(padding-left, ~"calc(@{val} + @{offset_tiny_one_side})");
}

.shift-tiny-padding-right(@n){
    @val: 100% / @columns * @n;
    .tiny(padding-right, ~"calc(@{val} + @{offset_tiny_one_side})");
}

.xxl(@name, @value){
    @media screen and (max-width: @break_xxl){
        @{name}: @value;
    }
}

.xxl-block(@content){
    @media screen and (max-width: @break_xxl){
        @content();
    }
}

.xl(@name, @value){
    @media screen and (max-width: @break_xl){
        @{name}: @value;
    }
}

.xl-block(@content){
    @media screen and (max-width: @break_xl){
        @content();
    }
}

.ll(@name, @value){
    @media screen and (max-width: @break_ll){
        @{name}: @value;
    }
}

.ll-block(@content){
    @media screen and (max-width: @break_ll){
        @content();
    }
}

.lg(@name, @value){
    @media screen and (max-width: @break_lg){
        @{name}: @value;
    }
}

.lg-block(@content){
    @media screen and (max-width: @break_lg){
        @content();
    }
}

.middle(@name, @value){
    @media screen and (max-width: @break_middle){
        @{name}: @value;
    }
}

.middle-block(@content){
    @media screen and (max-width: @break_middle){
        @content();
    }
}

.md(@name, @value){
    @media screen and (max-width: @break_md){
        @{name}: @value;
    }
}

.md-block(@content){
    @media screen and (max-width: @break_md){
        @content();
    }
}

.sm(@name, @value){
    @media screen and (max-width: @break_sm){
        @{name}: @value;
    }
}

.sm-block(@content){
    @media screen and (max-width: @break_sm){
        @content();
    }
}

.xs(@name, @value){
    @media screen and (max-width: @break_xs){
        @{name}: @value;
    }
}

.xs-block(@content){
    @media screen and (max-width: @break_xs){
        @content();
    }
}

.xxs(@name, @value){
    @media screen and (max-width: @break_xxs){
        @{name}: @value;
    }
}

.xxs-block(@content){
    @media screen and (max-width: @break_xxs){
        @content();
    }
}

.tiny(@name, @value){
    @media screen and (max-width: @break_tiny){
        @{name}: @value;
    }
}

.tiny-block(@content){
    @media screen and (max-width: @break_tiny){
        @content();
    }
}

.from(@min_width, @content){
    @media screen and (min-width: @min_width){
        @content();
    }
}

.to(@max_width, @content){
    @media screen and (max-width: @max_width){
        @content();
    }
}

.from-to(@min_width, @max_width, @content){
    @media screen and (min-width: @min_width) and (max-width: @max_width){
        @content();
    }
}

.u-row-flex(@offset){
    display: flex;
    flex-wrap: wrap;
    margin-left: (@offset / -2);
    margin-right: (@offset / -2);
}

.u-col(@offset){
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: (@offset / 2);
    margin-right: (@offset / 2);
}

.u-size(@n, @columns, @offset){
    width: ~"calc(100% / @{columns} * @{n} - @{offset})";
}

.reset(){
    body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,input,textarea,p,blockquote,th,td{
        margin: 0;
        padding: 0;
    }
    table{
        border-collapse: collapse;
        border-spacing: 0;
    }
    fieldset,img,abbr{
        border: 0;
    }
    address,caption,cite,code,dfn,em,strong,th,var{
        font-style: normal;
        font-weight: normal;
    }
    ul li{
        list-style: none;
    }
    caption,th{
        text-align: left;
    }
    h1,h2,h3,h4,h5,h6{
        font-size: 100%;
        font-weight: normal;
    }
    sup{
        vertical-align: text-top;
    }
    sub{
        vertical-align: text-bottom;
    }
    input,textarea,select{
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
    legend{
        color: #000;
    }
    article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main{
        display: block;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

.debug(@background, @outline){
    height: 100vh;
    position: fixed;
    width: 100%;
    
    > div{
        height: inherit;
        .wrapper();
        
        > div{
            height: inherit;
            .row-flex();
            
            > div{
                .col();
                .size(1);
                .size-xxs(1);
                height: inherit;
                outline: @outline;
                background: @background;
            }
        }
    }
}

.clearfix(){
    &::after{
        display: block;
        content: "";
        clear: both;
    }
}