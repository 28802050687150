.container_main {
   margin-top: 20px;
   .wrapper {
      .sm-block({
         padding-left: 5px;
         padding-right: 5px !important;
      });
      .xxs-block({
          padding-left: 15px !important;
         padding-right: 5px !important;
      });
   }
   .slick-list {
      .sm-block({
         padding-left: 30px !important;
         padding-right: 30px !important;
      });
      .xxs-block({
         padding-left: 0 !important;
         padding-right: 15px !important;
      });
   }
   .slick-prev.slick-arrow,
   .slick-next.slick-arrow {
      position: absolute;
      transform: none;
      z-index: 100;
      top: 0;
      bottom: 0;
      width: 20%;
      height: 100%;
      color: transparent;
      font-size: 1px;
      line-height: 0;
   }
   .slick-prev.slick-arrow {
      height: 50%;
      left: 0;
      bottom: 50%;
   }
   .slick-next.slick-arrow {
      right: 0;
   }

}
.carousel__holder .carousel__item {
   min-height: 400px;
   .middle-block({
      min-height: 340px;
   });
   .sm-block({
      min-height: 265px;
   });
}
.carousel__item_inner {
   overflow: hidden;
   position: relative;
   min-height: 100%;
   border-radius: 20px;
   padding: 40px 40px 25px;
   box-sizing: border-box;
   .xl-block({
      padding: 35px 35px 25px;
   });
   .middle-block({
      border-radius: 10px;
      padding: 30px 30px 25px;
   });
   .sm-block({
      border-radius: 10px;
      padding: 15px 15px 40px;
   });
}
.carousel__item_text {
   position: relative;
   z-index: 150;
   
   .button-yellow {
      position: relative;
      z-index: 200;
   }
}
.carousel__item_text h2.text-16.text-white {
   margin-top: 18px;
   margin-bottom: 30px;
   .lg-block({
      margin-top: 10px;
      margin-bottom: 20px;
   });
   .sm-block({
      font-size: 16px;
      margin-top: 5px;
      margin-bottom: 10px;
      line-height: 17px;
   })
}
.carousel__item_inner:before {
   .pseudo();
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   .lines2();
}
.carousel__item_inner {
   position: relative;
}
.carousel__item_inner .carousel__item_img {
   position: absolute;
   display: block;
   z-index: 100;
   right: 0;
   bottom: 0;
   width: 60%;
   min-width: 95px;
   height: 100%;
   .middle-block({
      width: 40%;
   });
   .md-block({
      width: 35%;
   });
   background-repeat: no-repeat;
   background-size: contain;
   background-position: bottom right;
}
.carousel__item-1 {
    background-color: @color-green-dark;
   .carousel__item_img {
      min-width: 126px;
      //.carousel__item-1-img()
   }
}
.carousel__item-2 {
    background-color: @color-blue-light;
   .carousel__item_img {
      //.carousel__item-2-img()
   }
}
.carousel__item-3 {
    background-color: @color-orange;
   .carousel__item_img {
      //.carousel__item-3-img();
         .middle-block({
            width: 45%;
         });
   }
}

.carousel__item h1.caption {
   width: 58%;
}
.carousel__item-1 h1.caption {
   .middle-block({
      width: 80%;
   });
   .xs-block({
      width: 100%;
   });
}
.carousel__item-2 h1.caption {
   width: 80%;
   .lg-block({
      width: 75%;
   });
   .middle-block({
      width: 80%;
   });
   .middle-block({
      width: 100%;
   })
}
.carousel__item-3 h1.caption {
   .middle-block({
      width: 100%;
   })
}











.slick-track {
    display: flex !important;
}
.slick-slide {
    height: inherit !important;
}


.slick-dots {
   list-style: none;
   .flex-center();
   width: 100%;
   position: absolute;
   bottom: 10px;
   .middle-block({
      bottom: 8px
   });
   li {
      position: relative;
      display: inline-block;
         height: 16px;
         width: 20px;
      margin: 0 1px;
      padding: 4px;
      box-sizing: border-box;
      cursor: pointer;
   
      button {
         display: block;
         height: 8px;
         width: 8px;
         background-color: rgba(255, 255, 255, 0.3);
         border-radius: 50px;
         outline: none;
         cursor: pointer;
         
         color: transparent;
         font-size: 0;
      
         &:hover, &:focus {
            outline: none;
         }
      }
   }
   .slick-active button {
      background-color: white;
   }
}

.relation_carousel {
   height: 0;
   padding-top: 35.7%;
}
