.filters_mobile__container {
   position: fixed;
   z-index: 2600;
   top: 0;
   right: -550px;
   .from(@break_second, {
      display: none;
      right: -99999999px;
   });
   
   .column();
   .justify-between();
   .items-start();
   
   
   background-color: @color-white;
   border-radius: 8px 0 8px 8px;
   min-width: 272px;
   width: calc(450px - 12vw);
   height: 85vh;
   .xs-block({
      width: 350px;
   });
   .xxs-block({
      width: 100%;
      height: 100vh;
      border-radius: 0;
   });
   
   .icon__angle {
      transform: unset;
   }
   .catalog__section-food {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
   }
}

.filters_mobile__header {
   .flex();
   .justify-between();
   width: 100%;
   border-bottom: 1px solid @color-gray-light;
   .icon__close {
      margin: 16px;
   }
}
.filters_mobile__caption {
    .montserrat-semibold();
   font-size: 18px;
   line-height: 120%;
   color: @color-blue;
   padding: 16px;
   box-sizing: border-box;
}
.filters_mobile__footer {
   .flex();
   width: 100%;
   padding: 16px;
   box-sizing: border-box;
   box-shadow: 0 0 18px 0 rgba(32, 54, 134, 0.15);
   
   button {
      width: auto;
   }
}
.filters_mobile__content {
   flex-grow: 1;
   padding: 16px;
   box-sizing: border-box;
   
   > .scroll-wrapper > .scroll-element > .scroll-element_outer > .scroll-bar {
      background-color: @opacity-blue-50;
   }
   > .scroll-wrapper .scroll-element.scroll-y {
      height: 96%;
      right: 10px;
      top: 2%;
      bottom: 2%;
      width: 4px;
   }
}
.filters_mobile__holder {
   max-height: calc(85vh - 56.8px - 120px);
   padding-right: 20px;
   box-sizing: border-box;
   .xxs-block({
      max-height: calc(100vh - 56.8px - 120px);
   });
   .catalog__section {
      min-width: 267px;
   }
   .catalog__section_caption {
      margin-right: 0;
      margin-left: 0;
      padding: 8px 8px 8px 0;
      height: auto;
      div[class^='icofish__'],
      div[class^='icofood__'] {
         width: 26px;
         height: 26px;
         padding: 0;
         margin-right: 6px;
      }
      div[class^='icofish__'] {
         margin-right: 14px;
      }
   }
   .catalog__section-name {
      .montserrat-medium();
      font-size: 14px;
      line-height: 130%;
   }
   .catalog__section-name:hover {
      .montserrat-semibold();
   }
   .catalog__articles {
      background-color: transparent;
      height: unset;
      padding-left: 32px;
      .catalog__scroll {
         height: 100% !important;
         max-height: unset !important;
      }
      .scrollbar-inner > .scroll-element.scroll-y {
         width: 4px;
         right: 8px;
      }
      .scrollbar-inner > .scroll-element_outer > .scroll-bar {
         background-color: @opacity-blue-30;
      }
   }
   .catalog__link {
      padding: 8px 4px 8px 0;
      font-size: 12px;
      line-height: 130%;
   }
   .catalog__articles .scroll-wrapper {
      margin-top: 5px;
      margin-bottom: 10px;
   }
   
   
   
   
   .catalog_main__title {
      padding: 8px 10px 8px 0;
      box-sizing: border-box;
      margin-top: 8px;
      margin-right: 8px;
   }
   .catalog_main__list_holder {
      padding-left: 8px;
   }
}
