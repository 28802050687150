/*  Страница производства  */
@break_first: 1555px;
@break_second: 1095px;
#container_production {
   .icon__angle {
      transform: rotate(0);
   }
}
#container_production .header__main.wrapper,
#container_production .header__aside .wrapper {
   .from( @break_first, {
      .wrapper-big();
   });
   .wrapper();
}
#container_production .header__aside .header__form {
   .from( @break_first, {
      height: 40px;
      max-width: 520px;
      width: 520px;
      margin-right: auto;
   });
   .to( 1825px, {
      height: 40px;
      max-width: 520px;
      width: auto;
      margin-right: auto;
   });
}
#container_production #catalog_trigger {
   .from( @break_first, {
      width: 311px;
      margin-right: auto;
   });
}
.production_caption__holder { // del
}
.production__caption {
   text-align: left;
   margin-top: 60px;
   padding-bottom: 20px;
   border-bottom: 1px dotted @opacity-blue-30;
   margin-bottom: 20px;
   
   .to( 1440px, {
      padding-bottom: 10px;
   });
   .xxl-block({
      margin-top: 40px;
   });
   .md-block({
      margin-top: 20px;
   });
   .xs-block({
      padding-bottom: 10px;
   });
   h1 {
      width: 60%;
      min-width: 780px;
      margin-right: 12px;
      .xl-block({
         min-width: 660px;
      });
      .md-block({
         min-width: unset;
         width: 80%;
      });
      .sm-block({
         width: 90%;
      });
      .xs-block({
         width: 100%;
      });
      .sm-block({
         hyphens: auto;
      });
   }
}

.production_about__wrapper {
   .gr();
   grid-template-columns: repeat(4, 1fr);
   grid-template-rows: 1fr;
   grid-column-gap: 50px;
   margin-bottom: 70px;
   .to( 1800px, {
      grid-column-gap: 20px;
   });
   .lg-block({
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: 30px;
      margin-bottom: 40px;
   });
   .sm-block({
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      grid-row-gap: 20px;
      margin-bottom: 43px;
   });
}
.production__block {
   grid-column: span 1;
   grid-row: span 1;
   
   h2 {
      letter-spacing: -0.2px;
      margin-top: 10px;
      line-height: 120%;
      .to( 1800px, {
         font-size: 18px;
      });
      .lg-block({
         font-size: 14px;
      });
      .sm-block({
         font-size: 14px;
      });
   }
}
.production__image {
   width: 100%;
   height: 170px;
   .sm-block({
      height: 150px;
   });
   .sm-block({
      height: 130px;
   });
}
.p_img {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   border-radius: 10px;
}
.catalog_main__labels {
   .gr();
   grid-template-columns: auto 120px;
   grid-column-gap: 20px;
   .to( 525px, {
      grid-template-columns: auto 120px;
   });
   .to( 1800px, {
      grid-row-gap: 16px;
   });
   .to( 660px, {
      grid-column-gap: 10px;
   });
}
.catalog_main__tags {
   .flex-wrap();
   margin-left: -8px;
   margin-right: -8px;
   
   & > * {
      margin-right: 8px;
      margin-bottom: 8px;
      margin-left: 8px;
   }
/*   .gr();
   grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
   grid-template-rows: repeat(auto-fit, minmax(30px, max-content));
   grid-column-gap: 20px;
   grid-row-gap: 20px;
   .to( 1800px, {
      grid-row-gap: 16px;
   });
   .to( 660px, {
      grid-template-columns: repeat(auto-fit, minmax(100px, 280px));
      grid-column-gap: 10px;
      grid-row-gap: 10px;
   });
   .xxs-block({
      grid-template-columns: repeat(auto-fit, minmax(100px, 100%));
   })*/
}
.cm__tag {
   .flex();
   .items-center();
   .justify-between();
   padding: 4px 6px 4px 12px;
   border-radius: 8px;
   border: 1px solid @color-blue;
   box-sizing: border-box;
   background-color: white;
   
   .montserrat-medium();
   font-size: 14px;
   line-height: 14px;
   color: @color-blue;
   .ico__del {
      margin-left: 8px;
   }
   
}

#container_production .catalog_main__caption {
   .flex();
   .justify-start();
   .items-end();
   h3 {
      font-size: 32px;
      margin-right: 12px;
   }
   span {
      .montserrat-medium();
      font-size: 14px;
      line-height: 130%;
      margin-bottom: 3px;
      .md-block({
         margin-top: 10px;
         margin-bottom: 0;
         margin-left: 3px;
      });
   }
   .md-block({
      .column();
      .items-start();
   });
}
#production .catalog__link {
   font-size: 12px;
   line-height: 14px;
   padding: 8px 16px;
   box-sizing: border-box;
   background-color: transparent;
}
#production .catalog__link:hover {
   font-size: 12px;
   line-height: 14px;
   padding: 8px 16px;
   box-sizing: border-box;
   background-color: @color-blue-gray;
}
#production .catalog__articles {
   padding: 10px 0 16px;
}
#production .catalog__section.open .icon__angle {
   transform: rotate(180deg);
   .tr();
}
#container_production .catalog__fbutton {
   margin-left: 0;
   .to( 525px, {
      width: unset;
   });
   span {
      
      .sm-block({
         display: block;
      });
   }
}
