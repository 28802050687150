body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.items-center {
  display: flex;
  align-items: center;
}

/*
@media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi) {
  .example {
    background-image: url(img.png);
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .example {
    background-image: url(img@2x.png);
  }
}
*/

html {
  font-size: 15px;
}

body {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  background-color: #EBEDF0;
  min-width: 280px;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
}

.container {
  overflow-x: hidden;
  position: relative;
  margin-bottom: auto;
}

.wrapper {
  max-width: 1120px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.wrapper-small {
  max-width: 862px;
}

.wrapper-add-address {
  max-width: 630px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.col {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
}

a {
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  transition: all 0.3s ease-in-out;
}

input {
  padding: 0;
  outline: none;
  border: none;
  background: white;
  width: 90%;
  height: 90%;
  color: #203686;
  font-size: 14px;
  line-height: 110%;
}

input:focus {
  outline: none;
}

input:focus {
  color: #70798E;
}

textarea {
  padding: 0;
  outline: none;
  border: none;
  background: white;
  width: 90%;
  height: 90%;
  color: #203686;
  font-size: 14px;
  line-height: 110%;
}

textarea:focus {
  outline: none;
}

textarea:focus {
  color: #70798E;
}

button {
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

menu {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

input::-moz-placeholder {
  color: #969ba8;
  font-size: 14px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

input::-webkit-input-placeholder {
  color: #969ba8;
  font-size: 14px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

input:-ms-input-placeholder {
  color: #969ba8;
  font-size: 14px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

input::-ms-input-placeholder {
  color: #969ba8;
  font-size: 14px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

input::placeholder {
  color: #969ba8;
  font-size: 14px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

#black_back {
  position: fixed;
  z-index: 490;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(32, 54, 134, 0.6);
  transition: opacity 0.2s;
  display: none;
}

#black_back.up {
  z-index: 1900;
}

.absolute-img {
  width: 100%;
  height: 100%;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #203686;
  border-radius: 50px;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 400;
}

.wrapper.flex {
  display: flex;
}

.grid-wrapper {
  display: grid;
}

.flex {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.center {
  display: flex;
  align-items: center;
}

.start {
  display: flex;
  align-items: flex-start;
}

.hide {
  display: none;
}

.dis {
  opacity: 0;
}

.element {
  padding: 0 !important;
  color: inherit;
}

.relative {
  position: relative;
}

.relation {
  height: 0;
}

.relation-square {
  height: 0;
  padding-top: 100%;
}

.relation-rectangle {
  height: 0;
  padding-top: 48.5%;
}

.relation_content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.form_pass .ico__eye {
  position: absolute;
  z-index: 1000;
  right: 16px;
}

.row_flex-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.row_flex-10 > * {
  padding-right: 5px;
  padding-left: 5px;
}

.quarter {
  width: 25%;
}

.third {
  width: 33%;
}

.half {
  width: 50%;
}

.two-thirds {
  width: 66%;
}

.full {
  width: 100%;
}

h1.caption {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 100%;
}

h1.title,
h3.caption,
h2.caption {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
}

h2.title,
h3.title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.text-18-title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #203686;
}

.text-18 {
  font-size: 18px;
  line-height: 120%;
}

.text-16 {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}

.text-16-big {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
}

.text-14 {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
}

.text-14-small {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
}

.text-14-big {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
}

.section-title-text-14 {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #70798E;
}

.text-small {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.text-12-bold {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.row_flex-8 {
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
}

.row_flex-8 > * {
  padding-right: 4px;
  padding-left: 4px;
}

.row_flex-10-m {
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
}

.row_flex-10-m > * {
  margin-right: 5px;
  margin-left: 5px;
}

.row_flex-12 {
  display: flex;
  margin-left: -6px;
  margin-right: -6px;
}

.row_flex-12 > * {
  padding-right: 6px;
  padding-left: 6px;
}

.row_flex-16 {
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
}

.row_flex-16 > * {
  padding-right: 8px;
  padding-left: 8px;
}

.row_flex-20 {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

.row_flex-20 > * {
  padding-right: 10px;
  padding-left: 10px;
}

.row_flex-32 {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
}

.row_flex-32 > * {
  padding-right: 16px;
  padding-left: 16px;
}

.row_flex-80-media {
  margin-left: -40px;
  margin-right: -40px;
}

.row_flex-80-media > * {
  padding-right: 40px;
  padding-left: 40px;
}

.text-white {
  color: #FFFFFF;
}

.text-blue {
  color: #203686;
}

.text-gray {
  color: #F4F7FB;
}

.text-opacity {
  color: rgba(32, 54, 134, 0.3);
}

.text-dark {
  color: #70798E;
}

.text-red {
  color: #F10B34;
}

.text-green {
  color: #6FBD15;
}

.status-green {
  border-color: #6FBD15;
  color: #6FBD15;
}

.status-red {
  border-color: #F10B34;
  color: #F10B34;
}

.status-blue {
  border-color: #203686;
  color: #203686;
}

.status-gray {
  border-color: #70798E;
  color: #70798E;
}

.back-blue {
  background-color: #F4F7FB;
}

.text-center {
  text-align: center;
}

.mtauto {
  margin-top: auto;
}

.mlauto {
  margin-left: auto;
}

.mb {
  margin-bottom: 6px;
}

.mr {
  margin-right: 6px;
}

.mt4 {
  margin-top: 4px;
}

.mr4 {
  margin-right: 4px;
}

.mb4 {
  margin-bottom: 4px;
}

.mr6 {
  margin-right: 6px;
}

.mb6 {
  margin-bottom: 6px;
}

.mr8 {
  margin-right: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb10 {
  margin-bottom: 10px;
}

.mr10 {
  margin-right: 10px;
}

.mb12 {
  margin-bottom: 12px;
}

.mt10 {
  margin-top: 10px;
}

.mt16 {
  margin-top: 16px;
}

.mr16 {
  margin-right: 16px;
}

.mb16 {
  margin-bottom: 16px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mr20 {
  margin-right: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt40 {
  margin-top: 40px;
}

.pt20 {
  padding-top: 20px;
}

.p20 {
  padding: 20px;
}

.block {
  display: block !important;
}

.block-12 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(50% - 20px);
}

.section__caption {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 32px;
  margin-bottom: 40px;
}

.section__caption .section-title-text-14 {
  grid-column: 1 / 4;
}

.section__caption .caption {
  grid-column: 4 / 13;
}

.text-bold {
  font-family: 'Montserrat-Bold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif !important;
  font-weight: 700 !important;
}

.text-big {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
}

.pointer {
  cursor: pointer;
}

.default {
  cursor: default;
}

.header__logo .logo__image {
  background-image: url("../images/svg/logo/b-logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  flex-shrink: 0;
}

.header__logo .logo__text {
  background-image: url("../images/svg/logo/b-words.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  flex-shrink: 0;
  margin-left: 10px;
}

.footer__logo .logo__image {
  background-image: url("../images/svg/logo/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  flex-shrink: 0;
}

.footer__logo .logo__text {
  background-image: url("../images/svg/logo/words.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  flex-shrink: 0;
  margin-left: 16px;
}

/* line */

/*.carousel-little-img( @numb, @size ) {
   background-image: url("../images/@{size}/carousel/index-@{numb}.webp");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: bottom right;
}
.carousel__item-1-img() {
   .carousel-little-img( 1, 'large' );
   .lg-block({
      .carousel-little-img( 1, 'middle' );
   });
   .sm-block({
      .carousel-little-img( 1, 'small' );
   });
}
.carousel__item-2-img() {
   .carousel-little-img( 2, 'large' );
   .lg-block({
      .carousel-little-img( 2, 'middle' );
   });
   .sm-block({
      .carousel-little-img( 2, 'small' );
   });
}
.carousel__item-3-img() {
   .carousel-little-img( 3, 'large' );
   .lg-block({
      .carousel-little-img( 3, 'middle' );
      background-position: top right;
   });
   .middle-block({
      background-position: bottom right;
   });
   .sm-block({
      .carousel-little-img( 3, 'small' );
   });
}*/

/* главная - cover, белые прямоугольники  */

/* ГЛАВНАЯ разная продукция */

/*
.variety_products( @size, @name ) {
    background-image: url("../images/@{size}/product/@{name}.webp");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   width: 100%;
   height: 100%;
}
.to485( @name ) {
   .to( 485px, {
      background-image: url("../images/small/product/@{name}.webp");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
   });
   
}
.image__sveshemor {
   .variety_products( 'large', 'sveshemor' );
   .to485('sveshemor');
}
.image__okhlazhdennaya_ryba {
   .variety_products( 'large', 'okhlazhdennaya_ryba' );
      .to485('okhlazhdennaya_ryba');
}
.image__myaso_i_ptitsa {
   .variety_products( 'large', 'myaso_i_ptitsa' );
      .to485('myaso_i_ptitsa');
}
.image__gotovaya {
   .variety_products( 'large', 'gotovaya' );
      .to485('gotovaya');
}
.image__moreprodukty {
   .variety_products( 'large', 'moreprodukty' );
   .to485( 'moreprodukty');
}
.image__fileryby {
   .variety_products( 'large', 'fileryby' );
   .ll-block({
       .variety_products( 'middle', 'fileryby' );
   });
   .to485( 'fileryby');
}
.image__fish_in_packaging {
   .variety_products( 'large', 'fish_in_packaging' );
   .ll-block({
       .variety_products( 'middle', 'fish_in_packaging' );
   });
   .to485( 'fish_in_packaging');
}
.image__zamorozhennyye-ovoshchi-yagody {
   .variety_products( 'large', 'zamorozhennyye-ovoshchi-yagody' );
   .to485( 'zamorozhennyye-ovoshchi-yagody');
}
.image__molochnaya-produktsiya {
   .variety_products( 'large', 'molochnaya-produktsiya' );
      .to485('molochnaya-produktsiya');
}
.image__bakaleya {
   .variety_products( 'large', 'bakaleya' );
      .to485('bakaleya');
}
.image__maslo-rastitelnyoe {
   .variety_products( 'large', 'maslo-rastitelnyoe' );
      .to485('maslo-rastitelnyoe');
}
.image__sousy {
   .variety_products( 'large', 'sousy' );
      .to485('sousy');
}
*/

/* индивидуальное предложение  */

/* поставки в разные сферы бизнесса  */

/* ГЛАВНАЯ эффективная доставка */

/*.delivery-index() {                    ВНЕШНЯЯ ЗАГРУЗКА
   .delivery-index-img( 'large' );
    .lg-block({
      .delivery-index-img( 'middle' );
   });
   .middle-block({
      .r2({
         .delivery-index-img('middle');
      });
      .r1( .delivery-index-img( 'small'));
   });
   .sm-block({
      .delivery-index-img( 'small');
   })
}*/

/* ГЛАВНАЯ рука */

/* ГЛАВНОЕ видео и камера */

/* ГЛАВНАЯ контакты карта */

/* рамка у менеджера */

.button-yellow {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 288px;
  max-width: 288px;
  height: 48px;
  border-radius: 10px;
  background-color: #FFED32;
  color: #203686;
  padding: 12.5px 24px;
  flex-grow: 1;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #FFED32;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.button-gray {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 288px;
  max-width: 288px;
  height: 48px;
  border-radius: 10px;
  background-color: transparent;
  color: #969ba8;
  padding: 12.5px 24px;
  flex-grow: 1;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid transparent;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.button-yellow:hover,
.button-yellow:active {
  background-color: #FFF681;
  border: 1px solid #FFF681;
  transition: all 0.3s ease-in-out;
}

a.button-yellow[disabled='true'],
button[disabled='true'] {
  background-color: #DDE1E6;
  border: 1px solid #DDE1E6;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  cursor: default;
}

#catalog_filters_submit[disabled] {
  background-color: #DDE1E6;
  border: 1px solid #DDE1E6;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  cursor: default;
}

#payment_btn[disabled],
#supplier_btn[disabled],
#reset_password_button[disabled] {
  background-color: #DDE1E6;
  border: 1px solid #DDE1E6;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  cursor: default;
}

.button-yellow:focus,
.button-yellow:target {
  background-color: white;
  border: 1px solid #203686;
  transition: all 0.3s ease-in-out;
}

.button-gray:hover {
  transition: all 0.3s ease-in-out;
  color: rgba(32, 54, 134, 0.3);
}

.button-gray:focus,
.button-gray:target {
  transition: all 0.3s ease-in-out;
  color: #70798E;
}

.button-long {
  width: 100%;
  max-width: 100%;
}

.button-small {
  width: 235px;
}

.button-blue {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 110%;
  border-radius: 10px;
  color: #203686;
  background-color: #F4F7FB;
  padding: 16.5px 39px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.button-blue:hover {
  color: rgba(32, 54, 134, 0.9);
  transition: all 0.3s ease-in-out;
}

.button-hollow {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  border-radius: 10px;
  background-color: transparent;
  color: #203686;
  border: 1px solid #DDE1E6;
  padding: 12.5px 24px;
  flex-grow: 1;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.button-hollow:hover {
  color: rgba(32, 54, 134, 0.9);
  transition: all 0.3s ease-in-out;
}

.watch-more {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: #F4F7FB;
  cursor: pointer;
  display: unset;
}

.watch-more .watch-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.watch-more span {
  color: #203686;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
}

.watch-more:hover span {
  color: rgba(32, 54, 134, 0.9);
  transition: all 0.3s ease-in-out;
}

.button-buy {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  background-color: #FFED32;
  width: 40px;
  height: 40px;
  background-image: url("../images/svg/icons/product/buy.svg");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center;
  flex-shrink: 0;
}

.ico__minus[disabled='true'],
.ico__plus[disabled='true'] {
  background-color: transparent;
  border-color: transparent;
}

.label-checkbox {
  position: relative;
  z-index: 50;
}

.checkbox,
.radio {
  display: block;
}

.checkbox-hidden {
  position: absolute;
  left: -99999px;
  width: 0;
  height: 0;
  opacity: 0;
}

.label-input {
  position: relative;
  z-index: 50;
  display: flex;
  align-items: center;
  background: #F4F7FB;
  border-radius: 10px;
}

.label-input input {
  position: relative;
  z-index: 60;
  background-color: transparent;
}

.label-input .sup {
  position: absolute;
  z-index: 55;
  left: 16px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #70798E;
  transition: all 0.3s ease-in-out;
}

.label-input input:focus + .sup {
  font-size: 12px;
  line-height: 120%;
  color: rgba(60, 60, 67, 0.3);
  transition: all 0.3s ease-in-out;
  margin-bottom: 36px;
}

.label-tel input::-moz-placeholder,
.label-date input::-moz-placeholder {
  color: transparent;
  transition: all 0.3s ease-in-out;
}

.label-tel input::-webkit-input-placeholder,
.label-date input::-webkit-input-placeholder {
  color: transparent;
  transition: all 0.3s ease-in-out;
}

.label-tel input:-ms-input-placeholder,
.label-date input:-ms-input-placeholder {
  color: transparent;
  transition: all 0.3s ease-in-out;
}

.label-tel input::-ms-input-placeholder,
.label-date input::-ms-input-placeholder {
  color: transparent;
  transition: all 0.3s ease-in-out;
}

.label-tel input::placeholder,
.label-date input::placeholder {
  color: transparent;
  transition: all 0.3s ease-in-out;
}

.label-tel input:focus::-moz-placeholder,
.label-date input:focus::-moz-placeholder {
  color: #70798E;
  transition: all 0.3s ease-in-out;
}

.label-tel input:focus::-webkit-input-placeholder,
.label-date input:focus::-webkit-input-placeholder {
  color: #70798E;
  transition: all 0.3s ease-in-out;
}

.label-tel input:focus:-ms-input-placeholder,
.label-date input:focus:-ms-input-placeholder {
  color: #70798E;
  transition: all 0.3s ease-in-out;
}

.label-tel input:focus::-ms-input-placeholder,
.label-date input:focus::-ms-input-placeholder {
  color: #70798E;
  transition: all 0.3s ease-in-out;
}

.label-tel input:focus::placeholder,
.label-date input:focus::placeholder {
  color: #70798E;
  transition: all 0.3s ease-in-out;
}

textarea {
  resize: none;
  background-color: transparent;
  padding: 18px 16px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #DDE1E6;
  min-width: 100%;
  width: 100%;
  max-height: 120px;
  height: 120px;
}

.label-area .sup {
  top: 18px;
}

.label-area textarea:focus + .sup {
  top: 3px;
  font-size: 12px;
  line-height: 120%;
  color: rgba(60, 60, 67, 0.3);
  transition: all 0.3s ease-in-out;
  margin-bottom: 36px;
}

.input {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 18px 16px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #DDE1E6;
  background: #F4F7FB;
}

.input-big {
  width: 100%;
}

.input-thin {
  display: flex;
  align-items: center;
  height: 24px;
  background: transparent;
}

.input_number-holder {
  display: none;
  position: absolute;
  z-index: 100;
  bottom: -100%;
  left: 0;
  width: 180px;
  transform: translateY(50%);
  border-radius: 0 10px 10px 10px;
  padding-left: 5px;
}

.input_number-holder .show {
  display: block;
  display: block !important;
}

.input_number-holder input {
  padding-left: 15px;
  width: 160px;
  border-radius: 0 10px 10px 10px;
  height: 40px;
  background-color: #FFED32;
  color: #203686;
}

.input_number-holder input::-moz-placeholder {
  color: #203686;
}

.input_number-holder input::-webkit-input-placeholder {
  color: #203686;
}

.input_number-holder input:-ms-input-placeholder {
  color: #203686;
}

.input_number-holder input::-ms-input-placeholder {
  color: #203686;
}

.input_number-holder input::placeholder {
  color: #203686;
}

.social__icon-whatsapp:hover .input_number-holder {
  display: block;
}

.social__icon-whatsapp {
  position: relative;
}

.label-area textarea {
  resize: none;
  z-index: 50;
  background-color: transparent;
  padding: 18px 16px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #DDE1E6;
  min-width: 100%;
  width: 100%;
  max-height: 120px;
  height: 120px;
}

*[class^='icon__'] {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/*   Social   */

.icon__telegram {
  background-image: url("../images/svg/social/t.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__whatsapp {
  background-image: url("../images/svg/social/w.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__vkontakte {
  background-image: url("../images/svg/social/vk.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

header .icon__telegram {
  background-image: url("../images/svg/social/b-t.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

header .icon__whatsapp {
  background-image: url("../images/svg/social/b-w.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

footer .icon__telegram {
  background-image: url("../images/svg/social/t.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

footer .icon__whatsapp {
  background-image: url("../images/svg/social/w.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

/*   Header   */

header .icon__basket {
  background-image: url("../images/svg/icons/menu/basket.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

header .icon__favorites {
  background-image: url("../images/svg/icons/menu/favorites.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

header .icon__user {
  background-image: url("../images/svg/icons/menu/b-user.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

header .icon__search {
  background-image: url("../images/svg/icons/menu/search.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

header .icon__dots {
  background-image: url("../images/svg/icons/menu/dots.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

header .icon__burger {
  background-image: url("../images/svg/icons/menu/burger.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

header .icon__close-b {
  background-image: url("../images/svg/icons/menu/b-close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.header__holder_mobile .icon__basket {
  background-image: url("../images/svg/icons/menu/b-basket.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.header__holder_mobile .icon__favorites {
  background-image: url("../images/svg/icons/menu/b-favorites.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.header__holder_mobile .icon__user {
  background-image: url("../images/svg/icons/menu/b-user.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.header__holder_mobile .icon__search {
  background-image: url("../images/svg/icons/menu/b-search.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__search-g {
  background-image: url("../images/svg/icons/menu/search.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__close {
  background-image: url("../images/svg/icons/menu/b-close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__close-w {
  background-image: url("../images/svg/icons/menu/close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__filter {
  background-image: url("../images/svg/icons/product/filters.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

/*   checkbox  /  ratio  */

.checkbox {
  background-image: url("../images/svg/icons/control/no-checkbox.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.checkbox-hidden:checked + .checkbox {
  background-image: url("../images/svg/icons/control/yes-checkbox.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.radio {
  background-image: url("../images/svg/icons/control/no-radio.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.checkbox-hidden:checked + .radio {
  background-image: url("../images/svg/icons/control/yes-radio.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__angle {
  background-image: url("../images/svg/icons/up.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

/*   catalog food   */

*[class^='icofish__'] {
  width: 26px;
  height: 26px;
  transition: all 0.3s ease-in-out;
}

*[class^='icofood__'] {
  width: 26px;
  height: 26px;
  transition: all 0.3s ease-in-out;
}

.icofish__sveshemor {
  background-image: url("../images/svg/food/fish/svezhemorozhennaya_ryba.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofish__morejap {
  background-image: url("../images/svg/food/fish/moreprodukty_Japan.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofish__moreprodukty {
  background-image: url("../images/svg/food/fish/moreprodukty.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofish__fileryby {
  background-image: url("../images/svg/food/fish/file_i_steyki_ryby.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofish__fileryby2 {
  background-image: url("../images/svg/food/fish/file_i_steyki_ryby.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__polufabrikaty {
  background-image: url("../images/svg/food/1-polufabrikaty.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__konservy {
  background-image: url("../images/svg/food/2-konservy.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__molochnaya_produktsiya {
  background-image: url("../images/svg/food/3-molochnaya_produktsiya.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__gotovaya {
  background-image: url("../images/svg/food/4-gotovaya.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__izdelie_iz_testa {
  background-image: url("../images/svg/food/5-izdelie_iz_testa.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__bakaleya {
  background-image: url("../images/svg/food/6-bakaleya.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__myaso_i_ptitsa {
  background-image: url("../images/svg/food/7-myaso_i_ptitsa.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__okhlazhdennaya_ryba {
  background-image: url("../images/svg/food/8-okhlazhdennaya_ryba.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__maslo_rastitelnyoe {
  background-image: url("../images/svg/food/9-maslo_rastitelnyoe.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__zamorozhennyye_ovoshchi_yagody {
  background-image: url("../images/svg/food/10-zamorozhennyye_ovoshchi_yagody.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__krasnaya_ikra {
  background-image: url("../images/svg/food/11-krasnaya_ikra.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__sousy {
  background-image: url("../images/svg/food/12-sousy.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icofood__packaging {
  background-image: url("../images/svg/food/fish_in_packaging.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

/*   стрели крутилка   */

.arrow-next {
  background-image: url("../images/svg/icons/arrow-next.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

.arrow-prev {
  background-image: url("../images/svg/icons/arrow-prev.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

.arrow-next-active {
  background-image: url("../images/svg/icons/arrow-next-active.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

.arrow-prev-active {
  background-image: url("../images/svg/icons/arrow-prev-active.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

/*  ГЛАВНАЯ блок с рукой */

.ico__checkbox {
  background-image: url("../images/svg/icons/control/yes-checkbox-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

/*   ГЛАВНАЯ звезды рейтинг   */

/*  CATALOG один продукт  */

.icon__box {
  background-image: url("../images/svg/icons/product/b-box.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__scale {
  background-image: url("../images/svg/icons/product/b-scale.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__wholesale {
  background-image: url("../images/svg/icons/product/b-wholesale.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.opacity .icon__box {
  background-image: url("../images/svg/icons/product/box.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.opacity .icon__scale {
  background-image: url("../images/svg/icons/product/scale.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.opacity .icon__wholesale {
  background-image: url("../images/svg/icons/product/wholesale.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__like {
  background-image: url("../images/svg/icons/product/like.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__like.like {
  background-image: url("../images/svg/icons/product/b-like.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__minus {
  background-image: url("../images/svg/icons/product/b-minus.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__plus {
  background-image: url("../images/svg/icons/product/b-plus.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__minus[disabled='true'] {
  background-image: url("../images/svg/icons/product/minus.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__plus[disabled='true'] {
  background-image: url("../images/svg/icons/product/plus.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

/*  pagination arrow  */

.pagination_arrow[disabled='false'] .ico__arrow_prev {
  background-image: url("../images/svg/icons/b-arr-left.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.pagination_arrow[disabled='false'] .ico__arrow_next {
  background-image: url("../images/svg/icons/b-arr-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.pagination_arrow[disabled='true'] .ico__arrow_prev {
  background-image: url("../images/svg/icons/arr-left.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.pagination_arrow[disabled='true'] .ico__arrow_next {
  background-image: url("../images/svg/icons/arr-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__del {
  background-image: url("../images/svg/icons/close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease-in-out;
}

.ico__money {
  background-image: url("../images/svg/icons/delivery/money.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__day {
  background-image: url("../images/svg/icons/delivery/day.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__car {
  background-image: url("../images/svg/icons/delivery/car.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__coins {
  background-image: url("../images/svg/icons/delivery/coins.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__box {
  background-image: url("../images/svg/icons/delivery/box.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.dc-dot {
  background-image: url("../images/svg/icons/delivery/dot.svg");
  background-repeat: no-repeat;
  background-size: 6px;
  background-position: left;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  padding-top: 9px;
}

.icon__yes {
  background-image: url("../images/svg/icons/yes.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.icon__no {
  background-image: url("../images/svg/icons/no.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.icon__back {
  background-image: url("../images/svg/icons/back.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

/*  basket  */

.thanks {
  background-image: url("../images/svg/icons/lk/basket/thanks.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 110px;
  height: 110px;
}

.ico__box {
  background-image: url("../images/svg/icons/lk/basket/box.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.icon__bin {
  background-image: url("../images/svg/icons/lk/basket/bin.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__bell {
  background-image: url("../images/svg/icons/lk/basket/bell.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.ico__options {
  background-image: url("../images/svg/icons/lk/basket/options.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.icon__address_add {
  background-image: url("../images/svg/icons/product/b-plus.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__navigation {
  background-image: url("../images/svg/icons/lk/basket/navigation.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.ico__bin-r {
  background-image: url("../images/svg/icons/lk/basket/r-bin.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.ico__del {
  background-image: url("../images/svg/icons/lk/basket/del.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.ico__edit {
  background-image: url("../images/svg/icons/lk/basket/edit.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.ico__again {
  background-image: url("../images/svg/icons/lk/basket/again.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.radio {
  background-image: url("../images/svg/icons/lk/basket/no-radio.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.checkbox-hidden:checked + .radio {
  background-image: url("../images/svg/icons/lk/basket/yes-radio.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__time {
  background-image: url("../images/svg/icons/lk/basket/time.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.ico__point {
  background-image: url("../images/svg/icons/lk/basket/point.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.ico__question-circle {
  background-image: url("../images/svg/icons/lk/basket/question-circle.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

/*  вход login  */

.icon__login_close {
  background-image: url("../images/svg/icons/login/close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.ico__google {
  background-image: url("../images/svg/icons/login/google.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.ico__eye.no {
  background-image: url("../images/svg/icons/login/eye-hide.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.ico__eye.show {
  background-image: url("../images/svg/icons/login/eye-show.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.welcome {
  background-image: url("../images/svg/icons/login/welcome.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 91px;
}

.register_social__vk {
  background-image: url("../images/svg/icons/login/vk.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
}

.register_social__instagram {
  background-image: url("../images/svg/icons/login/instagram.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
}

.register_social__facebook {
  background-image: url("../images/svg/icons/login/facebook.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
}

.register_social__odnoklassniki {
  background-image: url("../images/svg/icons/login/odnoklassniki.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
}

/*  personal_account  */

.ico__confirmed {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 24px;
  height: 24px;
}

[data-confirmed='false'] .ico__confirmed {
  background-image: url("../images/svg/icons/lk/personal_account/no.svg");
}

[data-confirmed='true'] .ico__confirmed {
  background-image: url("../images/svg/icons/lk/personal_account/yes.svg");
}

.ico__eye {
  background-image: url("../images/svg/icons/lk/personal_account/eye.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.radio_checked {
  background-image: url("../images/svg/icons/lk/personal_account/b-check-circle.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.radio_false {
  background-image: url("../images/svg/icons/lk/personal_account/check-circle.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.star {
  background-image: url("../images/svg/icons/lk/personal_account/star.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.star.fill {
  background-image: url("../images/svg/icons/lk/personal_account/star-fill.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

/* личный кабинет - доставка */

.ico__pin {
  background-image: url("../images/svg/icons/lk/personal_account/pin.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.ico__day {
  background-image: url("../images/svg/icons/lk/personal_account/day.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.ico__time {
  background-image: url("../images/svg/icons/lk/personal_account/time.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.ico__person {
  background-image: url("../images/svg/icons/lk/personal_account/men.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.ico__phone {
  background-image: url("../images/svg/icons/lk/personal_account/tel.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.ico__car {
  background-image: url("../images/svg/icons/lk/personal_account/car.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.ico__wallet {
  background-image: url("../images/svg/icons/lk/personal_account/money.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.ico__cheque {
  background-image: url("../images/svg/icons/lk/personal_account/cheque.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.ico__close {
  background-image: url("../images/svg/icons/lk/personal_account/close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.ico__load {
  background-image: url("../images/svg/icons/load.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.price_item__purchase .ico__minus[disabled='true'] {
  background-image: url("../images/svg/icons/minus.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

/*
.hover {
   position: relative;
   .tr();
}
.hover:after {
   .pseudo();
   bottom: -2px;
   left: -1px;
   right: -1px;
   width: calc( 100% + 2px );
   height: 1px;
   background-color: @color-back-gray-light;
   border-radius: 1px;
}
.hover:before {
   .pseudo();
   bottom: -2px;
   left: -1px;
   right: 0;
   width: 0;
   height: 1px;
   background-color: @color-blue-light;
   border-radius: 1px;
}

.hover:hover:before {
   width: calc( 100% + 2px );
   right: -1px;
   .tr();
}
.hover:hover:after {
   width: 0;
   left: unset;
   right: -1px;
   transition: all .15s ease-in-out;
}

*/

.logo__holder {
  display: flex;
  align-items: center;
}

header .logo__image {
  width: 110px;
  height: 30px;
}

header .logo__text {
  width: 148px;
  height: 25px;
}

footer .logo__image {
  width: 125px;
  height: 32px;
}

footer .logo__text {
  width: 241px;
  height: 26px;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.select2-container {
  width: 100%;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  padding-left: 16px;
  box-sizing: border-box;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #70798E;
}

.focus .select2-container .select2-selection--single .select2-selection__rendered {
  color: #203686;
}

.select2-selection__rendered {
  height: 100%;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.select2-container--default .select2-selection--single {
  width: 100%;
  height: 60px;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #DDE1E6;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  display: inline-block;
  background-image: url("../images/svg/icons/up.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
}

.select2-results__option {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #70798E;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #203686;
  background-color: transparent;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: transparent;
}

.select2-container--open .select2-dropdown--below {
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.select2-dropdown {
  margin-top: 8px;
  border: 1px solid #E4E7EC;
  border-radius: 10px;
  padding: 4px;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.select2.select2-container.select2-container--default.select2-container--open .select2-selection__arrow {
  transform: rotate(-180deg) translateY(50%);
  transition: all 0.3s ease-in-out;
}

.catalog__select + .select2-container {
  min-width: 190px;
}

.catalog__select + .select2-container--default .select2-selection--single {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  height: 36px;
  border: 1px solid #F4F7FB;
}

.catalog__select + .select2-container .select2-selection--single .select2-selection__rendered {
  color: #203686;
}

.price__form .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 30px;
}

.price__form .select2-container--default .select2-selection--single {
  height: 48px;
  width: 100%;
}

.price__form .select2.select2-container.select2-container--default.select2-container--below.select2-container--open.select2-container--focus {
  width: 100%;
}

.price__form .select2-hidden-accessible {
  width: 100%;
}

.price__form .select2-container {
  min-width: 100%;
}

.price__form .select2-container--default .select2-search--dropdown .select2-search__field {
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}

.price__form .select2-container--default .select2-selection--single .select2-selection__arrow {
  transform: translateY(0);
  top: 25%;
  left: -24px;
  transition: unset;
}

.price__form .select2.select2-container.select2-container--default.select2-container--open .select2-selection__arrow,
.price_section_form .select2.select2-container.select2-container--default.select2-container--open .select2-selection__arrow {
  transform: unset;
  top: 25%;
  transition: unset;
}

.price__form .select2-container--default .select2-selection--single {
  border-radius: 8px;
  border: unset;
}

#container_price .select2-container--default .select2-search--dropdown .select2-search__field,
#container_price .select2-search--dropdown .select2-search__field,
#container_price .select2-search--dropdown {
  border-radius: 8px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}

#container_price .select2-container--open .select2-dropdown.select2-dropdown--below {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.price__form .select2-container--default .select2-selection--single .select2-selection__arrow {
  background-image: url(../images/svg/icons/menu/search.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*************** SCROLLBAR BASE CSS ***************/

.scroll-wrapper {
  overflow: hidden !important;
  padding: 2px !important;
  padding-left: 5px !important;
  position: relative;
}

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.scroll-element {
  display: none;
}

.scroll-element,
.scroll-element div {
  box-sizing: content-box;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default;
}

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999;
}

.scroll-textarea > .scroll-content {
  overflow: hidden !important;
}

.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important;
}

.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0;
}

/*           css          */

.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.scrollbar-inner > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.scrollbar-inner > .scroll-element.scroll-x {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%;
}

.scrollbar-inner > .scroll-element.scroll-y {
  height: 100%;
  right: 2px;
  top: 0;
  width: 6px;
}

.scrollbar-inner > .scroll-element .scroll-element_outer {
  overflow: hidden;
}

.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: 0.4;
}

.scrollbar-inner > .scroll-element {
  z-index: 50;
}

.scrollbar-inner > .scroll-element .scroll-element_track {
  background-color: rgba(77, 168, 207, 0.05);
}

/* ������  */

.scrollbar-inner > .scroll-element .scroll-bar {
  background-color: #DDE1E6;
}

/* ��� �������� */

.scrollbar-inner > .scroll-element:hover .scroll-bar {
  background-color: #d3d7dc;
}

.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #ccd0d5;
}

/* update scrollbar offset if both scrolls are visible */

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px;
}

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px;
}

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -12px;
}

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -12px;
}

.scrollbar-inner > .scroll-element.scroll-x {
  display: none;
}

/* Стили всплывающего окна по-умолчанию */

.modal {
  position: fixed;
  z-index: 2000;
  top: 22vh;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s;
  display: none;
}

/* При отображении модального окно */

.show.modal {
  display: block;
  opacity: 1;
  pointer-events: auto;
  /* элемент видим для событий мыши */
  overflow-y: auto;
  /* добавляем прокрутку по y, когда элемент не помещается на страницу */
}

/* ширина модального окна и его отступы от экрана */

.modal__dialog {
  display: flex;
  position: relative;
  width: 100%;
}

/* Стили для блока с контентом окна */

.modal__content {
  z-index: 1550;
  display: flex;
  background-color: #fff;
  width: calc(100% - 20px);
  overflow: hidden;
  outline: 0;
  border-radius: 20px;
}

.modal__content.relative:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 1500;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/svg/bg/lines.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.modal__content .request__form_holder {
  position: relative;
  z-index: 1600;
}

.order_info__modal {
  top: 45%;
  transform: translateY(-50%);
}

.modal__close {
  width: 20px;
  height: 100%;
}

header {
  position: relative;
  z-index: 510;
  background-color: #FFFFFF;
}

.header__main {
  background-color: #FFFFFF;
  padding-top: 20px;
  padding-bottom: 15px;
}

.header__logo {
  margin-right: auto;
}

.header__aside {
  position: relative;
  z-index: 50;
  background-color: #203686;
  padding-top: 12px;
  padding-bottom: 12px;
}

.header__to-catalog {
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
}

.header__to-catalog span {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
}

.header__search {
  height: 40px;
  max-width: 375px;
  flex-grow: 1;
  margin-right: auto;
}

.header__form {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.header__form button {
  margin-right: 8px;
}

.header__aside_icons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 424px;
  flex-grow: 1;
}

.header__section {
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.header__section span,
.header__section .login {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 1px;
  color: #FFFFFF;
  cursor: pointer;
}

.header__section div[class^='icon__'] {
  position: relative;
  z-index: 10;
  line-height: 1px;
  cursor: pointer;
}

.header__section .icon__basket + span {
  letter-spacing: 1px;
}

.amount {
  position: absolute;
  top: -2px;
  right: -1px;
  z-index: 100;
  padding: 0;
  width: 14px;
  height: 14px;
  background-color: #FFED32;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 0.5px;
  color: #203686;
}

.header__menu {
  margin-right: auto;
}

.menu__holder {
  display: flex;
  flex-direction: row;
}

.menu__item_link {
  display: inline-block;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #203686;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
}

.header__menu_mobile {
  position: absolute;
  top: 74px;
  z-index: 2500;
  width: 100%;
  background-color: #EBEDF0;
  padding-top: 40px;
  padding-bottom: 42px;
  display: none;
}

.header__menu_mobile .column.start {
  width: 100%;
}

.header__menu_mobile .menu__holder {
  padding-bottom: 60px;
}

.header__menu_mobile .menu__item {
  padding-bottom: 20px;
}

.header__menu_mobile .header__contact {
  padding-bottom: 20px;
  margin-top: auto;
}

.header__menu_mobile .header__social [class^='icon__'] {
  width: 40px;
  height: 40px;
}

.header__holder_mobile {
  display: none;
}

.header__holder_mobile .header__m_search {
  display: none;
}

.header__holder_mobile .header__m_icons {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.header__holder_mobile .header__m_icons .amount {
  right: 0;
}

.icon__close-b {
  display: none;
}

.burger__holder {
  display: flex;
  cursor: pointer;
  position: relative;
  z-index: 500;
}

.burger__holder,
.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 20px;
  padding: 2.5px;
  box-sizing: border-box;
}

.burger__holder:after,
.close:after,
.burger__holder:before,
.close:before {
  content: '';
  display: block;
  position: absolute;
  width: calc(100% - 6.5px);
  height: 0;
  border-radius: 6px;
  border: 1px solid #203686;
  transition: all 0.3s ease-in-out;
  right: 2.5px;
  left: 2.5px;
}

.burger__holder:before,
.close:before {
  top: 2.5px;
}

.burger__holder:after,
.close:after {
  bottom: 2.5px;
}

.burger__line {
  width: 17px;
  height: 0;
  border-radius: 6px;
  border: 1px solid #203686;
  transition: all 0.3s ease-in-out;
}

.burger__holder.close .burger__line {
  transition: transform 0.3s, opacity 0.2s;
  transform: rotate(135deg);
  opacity: 0;
}

.burger__holder.close {
  transition: all 0.3s ease-in-out;
}

.burger__holder.close:before {
  transform: rotate(135deg) translateY(-7.2px) translateX(1.4px);
  transition: all 0.3s ease-in-out;
}

.burger__holder.close:after {
  transform: rotate(-135deg) translateY(7.2px) translateX(2.4px);
  transition: all 0.3s ease-in-out;
}

.burger__holder:hover .burger__line {
  width: 14px;
}

.burger__holder:hover:after {
  width: calc(100% - 13px);
  left: 6px;
  right: 6.5px;
}

.burger__holder.close:hover:after,
.burger__holder.close:hover:before {
  width: calc(100% - 3px);
  left: 1.5px;
  right: 1.5px;
}

.burger__holder .close {
  position: absolute;
  z-index: 100;
  opacity: 0;
  display: none;
  transition: all 0.3s ease-in-out;
}

.burger__holder .close:before {
  transform: rotate(135deg) translateY(-7.2px) translateX(1.4px);
  transition: all 0.3s ease-in-out;
}

.burger__holder .close:after {
  transform: rotate(-135deg) translateY(7.2px) translateX(2.4px);
  transition: all 0.3s ease-in-out;
}

.close:after {
  bottom: 3px;
}

.dots__holder {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 24px;
  height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.dots {
  position: relative;
  z-index: 50;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 4px;
}

.dots .d {
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.dots:before,
.dots:after {
  content: '';
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.dots:before {
  left: 0;
}

.dots:after {
  right: 0;
}

.dots__holder:hover .dots:nth-of-type(2):before {
  left: 50%;
  transform: translateX(-60%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.dots__holder:hover .dots:nth-of-type(2):after {
  left: 50%;
  transform: translateX(-60%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.dots__holder:hover .dots:first-of-type .d {
  margin-left: 100%;
  transition: all 0.3s ease-in-out;
}

.dots__holder:hover .dots:nth-of-type(3) .d {
  margin-left: 100%;
  transition: all 0.3s ease-in-out;
}

.header__to-catalog.open .dots__holder .dots:first-of-type {
  transform: rotate(135deg) translateY(-3.2px) translateX(9.4px);
  transition: all 0.3s ease-in-out;
}

.header__to-catalog.open .dots__holder .dots:first-of-type .d {
  width: 28px;
  height: 2px;
  transition: all 0.3s ease-in-out;
}

.header__to-catalog.open .dots__holder .dots:first-of-type:before,
.header__to-catalog.open .dots__holder .dots:first-of-type:after {
  height: 2px;
}

.header__to-catalog.open .dots__holder .dots:nth-of-type(2) {
  transition: transform 0.3s, opacity 0.2s;
  transform: rotate(45deg);
  opacity: 0;
}

.header__to-catalog.open .dots__holder .dots:nth-of-type(3) {
  transform: rotate(-135deg) translateY(2.4px) translateX(6.9px);
  transition: all 0.3s ease-in-out;
}

.header__to-catalog.open .dots__holder .dots:nth-of-type(3) .d {
  width: 28px;
  height: 2px;
  transition: all 0.3s ease-in-out;
}

.header__to-catalog.open .dots__holder .dots:nth-of-type(3):before,
.header__to-catalog.open .dots__holder .dots:nth-of-type(3):after {
  height: 2px;
}

.icon__dots {
  display: none;
}

#catalog {
  position: absolute;
  z-index: 600;
  width: 100%;
  display: none;
}

.catalog__holder {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.catalog__top {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  flex-grow: 1;
  height: 0;
}

.catalog__top > div {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
}

.catalog__top .icon__angle {
  margin-right: auto !important;
}

.catalog__top .icon__close {
  margin-left: auto !important;
  display: none;
}

.catalog__caption {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #203686;
  margin-right: auto !important;
  margin-left: auto !important;
  display: none;
}

.catalog__block {
  /*.to(1000px, {
      display: none;
   });*/
}

.catalog__block-aside {
  flex-basis: 256px;
  margin-right: 10px;
}

.catalog__section {
  min-width: 253px;
}

.catalog__section_caption {
  display: flex;
  align-items: center;
  padding: 18px 11px;
  box-sizing: border-box;
  cursor: pointer;
  height: 75px;
  margin-right: -5px;
  margin-left: -5px;
}

.catalog__section_caption > div {
  padding-right: 5px;
  padding-left: 5px;
}

.catalog__section-name {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #203686;
  max-width: 192px;
  margin-right: auto;
}

.catalog__articles {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: #F4F7FB;
  padding: 15px 10px 0 52px;
  border-radius: 4px;
  height: 170px;
  display: none;
}

.catalog__scroll {
  max-height: 144px;
}

.catalog__scroll-main {
  display: flex;
  overflow: hidden;
}

.catalog__articles .scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.scroll-wrapper {
  padding: 0 !important;
}

.catalog__link {
  color: #203686;
  font-size: 12px;
  line-height: 200%;
  cursor: pointer;
  border-radius: 6px;
}

.catalog__block.row {
  align-content: flex-start;
  justify-content: flex-start;
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  margin-left: -3px;
  margin-right: -3px;
}

.catalog__block.row .catalog__scroll {
  max-height: 120px;
}

.catalog__block.row .catalog__articles {
  height: 125px;
}

.catalog__section-food {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 3px;
  margin-right: 3px;
  width: calc(100% / 24 * 8 - 6px);
}

.icon__angle.open {
  transform: rotate(-180deg);
}

.icon__angle.cat:hover {
  transform: scale(1.3) rotate(90deg);
}

.container_main {
  margin-top: 20px;
}

.container_main .slick-prev.slick-arrow,
.container_main .slick-next.slick-arrow {
  position: absolute;
  transform: none;
  z-index: 100;
  top: 0;
  bottom: 0;
  width: 20%;
  height: 100%;
  color: transparent;
  font-size: 1px;
  line-height: 0;
}

.container_main .slick-prev.slick-arrow {
  height: 50%;
  left: 0;
  bottom: 50%;
}

.container_main .slick-next.slick-arrow {
  right: 0;
}

.carousel__holder .carousel__item {
  min-height: 400px;
}

.carousel__item_inner {
  overflow: hidden;
  position: relative;
  min-height: 100%;
  border-radius: 20px;
  padding: 40px 40px 25px;
  box-sizing: border-box;
}

.carousel__item_text {
  position: relative;
  z-index: 150;
}

.carousel__item_text .button-yellow {
  position: relative;
  z-index: 200;
}

.carousel__item_text h2.text-16.text-white {
  margin-top: 18px;
  margin-bottom: 30px;
}

.carousel__item_inner:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/svg/bg/lines2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.carousel__item_inner {
  position: relative;
}

.carousel__item_inner .carousel__item_img {
  position: absolute;
  display: block;
  z-index: 100;
  right: 0;
  bottom: 0;
  width: 60%;
  min-width: 95px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}

.carousel__item-1 {
  background-color: #6BA252;
}

.carousel__item-1 .carousel__item_img {
  min-width: 126px;
}

.carousel__item-2 {
  background-color: #65ABE4;
}

.carousel__item-3 {
  background-color: #EFB526;
}

.carousel__item h1.caption {
  width: 58%;
}

.carousel__item-2 h1.caption {
  width: 80%;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-dots {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 10px;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 16px;
  width: 20px;
  margin: 0 1px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

.slick-dots li button {
  display: block;
  height: 8px;
  width: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  color: transparent;
  font-size: 0;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots .slick-active button {
  background-color: white;
}

.relation_carousel {
  height: 0;
  padding-top: 35.7%;
}

.social__holder {
  display: flex;
}

.social__icon {
  cursor: pointer;
}

.header__social {
  margin-right: 20px;
}

.contact__number {
  font-family: 'Montserrat-Bold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0;
  color: #203686;
}

.contacts__text {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 1px;
  color: #70798E;
  text-transform: uppercase;
  cursor: pointer;
}

.request__container {
  margin-top: 84px;
  margin-bottom: 80px;
}

.request__wrapper {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
}

.request__holder {
  min-height: 100%;
}

.request__holder .text-small {
  margin-top: -2px;
  cursor: default;
}

.request__image_holder {
  width: 51.5%;
}

.request__image-1 {
  width: 100%;
  height: 100%;
}

.request_img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.request__form_holder {
  width: 48.5%;
  background-image: url("../images/svg/bg/request.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 40px;
  box-sizing: border-box;
}

.request__form_holder h2 {
  margin-bottom: 20px;
}

form.request__form button {
  margin-top: 40px;
}

/* ГЛАВНАЯ index "обложка" */

.cover__container {
  margin-top: 10px;
}

.cover__container div[class^='cover_holder-'] {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.cover__container .cover_img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}

.cover__container .wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-rows: minmax(290px, auto);
}

.cover__holder {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row: 1 / 3;
  align-self: stretch;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  min-height: 220px;
}

.cover__holder .cover_img {
  display: block;
  position: absolute;
  z-index: 50;
  width: 80%;
  height: 80%;
  right: 20px;
}

.cover__holder a {
  position: relative;
  z-index: 60;
}

.cover__holder-1 .cover_img {
  max-width: 32%;
  background-size: contain;
  bottom: 20px;
}

.cover__holder-2 {
  padding-bottom: 24px;
}

.cover__caption {
  position: relative;
  z-index: 60;
  color: #203686;
  margin-bottom: 6px;
}

.cover__text {
  position: relative;
  z-index: 60;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #203686;
  max-width: 61%;
}

.cover__holder .btn {
  padding-top: 10px;
}

.badge_yellow {
  position: relative;
  z-index: 60;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFED32;
  width: 126px;
  padding: 5px 12px;
  border-radius: 12px;
  box-sizing: border-box;
}

.badge_yellow span {
  display: inline-block;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #203686;
}

.cover__holder-1 .cover__text {
  margin-bottom: 12px;
}

.cover__holder-1 .cover_img {
  background-size: 107px auto;
  bottom: 0;
}

.cover__holder-2 .cover_img {
  max-width: 49%;
  background-size: contain;
  bottom: 13px;
}

.cover__holder-3 .cover_img {
  bottom: 23px;
  width: 75%;
}

/* компания предлогает и всякое такое */

.variety_products__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.variety_products__container br {
  display: none;
}

.variety_products__container .variety_products_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.variety_products__container .relation_content {
  border-radius: 10px;
  overflow: hidden;
}

.variety_products__container .text-18-title {
  margin-top: 16px;
}

.variety_products__container .text-14 {
  display: inline-block;
  width: 90%;
  margin-top: 6px;
}

.variety_products__container .variety_products__list {
  display: grid;
  margin-top: 40px;
  grid-column-gap: 20px;
  grid-row-gap: 28px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr auto auto 1fr;
}

.variety_products__container h3.caption.text-blue.second {
  display: none;
}

.variety_product-7,
.variety_product-8 {
  margin-top: 10px;
}

.variety_product-9,
.variety_product-10,
.variety_product-11,
.variety_product-12 {
  margin-top: 12px;
}

.offer__container {
  margin-top: 80px;
  margin-bottom: 80px;
  padding-top: 60px;
  padding-bottom: 115px;
  background-image: url("../images/large/offer.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 100%;
}

.offer__container h2.caption {
  margin-bottom: 40px;
}

.offer__form {
  background-color: #F4F7FB;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 40px;
}

.offer__form .row {
  display: flex;
  justify-content: center;
}

.offer__form .row > div {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(50% - 20px);
}

.offer__info {
  padding-top: 17px;
}

.offer__label-select {
  margin-bottom: 10px;
  width: 100%;
}

.offer__label {
  display: flex;
}

.offer__label .offer__li {
  margin-right: 16px;
}

.offer__li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 48px;
  height: 48px;
  padding: 10px;
  border: 1px solid #DDE1E6;
  box-sizing: border-box;
  border-radius: 50px;
  background-color: transparent;
  color: #70798E;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
}

.offer__select {
  width: 100%;
}

.offer__li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 48px;
  height: 48px;
  padding: 10px;
  border: 1px solid #DDE1E6;
  box-sizing: border-box;
  border-radius: 50px;
  background-color: transparent;
  color: #70798E;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
}

.offer__select {
  width: 100%;
}

.offer__title {
  color: #70798E;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 4px;
  transition: all 0.3s ease-in-out;
}

.offer__info .focus .offer__li {
  border: 1px solid #203686;
  background-color: #203686;
  color: #F4F7FB;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}

.offer__info .focus .offer__title {
  color: #203686;
  transition: all 0.3s ease-in-out;
}

.offer__contact button {
  margin-top: 30px;
}

.offer__contact .label-input input {
  background-color: #FFFFFF;
}

/*  специализируемся на разных сферах бизнесса   */

.busareas__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.busareas__container .caption.text-blue {
  width: 85%;
}

.busareas__container .caption.text-blue br:first-of-type {
  display: none;
}

.busareas__content {
  display: flex;
  flex-wrap: wrap;
  background-color: #FFFFFF;
  border-radius: 20px;
}

.busareas__block {
  display: flex;
  width: 50%;
}

.busareas__block:nth-of-type(3),
.busareas__block:nth-of-type(4) {
  display: flex;
  flex-direction: row-reverse;
}

.busareas__block:nth-of-type(3) span,
.busareas__block:nth-of-type(4) span,
.busareas__block:nth-of-type(3) .text-18-title,
.busareas__block:nth-of-type(4) .text-18-title {
  text-align: left;
}

.busareas__text {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;
  max-width: 280px;
  height: 200px;
  padding: 20px;
}

.busareas__text:before,
.busareas__text:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #DDE1E6;
  border-radius: 5px;
}

.busareas__text span {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #70798E;
}

.busareas__image {
  position: relative;
  min-width: 240px;
  width: 240px;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
}

.busareas__image:before,
.busareas__image:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #DDE1E6;
  border-radius: 5px;
}

.busareas__text:before,
.busareas__image:before {
  top: 20px;
  bottom: 20px;
  width: 1px;
  height: calc(100% - 40px);
  right: 0;
}

.busareas__text:after,
.busareas__image:after {
  right: 20px;
  left: 20px;
  height: 1px;
  width: calc(100% - 40px);
  bottom: 0;
}

.busareas_image-1,
.busareas_image-2,
.busareas_image-3,
.busareas_image-4 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.busareas__img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.busareas__img:nth-of-type(1) {
  background-position: 50% 30%;
}

.busareas__img:nth-of-type(2) {
  background-position: 50% 73%;
}

.busareas__img:nth-of-type(3) {
  background-position: 50% 90%;
}

.busareas__img:nth-of-type(4) {
  background-position: 50% 93%;
}

.busareas__block:nth-of-type(2) .busareas__text:before,
.busareas__block:nth-of-type(4) .busareas__image:before {
  display: none;
}

.busareas__block:nth-of-type(3) .busareas__text:after,
.busareas__block:nth-of-type(3) .busareas__image:after,
.busareas__block:nth-of-type(4) .busareas__text:after,
.busareas__block:nth-of-type(4) .busareas__image:after {
  display: none;
}

.delivery_index__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.delivery_index__container .relation {
  padding-top: 48.1%;
}

.delivery_index__wrapper {
  margin-top: -20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 32px;
  grid-row-gap: 40px;
}

.delivery_index__text {
  grid-column: 4 / 13;
  grid-row: 1 / 2;
  display: flex;
}

.delivery_index__text p {
  line-height: 20px;
  margin-right: 10px;
  max-width: 340px;
}

.delivery_index__text p:first-of-type {
  margin-right: 10px;
}

.delivery_index__facts {
  grid-column: 4 / 13;
  grid-row: 2 / 3;
  display: flex;
  justify-content: space-between;
  padding-top: 36px;
}

.delivery_index__facts p {
  position: relative;
  width: 100%;
}

.delivery_index__facts p span {
  display: inline-block;
}

.delivery_index__facts p:nth-of-type(1) {
  padding-right: 37px;
}

.delivery_index__facts p:nth-of-type(2) {
  padding-right: 25px;
}

.delivery_index__facts p:nth-of-type(1) span {
  max-width: 187px;
}

.delivery_index__facts p:nth-of-type(2) span {
  max-width: 182px;
}

.delivery_index__facts p:nth-of-type(3) span {
  max-width: 182px;
}

.delivery_index__image {
  grid-column: 4 / 13;
  grid-row: 3 / 4;
}

.delivery_index__facts p:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 55;
  background-image: url("../images/svg/icons/dot-dot.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  top: -36px;
}

.delivery_index__facts p:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 50;
  top: -29px;
  left: 4px;
  height: 0;
  border: 1px solid rgba(32, 54, 134, 0.2);
  width: 100%;
}

.delivery_index__facts p:last-of-type:before {
  width: 82%;
}

.delivery_index__img-1 .di-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
}

.delivery_index__image {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
}

.choose__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.choose__wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 20px;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 40px 40px 10px;
}

.choose__wrapper h2.caption.text-blue {
  margin-bottom: 20px;
}

.choose__block_text {
  grid-column: 1 / 6;
  grid-row: 1 / 2;
  min-width: 312px;
}

.choose__container .button-yellow {
  grid-column: 1 / 6;
  grid-row: 2 / 3;
  margin-top: auto;
  margin-bottom: 30px;
}

.choose__block_photo {
  grid-column: 6 / 13;
  grid-row: 1 / 3;
}

.choose__block_photo {
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
  display: flex;
  justify-content: flex-end;
}

.choose__manager {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 14px;
  margin-right: 14px;
  width: calc(100% / 24 * 6 - 28px);
  margin-bottom: 30px;
  min-width: 100px;
  min-height: 100px;
}

.choose__manager.first-of-type .choose__manager_name {
  margin-top: 8px;
}

.choose__manager.first-of-type .choose__manager_image img {
  position: relative;
  z-index: 52;
}

.choose__manager.first-of-type .choose__manager_image {
  position: relative;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose__manager.first-of-type .choose__manager_image:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 50;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  height: calc(100% + 12px);
  width: calc(100% + 12px);
  border-radius: 100px;
  background-image: url("../images/middle/managers/stroke.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.choose__manager_name {
  text-align: center;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #203686;
  font-size: 16px;
  line-height: 120%;
  margin-top: 5px;
}

.choose__container .slick-arrow {
  position: absolute;
  z-index: 70;
  top: 50%;
  transform: translateY(-74%);
  color: transparent;
  font-size: 0;
  line-height: 0;
}

.choose__container .slick-prev[aria-disabled="false"] {
  left: 6px;
  background-image: url("../images/svg/icons/arrow-prev-active.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

.choose__container .slick-next[aria-disabled="false"] {
  right: 6px;
  background-image: url("../images/svg/icons/arrow-next-active.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

.choose__container .slick-next[aria-disabled="true"] {
  right: 6px;
  background-image: url("../images/svg/icons/arrow-next.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

.choose__container .slick-prev[aria-disabled="true"] {
  left: 6px;
  background-image: url("../images/svg/icons/arrow-prev.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

.advantages__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.advantages__text {
  color: rgba(32, 54, 134, 0.7);
}

.advantages__item {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 32px;
  padding: 16px 0 17px;
  border-top: 1px solid rgba(32, 54, 134, 0.2);
}

.advantages__number {
  grid-column: 1 / 4;
}

.advantages__title {
  grid-column: 4 / 9;
}

.advantages__text {
  grid-column: 9 / 13;
}

.advantages__item-6 {
  border-bottom: 1px solid rgba(32, 54, 134, 0.2);
  margin-bottom: 80px;
}

#container_contacts .advantages__item-6,
#container_payment .advantages__item-6 {
  border-bottom: unset;
  margin-bottom: 0;
}

#container_contacts .advantages__item-7,
#container_payment .advantages__item-7 {
  border-bottom: 1px solid rgba(32, 54, 134, 0.2);
  margin-bottom: 0;
}

.reputation__container {
  margin-top: 150px;
  margin-bottom: 80px;
}

.reputation__tile {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, 235px);
  grid-template-areas: "a a a a b b с c" "d d d d e e e e" "f f j j k k k k";
}

.reputation__block,
.reputation__block-rectangle {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #FFFFFF;
}

.reputation__title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #203686;
}

.reputation__text {
  color: #70798E;
  margin-top: auto;
  padding-top: 80px;
  max-width: 65%;
  line-height: 140%;
}

.reputation__block-2 .reputation__text,
.reputation__block-3 .reputation__text,
.reputation__block-7 .reputation__text {
  padding-top: 38px;
}

.reputation__number {
  margin-bottom: 8px;
}

.reputation__block-1 {
  grid-column: 1 / 5;
}

.reputation__block-1 .reputation__text {
  padding-top: 40px;
}

.reputation__block-2 {
  grid-area: b;
}

.reputation__block-2 .reputation__text {
  max-width: 95%;
}

.reputation__block-3 {
  grid-area: c;
  grid-column: 7 / 9;
}

.reputation__block-3 .reputation__text {
  max-width: 95%;
}

.reputation__block-4 {
  grid-area: d;
}

.reputation__block-5 {
  grid-area: e;
}

.reputation__block-6 {
  grid-area: f;
}

.reputation__block-6 .reputation__text {
  max-width: 95%;
}

.reputation__block-7 {
  grid-area: j;
}

.reputation__block-7 .reputation__text {
  max-width: 95%;
}

.reputation__block-8 {
  grid-area: k;
}

.subscribe__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.subscribe__wrapper {
  position: relative;
  z-index: 50;
  padding: 40px;
  display: flex;
  flex-direction: column;
  background-color: #70798E;
  border-radius: 20px;
  overflow: hidden;
}

.subscribe__wrapper:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 55;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/svg/bg/lines.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.subscribe__wrapper:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 58;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/large/hang-2.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}

.subscribe__wrapper > * {
  position: relative;
  z-index: 60;
}

.subscribe__caption {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  font-size: 60px;
  line-height: 90%;
  margin-bottom: 20px;
  width: 10em;
}

.subscribe__title {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 18px;
  line-height: 120%;
  width: 18em;
  letter-spacing: 0.2px;
}

.subscribe__holder {
  width: 60%;
  margin-top: auto;
  padding-top: 100px;
}

.subscribe__check {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.subscribe__check span {
  width: 353px;
}

.subscribe__socials {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 10px;
}

.subscribe__social_holder {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 25px;
  border-radius: 10px;
  grid-column: span 1;
  grid-row: 1 / 2;
}

.subscribe__social_holder span {
  display: inline-block;
  margin-left: 8px;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.subscribe__social-whatsapp {
  background-color: #4ADD47;
}

.subscribe__social-telegram {
  background: linear-gradient(180deg, #2AABEE 0%, #229ED9 99.26%);
}

.subscribe__social-vkontakte {
  background-color: #1877F2;
}

.subscribe__social_input {
  display: none;
}

.subscribe__social_holder input {
  color: #FFFFFF;
}

.subscribe__social_holder input::-moz-placeholder {
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.subscribe__social_holder input::-webkit-input-placeholder {
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.subscribe__social_holder input:-ms-input-placeholder {
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.subscribe__social_holder input::-ms-input-placeholder {
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.subscribe__social_holder input::placeholder {
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.subscribe__social_holder input {
  width: 140px;
  margin-left: 10px;
  display: none;
}

.subscribe__social_holder input:focus {
  display: block;
}

.subscribe__social_holder input:focus + span {
  display: none;
}

.about_index__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.about_index__content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 32px;
}

.about_index__text {
  grid-row: span 1;
  grid-column: 4 / 13;
  margin-top: -20px;
  display: flex;
}

.about_index__text p:first-of-type {
  margin-right: 20px;
}

.about_index__text p {
  max-width: 310px;
  line-height: 20px;
}

.about_index__facts {
  grid-row: span 1;
  grid-column: 1 / 13;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  margin-top: 40px;
}

.about_index__block {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #FFFFFF;
  border-radius: 10px;
  grid-column: span 3;
  grid-row: span 1;
}

.about_index__number {
  margin-bottom: 8px;
}

.about_index__span {
  padding-top: 40px;
  margin-top: auto;
}

.programs__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.programs__container h3.caption.text-blue {
  max-width: 90%;
}

.programs__holder .relative {
  z-index: 10;
}

.programs__holder .relative:after,
.programs__holder .relative:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.programs__holder .relative:before {
  z-index: 20;
  width: 70px;
  height: 70px;
  background-color: #FFED32;
  border-radius: 100px;
}

.programs__holder .relative:after {
  z-index: 30;
  content: '';
  display: block;
  position: absolute;
  background-image: url("../images/svg/icons/control/arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  transform: translateX(-35%) translateY(-50%);
}

.programs__holder .relative .relation {
  padding-top: 44.6%;
}

.programs__video {
  border-radius: 20px;
}

.programs_video-1 {
  background-image: url("../images/middle/video/poster-1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.programs_video-2 {
  background-image: url("../images/middle/video/poster-2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.programs__name {
  margin-top: 22px;
}

.cameras__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.cameras__wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 32px;
  margin-top: -20px;
}

.cameras__wrapper .relation {
  padding-top: 56.8%;
}

.cameras__text {
  grid-column: 4 / 13;
  grid-row: span 1;
  display: flex;
}

.cameras__text p {
  max-width: 300px;
  line-height: 20px;
}

.cameras__text p:first-of-type {
  margin-right: 10px;
}

.camera__block .relative {
  z-index: 10;
}

.camera__block .relative:after,
.camera__block .relative:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.camera__block .relative:before {
  z-index: 20;
  width: 40px;
  height: 40px;
  background-color: #FFED32;
  border-radius: 100px;
}

.camera__block .relative:after {
  z-index: 30;
  content: '';
  display: block;
  position: absolute;
  background-image: url("../images/svg/icons/control/arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  transform: translateX(-33%) translateY(-50%);
}

.cameras__row {
  grid-column: 4 / 13;
  grid-row: span 1;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 20px;
}

.camera__block-1 {
  grid-column: 1 / 4;
}

.camera__block-2 {
  grid-column: 4 / 7;
}

.camera__block-3 {
  grid-column: 7 / 10;
}

.cameras__video-1 {
  background-image: url("../images/middle/camera/1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.cameras__video-2 {
  background-image: url("../images/middle/camera/2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.cameras__video-3 {
  background-image: url("../images/middle/camera/3.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.camera__name {
  margin-top: 10px;
}

.reviews__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.reviews__content {
  margin-top: -20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 20px;
  grid-column-gap: 32px;
}

.reviews__content > * {
  grid-column: 4 / 13;
  grid-row: span 1;
}

.reviews__content p {
  grid-column: 4 / 13;
  max-width: 345px;
  line-height: 140%;
}

.review__wrapper {
  padding: 40px;
  border-radius: 20px;
  background-color: #FFFFFF;
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 32px;
  grid-row-gap: 20px;
}

.review__wrapper .review__first {
  grid-column: span 9;
  grid-row: 1 / 2;
}

.review__wrapper .review__first:nth-of-type(2) {
  text-align: right;
}

.review__block-1 {
  grid-row: span 1;
  grid-column: 1 / 4;
}

.review__block-2 {
  grid-row: span 1;
  grid-column: 9 / 19;
}

.review__company {
  justify-self: flex-start;
}

.review__source {
  justify-self: flex-end;
  color: #70798E;
}

.review__points {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 70px;
  line-height: 100%;
}

.review__stars,
.review__grade {
  width: 88px;
  height: 16px;
}

.review__stars {
  margin-bottom: 4px;
  background-image: url("../images/svg/icons/review/stars-4.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.review__grade {
  background-image: url("../images/svg/icons/review/stars-5.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.review__photo {
  width: 50px;
  height: 50px;
  background-image: url("../images/svg/icons/review/user.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.review__name {
  margin-bottom: 5px;
}

.review__block-1 {
  display: grid;
  grid-template-columns: 108px 75px 75px;
  grid-template-rows: 10px 20px 14px 30px 68px;
  grid-column-gap: 14px;
}

.review__points {
  grid-column: 1 / 2;
  grid-row: 1 / 5;
}

.review__stars {
  grid-column: 2 / 4;
  grid-row: 2 / 3;
}

.review__rating {
  grid-column: 2 / 4;
  grid-row: 3 / 4;
  width: 108px;
}

.review__button {
  grid-column: 1 / 4;
  grid-row: 5 / 6;
  margin-top: 20px;
  max-width: 261px;
}

.review__block-2 {
  display: grid;
  grid-template-columns: 50px minmax(137px, auto);
  grid-template-rows: 23px 18px 23px 26px auto 24px;
  grid-column-gap: 10px;
}

.review__photo {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  margin-bottom: 10px;
}

.review__name {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.review__date {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.review__grade {
  grid-column: 1 / 3;
  grid-row: 4 / 5;
}

.review__text {
  grid-column: 1 / 3;
  grid-row: 5 / 6;
}

.review__link {
  grid-column: 1 / 3;
  grid-row: 6 / 7;
  margin-top: 10px;
}

.contacts_index__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.contact_index___item {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  padding: 16px 0;
  border-top: 1px solid rgba(32, 54, 134, 0.2);
  grid-column-gap: 32px;
}

.contact_index___title {
  grid-column: 1 / 7;
}

.contact_index___text {
  grid-column: 7 / 14;
  padding-right: 15px;
}

.contact_index___map {
  grid-column: 14 / 25;
}

.contact_index___map .ci_img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.contact_index___map div {
  width: 100%;
  border-radius: 20px;
}

.contact_index__item-3 {
  border-bottom: 1px solid rgba(32, 54, 134, 0.2);
}

.contact_index___map {
  height: 220px;
}

.contact_index__image-1 {
  background-image: url("../images/large/index-contact/1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.contact_index__image-2 {
  background-image: url("../images/large/index-contact/2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.contact_index__image-3 {
  background-image: url("../images/large/index-contact/3.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.breadcrumbs__container {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  align-items: flex-end;
  height: 20px;
  margin-top: 25px;
  margin-bottom: 29px;
}

.breadcrumbs__container .icon__back {
  margin-right: 12px;
}

.breadcrumbs__link {
  position: relative;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #70798E;
  margin-right: 24px;
  margin-left: 1px;
}

.breadcrumbs__link:after {
  content: '/';
  display: block;
  position: absolute;
  font-size: 14px;
  line-height: 17px;
  width: 5px;
  top: -1px;
  bottom: -2px;
  right: -10px;
  transform: translateX(100%);
}

.breadcrumbs__active {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  color: #203686;
  margin-left: 1px;
  margin-top: 2px;
}

.breadcrumbs__button {
  position: relative;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #70798E;
  margin-right: 24px;
}

#container_one .header__main.wrapper,
#container_catalog .header__main.wrapper,
.breadcrumbs__container .wrapper,
#container_catalog .header__aside .wrapper {
  max-width: 1120px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.catalog_main__container {
  position: relative;
  z-index: 100;
}

.catalog_main__container .catalog__block.catalog__block-aside > .scroll-wrapper {
  overflow: visible !important;
}

.catalog_main__container .catalog__block.catalog__block-aside > .scroll-wrapper > .scroll-content {
  overflow: visible !important;
}

.wrapper-big {
  max-width: 1120px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.catalog_main__wrapper {
  display: grid;
  grid-template-columns: 252px auto;
  grid-template-rows: auto;
  grid-column-gap: 50px;
}

.catalog_main__section {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin-left: auto;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(214.67px, auto));
  grid-template-rows: auto;
  grid-column-gap: 51px;
  grid-row-gap: 50px;
}

.catalog_main__products {
  min-width: 214.67px;
  flex-grow: 1;
  max-width: 345px;
}

.catalog_main__filter {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

h2.catalog_main__caption {
  margin-bottom: 20px;
}

.catalog_main__about {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;
}

.catalog_main__show {
  display: flex;
  justify-content: flex-end;
  min-height: 36px;
  margin-bottom: 20px;
}

.catalog__select_holder-2 {
  margin-left: auto;
}

.subscribe__wrapper_catalog.subscribe__wrapper {
  grid-column: 1 / 4;
  grid-row: 3 / 4;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 50;
  box-sizing: border-box;
  padding: 55px 50px 50px;
  display: flex;
  flex-direction: column;
  background-color: #70798E;
  border-radius: 10px;
  overflow: hidden;
}

.subscribe__wrapper_catalog.subscribe__wrapper:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 55;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/svg/bg/lines.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.subscribe__wrapper_catalog.subscribe__wrapper:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 58;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  background-image: url("../images/middle/hand-catalog.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}

.subscribe__wrapper_catalog.subscribe__wrapper > * {
  position: relative;
  z-index: 60;
}

.subscribe__wrapper_catalog .subscribe__caption {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  font-size: 40px;
  line-height: 100%;
  margin-bottom: 10px;
  width: 13em;
}

.subscribe__wrapper_catalog .subscribe__title {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 135%;
  width: 28em;
  letter-spacing: 0.2px;
}

.subscribe__wrapper_catalog .subscribe__holder {
  width: 60%;
  margin-top: auto;
  padding-top: 120px;
}

.subscribe__wrapper_catalog .subscribe__social_holder *[class^='icon__'] {
  width: 24px;
  height: 24px;
}

.catalog__fbutton {
  margin-left: 16px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px 9.5px;
  height: 40px;
  min-width: 36px;
  display: none;
}

.catalog__fbutton span {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  margin-left: 8px;
}

/*#container_catalog .catalog_main__labels {
   .gr();
   grid-template-columns: auto;
   grid-column-gap: 0;
   .to( 525px, {
      grid-template-columns: auto;
   });
}*/

.catalog_main__container .icon__angle {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  margin-right: -4px;
  margin-bottom: 3px;
}

.catalog_main__container .icon__angle.open {
  transform: rotate(0);
}

.catalog_main__container .catalog__section_caption {
  padding: 8px 16px;
  height: auto;
  border-radius: 10px;
  margin-right: 0;
  margin-left: 0;
}

.catalog_main__container .catalog__section_caption > div {
  padding-right: 0;
  padding-left: 0;
}

.catalog_main__container .catalog__section_caption div[class^='icofish__'],
.catalog_main__container .catalog__section_caption div[class^='icofood__'] {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  padding-right: 0;
  padding-left: 0;
}

.catalog_main__container .catalog__section_caption div[class^='icofood__'] {
  margin-left: -4px;
}

.catalog_main__container div[class^='icofood__'] + .catalog__section-name + .icon__angle {
  margin-right: 0;
}

.catalog_main__container .catalog__section-name {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 128%;
}

.catalog_main__container .catalog__section.open .catalog__section_caption {
  transition: all 0.3s ease-in-out;
  background-color: white;
}

.catalog_main__container .catalog__section.open .catalog__section-name {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.catalog_main__container .catalog__section:hover .catalog__section_caption {
  transition: all 0.3s ease-in-out;
  background-color: white;
}

.catalog_main__container .catalog__section:hover .catalog__section-name {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.catalog_main__container .catalog__articles {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: transparent;
  padding: 0 20px 16px 0;
  height: unset;
  display: none;
}

.catalog_main__container .catalog__articles .scroll-wrapper {
  margin-top: 10px;
}

.catalog_main__container .catalog__link {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  padding: 5px 16px;
  background-color: transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.catalog_main__container .catalog__link:hover {
  background-color: #F4F7FB;
  transition: all 0.3s ease-in-out;
}

.catalog_main__container .scroll-wrapper > .scroll-content {
  height: 100% !important;
  max-height: unset;
}

.catalog_main__container .scrollbar-inner > .scroll-element div {
  height: 96%;
  top: 2%;
  bottom: 2%;
}

.catalog_main__container .scrollbar-inner > .scroll-element .scroll-bar {
  background-color: #879fbe;
}

.catalog_main__container .catalog__scroll-main {
  max-height: 700px;
  min-height: 700px;
}

.catalog_main__filters {
  margin-top: 20px;
}

.catalog_main__title {
  justify-content: space-between;
  display: flex;
  align-items: flex-end;
  padding: 8px 0 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #DDE1E6;
}

.catalog_main__title > span.text-blue {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
}

.ico__filters {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 5px 4.45px 4px;
  box-sizing: border-box;
}

.ico__filters .f {
  position: absolute;
  height: 0;
  width: 7px;
  background-color: #969ba8;
  border: 1px solid #969ba8;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.ico__filters .f:first-of-type {
  transform: rotate(45deg) translateY(2.1px) translateX(-1.8px);
}

.ico__filters .f:last-of-type {
  transform: rotate(-45deg) translateY(2.3px) translateX(1.6px);
}

.catalog_main__title.open .ico__filters {
  padding: 5px 4.45px 4px;
}

.catalog_main__title.open .ico__filters .f {
  width: 11px;
  transition: all 0.3s ease-in-out;
}

.catalog_main__title.open .ico__filters .f:first-of-type {
  transform: rotate(45deg) translateY(0) translateX(0);
}

.catalog_main__title.open .ico__filters .f:last-of-type {
  transform: rotate(-45deg) translateY(0) translateX(0);
}

.catalog_main__list_holder {
  padding-right: 16px;
  padding-left: 16px;
  display: none;
}

.catalog_main__block {
  border-bottom: 1px solid #DDE1E6;
}

.catalog_main__block:first-of-type {
  border-top: 1px solid #DDE1E6;
}

.catalog_main__block:first-of-type .catalog_main__title_14 {
  padding-top: 20px;
}

.catalog_main__title_14 {
  display: flex;
  justify-content: space-between;
  padding: 18px 0 20px;
  cursor: pointer;
}

.catalog_main__list {
  display: none;
}

.catalog_main__item {
  display: flex;
  margin-bottom: 10px;
}

.catalog_main__item .label-checkbox {
  margin-right: 12px;
}

.catalog_main__item > span {
  font-size: 14px;
  line-height: 20px;
}

.catalog_main__buttons {
  display: flex;
  margin-top: 28px;
  display: none;
}

#production .catalog__link {
  font-size: 12px;
  line-height: 14px;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: transparent;
}

#production .catalog__link:hover {
  font-size: 12px;
  line-height: 14px;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: #F4F7FB;
}

#production .catalog__articles {
  padding: 10px 0 16px;
}

#production .catalog__section.open .icon__angle {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}

.product__image .relation {
  padding-top: 78.1%;
}

.product__image .relation_content {
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #FFFFFF;
}

.product__image img {
  max-width: 92%;
}

.product__image button.icon__like {
  position: absolute;
  z-index: 100;
  width: 24px;
  height: 24px;
  top: 10px;
  right: 10px;
}

.product__name {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.1px;
  margin-top: 8px;
}

.product__data {
  margin-top: 6px;
  margin-bottom: 8px;
}

.product__data .block-12 {
  max-width: 135px;
}

.product__data .block-12 > * {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
}

.product__options {
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 33.3334%);
  grid-template-rows: 40px 34px;
  border: 1px solid #DDE1E6;
  margin-bottom: 8px;
}

.product__options_btn {
  grid-column: span 1;
  grid-row: span 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #DDE1E6;
  border-bottom: 1px solid #DDE1E6;
  background-color: #FFFFFF;
}

.opacity.product__options_btn {
  background-color: #F4F7FB;
}

.product__options .product__wholesale {
  border-right: unset;
}

.product__text {
  grid-column: 1 / 4;
  grid-row: 2 / 3;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  padding: 9px 0 11px 9px;
  box-sizing: border-box;
  background-color: #FFFFFF;
}

.product__purchase {
  display: grid;
  grid-template-columns: auto 48px;
  grid-template-rows: auto auto;
  grid-row-gap: 10px;
}

.product__purchase .product__amount {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

.product__purchase .product__buy {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.product__purchase .product__button {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  justify-self: flex-end;
}

.product__amount {
  background-color: #FFFFFF;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 16px auto 16px;
  grid-template-rows: 15px;
  padding: 12px 22px;
}

.product__amount > button[disabled='true'] {
  background-color: transparent;
  border: none;
}

.product__weight {
  justify-content: center;
  display: flex;
  align-items: flex-end;
}

.product__weight .weight {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
}

.product__weight span {
  font-size: 14px;
  line-height: 110%;
}

.product__buy {
  display: flex;
  justify-content: space-between;
}

.product__price .price {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 110%;
}

.product__price .price-kg {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
}

.product__price i {
  display: inline;
  font-style: normal;
}

.catalog_main__pagination {
  width: 100%;
  padding: 16px 0;
  margin-top: 30px;
  display: flex;
}

.catalog_main__pagination button.button {
  cursor: pointer;
}

.catalog_pagination {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pagination_numbers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_numb,
.pagination_middle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
}

.pagination_numb span,
.pagination_middle span {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #969ba8;
}

.pagination_numb {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_numb-active {
  border-radius: 6px;
  background-color: #FFFFFF;
}

.pagination_numb-active span {
  color: #203686;
}

button.button.pagination_arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 36px;
  border-radius: 8px;
  background-color: #FFFFFF;
  border: 1px solid white;
}

button.button.pagination_arrow span {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.pagination_arrow {
  padding: 8px;
}

.pagination_arrow div[class^='ico__arrow'] {
  width: 20px;
  height: 20px;
}

.pagination_arrow .ico__arrow_prev {
  padding-right: 10px;
}

.pagination_arrow .ico__arrow_next {
  padding-left: 10px;
}

.pagination_arrow[disabled='false'] {
  background-color: #FFFFFF;
  border: 1px solid white;
}

.pagination_arrow[disabled='false'] span {
  color: #203686;
}

.pagination_arrow[disabled='true'] {
  background-color: #FFFFFF;
  border: 1px solid white;
}

.pagination_arrow[disabled='true'] span {
  color: #969ba8;
}

.filters_mobile__container {
  position: fixed;
  z-index: 2600;
  top: 0;
  right: -550px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  background-color: #FFFFFF;
  border-radius: 8px 0 8px 8px;
  min-width: 272px;
  width: calc(450px - 12vw);
  height: 85vh;
}

.filters_mobile__container .icon__angle {
  transform: unset;
}

.filters_mobile__container .catalog__section-food {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.filters_mobile__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #DDE1E6;
}

.filters_mobile__header .icon__close {
  margin: 16px;
}

.filters_mobile__caption {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #203686;
  padding: 16px;
  box-sizing: border-box;
}

.filters_mobile__footer {
  display: flex;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 0 18px 0 rgba(32, 54, 134, 0.15);
}

.filters_mobile__footer button {
  width: auto;
}

.filters_mobile__content {
  flex-grow: 1;
  padding: 16px;
  box-sizing: border-box;
}

.filters_mobile__content > .scroll-wrapper > .scroll-element > .scroll-element_outer > .scroll-bar {
  background-color: rgba(32, 54, 134, 0.5);
}

.filters_mobile__content > .scroll-wrapper .scroll-element.scroll-y {
  height: 96%;
  right: 10px;
  top: 2%;
  bottom: 2%;
  width: 4px;
}

.filters_mobile__holder {
  max-height: calc(85vh - 56.8px - 120px);
  padding-right: 20px;
  box-sizing: border-box;
}

.filters_mobile__holder .catalog__section {
  min-width: 267px;
}

.filters_mobile__holder .catalog__section_caption {
  margin-right: 0;
  margin-left: 0;
  padding: 8px 8px 8px 0;
  height: auto;
}

.filters_mobile__holder .catalog__section_caption div[class^='icofish__'],
.filters_mobile__holder .catalog__section_caption div[class^='icofood__'] {
  width: 26px;
  height: 26px;
  padding: 0;
  margin-right: 6px;
}

.filters_mobile__holder .catalog__section_caption div[class^='icofish__'] {
  margin-right: 14px;
}

.filters_mobile__holder .catalog__section-name {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
}

.filters_mobile__holder .catalog__section-name:hover {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.filters_mobile__holder .catalog__articles {
  background-color: transparent;
  height: unset;
  padding-left: 32px;
}

.filters_mobile__holder .catalog__articles .catalog__scroll {
  height: 100% !important;
  max-height: unset !important;
}

.filters_mobile__holder .catalog__articles .scrollbar-inner > .scroll-element.scroll-y {
  width: 4px;
  right: 8px;
}

.filters_mobile__holder .catalog__articles .scrollbar-inner > .scroll-element_outer > .scroll-bar {
  background-color: rgba(32, 54, 134, 0.3);
}

.filters_mobile__holder .catalog__link {
  padding: 8px 4px 8px 0;
  font-size: 12px;
  line-height: 130%;
}

.filters_mobile__holder .catalog__articles .scroll-wrapper {
  margin-top: 5px;
  margin-bottom: 10px;
}

.filters_mobile__holder .catalog_main__title {
  padding: 8px 10px 8px 0;
  box-sizing: border-box;
  margin-top: 8px;
  margin-right: 8px;
}

.filters_mobile__holder .catalog_main__list_holder {
  padding-left: 8px;
}

/*  Страница производства  */

#container_production .icon__angle {
  transform: rotate(0);
}

#container_production .header__main.wrapper,
#container_production .header__aside .wrapper {
  max-width: 1120px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.production__caption {
  text-align: left;
  margin-top: 60px;
  padding-bottom: 20px;
  border-bottom: 1px dotted rgba(32, 54, 134, 0.3);
  margin-bottom: 20px;
}

.production__caption h1 {
  width: 60%;
  min-width: 780px;
  margin-right: 12px;
}

.production_about__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
  margin-bottom: 70px;
}

.production__block {
  grid-column: span 1;
  grid-row: span 1;
}

.production__block h2 {
  letter-spacing: -0.2px;
  margin-top: 10px;
  line-height: 120%;
}

.production__image {
  width: 100%;
  height: 170px;
}

.p_img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.catalog_main__labels {
  display: grid;
  grid-template-columns: auto 120px;
  grid-column-gap: 20px;
}

.catalog_main__tags {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  /*   .gr();
   grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
   grid-template-rows: repeat(auto-fit, minmax(30px, max-content));
   grid-column-gap: 20px;
   grid-row-gap: 20px;
   .to( 1800px, {
      grid-row-gap: 16px;
   });
   .to( 660px, {
      grid-template-columns: repeat(auto-fit, minmax(100px, 280px));
      grid-column-gap: 10px;
      grid-row-gap: 10px;
   });
   .xxs-block({
      grid-template-columns: repeat(auto-fit, minmax(100px, 100%));
   })*/
}

.catalog_main__tags > * {
  margin-right: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
}

.cm__tag {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 4px 6px 4px 12px;
  border-radius: 8px;
  border: 1px solid #203686;
  box-sizing: border-box;
  background-color: white;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #203686;
}

.cm__tag .ico__del {
  margin-left: 8px;
}

#container_production .catalog_main__caption {
  justify-content: flex-start;
  display: flex;
  align-items: flex-end;
}

#container_production .catalog_main__caption h3 {
  font-size: 32px;
  margin-right: 12px;
}

#container_production .catalog_main__caption span {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 3px;
}

#production .catalog__link {
  font-size: 12px;
  line-height: 14px;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: transparent;
}

#production .catalog__link:hover {
  font-size: 12px;
  line-height: 14px;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: #F4F7FB;
}

#production .catalog__articles {
  padding: 10px 0 16px;
}

#production .catalog__section.open .icon__angle {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}

#container_production .catalog__fbutton {
  margin-left: 0;
}

.suppliers_main__content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 32px;
  margin-bottom: 40px;
}

.suppliers_main__content .section-title-text-14 {
  grid-column: 1 / 4;
}

.suppliers_main__content .caption {
  grid-column: 4 / 13;
}

.suppliers_main__content .caption {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
}

.suppliers_steps__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.cooperation__step {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 32px;
  padding: 16px 0 17px;
  border-top: 1px solid rgba(32, 54, 134, 0.2);
}

.cooperation__step:last-of-type {
  border-bottom: 1px solid rgba(32, 54, 134, 0.2);
}

.cooperation__text {
  grid-column: 9 / 13;
}

.label-load .button-hollow {
  height: 100%;
  padding: 12.5px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-load .button-hollow .ico__plus {
  width: 15px;
  height: 15px;
}

.supplier_form__holder .gr {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}

.supplier_form__holder .mb {
  margin-bottom: 40px;
}

/* О КОМПАНИИ */

.caption__wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

.about__video {
  position: relative;
  z-index: 10;
  border-radius: 20px;
  overflow: hidden;
}

.about__video:after,
.about__video:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.about__video:before {
  z-index: 20;
  width: 70px;
  height: 70px;
  background-color: #FFED32;
  border-radius: 100px;
}

.about__video:after {
  z-index: 30;
  content: '';
  display: block;
  position: absolute;
  background-image: url("../images/svg/icons/control/arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  transform: translateX(-35%) translateY(-50%);
}

.contacts_map__container {
  display: grid;
  grid-template-columns: 352px auto;
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
}

.contacts_map__info {
  display: grid;
  grid-template-rows: repeat(4, max-content);
  grid-row-gap: 10px;
}

.contacts_map__block {
  background-color: #F4F7FB;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.contacts_map__block.show {
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.contacts_map__name {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  margin-bottom: 4px;
}

.contacts_map__address,
.contacts_map__tel {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  cursor: auto;
}

.contacts_map__tel {
  margin-top: 4px;
}

.contacts_map___link {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.contacts_map__img {
  object-fit: cover;
  object-position: center;
  min-width: 100%;
  min-height: 100%;
}

.contacts_camera .watch-more {
  grid-column: 1 / 13;
  margin-top: 20px;
  display: none;
}

.contact_cameras__row {
  grid-column: 1 / 13;
  grid-row: span 1;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 20px;
}

.contact_cameras__row .cameras__video-4 {
  background-image: url("../images/middle/camera/2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.contact_cameras__row .camera__block-1 {
  grid-column: 1 / 4;
}

.contact_cameras__row .camera__block-4 {
  grid-column: 4 / 7;
}

.contact_cameras__row .camera__block-3 {
  grid-column: 7 / 10;
}

.contact_cameras__row .camera__block-2 {
  grid-column: 10 / 13;
}

.payment_labels__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  border-radius: 8px;
  color: #70798E;
  background-color: #F4F7FB;
  margin-bottom: 40px;
}

.payment__label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 21px 10px;
  color: #70798E;
  background-color: #F4F7FB;
  border: 1px solid #F4F7FB;
  border-top-color: #DDE1E6;
  border-bottom-color: #DDE1E6;
  box-sizing: border-box;
  cursor: pointer;
}

.payment__label:first-of-type {
  border-left-color: #DDE1E6;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.payment__label:last-of-type {
  border-right-color: #DDE1E6;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.payment__label.chosen {
  color: #203686;
  background-color: #FFFFFF;
  border: 1px solid #203686;
  border-radius: 8px;
}

.payment_info {
  display: grid;
  grid-template-columns: auto 352px;
  grid-template-rows: auto;
  grid-column-gap: 32px;
  display: none;
}

.payment_info.chosen {
  display: grid;
}

.payment_info__image {
  border-radius: 20px;
  overflow: hidden;
}

.payment_info__screen {
  overflow: visible;
}

.payment_info__line {
  padding: 20px 0;
  border-bottom: 1px solid #DDE1E6;
}

.payment_info__line:first-of-type {
  padding-top: 0;
}

.payment_info__line:last-of-type {
  border-bottom: unset;
}

.payment_info__line .text-18-title {
  margin-bottom: 10px;
}

.payment_info__form {
  background-color: white;
  padding: 40px;
  box-sizing: border-box;
  position: relative;
}

.payment_info__form:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 55;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.payment_info__form .caption {
  position: relative;
  z-index: 110;
  margin-bottom: 30px;
}

.payment__form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 100;
  margin-left: -5px;
  margin-right: -5px;
}

.payment__form .label-input {
  height: 60px;
  border: 1px solid #DDE1E6;
  box-sizing: border-box;
  margin-right: 5px;
  margin-left: 5px;
}

.payment__form input {
  border: unset;
}

.payment__form .half {
  width: calc(50% - 10px);
}

.payment__form #payment_btn {
  margin-top: 40px;
}

.payment__title {
  display: none;
}

.delivery_main__content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  grid-column-gap: 32px;
  grid-row-gap: 40px;
}

.delivery_main__content .relation {
  padding-top: 61.1%;
}

.delivery_main__text {
  grid-row: span 1;
  grid-column: 4 / 13;
  margin-top: -20px;
  display: flex;
}

.delivery_main__text p:first-of-type {
  min-width: 340px;
  margin-right: 20px;
}

.delivery_main__text p {
  max-width: 310px;
  line-height: 20px;
}

.delivery_main__theses {
  display: grid;
  grid-column: 1 / 13;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 0;
}

.delivery_main__thesis {
  grid-row: span 1;
}

.delivery_thesis-1 {
  grid-column: 1 / 5;
}

.delivery_thesis-2 {
  grid-column: 5 / 9;
}

.delivery_thesis-3 {
  grid-column: 9 / 13;
}

.delivery_main__thesis_title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  width: 83%;
  font-size: 18px;
  line-height: 120%;
  margin-top: 10px;
}

.delivery_main__thesis_image {
  border-radius: 10px;
  overflow: hidden;
}

.delivery__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.delivery_conditions__container {
  margin-top: 80px;
}

.delivery_conditions__blocks {
  display: grid;
  grid-column: 4 / 13;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-column-gap: 32px;
  grid-row-gap: 20px;
  margin-top: 40px;
}

.delivery_conditions__block {
  grid-column: 4 / 13;
  display: flex;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.delivery_conditions__icon {
  margin-right: 16px;
  width: 44px;
  height: 44px;
}

.delivery_conditions__title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.condition-text .dc-dot {
  margin-right: 8px;
}

.delivery_conditions__text {
  max-width: 600px;
}

.condition-text {
  margin-right: 8px;
}

.delivery_conditions__text_big {
  display: flex;
  flex-direction: column;
  width: calc(50% - 2px);
  box-sizing: border-box;
}

.delivery_conditions__text_big:first-of-type {
  border-right: 1px solid #DDE1E6;
  padding-right: 10px;
}

.delivery_conditions__text_big:last-of-type {
  border-left: 1px solid #DDE1E6;
  padding-left: 10px;
}

.delivery_camera__holder {
  display: grid;
  grid-column: 4 / 13;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 32px;
}

.delivery_camera__text {
  grid-column: 10 / 13;
  grid-row: 1 / 2;
}

.delivery_camera__video {
  grid-column: 4 / 10;
  grid-row: 1 / 2;
  z-index: 10;
}

.delivery_camera__video:after,
.delivery_camera__video:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.delivery_camera__video:before {
  z-index: 20;
  width: 70px;
  height: 70px;
  background-color: #FFED32;
  border-radius: 100px;
}

.delivery_camera__video:after {
  z-index: 30;
  content: '';
  display: block;
  position: absolute;
  background-image: url("../images/svg/icons/control/arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  transform: translateX(-35%) translateY(-50%);
}

.delivery_camera__video .relation {
  padding-top: 59.3%;
}

.delivery_camera__video .relation_content {
  border-right: 20px;
  overflow: hidden;
}

.delivery_camera {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.price__caption {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 32px;
  grid-row-gap: 40px;
}

.price__caption h1.caption {
  grid-column: 1 / 4;
}

.price__caption p {
  grid-column: 4 / 9;
  max-width: 380px;
}

.price__caption .button-yellow {
  grid-column: 11/ 13;
  width: 245px;
  min-width: 245px;
  justify-self: flex-end;
}

.price__caption .ico__load {
  width: 20px;
  height: 20px;
}

.price__search {
  margin-bottom: 20px;
}

.price__form {
  padding: 0;
  padding-left: 36px;
  border: 1px solid #DDE1E6;
}

.price_section__column {
  background-color: white;
  border-radius: 10px;
}

.price_section__about {
  display: grid;
  grid-template-columns: 13px auto 300px;
  grid-column-gap: 10px;
  padding: 16px 24px;
  box-sizing: border-box;
}

.price_section__title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}

.price_section__item {
  display: grid;
  grid-template-columns: auto 90px 120px  128px 40px;
  grid-column-gap: 10px;
  align-items: center;
  padding: 10px 24px;
  border-top: 1px solid #DDE1E6;
}

.price__container .mb {
  margin-bottom: 40px;
}

.price_item__title {
  padding-right: 24px;
  box-sizing: border-box;
}

.price_item__purchase {
  display: flex;
  justify-content: space-between;
  width: 128px;
  height: 40px;
  border: 1px solid #DDE1E6;
  border-radius: 10px;
  padding: 12.5px 24px;
  box-sizing: border-box;
}

.price_item__purchase .ico__minus,
.price_item__purchase .ico__plus {
  width: 15px;
  height: 15px;
}

.select-icon {
  background-image: url(../images/svg/icons/menu/search.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: none;
}

.price_section_form .select2-container--default .select2-selection--single .select2-selection__arrow {
  background-image: url(../images/svg/icons/menu/search.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.select-price-md .select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 0;
}

.select-price-md .select2-container,
.select-price-md .select2-container--default .select2-selection--single {
  min-width: 20px;
  max-width: 20px;
  height: 20px;
}

.select-price-md .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: unset;
}

.select-price-md .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
}

.select-price-md .select2.select2-container.select2-container--default.select2-container--open .select2-selection__arrow {
  top: 0;
  transition: unset;
}

.select-price-md .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
  transition: unset;
}

.select-price-md .select2-container--default .select2-selection--single {
  border: unset;
}

.select-price-md select::-moz-placeholder {
  color: transparent;
  transition: all 0.3s ease-in-out;
}

.select-price-md select::-webkit-input-placeholder {
  color: transparent;
  transition: all 0.3s ease-in-out;
}

.select-price-md select::-ms-input-placeholder {
  color: transparent;
  transition: all 0.3s ease-in-out;
}

.select-price-md select::-ms-input-placeholder {
  color: transparent;
  transition: all 0.3s ease-in-out;
}

.select-price-md select::placeholder {
  color: transparent;
  transition: all 0.3s ease-in-out;
}

.select-price-full {
  border: 1px solid #DDE1E6;
}

#container_one .header__main.wrapper,
#container_one aside .wrapper {
  max-width: 1120px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.one_item__wrapper {
  display: grid;
  grid-template-columns: 256px auto;
  grid-template-rows: auto auto auto;
  grid-column-gap: 50px;
}

.one_item__content {
  display: grid;
  grid-template-columns: 553px minmax(300px, 450px) minmax(255px, 460px);
  grid-template-rows: auto 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

.one_image__holder {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  max-width: 553px;
}

.one_data__holder {
  display: grid;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  grid-template-columns: 353px;
  grid-template-rows: repeat(4, min-content);
  grid-row-gap: 20px;
}

.one_item__characteristic {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
}

.one_purchase__holder {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

.one_about__holder {
  grid-column: 3 / 4;
}

.one_item__wrapper .subscribe__wrapper_catalog.subscribe__wrapper {
  grid-column: 1 / 4;
  grid-row: 3 / 4;
  padding: 50px;
}

.one_item__wrapper .subscribe__wrapper_catalog.subscribe__wrapper .subscribe__holder {
  padding-top: 64px;
}

.one_item__wrapper .subscribe__wrapper_catalog.subscribe__wrapper:after {
  height: 94%;
}

.one_image__holder .slick-track:before,
.one_image__holder .slick-track:after {
  position: absolute;
  background-color: #EBEDF0;
}

.one_image__holder button.slick-arrow {
  color: transparent;
  font-size: 0;
}

.one_image__holder .slick-prev.slick-arrow,
.one_image__holder .slick-next.slick-arrow {
  position: absolute;
  background-color: #EBEDF0;
  transform: none;
  z-index: 100;
  top: -1px;
  bottom: -1px;
  width: 51px;
  height: calc(100% + 2px);
  color: transparent;
  font-size: 1px;
  line-height: 0;
}

.one_image__holder .slick-prev.slick-arrow {
  left: -2px;
  background-image: url("../images/svg/icons/b-angle-l.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
}

.one_image__holder .slick-next.slick-arrow {
  right: -2px;
  background-image: url("../images/svg/icons/b-angle-r.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
}

.one_image__main {
  margin-bottom: 20px;
}

.one_image__main,
.one_image__main .slick-list,
.one_image__main img {
  border-radius: 10px;
  overflow: hidden;
}

.one_item__small img {
  border-radius: 10px;
  overflow: hidden;
  width: 82px;
  height: 82px;
  object-fit: cover;
  border: 1px solid transparent;
  box-sizing: border-box;
}

.one_item__small .slick-slide.slick-current.slick-active.slick-center {
  border-color: #203686;
}

.one_item__caption {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
}

.one_item__parameter_title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.one_item__parameter_holder {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, 34px);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: start;
}

.one_item__parameter_holder .parameter_pack-1 {
  width: 218px;
  grid-column: 1 / 3;
}

.one_item__parameter_holder .parameter_pack-2 {
  width: 142px;
  grid-column: 1 / 2;
}

.one_item__parameter_holder .parameter_pack-3 {
  width: 71px;
  grid-column: 2 / 3;
}

.one_item__parameter .product__amount {
  max-width: 143px;
}

.one_item__parameter_pack {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content;
  padding: 8px 12px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 8px;
  border: 1px solid transparent;
}

.one_item__parameter_pack:first-of-type {
  grid-column: 1 / 2;
}

.one_item__parameter_pack:nth-of-type(2) {
  grid-column: 2 / 3;
}

.one_item__parameter_pack.chosen {
  border-color: #203686;
}

.one_item__parameter_pack:not(.chosen) {
  box-shadow: 1px 1px 2px 0 rgba(37, 37, 37, 0.15);
}

.one__characteristic {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  padding: 10px 0;
}

.one__characteristic > div {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 5px;
  margin-right: 5px;
}

.one_characteristic__title {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  min-width: 120px;
  width: 120px;
}

.one_characteristic__text {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  width: calc(100% - 120px - 20px);
}

.one_item__purchase_holder {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.one_item__purchase_holder button {
  margin-top: 20px;
}

.one_item__total {
  display: flex;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 10px;
}

.one_item__price {
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
}

.one_item__buy {
  border-bottom: 1px solid #DDE1E6;
  padding-bottom: 20px;
}

.one_item__delivery {
  margin-top: 20px;
}

.one_item__schedule {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(2, min-content);
  grid-column-gap: 20px;
}

.one_item__schedule .mb10.text-blue.text-16-big {
  grid-column: 1 / 2;
}

.one_item__schedule .one_item__schedule_day:first-of-type {
  grid-column: 1 / 2;
}

.one_item__schedule .one_item__schedule_day:nth-of-type(2) {
  grid-column: 2 / 3;
}

.one_item__schedule_day {
  padding-bottom: 20px;
}

.one_item__availability {
  padding-top: 20px;
  border-top: 1px solid #DDE1E6;
}

.one_item__availability_warehouse {
  display: flex;
}

.one_item__availability_warehouse div[class^='icon__'] {
  margin-right: 10px;
}

.one_purchase__title {
  display: none;
}

.similar_carousel__holder {
  margin-top: 140px;
}

.similar_carousel__holder .caption {
  margin-bottom: 40px;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
}

.similar_carousel__holder .section__caption {
  display: block;
  display: block !important;
}

.login__container {
  background-color: white;
  position: fixed;
  z-index: 2000;
  top: 0;
  bottom: 0;
  width: 730px;
  padding: 40px;
  box-sizing: border-box;
  right: -1000px;
  display: none;
  transition: all 0.3s ease-in-out;
}

.login__container input {
  color: #203686;
}

.register__container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.login__title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
}

.login__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #DDE1E6;
  border-radius: 10px;
}

.login__or {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.login__or:before,
.login__or:after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  width: 50%;
  top: 50%;
  transform: translateY(50%);
  background-color: #DDE1E6;
  z-index: 100;
}

.login__or:before {
  left: 0;
}

.login__or:after {
  right: 0;
}

.login__or p {
  position: relative;
  z-index: 200;
  padding: 0 8px;
  background-color: white;
}

.login__form,
.register__form {
  display: flex;
  flex-direction: column;
}

.login__form .button-yellow,
.login__form .button-hollow {
  height: 60px;
}

.register__entities {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 60px;
  grid-column-gap: 16px;
  margin-bottom: 20px;
}

.register_entities__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  border-radius: 10px;
  background-color: transparent;
  color: #203686;
  border: 1px solid #DDE1E6;
  padding: 12.5px 24px;
  flex-grow: 1;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.register_entities__button.active {
  background-color: #203686;
  color: white;
  border: 1px solid #203686;
}

.register_form {
  display: flex;
  flex-direction: column;
}

.register__form {
  grid-row-gap: 10px;
}

.register__form {
  display: none;
}

.register__form:first-of-type {
  display: grid;
}

.register_agree {
  margin-bottom: 6px;
}

.register__form button {
  margin-top: 10px;
}

.login_via_social__holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  position: absolute;
  bottom: 45px;
}

.login_via_social__title {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 15px;
}

.login_via_social__buttons {
  display: grid;
  grid-template-columns: repeat(4, 40px);
  grid-template-rows: 40px;
  grid-column-gap: 16px;
}

#register_form_yr .select2-container {
  min-width: 100%;
}

#register_form_yr .select2-container--default .select2-selection--single {
  background: #F4F7FB;
}

.login__container .margin {
  margin-bottom: 20px;
}

.login__container .button-yellow {
  height: 60px;
}

#recover_gomail,
#reset_done {
  display: none;
}

.login__welcome {
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 345px;
  margin: 25% auto 0;
}

.login__welcome .welcome {
  margin-bottom: 31px;
}

.login__welcome .margin {
  margin-bottom: 10px;
}

.login__welcome #login_finish {
  margin: 30px auto 0;
}

.login_welcome__title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
}

.basket__caption_top {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.basket__caption_top #amount_basket {
  position: relative;
  top: unset;
  right: unset;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 145%;
  background-color: #FFED32;
  color: #203686;
  box-sizing: border-box;
  padding: 0 0 1px 1px;
  margin-left: 20px;
}

.basket_order__steps {
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #F4F7FB;
}

.basket_order__step {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  width: 25%;
  padding: 18px 20px;
  box-sizing: border-box;
  color: #70798E;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  cursor: default;
  transition: all 0.3s ease-in-out;
}

.basket_order__step:first-of-type,
.basket_order__step:nth-of-type(2) {
  border-right: 1px solid #DDE1E6;
}

.basket_order__step:last-of-type {
  border-left: 1px solid #DDE1E6;
}

.basket_order__number {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin-right: 8px;
  box-sizing: border-box;
  padding: 0 0 1px 1px;
  transition: all 0.3s ease-in-out;
  flex-shrink: 0;
}

.basket_order__title {
  transition: all 0.3s ease-in-out;
  text-align: left;
  white-space: nowrap;
}

.basket_order__step.progress {
  cursor: pointer;
  background-color: white;
}

.basket_order__step.progress .basket_order__number {
  background-color: #203686;
  color: white;
}

.basket_order__step.progress .basket_order__title {
  color: #203686;
}

.basket_order__step.progress[disable="true"] {
  cursor: default;
}

.basket_order__wrapper .section_holder {
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: none;
}

.basket_order__wrapper .section_holder.show {
  display: grid;
}

.basket_order__wrapper .section_main {
  grid-column: 1 / 9;
}

.basket_order__wrapper .section_second {
  grid-column: 9 / 13;
}

.basket_order__wrapper .section_block {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.basket_order__main {
  display: grid;
  grid-row-gap: 20px;
  align-items: start;
}

.basket_order__important {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  border: 1px solid #DDE1E6;
  border-left: unset;
}

.basket_order__important .ico__box {
  margin-right: 12px;
}

.line_blue {
  width: 6px;
  min-height: 100%;
  background-color: #203686;
}

.order__important {
  display: flex;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 12px 16px;
}

.basket_order_total__promo {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 48px;
  grid-column-gap: 16px;
  padding-bottom: 20px;
  border-bottom: 1px solid #DDE1E6;
}

.basket_order_total__promo .input {
  height: unset;
  border: unset;
  color: #203686;
}

.order_total__input,
.order_total__button {
  height: 48px;
  border-radius: 10px;
}

.order_total__input {
  grid-column: 1 / 9;
  border: 1px solid #DDE1E6;
}

.order_total__button {
  grid-column: 9 / 13;
  min-width: 117px;
}

.basket_order_total__sum {
  padding: 20px 0;
  border-bottom: 1px solid #DDE1E6;
}

.order_total__sum,
.total_sum__text,
.total__sum,
.order_total__discount,
.total__discount,
.basket_order__total,
.order__total {
  display: flex;
}

.order_total__sum,
.order_total__discount,
.basket_order__total {
  display: flex;
  justify-content: space-between;
}

.order_total__sum {
  margin-bottom: 16px;
}

.total_sum__text,
.total_discount__text,
.total__sum,
.total__discount,
.order_total__text {
  font-size: 14px;
  line-height: 110%;
}

.total_sum__text,
.total_discount__text,
.order_total__text {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.basket_order__total {
  padding: 20px 0;
  flex-wrap: wrap;
}

.total__sum,
.total__discount,
.order__total {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.order__total {
  font-size: 16px;
  line-height: 120%;
}

.basket_order__total .text-dark {
  width: 100%;
}

#basket_order_code {
  display: grid;
  grid-template-columns: 191px 200px 182px;
  grid-template-rows: 1fr 48px;
  grid-column-gap: 10px;
  display: none;
}

#basket_order_code .text-small {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  width: 100%;
}

#basket_order_code label {
  position: relative;
  width: 191px;
  grid-row: 2 / 3;
}

#basket_order_code input {
  height: 48px;
}

#basket_order_code #order_code_button {
  width: 200px;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.order_button__again {
  display: flex;
  align-items: center;
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

.options_popup {
  position: relative;
  z-index: 70;
  padding: 16px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 18px 0 rgba(32, 54, 134, 0.16);
  white-space: nowrap;
}

.options_popup:after {
  content: '';
  display: block;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 12px;
  background-image: url("../images/svg/icons/lk/basket/pointer.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.basket_thanks__holder.last {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  grid-row-gap: 10px;
  justify-items: center;
  justify-content: center;
  padding-top: 60px;
}

.basket_thanks__holder.last p {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  max-width: 360px;
  text-align: center;
}

.basket_thanks__caption {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
}

.basket_thanks__text {
  display: inline-block;
}

.basket_thanks__text span {
  display: inline;
}

.basket_thanks__button_holder {
  width: 250px;
  max-width: 250px;
}

.basket_thanks__button {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding-top: 22.5px;
  padding-bottom: 22.5px;
  margin-top: 10px;
}

.basket_thanks__holder .f,
.basket_thanks__holder .t {
  display: block;
}

.basket_thanks__holder .s {
  display: none;
}

/*.basket_order__out {
   margin-top: 20px;
}*/

.basket_order__cart {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 20px;
}

.basket_order__management {
  grid-column: 1 / 13;
  display: flex;
  align-items: center;
}

.basket_order__management .icon__bin {
  display: none;
}

.basket_order__del {
  color: #F10B34;
  margin-left: 10px;
}

.basket_order__stock_btn {
  margin-left: 20px;
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #DDE1E6;
}

.basket_order__stock_btn.chosen {
  border-color: #203686;
}

.basket_group__title,
.basket_order_total__title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
}

.product__title {
  max-width: 260px;
}

.basket_order__group {
  grid-column: 1 / 13;
}

.basket_product__block {
  padding: 16px 0 16px;
  border-top: 1px solid #DDE1E6;
  box-sizing: border-box;
}

.basket_order__group .basket_product__block:nth-child(2) {
  border-top-color: transparent;
}

.basket_product__block {
  display: grid;
  grid-template-columns: 20px 120px repeat(10, auto);
  grid-template-rows: auto 40px minmax(18px, auto);
  grid-column-gap: 16px;
  grid-row-gap: 0;
  align-items: center;
}

.basket_product__image {
  grid-column: 2 / 3;
  grid-row: 1 / 4;
  min-width: 120px;
  min-height: 120px;
  background-color: #F4F7FB;
  border-radius: 10px;
}

.basket_product__checkbox {
  grid-column: 1 / 2;
  grid-row: 1 / 4;
}

.product__title {
  grid-column: 3 / 8;
  grid-row: 1 / 2;
  align-self: flex-end;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #203686;
  margin-bottom: 10px;
}

.product__info {
  grid-column: 3 / 8;
  grid-row: 2 / 3;
  align-self: flex-start;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #70798E;
}

.product__article,
.product__piece {
  display: flex;
}

.basket_product__buy {
  grid-column: 8 / 13;
  grid-row: 1 / 2;
  justify-self: flex-end;
  align-self: flex-end;
  margin-bottom: 10px;
}

.basket__price {
  grid-column: 9 / 13;
  justify-self: flex-end;
  display: flex;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #203686;
  text-align: right;
  margin-bottom: 2px;
}

.basket__item {
  grid-column: 9 / 13;
  justify-self: flex-end;
  display: flex;
  justify-content: flex-end;
  font-family: 'Montserrat-Regular', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #70798E;
  text-decoration: line-through;
}

.basket__amount {
  grid-column: 9 / 13;
  grid-row: 2 / 3;
  justify-self: flex-end;
  width: 123px;
  background-color: #FFFFFF;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 16px auto 16px;
  grid-template-rows: 15px;
  align-self: flex-start;
  padding: 12.5px 11.5px;
  box-sizing: border-box;
  border: 1px solid #DDE1E6;
}

.basket__amount > button[disabled='true'] {
  background-color: transparent;
  border: none;
}

.amount_important {
  grid-column: 9 / 13;
  grid-row: 3 / 4;
  display: flex;
  justify-content: flex-end;
  line-height: 120%;
  margin-top: 4px;
  text-align: right;
  white-space: nowrap;
}

.basket_product__block_out {
  grid-template-columns: 20px 120px repeat(10, auto);
  grid-template-rows: auto 40px 42px;
}

.basket_product__block_out .product__title {
  color: #70798E;
}

.product__another {
  grid-column: 3 / 13;
  grid-row: 3 / 4;
  align-self: flex-end;
  display: flex;
}

.button-another {
  width: 100%;
  height: 32px;
  border-radius: 8px;
  background-color: #F4F7FB;
  border: 1px solid #DDE1E6;
  cursor: pointer;
  color: #203686;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
}

.product__another .button-another {
  white-space: nowrap;
  margin-right: 10px;
}

.basket_order_total__title {
  margin-bottom: 20px;
}

.basket_address__contact button {
  width: 200px;
  max-width: unset;
}

.basket_address__obtaining {
  position: relative;
  z-index: 10;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(32, 54, 134, 0.2);
  margin-bottom: 20px;
}

.address_obtaining__button {
  position: relative;
  z-index: 40;
  padding: 16px;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  transition: all 0.3s ease-in-out;
}

.address_obtaining__button.hidden {
  border-bottom-color: transparent;
}

.address_obtaining__button.chosen {
  border-bottom-color: #203686;
}

.basket_address__obtaining .hide {
  display: none;
}

.address_obtaining__point,
.address_obtaining__items label {
  display: flex;
}

.address_obtaining__items .label-checkbox {
  margin-right: 12px;
}

.ico__options {
  position: relative;
  z-index: 50;
  width: 32px;
  height: 32px;
  margin-top: 4px;
}

.address_obtaining__point {
  position: relative;
  z-index: 50;
}

.point_options__popup {
  position: absolute;
  z-index: 1500;
  top: 40px;
  left: calc(100% - 16px);
  transform: translateX(-50%);
  display: none;
}

.address_obtaining__add {
  align-items: center;
  display: flex;
  justify-content: center;
  width: max-content;
  padding: 15px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #DDE1E6;
  white-space: nowrap;
}

.address_obtaining__address {
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  background-color: #F4F7FB;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 20px auto 24px;
  grid-column-gap: 12px;
  align-items: center;
}

.address_obtaining__address input {
  grid-column: 1 / 2;
}

.address_obtaining__address .obtaining_address__text {
  grid-column: 2 / 3;
}

.basket_address__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}

.obtaining__select {
  width: 100%;
}

.obtaining__select + .select2-container {
  min-width: 100%;
}

.obtaining__select + .select2-container--default .select2-selection--single {
  background-color: #F4F7FB;
}

.obtaining__select + .select2-container .select2-selection--single .select2-selection__rendered {
  color: #203686;
}

.send_again__popup {
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  display: none;
}

.order_code_again {
  width: 203px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  white-space: normal;
}

.point_options__popup .line_gray {
  width: 100%;
  height: 4px;
  margin-top: 8px;
  background-image: url("../images/svg/icons/lk/basket/line.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  display: none;
}

.obtaining_map {
  color: #70798E;
  margin-bottom: 10px;
}

.options_popup__text {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.address_obtaining div[class^='ico__'] {
  margin-right: 4px;
}

.address_popup__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: #F4F7FB;
  border: 1px solid #DDE1E6;
  cursor: pointer;
  color: #203686;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  margin-top: 20px;
  display: none;
}

.address_obtaining__items.godown .radio {
  background-image: url("../images/svg/icons/lk/basket/no-radio-back.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.address_obtaining__items.godown .checkbox-hidden:checked + .radio {
  background-image: url("../images/svg/icons/lk/basket/yes-radio.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.address_obtaining__items.godown .ico__options {
  background-image: url("../images/svg/icons/lk/basket/options-back.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
}

.watch_place__dostavka {
  flex-grow: 999;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.add_place_form__holder,
.add_place_map__holder {
  width: 50%;
  height: 445px;
  display: flex;
  flex-direction: column;
}

.add_place_map__holder > * {
  min-height: 100%;
}

.add_place_form__holder {
  padding: 20px;
  box-sizing: border-box;
}

.obtaining__dostavka {
  flex-grow: 999;
}

.obtaining__dostavka .label-input,
.obtaining__dostavka input[type='text'] {
  max-height: 60px;
  height: 60px;
}

.add_place__title {
  margin-bottom: 20px;
}

.dostavka__form {
  min-height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
}

.dostavka__form input {
  width: 100%;
}

.dostavka_st {
  width: 100%;
  margin-bottom: 10px;
}

.dostavka_ch,
.dostavka_of {
  width: 100%;
  height: 20px;
}

.dostavka_of {
  margin-bottom: 20px;
}

.dostavka_info {
  height: 48px;
}

.dostavka_btn {
  margin-top: auto;
  max-height: 48px;
}

.add_place__title_xs {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 18px;
  display: none;
}

input.obtaining_point_checkbox:checked + .point_options__popup {
  display: block;
}

input.obtaining_point_checkbox:not(:checked) + .point_options__popup {
  display: none;
}

.check_type__holder {
  padding-bottom: 20px;
}

.check_info__holder {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #DDE1E6;
}

.order_info__holder {
  border-top: 1px solid #DDE1E6;
  padding-top: 20px;
}

.payment_type__item {
  margin-bottom: 20px;
  display: flex;
}

.payment_type__item:last-of-type {
  margin-bottom: 0;
}

.payment_type__item .label-checkbox {
  margin-right: 12px;
}

.payment_type__item > span {
  font-size: 14px;
  line-height: 20px;
}

.personal_account__container {
  display: grid;
  grid-template-columns: 252px auto;
  grid-template-rows: auto;
  grid-column-gap: 32px;
  margin-top: 40px;
}

.personal_account__item {
  position: relative;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #70798E;
  margin-bottom: 16px;
  transition: all 0.3s ease-in-out;
}

.personal_account__current {
  color: #203686;
  padding-left: 20px;
  transition: all 0.3s ease-in-out;
}

.personal_account__current:before {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 80px;
  background-color: #203686;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}

.personal_account__content h1.title {
  margin-bottom: 40px;
}

.personal_account__container {
  display: grid;
  grid-row-gap: 20px;
}

.personal_account__container .section_block {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}

.personal_account__container .account__title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
}

.my_account__card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.my_account__card .account__title {
  margin-bottom: 10px;
}

.account_block__cashback {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  min-height: calc(100% - 38px);
}

.account_block__card {
  min-width: 45%;
  max-width: 380px;
  margin-top: -20px;
  margin-right: -20px;
  margin-bottom: -38px;
}

.my_account__card .account__span {
  max-width: 300px;
}

.account_tile__cashback {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 20px;
}

.account_tile__cashback {
  padding-top: 16px;
}

.account_cashback__block {
  min-width: 175px;
}

.my_account__info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #DDE1E6;
}

.my_account__article {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  background-color: #F4F7FB;
  border: 1px solid #DDE1E6;
  border-radius: 10px;
  padding: 16px;
  box-sizing: border-box;
  height: 110px;
}

.my_account__article_text {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
}

.my_account__article_change {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
}

.my_account__contact_information .account__title {
  margin-bottom: 20px;
}

.my_account__contact_form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 16px;
}

.contact_information__input {
  grid-column: 1 / 3;
  display: flex;
}

.contact_information__item {
  display: flex;
  align-items: center;
}

.contact_information__item .label-checkbox {
  margin-right: 12px;
}

[data-confirmed='true'] .input {
  border: 1px solid #6FBD15;
}

[data-confirmed='false'] .input {
  border: 1px solid #F10B34;
}

.ico__confirmed {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.confirmed {
  position: absolute;
  left: 0;
  bottom: -20px;
}

[data-confirmed='true'] .confirmed .yes {
  color: #6FBD15;
}

[data-confirmed='false'] .confirmed .no {
  color: #F10B34;
}

[data-confirmed='true'] .confirmed .no,
[data-confirmed='false'] .confirmed .yes {
  display: none;
}

.my_account__contact_button {
  grid-column: 1 / 3;
  display: flex;
  margin-top: 38px;
}

.my_account__contact_button button.button-cancel {
  cursor: pointer;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  padding: 16.5px 26.5px;
  margin-left: 10px;
  transition: all 0.3s ease-in-out;
}

.my_account__contact_button .button-cancel:hover {
  transition: all 0.3s ease-in-out;
  color: rgba(32, 54, 134, 0.3);
}

.my_account__contact_button .button-cancel:focus,
.my_account__contact_button .button-cancel:target {
  transition: all 0.3s ease-in-out;
  color: #70798E;
}

.orders_history {
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
}

.orders_info__admin {
  display: grid;
  grid-template-columns: 245px auto 189px;
  grid-template-rows: 36px;
  align-items: center;
  grid-column-gap: 16px;
  margin-bottom: 20px;
}

.orders_info__show {
  justify-self: flex-end;
}

.orders_info__change {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.orders_info__select {
  width: 189px;
  height: 36px;
}

.orders_info__select .select2.select2-container.select2-container--default {
  min-width: 189px;
  max-width: 189px;
  min-height: 36px;
  max-height: 36px;
}

.orders_info__select .select2-container--default .select2-selection--single {
  min-height: 36px;
  max-height: 36px;
}

.orders_info__select .select2-container .select2-selection--single .select2-selection__rendered {
  color: #203686;
  height: 36px;
}

.orders_info__table {
  padding: 0;
  margin-bottom: 40px;
}

.orders_info__table_header {
  border-bottom: 1px solid #DDE1E6;
  padding: 12px 24px;
  box-sizing: border-box;
  display: flex;
}

.orders_col_numb {
  width: 34.4%;
  text-align: left;
  flex-grow: 1;
}

.orders_col_date {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17.5%;
  flex-grow: 2;
}

.orders_col_status {
  width: 18.3%;
  text-align: center;
  flex-grow: 2;
}

.orders_col_amount {
  width: 22.5%;
  text-align: center;
  flex-grow: 1;
}

.orders_col_watch {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  width: 7.3%;
  flex-grow: 1;
}

.orders_info__table_row {
  border-bottom: 1px solid #DDE1E6;
  padding: 30.5px 24px;
  box-sizing: border-box;
  display: flex;
}

.orders_info__table_row:last-of-type {
  border-bottom: unset;
}

.orders_info__table_row .orders_col_numb {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}

.orders_info__table_row .orders_col_numb span {
  display: none;
}

.orders_info__table_row .block-sm {
  display: none;
}

.orders_info__table_row .orders_col_date,
.orders_info__table_row .orders_col_status {
  font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.orders_info__table_row .orders_col_amount {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.button-orders-more {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: #F4F7FB;
  cursor: pointer;
  border: 1px solid #DDE1E6;
}

.button-orders-more .watch-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.button-orders-more span {
  color: #203686;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
}

.button-orders-more:hover span {
  color: rgba(32, 54, 134, 0.9);
  transition: all 0.3s ease-in-out;
}

.orders_history__container .catalog_main__pagination {
  margin-top: 10px;
}

.one_order__container {
  display: grid;
  grid-row-gap: 20px;
  margin-bottom: 40px;
}

.one_order__assessment .star {
  cursor: pointer;
}

.one_order_status__radio {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.one_order_status {
  position: relative;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.one_order_status:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 100;
  top: 12px;
  left: 12px;
  width: 100%;
  height: 1px;
  background-color: #DDE1E6;
}

.one_order_status span {
  display: inline-block;
  margin-top: 8px;
  width: 100%;
}

.one_order_status:last-of-type {
  flex-grow: 0;
  display: grid;
}

.one_order_status:last-of-type:after {
  display: none;
}

.one_order_status .radio_checked,
.one_order_status .radio_false {
  position: relative;
  z-index: 500;
}

.one_order_status__button {
  margin-bottom: 40px;
}

.one_order_delivery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 10px;
}

.one_order_delivery__block {
  display: flex;
  grid-column: span 1;
  grid-row: span 1;
}

.delivery__info,
.details__info,
.payment__info {
  margin-left: 16px;
}

.one_order_details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 10px;
}

.one_order_details__block {
  display: flex;
  grid-column: span 1;
  grid-row: span 1;
}

.one_order_payment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
}

.one_order_payment__block {
  display: flex;
  grid-column: span 1;
  grid-row: span 1;
}

.one_order__information {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  align-items: center;
}

.one_order_information__status {
  width: min-content;
  border-radius: 12px;
  border: 1px solid;
  padding: 5px 12px;
}

.one_order_composition__block {
  padding: 16px 0 16px;
  border-top: 1px solid #DDE1E6;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 120px minmax(150px, 260px) repeat(2, 1fr);
  grid-template-rows: auto 40px;
  grid-column-gap: 16px;
  grid-row-gap: 0;
  align-items: center;
}

.one_order_composition__block:first-of-type {
  border-top: 1px solid transparent;
  padding-top: 0;
}

.one_order_composition__image {
  grid-column: 1 / 2;
  grid-row: 1 / 4;
  min-width: 120px;
  min-height: 120px;
  background-color: #F4F7FB;
  border-radius: 10px;
}

.one_order_composition__title {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
}

.one_order_composition__info {
  grid-column: 2 / 3;
  grid-row: 2 / 4;
}

.one_order_composition__amount,
.one_order_composition__buy {
  margin-right: 60px;
  justify-self: flex-end;
}

.one_order_composition__amount {
  grid-column: 3 / 4;
  grid-row: 1 / 4;
}

.one_order_composition__buy {
  grid-column: 4 / 5;
  grid-row: 1 / 4;
}

.delivery_addresses__holder {
  margin-top: 40px;
}

.delivery_addresses__title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 16px;
}

.delivery_addresses .address_obtaining__address {
  background-color: white;
  max-width: 658px;
}

.address_personal__address {
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 20px auto 24px;
  grid-column-gap: 12px;
  align-items: center;
}

#lk_add_address.delivery_addresses {
  padding: 15px 14.5px;
  background-color: #F4F7FB;
}

.add_place_form__holder.add_address {
  width: 100%;
}

.add_address__title {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 20px;
}

.add_address__title_xs {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  width: 80%;
  text-align: left;
  display: none;
}

.add_place_form__holder.add_address {
  height: auto;
}

.payment_info__admin {
  display: grid;
  grid-template-columns: 245px 189px;
  grid-template-rows: 36px;
  align-items: center;
  grid-column-gap: 16px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.payment_info__admin .orders_info__sum {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  min-width: unset;
}

.payment_info__admin .orders_info__select {
  grid-row: 1 / 2;
  justify-self: flex-end;
  margin-left: 0;
}

.personal_menu__mobile {
  position: fixed;
  right: -999px;
  width: 320px;
  padding: 21px 15px;
  border: 1px solid #DDE1E6;
  box-sizing: border-box;
  background-color: white;
  z-index: 500;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  height: 60vh;
}

.personal_menu__header {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-bottom: 21px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

#open_menu {
  cursor: default;
}

.personal_menu__link,
.personal__out {
  font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #70798E;
}

.personal_link_current {
  color: #203686;
}

.personal__out {
  margin-top: auto;
  cursor: pointer;
}

.personal_account__setting .account__title {
  margin-bottom: 20px;
}

.personal_account__setting .personal_account__checkbox.mb {
  margin-bottom: 16px;
}

.personal_account__setting .title-mt {
  margin-top: 30px;
}

.personal_account__bottom {
  border-bottom: 1px solid #DDE1E6;
  padding-bottom: 30px;
}

.personal_account__password {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}

.footer__holder {
  overflow: hidden;
  background-color: #203686;
  padding-top: 30px;
  padding-bottom: 60px;
  margin-top: 80px;
}

.footer__holder .wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer__holder .wrapper > div {
  padding-top: 30px;
  flex-grow: 1;
}

.footer__holder .wrapper .row_flex-16 {
  margin-right: 0;
  margin-left: 0;
}

.footer__information_holder {
  flex-basis: 43%;
}

.footer__information_holder .footer__logo {
  margin-bottom: 32px;
}

.footer__information > div {
  margin-bottom: 32px;
}

.footer__information > div:last-of-type {
  margin-bottom: 0;
}

.footer__information .contact__number {
  margin-bottom: 32px;
  font-size: 24px;
  color: #FFFFFF;
}

.footer__information_section {
  font-family: 'Montserrat-Regular', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 145%;
}

.footer__links_holder {
  display: flex;
  height: 100%;
  flex-basis: 50%;
  min-width: 669px;
}

.footer__links_section:first-of-type {
  width: 193px;
}

.footer__links_caption {
  font-size: 16px;
  line-height: 145%;
  color: #F4F7FB;
  margin-bottom: 8px;
  opacity: 0.7;
}

.footer__links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 262px;
}

.footer__link {
  display: inline-block;
  min-width: 160px;
  max-width: 216px;
  margin-top: 18px;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.block {
  display: block !important;
}

@media screen and (min-width: 590px) {
  .similar_carousel__holder .slick-arrow {
    position: absolute;
    z-index: 70;
    top: -40px;
    transform: translateY(-100%);
    color: transparent;
    font-size: 0;
    line-height: 0;
  }

  .similar_carousel__holder .slick-prev[aria-disabled="false"] {
    left: calc(100% - 66px);
    background-image: url("../images/svg/icons/arrow-prev-active.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }

  .similar_carousel__holder .slick-next[aria-disabled="false"] {
    left: calc(100% - 28px);
    background-image: url("../images/svg/icons/arrow-next-active.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }

  .similar_carousel__holder .slick-next[aria-disabled="true"] {
    left: calc(100% - 28px);
    background-image: url("../images/svg/icons/arrow-next.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }

  .similar_carousel__holder .slick-prev[aria-disabled="true"] {
    left: calc(100% - 66px);
    background-image: url("../images/svg/icons/arrow-prev.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }

  #basket_wrapper .similar_carousel__holder .slick-arrow {
    position: absolute;
    z-index: 70;
    top: -40px;
    transform: translateY(-100%);
    color: transparent;
    font-size: 0;
    line-height: 0;
  }

  #basket_wrapper .similar_carousel__holder .slick-prev[aria-disabled="false"] {
    left: calc(100% - 66px);
    background-image: url("../images/svg/icons/arrow-prev-active.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }

  #basket_wrapper .similar_carousel__holder .slick-next[aria-disabled="false"] {
    left: calc(100% - 28px);
    background-image: url("../images/svg/icons/arrow-next-active.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }

  #basket_wrapper .similar_carousel__holder .slick-next[aria-disabled="true"] {
    left: calc(100% - 28px);
    background-image: url("../images/svg/icons/arrow-next.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }

  #basket_wrapper .similar_carousel__holder .slick-prev[aria-disabled="true"] {
    left: calc(100% - 66px);
    background-image: url("../images/svg/icons/arrow-prev.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }
}

@media screen and (min-width: 890px) {
  .programs__container .slick-arrow {
    position: absolute;
    z-index: 70;
    top: -90px;
    transform: translateY(-100%);
    color: transparent;
    font-size: 0;
    line-height: 0;
  }

  .programs__container .slick-prev[aria-disabled="false"] {
    right: 43px;
    background-image: url("../images/svg/icons/arrow-prev-active.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }

  .programs__container .slick-next[aria-disabled="false"] {
    right: 5px;
    background-image: url("../images/svg/icons/arrow-next-active.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }

  .programs__container .slick-next[aria-disabled="true"] {
    right: 5px;
    background-image: url("../images/svg/icons/arrow-next.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }

  .programs__container .slick-prev[aria-disabled="true"] {
    right: 43px;
    background-image: url("../images/svg/icons/arrow-prev.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }
}

@media screen and (min-width: 970px) {
  .variety_products__block-square {
    grid-column: span 1;
    grid-row: span 1;
  }

  .variety_products__block-rectangle {
    grid-column: span 2;
    grid-row: span 1;
  }
}

@media screen and (min-width: 1095px) {
  .filters_mobile__container {
    display: none;
    right: -99999999px;
  }
}

@media screen and (min-width: 1215px) {
  .personal_menu__mobile {
    display: none;
    right: -99999999px;
  }
}

@media screen and (min-width: 1220px) {
  .cover__holder {
    height: auto;
  }
}

@media screen and (min-width: 1555px) {
  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    max-width: 1756px !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }

  #container_catalog .header__aside .header__form {
    height: 40px;
    max-width: 520px;
    width: 520px;
    margin-right: auto;
  }

  #container_catalog #catalog_trigger {
    width: 311px;
    margin-right: auto;
  }

  .wrapper-big {
    max-width: 1756px !important;
    margin: 0 auto;
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    max-width: 1756px !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }

  #container_production .header__aside .header__form {
    height: 40px;
    max-width: 520px;
    width: 520px;
    margin-right: auto;
  }

  #container_production #catalog_trigger {
    width: 311px;
    margin-right: auto;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    max-width: 1756px !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 1555px) and screen and (min-width: 1555px) {
  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    max-width: 1756px !important;
    margin: 0 auto;
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    max-width: 1756px !important;
    margin: 0 auto;
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    max-width: 1756px !important;
    margin: 0 auto;
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}

@media screen and (max-width: 1825px) {
  #container_catalog .header__aside .header__form {
    height: 40px;
    max-width: 520px;
    width: auto;
    margin-right: auto;
  }

  .catalog_main__section {
    grid-column-gap: 20px;
    grid-row-gap: 40px;
  }

  .product__image .relation {
    padding-top: 73.7%;
  }

  #container_production .header__aside .header__form {
    height: 40px;
    max-width: 520px;
    width: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1800px) {
  .production_about__wrapper {
    grid-column-gap: 20px;
  }

  .production__block h2 {
    font-size: 18px;
  }

  .catalog_main__labels {
    grid-row-gap: 16px;
  }
}

@media screen and (max-width: 1640px) {
  .one_item__wrapper {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 32px;
  }

  .one_item__filter {
    display: none;
  }

  .one_item__content {
    display: grid;
    grid-template-columns: 448px minmax(353px, 465px) minmax(255px, 460px);
  }
}

@media screen and (max-width: 1555px) {
  .catalog_main__wrapper {
    display: grid;
    grid-template-columns: minmax(210px, 256px) auto;
    grid-column-gap: 32px;
  }

  .subscribe__wrapper_catalog.subscribe__wrapper {
    padding: 20px;
  }

  .subscribe__wrapper_catalog.subscribe__wrapper:after {
    width: 100%;
    height: 95%;
    right: -30px;
    bottom: -10px;
  }

  .subscribe__wrapper_catalog .subscribe__caption {
    width: 15em;
  }

  .subscribe__wrapper_catalog .subscribe__holder {
    padding-top: 40px;
  }

  .product__image img {
    max-width: 92%;
  }

  .one_item__content {
    grid-column-gap: 32px;
    grid-row-gap: 40px;
  }

  .one_about__holder {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }

  .one_item__wrapper .subscribe__wrapper_catalog.subscribe__wrapper {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
  }

  .personal_account__container {
    display: grid;
    grid-template-columns: minmax(210px, 256px) auto;
    grid-column-gap: 32px;
  }
}

@media screen and (max-width: 1445px) {
  .similar_carousel__holder {
    margin-top: 100px;
  }
}

@media screen and (max-width: 1440px) {
  .production__caption {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 1235px) {
  .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  .block-12 {
    width: calc(50% - 20px);
  }

  .offer__form .row > div {
    width: calc(50% - 20px);
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  .wrapper-big {
    padding-left: 60px;
    padding-right: 60px;
  }

  .wrapper-big {
    padding-left: 60px;
    padding-right: 60px;
  }

  .catalog_main__container .icon__angle {
    width: 20px;
    height: 20px;
    transform: rotate(0);
    margin-right: -4px;
    margin-bottom: 3px;
  }

  .catalog_main__container .icon__angle.open {
    transform: rotate(180deg);
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  .production__caption {
    margin-top: 40px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  .one_item__content {
    grid-template-columns: 448px auto auto;
  }

  .one_data__holder {
    grid-template-columns: auto;
    grid-column: 2 / 4;
    grid-row: 2 / 3;
  }

  .one_purchase__holder {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
  }

  .one_about__holder {
    grid-column: 1 / 4;
    grid-row: 3 / 4;
  }

  .one_item__wrapper .subscribe__wrapper_catalog.subscribe__wrapper {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
    padding: 30px;
  }

  .account_tile__cashback {
    grid-column-gap: 20px;
    grid-row-gap: 16px;
  }

  .footer__information {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }

  .footer__information > div {
    margin-bottom: 20px;
    flex-basis: 30%;
    min-width: 215px;
    width: 33%;
    flex-grow: 1;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
  }

  .footer__information .contact__number {
    min-width: 217px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .footer__links_holder.row_flex-32 {
    margin-left: -20px;
    margin-right: -20px;
  }

  .footer__links_holder.row_flex-32 > * {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 1215px) {
  .personal_account__container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 0;
    grid-row-gap: 16px;
  }

  .personal_account__navigation {
    display: none;
  }

  #open_menu {
    cursor: pointer;
  }
}

@media screen and (max-width: 1190px) {
  h1.caption {
    font-size: 32px;
    line-height: 120%;
  }

  h1.title,
  h3.caption,
  h2.caption {
    font-size: 30px;
    line-height: 110%;
    letter-spacing: -0.6px;
  }

  .input_number-holder {
    right: 0;
    top: 0;
    bottom: unset;
    left: unset;
    transform: translateX(100%);
  }

  .header__main {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .header__aside_icons {
    max-width: 169px;
  }

  .header__section span,
  .header__section .element {
    display: none;
  }

  .header__section.row_flex-10 {
    margin-left: -12px;
    margin-right: -12px;
  }

  .header__section.row_flex-10 > * {
    padding-right: 12px;
    padding-left: 12px;
  }

  .amount {
    right: 4px;
  }

  .menu__holder {
    flex-direction: column;
  }

  .header__menu_mobile {
    top: 60px;
  }

  .carousel__item_inner {
    padding: 35px 35px 25px;
  }

  .request__image_holder {
    width: 45%;
  }

  .request__form_holder {
    width: 55%;
  }

  .cover__container .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .cover__holder-1 {
    min-height: 402px;
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }

  .cover__holder-1 .cover_img {
    max-width: 32%;
    background-size: contain;
    bottom: 20px;
  }

  .cover__holder-2 {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .cover__holder-2 .cover_img {
    max-width: 32%;
    background-size: contain;
  }

  .cover__holder-3 {
    min-height: 196px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .cover__holder-3 .cover_img {
    max-height: 38%;
    background-size: contain;
    bottom: 20px;
  }

  .cover__text {
    max-width: 65%;
  }

  .busareas__content {
    display: flex;
    flex-direction: column;
  }

  .busareas__block {
    width: 100%;
  }

  .busareas__text {
    max-width: unset;
    width: 50%;
  }

  .busareas__image {
    width: 50%;
  }

  .busareas__content {
    padding: 1vw 8vw 2vw;
    box-sizing: border-box;
  }

  .busareas__block:nth-of-type(2) {
    display: flex;
    flex-direction: row-reverse;
  }

  .busareas__block:nth-of-type(2) span,
  .busareas__block:nth-of-type(2) .text-18-title {
    text-align: left;
  }

  .busareas__block:nth-of-type(3) {
    flex-direction: row;
  }

  .busareas__block:nth-of-type(3) span,
  .busareas__block:nth-of-type(3) .text-18-title {
    text-align: left;
  }

  .busareas__block:nth-of-type(1) .busareas__text:before {
    display: none;
  }

  .busareas__block:nth-of-type(2) .busareas__text:before {
    display: block;
  }

  .busareas__block:nth-of-type(2) .busareas__image:before {
    display: none;
  }

  .busareas__block:nth-of-type(3) .busareas__text:before {
    display: none;
  }

  .busareas__block:nth-of-type(4) .busareas__text:after,
  .busareas__block:nth-of-type(4) .busareas__image:after {
    display: block;
    top: 0;
    bottom: unset;
  }

  .advantages__item {
    padding: 16px 0;
  }

  .advantages__number {
    grid-column: 1 / 4;
  }

  .advantages__title {
    grid-column: 4 / 13;
    margin-bottom: 10px;
  }

  .advantages__text {
    grid-column: 4 / 13;
  }

  .advantages__item-6 {
    margin-bottom: 40px;
  }

  .reputation__tile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-template-areas: "a b" "a c" "d e" "f k" "j k";
  }

  .reputation__block-3 {
    grid-area: c;
    grid-column: 2 / 3;
  }

  .reputation__block-1 {
    grid-column: 1 / 2;
    grid-row: span 2;
  }

  .reputation__text {
    max-width: 95%;
    padding-top: 30px;
  }

  .reviews__content > * {
    grid-column: 1 / 13;
    grid-row: span 1;
  }

  .contact_index___item {
    padding: 16px 0;
  }

  .production__caption h1 {
    min-width: 660px;
  }

  .payment__label {
    padding: 10px 20px;
  }

  .payment_info__form:before {
    background-image: url("../images/svg/bg/request.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .delivery_conditions__text {
    display: block;
    display: block !important;
  }

  .delivery_conditions__text_big {
    width: 100%;
  }

  .delivery_conditions__text_big:first-of-type {
    border-right: unset;
    padding-right: 0;
  }

  .delivery_conditions__text_big:last-of-type {
    border-left: unset;
    padding-left: 0;
  }

  .price_section__item {
    grid-template-columns: auto 50px 90px  128px 40px;
  }

  .similar_carousel__holder .caption {
    font-size: 30px;
    line-height: 110%;
    letter-spacing: -0.6px;
  }

  .basket_order__step:nth-of-type(2) {
    width: 32%;
  }

  .basket_order__wrapper .section_holder {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-column-gap: 0;
    grid-row-gap: 40px;
  }

  .basket_order__wrapper .section_main {
    grid-column: span 1;
  }

  .basket_order__wrapper .section_second {
    grid-column: span 1;
  }

  .order_total__input {
    grid-column: 1 / 7;
    min-width: 50%;
    grid-row: 1 / 2;
  }

  .order_total__button {
    grid-column: 7 / 13;
    min-width: 50%;
    grid-row: 1 / 2;
  }

  .point_options__popup {
    position: absolute;
    z-index: 1501;
    top: 0;
    left: calc(100% - 36px);
    transform: translateX(-100%);
    display: none;
  }

  .address_obtaining:after {
    content: '';
    display: block;
    position: absolute;
    top: 20%;
    right: -15px;
    left: unset;
    transform: rotate(90deg);
    width: 20px;
    height: 12px;
    background-image: url("../images/svg/icons/lk/basket/pointer.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
  }

  .footer__holder .wrapper {
    display: flex;
    flex-direction: column;
  }

  .footer__links_caption {
    margin-bottom: 0;
  }

  .footer__link {
    margin-top: 10px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1150px) {
  .contacts_camera .cameras__wrapper {
    grid-template-columns: repeat(12, 1fr);
    grid-column: span 1;
  }

  .contact_cameras__row {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .contact_cameras__row .camera__block-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .contact_cameras__row .camera__block-4 {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .contact_cameras__row .camera__block-3 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .contact_cameras__row .camera__block-2 {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .contact_cameras__row .cameras__video {
    grid-column: span 1;
    grid-row: span 1;
  }
}

@media screen and (max-width: 1095px) {
  .catalog_main__wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 0;
    grid-row-gap: 16px;
  }

  .catalog_main__section {
    grid-column: 1 / 2;
  }

  .catalog_main__section {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
  }

  .catalog_main__products {
    min-width: 200px;
  }

  .catalog_main__filter {
    display: none;
  }

  .catalog__fbutton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .one_item__wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 0;
    grid-row-gap: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .catalog_main__filters {
    display: none;
  }

  .product__image .relation {
    padding-top: 93.7%;
  }

  .product__image img {
    max-width: 95%;
  }

  .product__weight .weight {
    padding-top: 8px;
  }
}

@media screen and (max-width: 970px) {
  .section__caption {
    margin-bottom: 30px;
    grid-template-columns: repeat(12, 1fr);
  }

  .section__caption .section-title-text-14 {
    grid-column: 1 / 4;
    word-break: break-word;
  }

  .section__caption .caption {
    grid-column: 4 / 13;
  }

  .catalog__holder.open > .scroll-wrapper {
    padding: 0 !important;
  }

  .catalog__holder.open > .scroll-wrapper > .scroll-element.scroll-y.scroll-scrolly_visible {
    width: 0 !important;
    right: 0 !important;
  }

  .catalog__holder {
    min-height: 70vh;
  }

  .catalog__block.row .catalog__scroll {
    height: 60vh;
    min-height: 60vh;
    max-height: 60vh;
  }

  .catalog__block.row .catalog__articles {
    height: 60vh;
    min-height: 60vh;
    max-height: 60vh;
  }

  .catalog__scroll {
    height: 60vh;
    min-height: 60vh;
    max-height: 60vh;
  }

  .catalog__scroll-main {
    overflow: unset;
    display: block;
    max-height: 60vh;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .scroll-wrapper {
    overflow: hidden !important;
    padding: 0 !important;
    padding-left: 5px !important;
  }

  .icon__angle {
    transform: rotate(-90deg);
  }

  .icon__angle.open {
    transform: rotate(-90deg) translateY(-10px);
  }

  .catalog__holder {
    background-color: #EBEDF0;
    padding: 0 26px 10vh 40px;
  }

  .catalog__holder {
    flex-wrap: wrap;
  }

  .catalog__block {
    flex-basis: 100%;
    width: 100%;
  }

  .catalog__block.row,
  .catalog__section {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .catalog__section-name {
    max-width: unset;
  }

  .catalog__section_caption {
    padding: 8px 16px;
    border-radius: 10px;
    margin: 0;
    height: unset;
  }

  .catalog__section-name {
    font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .catalog__section:hover .catalog__section_caption {
    transition: all 0.3s ease-in-out;
    background-color: white;
  }

  .catalog__section:hover .catalog__section-name {
    font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
    font-weight: 600;
  }

  .catalog__articles {
    height: 60vh;
    max-height: unset;
    padding: 0;
    background-color: transparent;
  }

  .catalog__link {
    font-family: 'Montserrat-Medium', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    padding: 8px 16px;
    transition: all 0.3s ease-in-out;
  }

  .catalog__link:hover {
    transition: all 0.3s ease-in-out;
    background-color: white;
  }

  .catalog__top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
    margin-bottom: 30px;
    margin-right: 0;
    margin-left: 0;
  }

  .catalog__top.open {
    height: 60px;
    margin-bottom: 10px;
  }

  .icon__close {
    margin-left: auto;
  }

  .icon__close:hover {
    transform: scale(1.15);
    transition: all 0.3s ease-in-out;
  }

  .catalog__caption {
    display: none;
  }

  .catalog__top .icon__angle {
    margin-right: auto;
    transform: rotate(90deg) translateY(-10px);
  }

  .scrollbar-inner > .scroll-element .scroll-element_track {
    background-color: rgba(255, 255, 255, 0.3);
  }

  /* полоса  */

  .scrollbar-inner > .scroll-element .scroll-bar {
    background-color: #bec2c6;
  }

  /* сам ползунок */

  .catalog__holder.open .catalog__scroll-main {
    padding-right: 0;
  }

  .catalog__holder.open .catalog__scroll {
    padding-right: 25px;
  }

  .variety_products__container .variety_products__list {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, auto);
  }

  .variety_products__container .variety_products__list .variety_products_image {
    border-radius: 10px;
    overflow: hidden;
  }

  .variety_products__container .variety_products__list .relation-rectangle {
    padding-top: 46.3%;
  }

  .variety_products__block-square.variety_product-1 {
    grid-column: 1 / 2;
  }

  .variety_products__block-square.variety_product-2 {
    grid-column: 2 / 3;
  }

  .variety_products__block-square.variety_product-3 {
    grid-column: 3 / 4;
  }

  .variety_products__block-square.variety_product-1,
  .variety_products__block-square.variety_product-2,
  .variety_products__block-square.variety_product-3 {
    grid-column: span 1;
    grid-row: 2 / 3;
  }

  .variety_products__block-square.variety_product-4 {
    grid-column: span 1;
    grid-row: 3 / 4;
  }

  .variety_products__block-square.variety_product-9 {
    grid-column: span 1;
    grid-row: 6 / 7;
    margin-top: 10px;
  }

  .variety_products__block-square.variety_product-9 .relation-square {
    padding-top: 96.4%;
  }

  .variety_products__block-square.variety_product-10,
  .variety_products__block-square.variety_product-11 {
    grid-column: span 1;
    grid-row: 5 / 6;
  }

  .variety_products__block-square.variety_product-12 {
    grid-column: span 1;
    grid-row: 5 / 6;
  }

  .variety_products__block-rectangle {
    grid-column: unset;
    grid-row: unset;
  }

  .variety_products__block-rectangle.variety_product-5 {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }

  .variety_products__block-rectangle.variety_product-6 {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
  }

  .variety_products__block-rectangle.variety_product-7 {
    grid-column: 1 / 4;
    grid-row: 7 / 8;
  }

  .variety_products__block-rectangle.variety_product-8 {
    grid-column: 1 / 3;
    grid-row: 6 / 7;
  }

  .variety_products__block-rectangle.variety_product-6 .relation-rectangle,
  .variety_products__block-rectangle.variety_product-7 .relation-rectangle {
    padding-top: 31.4%;
  }

  .programs__container h3.caption.text-blue {
    max-width: 88%;
  }

  .review__wrapper {
    grid-column: 1 / 13;
  }

  .suppliers_main__content {
    margin-bottom: 30px;
    grid-template-columns: repeat(12, 1fr);
  }

  .suppliers_main__content .section-title-text-14 {
    grid-column: 1 / 4;
    word-break: break-word;
  }

  .suppliers_main__content .caption {
    grid-column: 4 / 13;
  }

  .delivery_main__text p:first-of-type {
    min-width: unset;
  }

  .account_tile__cashback {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
}

@media screen and (max-width: 890px) {
  .block-12 {
    width: calc(100% - 20px);
  }

  .button-yellow {
    width: 223px;
  }

  .button-gray {
    width: 223px;
  }

  .button-long {
    width: 100%;
    max-width: 100%;
  }

  *[class^='icon__'] {
    width: 20px;
    height: 20px;
  }

  header .icon__basket {
    background-image: url("../images/svg/icons/menu/b-basket.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
  }

  header .icon__favorites {
    background-image: url("../images/svg/icons/menu/b-favorites.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
  }

  header .icon__dots {
    background-image: url("../images/svg/icons/menu/b-dots.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
  }

  header .icon__close-b {
    background-image: url("../images/svg/icons/menu/b-close.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
  }

  header footer *[class^='icon__'] {
    width: 24px;
    height: 24px;
  }

  .header__aside {
    display: none;
  }

  .header__aside_icons {
    max-width: unset;
  }

  .header__menu_mobile {
    padding-top: 38px;
  }

  .header__holder_mobile {
    display: flex;
  }

  .header__aside_icons {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    padding-bottom: 35px;
  }

  .header__to-catalog > span,
  .header__section > span,
  .header__section .login {
    display: block;
    color: #203686;
  }

  .header__to-catalog,
  .header__section {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(32, 54, 134, 0.2);
  }

  .header__to-catalog {
    border-top: 1px solid rgba(32, 54, 134, 0.2);
  }

  .header__search {
    width: 100%;
    max-width: unset;
    padding-bottom: 40px;
  }

  .header__section:last-of-type {
    order: -1;
  }

  .icon__dots {
    display: block;
  }

  .dots {
    display: none;
  }

  .catalog__section-name {
    max-width: unset;
  }

  #catalog .wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .catalog__holder {
    min-height: 80vh;
  }

  .catalog__block.row .catalog__scroll {
    height: 70vh;
    min-height: 70vh;
    max-height: 70vh;
  }

  .catalog__block.row .catalog__articles {
    height: 70vh;
    min-height: 70vh;
    max-height: 70vh;
  }

  .catalog__scroll {
    height: 70vh;
    min-height: 70vh;
    max-height: 70vh;
  }

  .catalog__scroll-main {
    max-height: 70vh;
    min-height: 70vh;
  }

  .catalog__holder {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: 0 10px 30px 15px;
  }

  .icon__dots,
  .catalog__caption,
  .icon__close {
    display: block;
  }

  .catalog__top {
    height: 60px;
    margin-bottom: 10px;
  }

  .dots {
    display: none;
  }

  .catalog__section_caption {
    padding: 8px 12px;
  }

  .catalog__holder.open .catalog__scroll-main {
    padding-right: 0;
  }

  .catalog__holder.open .catalog__scroll {
    padding-right: 25px;
  }

  .carousel__item_text h2.text-16.text-white {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .carousel__item-2 h1.caption {
    width: 75%;
  }

  .request__wrapper {
    flex-wrap: wrap;
  }

  .request__form_holder {
    width: 100%;
    padding: 30px;
  }

  .request__form_holder .button-yellow {
    width: 100%;
    min-width: 100%;
  }

  form.request__form button {
    margin-top: 20px;
  }

  .cover__container {
    margin-top: 20px;
  }

  .variety_products__container .text-18-title {
    margin-top: 10px;
  }

  .offer__container {
    padding-top: 40px;
    padding-bottom: 45px;
  }

  .offer__container {
    background-image: url("../images/middle/offer.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100%;
    height: 100%;
  }

  .offer__container h2.caption {
    margin-bottom: 26px;
  }

  .offer__form {
    padding: 30px;
  }

  .offer__form .row > div {
    width: calc(100% - 20px);
  }

  .offer__info {
    padding-top: 0;
  }

  .offer__contact {
    margin-top: 20px;
  }

  .busareas__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .busareas__content {
    padding: 1vw 3vw 2vw;
    box-sizing: border-box;
  }

  .busareas__text:before,
  .busareas__image:before {
    top: 10px;
    bottom: 10px;
    width: 1px;
    height: calc(100% - 20px);
    right: 0;
  }

  .busareas__text:after,
  .busareas__image:after {
    right: 10px;
    left: 10px;
    height: 1px;
    width: calc(100% - 20px);
    bottom: 0;
  }

  .choose__container {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .choose__wrapper {
    padding: 30px;
  }

  .choose__wrapper h2.caption.text-blue {
    margin-bottom: 10px;
  }

  .choose__block_photo {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  .choose__manager {
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% / 24 * 8 - 20px);
    margin-bottom: 20px;
  }

  .choose__manager_name {
    margin-top: 5px;
    font-size: 14px;
  }

  .advantages__container {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .advantages__item-6 {
    margin-bottom: 0;
  }

  .reputation__container {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .reputation__title {
    font-size: 18px;
  }

  .reputation__number {
    margin-bottom: 4px;
  }

  .subscribe__container {
    margin-top: 76px;
    margin-bottom: 40px;
  }

  .subscribe__wrapper {
    padding: 30px;
  }

  .subscribe__wrapper:after {
    background-image: url("../images/middle/hand.webp");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
  }

  .subscribe__wrapper:after {
    width: 50%;
    height: 80%;
  }

  .subscribe__caption {
    width: 14em;
    font-size: 30px;
    line-height: 110%;
    margin-bottom: 10px;
  }

  .subscribe__title {
    font-size: 14px;
    line-height: 130%;
  }

  .subscribe__holder {
    padding-top: 64px;
  }

  .subscribe__socials {
    grid-template-columns: 244px;
    grid-template-rows: repeat(3, 1fr);
  }

  .subscribe__social_holder {
    padding: 8px 25px;
  }

  .subscribe__social_holder {
    grid-column: span 1;
    grid-row: span 1;
  }

  .about_index__container {
    margin-top: 76px;
    margin-bottom: 40px;
  }

  .about_index__number {
    margin-bottom: 4px;
  }

  .programs__container {
    margin-top: 78px;
    margin-bottom: 40px;
  }

  .programs__container h3.caption.text-blue {
    max-width: 100%;
  }

  .programs__container .wrapper {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .programs__container .section__caption,
  .programs__container .slick-list {
    padding-left: 62px !important;
    padding-right: 62px !important;
  }

  .programs__container .slick-prev.slick-arrow,
  .programs__container .slick-next.slick-arrow {
    position: absolute;
    transform: none;
    z-index: 100;
    top: 0;
    bottom: 0;
    width: 20%;
    height: 100%;
    color: transparent;
    font-size: 1px;
    line-height: 0;
  }

  .programs__container .slick-prev.slick-arrow {
    left: 0;
  }

  .programs__container .slick-next.slick-arrow {
    right: 0;
  }

  .programs__holder .relative .relation {
    padding-top: 58.5%;
  }

  .cameras__container {
    margin-top: 78px;
    margin-bottom: 40px;
  }

  .cameras__wrapper .relation {
    padding-top: 55.8%;
  }

  .cameras__row {
    margin-top: 30px;
    grid-column: 1 / 13;
  }

  .reviews__container {
    margin-top: 78px;
    margin-bottom: 40px;
  }

  .review__wrapper {
    padding: 30px;
  }

  .review__points {
    font-size: 40px;
  }

  .review__block-1 {
    grid-template-columns: 62px 75px 75px;
    grid-template-rows: 5px 16px 20px 68px;
  }

  .review__points {
    grid-row: 1 / 4;
  }

  .review__button {
    grid-row: 4 / 5;
    margin-top: 20px;
  }

  .contacts_index__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .contact_index___text {
    padding-right: 0;
    grid-column: 7 / 17;
  }

  .contact_index___map div {
    border-radius: 10px;
  }

  .contact_index___map {
    grid-column: 17 / 25;
  }

  .contact_index___map {
    height: 120px;
  }

  .contact_index__image-1 {
    background-image: url("../images/middle/index-contact/1.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  .contact_index__image-2 {
    background-image: url("../images/middle/index-contact/2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  .contact_index__image-3 {
    background-image: url("../images/middle/index-contact/3.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  .contact_index___item {
    grid-column-gap: 20px;
  }

  .subscribe__wrapper_catalog.subscribe__wrapper:after {
    width: 50%;
    height: 80%;
  }

  .subscribe__wrapper_catalog .subscribe__socials {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }

  .product__data .block-12 {
    max-width: unset;
    display: flex;
  }

  .product__data .block-12 span {
    margin-right: 3px;
  }

  .product__options *[class^='icon__'] {
    width: 24px;
    height: 24px;
  }

  .production_about__wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 30px;
    margin-bottom: 40px;
  }

  .production__block h2 {
    font-size: 14px;
  }

  .suppliers_main__content .caption {
    font-size: 18px;
  }

  .suppliers_steps__container {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .caption__wrapper {
    margin-top: 20px;
  }

  .contacts_map__container {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0;
    grid-row-gap: 53px;
  }

  .contacts_map__info {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .contacts_map__block {
    align-self: flex-start;
  }

  .contacts_map___link {
    border-radius: 10px;
  }

  .contact_cameras__row {
    margin-top: 30px;
  }

  .payment_labels__wrapper {
    margin-bottom: 20px;
  }

  .payment_info {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-column-gap: 0;
    grid-row-gap: 20px;
  }

  .payment_info__line {
    padding: 16px 0;
  }

  .payment_info__form {
    padding: 30px;
  }

  .payment_info__form .caption {
    margin-bottom: 20px;
  }

  .payment__form #payment_btn {
    margin-top: 20px;
  }

  .delivery_main__thesis_title {
    width: 90%;
    font-size: 14px;
  }

  .delivery_conditions__blocks {
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    margin-top: 24px;
  }

  .delivery_camera__holder {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .delivery_camera__text {
    grid-column: 4 / 13;
    grid-row: 1 / 2;
    grid-column-gap: 20px;
  }

  .delivery_camera__video {
    grid-column: 4 / 13;
    grid-row: 2 / 3;
  }

  .price__caption {
    grid-template-rows: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .price__caption h1.caption {
    grid-column: 1 / 13;
  }

  .price__caption p {
    grid-column: 1 / 9;
    grid-row: 2 / 3;
  }

  .price__caption .button-yellow {
    grid-column: 9 / 13;
    grid-row: 2 / 3;
  }

  .price_section__item {
    padding: 16px 24px;
  }

  .one_item__content {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 0;
    grid-row-gap: 20px;
  }

  .one_image__holder {
    width: 100%;
    max-width: unset;
    margin-bottom: 12px;
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }

  .one_data__holder {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    grid-template-rows: repeat(3, min-content);
  }

  .one_purchase__holder {
    grid-column: 1 / 4;
    grid-row: 3 / 4;
  }

  .one_about__holder {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
  }

  .one_item__wrapper .subscribe__wrapper_catalog.subscribe__wrapper {
    grid-column: 1 / 4;
    grid-row: 5 / 6;
    padding: 20px;
  }

  .one_item__parameter_holder {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: none;
    grid-row-gap: 0;
  }

  .one_item__parameter_holder .parameter_pack-1 {
    grid-column: 1 / 2;
  }

  .one_item__parameter_holder .parameter_pack-2 {
    grid-column: 2 / 3;
  }

  .one_item__parameter_holder .parameter_pack-3 {
    grid-column: 3 / 4;
  }

  .one_item__purchase_holder button {
    margin-top: 0;
  }

  .one_item__purchase_holder {
    margin-bottom: 20px;
  }

  .one_item__total {
    margin-bottom: 0;
  }

  .one_item__buy {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  .one_item__buy button {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }

  .one_item__buy .one_item__total {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .one_item__delivery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  .one_item__delivery .one_item__schedule {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  .one_item__availability {
    border-top: unset;
  }

  .one_purchase__title {
    display: block;
    display: block !important;
  }

  .basket__caption_top #amount_basket {
    margin-left: 16px;
    padding: 0;
  }

  .basket_order__steps {
    margin-top: 20px;
  }

  .basket_order__step {
    padding: 12px 20px;
  }

  .basket_order__step:nth-of-type(2) {
    min-width: 250px;
  }

  .basket_thanks__holder .f,
  .basket_thanks__holder .t {
    display: none;
  }

  .basket_thanks__holder .s {
    display: block;
  }

  .add_place_form__holder,
  .add_place_map__holder {
    height: 359px;
    width: 100%;
  }

  .obtaining__dostavka .label-input,
  .obtaining__dostavka input[type='text'] {
    height: 48px;
    max-height: 48px;
  }

  .obtaining__dostavka .label-input .sup {
    font-size: 12px;
  }

  .obtaining__dostavka .row_flex-10-m {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }

  .obtaining__dostavka .row_flex-10-m > * {
    margin-right: 2.5px;
    margin-left: 2.5px;
  }

  .dostavka_st {
    margin-bottom: 5px;
  }

  .dostavka_of {
    margin-bottom: 10px;
  }

  .personal_account__item {
    margin-bottom: 10px;
  }

  .personal_account__current:before {
    display: none;
  }

  .personal_account__container {
    grid-row-gap: 10px;
  }

  .orders_info__table {
    margin-bottom: 30px;
  }

  .orders_col_numb {
    width: 30%;
  }

  .footer__holder {
    padding-top: 10px;
    padding-bottom: 40px;
  }

  .footer__holder .wrapper > div {
    padding-top: 30px;
  }

  .footer__links_holder {
    min-width: unset;
  }
}

@media screen and (max-width: 890px) and screen and (max-width: 768px) {
  .contact_index__image-1 {
    border-radius: 10px;
  }

  .contact_index__image-2 {
    border-radius: 10px;
  }

  .contact_index__image-3 {
    border-radius: 10px;
  }
}

@media screen and (max-width: 800px) {
  .product__image .relation {
    padding-top: 90%;
  }

  .product__data {
    margin-top: 8px;
  }

  .product__data .block-12:first-of-type {
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 770px) {
  .product__image img {
    max-width: 100%;
    max-height: 79%;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }

  .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  .block-12 {
    width: calc(100% - 20px);
  }

  .section__caption {
    margin-bottom: 20px;
    grid-column-gap: 20px;
  }

  .carousel__holder .carousel__item {
    min-height: 340px;
  }

  .carousel__item_inner {
    border-radius: 10px;
    padding: 30px 30px 25px;
  }

  .carousel__item_inner .carousel__item_img {
    width: 40%;
  }

  .carousel__item-3 .carousel__item_img {
    width: 45%;
  }

  .carousel__item-1 h1.caption {
    width: 80%;
  }

  .carousel__item-2 h1.caption {
    width: 80%;
  }

  .carousel__item-2 h1.caption {
    width: 100%;
  }

  .carousel__item-3 h1.caption {
    width: 100%;
  }

  .slick-dots {
    bottom: 8px;
  }

  .request__holder .text-small {
    margin-top: 0;
  }

  .request__form_holder {
    padding-top: 24px;
  }

  .cover__holder {
    border-radius: 10px;
  }

  .cover__holder-2 .cover_img {
    max-width: 43%;
  }

  .cover__holder-3 .cover_img {
    max-width: 58%;
    height: 80%;
    max-height: unset;
    right: 5px;
  }

  .cover__caption {
    line-height: 105%;
  }

  .cover__text {
    max-width: 70%;
  }

  .variety_products__container .variety_products__list {
    margin-top: 0;
    grid-row-gap: 10px;
  }

  .variety_products__container br {
    display: block;
    display: block !important;
  }

  .variety_products__container .section__caption {
    margin-bottom: 20px;
  }

  .variety_products__block-square.variety_product-9 .relation-square {
    padding-top: 97.4%;
  }

  .offer__container h2.caption {
    width: 90%;
  }

  .offer__form .row > div {
    width: calc(100% - 20px);
  }

  .offer__contact {
    margin-top: 10px;
  }

  .offer__contact h3.title {
    font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  .busareas__container .caption.text-blue {
    width: 95%;
  }

  .busareas__content {
    padding: 0;
  }

  .busareas__text .text-18-title {
    width: 80%;
  }

  .delivery_index__container {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .delivery_index__container .text-18-title {
    font-size: 14px;
  }

  .delivery_index__wrapper {
    margin-top: -10px;
    grid-row-gap: 20px;
  }

  .delivery_index__text p {
    font-size: 14px;
    line-height: 130%;
  }

  .delivery_index__text p:first-of-type {
    max-width: 260px;
    font-size: 14px;
    line-height: 130%;
    margin-right: 7px;
  }

  .delivery_index__text p:nth-of-type(2) {
    max-width: 290px;
    font-size: 14px;
    line-height: 130%;
  }

  .delivery_index__image {
    height: 300px;
    border-radius: 10px;
  }

  .choose__container {
    margin-top: 82px;
  }

  .choose__manager.first-of-type .choose__manager_image:before {
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    height: calc(100% + 8px);
    width: calc(100% + 8px);
  }

  .choose__wrapper {
    padding: 30px 10px 10px 30px;
  }

  .choose__block_photo {
    display: flex;
    justify-content: center;
  }

  .choose__manager {
    width: calc(100% / 48 * 8 - 10px);
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    min-width: 90px;
    min-height: 90px;
  }

  .choose__manager img {
    max-width: 87px;
    width: 87px;
    height: 87px;
  }

  .choose__block_text {
    min-width: unset;
    width: 290px;
  }

  .choose__block_text .text-14 {
    line-height: 130%;
  }

  .choose__manager_name {
    margin-top: 3px;
  }

  .choose__container .button-yellow {
    width: 235px;
    margin-bottom: 20px;
  }

  .advantages__container {
    margin-top: 85px;
    margin-bottom: 40px;
  }

  .advantages__item {
    grid-column-gap: 20px;
    padding: 20px 0 15px;
  }

  .advantages__title {
    width: 86%;
  }

  .advantages__text {
    width: 86%;
  }

  .advantages__item-4 .advantages__title {
    width: 100%;
  }

  .advantages__item-4 .advantages__title br {
    display: none;
  }

  .advantages__item-4 .advantages__text {
    width: 100%;
  }

  .advantages__item-4 .advantages__text br {
    display: none;
  }

  .reputation__block,
  .reputation__block-rectangle {
    border-radius: 10px;
  }

  .reputation__text {
    padding-top: 20px;
    max-width: unset;
    line-height: 130%;
  }

  .subscribe__wrapper:after {
    width: 50%;
    height: 86%;
  }

  .subscribe__caption {
    letter-spacing: -0.7px;
  }

  .subscribe__title {
    width: 20em;
    letter-spacing: -0.1px;
  }

  .about_index__content {
    grid-column-gap: 20px;
  }

  .about_index__text p:first-of-type {
    max-width: 240px;
  }

  .about_index__text p {
    line-height: 18px;
    max-width: unset;
  }

  .about_index__text {
    margin-top: 0;
  }

  .about_index__facts {
    margin-top: 75px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .about_index__block {
    grid-column: span 1;
    grid-row: span 1;
  }

  .about_index__span {
    padding-top: 30px;
  }

  .programs__container .wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  .programs__container .section__caption,
  .programs__container .slick-list {
    padding-left: 38px !important;
    padding-right: 42px !important;
  }

  .programs_video-1 {
    background-image: url("../images/small/video/poster-1.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  .programs_video-2 {
    background-image: url("../images/small/video/poster-2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  .cameras__wrapper {
    grid-column-gap: 20px;
    margin-top: 0;
  }

  .cameras__text p {
    max-width: 224px;
    line-height: 19px;
  }

  .camera__block {
    grid-row: 1 / 2;
  }

  .camera__name {
    font-size: 14px;
  }

  .reviews__content {
    margin-top: 0;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
  }

  .review__wrapper {
    grid-column-gap: 20px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, auto);
  }

  .review__wrapper .review__first {
    grid-column: span 6;
  }

  .review__block-1 {
    grid-column: span 4;
    grid-row: 2 / 3;
  }

  .review__block-2 {
    grid-column: span 8;
    grid-row: 2 / 3;
  }

  .review__button {
    width: 243px;
  }

  .review__block-2 {
    grid-template-rows: 23px 18px 20px 26px auto 24px;
  }

  .contact_index___item {
    grid-column-gap: 20px;
  }

  .contact_index___title {
    font-size: 16px;
  }

  .contact_index___text {
    grid-column: 7 / 17;
  }

  .contact_index___map .ci_img {
    border-radius: 10px;
  }

  .contact_index___map {
    grid-column: 17 / 25;
  }

  .contact_index__image-1 {
    border-radius: 10px;
  }

  .contact_index__image-2 {
    border-radius: 10px;
  }

  .contact_index__image-3 {
    border-radius: 10px;
  }

  .breadcrumbs__container {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  .wrapper-big {
    padding-left: 42px;
    padding-right: 42px;
  }

  .wrapper-big {
    padding-left: 42px;
    padding-right: 42px;
  }

  .catalog_main__products {
    min-width: 140px;
  }

  .subscribe__wrapper_catalog.subscribe__wrapper {
    grid-column: 1 / 4;
    grid-row: 3 / 4;
  }

  .subscribe__wrapper_catalog.subscribe__wrapper:after {
    width: 70%;
    height: 100%;
    right: -8px;
  }

  .subscribe__wrapper_catalog .subscribe__caption {
    width: 14em;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.6px;
    margin-bottom: 10px;
  }

  .subscribe__wrapper_catalog .subscribe__title {
    width: 20em;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 130%;
  }

  .subscribe__wrapper_catalog .subscribe__social_holder {
    padding: 7.5px 18px;
  }

  .subscribe__wrapper_catalog .subscribe__social_holder span {
    font-size: 12px;
    line-height: 120%;
  }

  .subscribe__wrapper_catalog .subscribe__check {
    margin-top: 10px;
  }

  .subscribe__wrapper_catalog .subscribe__check span {
    width: 300px;
  }

  .product__name {
    margin-top: 8px;
  }

  .product__options {
    margin-bottom: 9px;
  }

  .product__amount {
    padding: 12px 22px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  .suppliers_main__content {
    margin-bottom: 20px;
    grid-column-gap: 20px;
  }

  .suppliers_steps__container {
    margin-top: 85px;
    margin-bottom: 40px;
  }

  .cooperation__step {
    grid-column-gap: 20px;
    padding: 20px 0 15px;
  }

  .cooperation__text {
    width: 86%;
  }

  .supplier_form__holder .mb {
    margin-bottom: 20px;
  }

  .delivery_main__content {
    grid-column-gap: 20px;
  }

  .delivery_main__text p:first-of-type {
    max-width: 240px;
  }

  .delivery_main__text p {
    line-height: 18px;
    max-width: unset;
  }

  .delivery_main__text {
    margin-top: 0;
  }

  .price__caption {
    grid-row-gap: 20px;
    grid-column-gap: 0;
  }

  .price_section__item {
    grid-template-columns: auto 30px 80px  128px 40px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  .one_image__holder {
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
  }

  .one_image__holder .slick-track {
    top: -10vw;
  }

  .one_image__holder .slick-prev.slick-arrow,
  .one_image__holder .slick-next.slick-arrow {
    width: 30%;
  }

  .one_image__holder .slick-track:before,
  .one_image__holder .slick-track:after {
    position: absolute;
    background-color: transparent;
  }

  .one_image__holder .slick-prev.slick-arrow {
    left: -2px;
    background-size: 0;
    background-color: transparent;
  }

  .one_image__holder .slick-next.slick-arrow {
    right: -2px;
    background-size: 0;
    background-color: transparent;
  }

  .one_image__main {
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
  }

  .one_image__wrapper,
  .one_image__main.slick-initialized.slick-slider.slick-dotted,
  .one_image__main .slick-list.draggable,
  .one_image__main img {
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
  }

  .one_item__small {
    display: none;
  }

  .similar_carousel__holder {
    margin-top: 70px;
  }

  .login__container {
    width: 100%;
  }

  .login_via_social__holder {
    bottom: 10px;
  }

  #basket_order_code {
    grid-template-columns: 191px 200px;
    grid-template-rows: 1fr 48px auto;
    grid-row-gap: 10px;
  }

  .order_button__again {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
  }

  .ico__options {
    margin-top: 0;
  }

  .send_again__popup {
    bottom: -12px;
    left: 0;
    transform: translateX(0) translateY(100%);
  }

  .one_order__container {
    grid-row-gap: 10px;
  }

  .one_order_status__button {
    margin-bottom: 20px;
  }

  .delivery__info,
  .details__info,
  .payment__info {
    margin-left: 10px;
  }

  .one_order_composition__amount {
    margin-right: 20px;
  }

  .footer__information_holder .footer__logo {
    margin-bottom: 20px;
  }

  .footer__information > div {
    min-width: 209px;
  }

  .footer__link {
    margin-top: 7px;
    max-width: 173px;
    min-width: unset;
  }
}

@media screen and (max-width: 760px) {
  .variety_products__container h3.caption.text-blue {
    display: none;
  }

  .variety_products__container h3.caption.text-blue.second {
    display: block;
  }

  .delivery_index__wrapper {
    margin-top: 0;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 32px;
  }

  .delivery_index__text {
    grid-column: span 1;
    grid-row: 1 / 2;
    display: flex;
  }

  .delivery_index__text p {
    line-height: 20px;
    margin-right: 10px;
  }

  .delivery_index__facts {
    grid-column: span 1;
    grid-row: 2 / 3;
    display: flex;
    justify-content: space-between;
    padding-top: 36px;
  }

  .delivery_index__facts p {
    position: relative;
    padding-right: 20px;
  }

  .delivery_index__image {
    grid-column: span 1;
    grid-row: 3 / 4;
  }

  .choose__manager {
    max-width: unset;
  }

  .choose__wrapper {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 0;
    grid-row-gap: 25px;
    padding: 40px;
  }

  .choose__block_text {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    min-width: unset;
    width: unset;
    margin-bottom: 20px;
  }

  .choose__container .button-yellow {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    margin-top: auto;
    margin-bottom: 0;
    justify-self: center;
  }

  .choose__block_photo {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .choose__block_photo {
    display: flex;
    justify-content: center;
  }

  .choose__manager {
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% / 24 * 5 - 20px);
    margin-bottom: 20px;
  }

  .basket_order__step {
    padding: 8px 10px;
  }

  .basket_order__step:nth-of-type(2) {
    min-width: 240px;
  }
}

@media screen and (max-width: 750px) {
  .one_order_delivery {
    grid-template-columns: 60% 40%;
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .one_order_delivery__block:first-of-type {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  .one_order_details {
    grid-template-columns: 60% 40%;
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .one_order_payment {
    grid-template-columns: 60% 40%;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}

@media screen and (max-width: 710px) {
  .basket__caption_top {
    margin-bottom: 20px;
  }

  .basket_order__steps {
    display: none;
  }

  .basket_thanks__holder.last {
    padding-top: 20px;
  }

  .basket_order__management {
    display: flex;
    justify-content: space-between;
  }

  .basket_order__management .basket_order__del_in,
  .basket_order__management #basket_show_in,
  .basket_order__management #basket_show_out {
    display: none;
  }

  .basket_order__management .icon__bin {
    display: block;
  }
}

@media screen and (max-width: 700px) {
  .catalog_main__section {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 40px;
  }

  .subscribe__wrapper_catalog.subscribe__wrapper {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }

  .product__image button.icon__like {
    top: 7.8px;
    right: 7px;
  }
}

@media screen and (max-width: 660px) {
  .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .row_flex-80-media {
    margin-left: 0;
    margin-right: 0;
  }

  .row_flex-80-media > * {
    padding-right: 0;
    padding-left: 0;
  }

  .block-12 {
    width: calc(100% - 20px);
  }

  .button-buy {
    width: 48px;
    height: 36px;
  }

  .ico__pin {
    width: 38px;
    height: 38px;
  }

  .ico__pin {
    width: 38px;
    height: 38px;
  }

  .ico__day {
    width: 38px;
    height: 38px;
  }

  .ico__day {
    width: 38px;
    height: 38px;
  }

  .ico__time {
    width: 38px;
    height: 38px;
  }

  .ico__time {
    width: 38px;
    height: 38px;
  }

  .ico__person {
    width: 38px;
    height: 38px;
  }

  .ico__person {
    width: 38px;
    height: 38px;
  }

  .ico__phone {
    width: 38px;
    height: 38px;
  }

  .ico__phone {
    width: 38px;
    height: 38px;
  }

  .ico__car {
    width: 38px;
    height: 38px;
  }

  .ico__car {
    width: 38px;
    height: 38px;
  }

  .ico__wallet {
    width: 38px;
    height: 38px;
  }

  .ico__wallet {
    width: 38px;
    height: 38px;
  }

  .ico__cheque {
    width: 38px;
    height: 38px;
  }

  .ico__cheque {
    width: 38px;
    height: 38px;
  }

  .carousel__item_inner .carousel__item_img {
    width: 35%;
  }

  .cover__container .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  .cover__holder {
    min-height: 206px;
  }

  .cover__holder .cover_img {
    max-height: 65%;
  }

  .cover__holder-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .cover__holder-1 .cover_img {
    max-height: 65%;
    bottom: 0;
  }

  .cover__holder-2 .cover_img {
    max-width: 55%;
  }

  .cover__holder-2 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .cover__holder-3 {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .cover__holder-1 .cover_img {
    right: 15px;
  }

  .cover__holder-2 .cover_img {
    max-height: unset;
    max-width: 55%;
    right: 12px;
    bottom: 10px;
  }

  .cover__holder-3 .cover_img {
    right: 7px;
    bottom: 16px;
  }

  .variety_products__container .text-14 {
    display: none;
  }

  .variety_products__block-square.variety_product-9 .relation-square {
    padding-top: 100%;
  }

  .offer__form .row > div {
    width: calc(100% - 20px);
  }

  .delivery_index__wrapper {
    margin-top: 10px;
    grid-row-gap: 20px;
  }

  .advantages__number {
    grid-column: 1 / 2;
  }

  .advantages__title {
    grid-column: 2 / 13;
  }

  .advantages__text {
    grid-column: 2 / 13;
  }

  .reputation__block-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  .reputation__block-1 .reputation__text {
    padding-top: 20px;
  }

  .subscribe__social_holder {
    padding: 8px 16px;
  }

  .about_index__content {
    grid-template-columns: repeat(2, 1fr);
  }

  .about_index__text p:first-of-type {
    max-width: unset;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .about_index__text {
    grid-column: 1 / 4;
    display: block;
  }

  .about_index__facts {
    grid-column: span 3;
    grid-gap: 10px;
    margin-top: 20px;
  }

  .cameras__wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }

  .cameras__text p {
    max-width: unset;
    line-height: 16px;
  }

  .cameras__text p:first-of-type {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .cameras__text {
    grid-column: span 1;
    display: block;
  }

  .review__wrapper {
    grid-column-gap: 20px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, auto);
  }

  .review__block-1 {
    grid-column: 1 / 13;
    grid-row: 2 / 3;
  }

  .review__block-2 {
    grid-column: 1 / 12;
    grid-row: 3 / 4;
  }

  .contact_index___map {
    height: 180px;
  }

  .contact_index__image-1 {
    background-image: url("../images/large/index-contact/1.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  .contact_index__image-2 {
    background-image: url("../images/large/index-contact/2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  .contact_index__image-3 {
    background-image: url("../images/large/index-contact/3.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  .contact_index___item {
    display: grid;
    grid-template-columns: auto;
    padding: 16px 0;
  }

  .contact_index___title {
    grid-column: span 1;
  }

  .contact_index___text {
    grid-column: span 1;
  }

  .contact_index___map {
    grid-column: span 1;
    margin-left: 0;
  }

  .contact_index__image-3 {
    background-position: 50% 100%;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .wrapper-big {
    padding-left: 30px;
    padding-right: 30px;
  }

  .wrapper-big {
    padding-left: 30px;
    padding-right: 30px;
  }

  .product__purchase {
    display: grid;
    grid-template-columns: auto 48px;
    grid-template-rows: 19px 36px;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }

  .product__purchase .product__amount {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .product__purchase .product__buy {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  .product__purchase .product__button {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .product__price .price {
    font-size: 16px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .production__caption {
    margin-top: 20px;
  }

  .production__caption h1 {
    min-width: unset;
    width: 80%;
  }

  .catalog_main__labels {
    grid-column-gap: 10px;
  }

  #container_production .catalog_main__caption span {
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 3px;
  }

  #container_production .catalog_main__caption {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
  }

  .contacts_camera .cameras__text,
  .contacts_camera .cameras__more {
    grid-column: 1 / 13;
  }

  .contacts_camera .cameras__text p:first-of-type {
    width: 100%;
    grid-column: 1 / 13;
  }

  .payment_labels__wrapper {
    display: none;
  }

  .payment_info {
    display: grid;
  }

  .payment__title {
    display: block;
    display: block !important;
  }

  .delivery_main__content {
    grid-template-columns: repeat(2, 1fr);
  }

  .delivery_main__content .relation {
    padding-top: 44.8%;
  }

  .delivery_main__text p:first-of-type {
    max-width: unset;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .delivery_main__text {
    grid-column: 1 / 13;
    display: block;
  }

  .delivery_main__theses {
    grid-template-rows: auto auto auto;
    grid-column-gap: 0;
    grid-row-gap: 20px;
  }

  .delivery_main__thesis {
    grid-column: 1 / 13;
  }

  .delivery_main__thesis_title {
    width: 100%;
  }

  .price__caption {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-row-gap: 10px;
  }

  .price__caption p {
    max-width: 85%;
    min-width: 233px;
  }

  .price__caption .button-yellow {
    grid-column: 1 / 13;
    grid-row: 3 / 4;
    justify-self: flex-start;
    margin-top: 10px;
  }

  .price_section__about {
    padding: 16px 24px 1px;
    grid-template-columns: 13px auto 20px;
    grid-template-rows: repeat(2, auto);
  }

  .price_section__item {
    grid-template-columns: auto 89px 28px;
    grid-template-rows: auto 18px 28px;
    grid-column-gap: 10px;
    grid-row-gap: 8px;
  }

  .price_item__title {
    grid-column: 1 / 4;
  }

  .price_item__weight {
    grid-column: 1 / 2;
  }

  .price_item_price {
    grid-column: 1 / 2;
  }

  .price_item__purchase {
    grid-column: 2 / 3;
    width: 89px;
    height: 28px;
    padding: 6.5px;
  }

  .button-buy {
    grid-column: 3 / 4;
    width: 28px;
    height: 28px;
    background-size: 17px;
  }

  .select-icon {
    display: block;
  }

  .price_section_form:focus {
    outline: none;
  }

  .price_section_form *:focus {
    outline: none;
  }

  .price_section_form * {
    outline: none;
  }

  .price_section_form .select2-container .select2-selection--single .select2-selection__rendered {
    color: transparent;
  }

  .price_section_form {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    width: 100%;
    min-width: 100%;
    height: 15px;
    border: unset;
    padding-left: 0;
  }

  .price_section_form .select2-container .select2-selection--single .select2-selection__rendered,
  .price_section_form .select2-container--default .select2-selection--single {
    max-height: 15px;
    padding-right: 0;
  }

  .price_section_form .select2-container--default .select2-selection--single .select2-selection__arrow {
    background-image: none;
  }

  .price_section_form .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: transparent;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  #one_item_wrapper .product__purchase {
    grid-template-columns: auto 48px;
    grid-template-rows: auto auto;
    grid-row-gap: 8px;
  }

  #one_item_wrapper .product__purchase .product__amount {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  #one_item_wrapper .product__purchase .product__buy {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  #one_item_wrapper .product__purchase .product__button {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-self: flex-end;
  }

  #one_item_wrapper .product__purchase .product__button {
    width: 40px;
    height: 40px;
  }

  .login__title {
    font-size: 24px;
    line-height: 120%;
  }

  #basket_wrapper .product__purchase {
    grid-template-columns: auto 48px;
    grid-template-rows: auto auto;
    grid-row-gap: 8px;
  }

  #basket_wrapper .product__purchase .product__amount {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  #basket_wrapper .product__purchase .product__buy {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  #basket_wrapper .product__purchase .product__button {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-self: flex-end;
  }

  #basket_wrapper .product__purchase .product__button {
    width: 40px;
    height: 40px;
  }

  .basket_order_total__promo {
    grid-template-rows: 48px 48px;
    grid-row-gap: 5px;
  }

  .order_total__input,
  .order_total__button {
    grid-column: 1 / 13;
    grid-row: span 1;
  }

  .modal[id^='openModal_add_'],
  .modal[id^='openModal_edit_'] {
    top: 15vh;
  }

  .modal[id^='openModal_add_'] .add_place_form__holder,
  .modal[id^='openModal_edit_'] .add_place_form__holder {
    padding-bottom: 25px;
  }

  .modal[id^='openModal_add_'] .add_place_form__holder,
  .modal[id^='openModal_edit_'] .add_place_form__holder {
    height: auto;
  }

  .modal[id^='openModal_add_'] .dostavka_btn,
  .modal[id^='openModal_edit_'] .dostavka_btn {
    margin-top: 20px;
  }

  .modal[id^='openModal_add_'] .modal__content,
  .modal[id^='openModal_edit_'] .modal__content {
    flex-direction: column;
  }

  .my_account__card {
    flex-direction: column-reverse;
  }

  .account_block__card {
    max-width: 100%;
    margin-bottom: -18px;
  }

  .account__card {
    margin-right: -20px;
    margin-left: -20px;
  }

  .account_tile__cashback {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .orders_info__admin {
    grid-template-columns: auto 189px;
    grid-template-rows: 20px 36px;
    grid-row-gap: 10px;
  }

  .orders_info__sum {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    min-width: 290px;
  }

  .orders_info__show {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .orders_info__select {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-self: flex-end;
  }

  .orders_col_numb {
    width: 28%;
  }

  .one_order__information {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, max-content);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    align-items: center;
  }

  .one_order_information__status {
    justify-self: flex-end;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }

  .one_order_information__text {
    grid-column: 1 / 2;
    line-height: 100%;
  }

  .information_text-1 {
    grid-row: 1 / 2;
  }

  .information_text-2 {
    grid-row: 2 / 3;
  }

  .information_text-3 {
    margin-top: 5px;
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }

  .one_order_composition__amount,
  .one_order_composition__buy {
    margin-right: 0;
  }
}

@media screen and (max-width: 660px) and screen and (max-width: 768px) {
  .contact_index__image-1 {
    border-radius: 10px;
  }

  .contact_index__image-2 {
    border-radius: 10px;
  }

  .contact_index__image-3 {
    border-radius: 10px;
  }
}

@media screen and (max-width: 591px) {
  .choose__wrapper {
    display: block;
    max-width: 100%;
    overflow: hidden;
  }

  .choose__manager {
    max-width: unset;
  }

  .choose__manager_image {
    display: flex;
    justify-content: center;
    max-height: 100%;
    max-width: unset;
    width: auto;
  }

  .choose__block_photo {
    max-height: unset;
    overflow: hidden;
  }

  .choose__block_photo .choose__manager_image {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .choose__block_photo .choose__manager_image img {
    width: 128px;
    height: 128px;
    max-width: unset;
  }

  .choose__block_photo .choose__manager.first-of-type .choose__manager_image img {
    width: 128px;
  }

  .choose__block_photo .choose__manager.first-of-type .choose__manager_image:before {
    width: 166px;
    height: 140px;
    top: unset;
    right: unset;
    bottom: -6px;
    left: unset;
  }

  .choose__block_text {
    margin-bottom: 10px;
  }

  .choose__container .button-yellow {
    width: 100%;
    margin-top: 10px;
  }
}

@media screen and (max-width: 590px) {
  h1.caption {
    font-size: 24px;
    line-height: 110%;
  }

  h1.title,
  h3.caption,
  h2.caption {
    font-size: 24px;
  }

  h2.title,
  h3.title {
    font-size: 18px;
  }

  .text-18-title {
    font-size: 16px;
  }

  .text-16 {
    font-size: 14px;
    line-height: 130%;
  }

  .button-yellow {
    width: 150px;
    height: 38px;
  }

  .button-gray {
    width: 150px;
    height: 38px;
  }

  .button-long {
    width: 100%;
    max-width: 100%;
    height: 48px;
  }

  .button-small {
    width: 260px;
  }

  .icon__login_close {
    background-image: url("../images/svg/icons/login/b-close.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
  }

  .header__menu_mobile {
    top: 52px;
    padding-top: 30px;
  }

  .header__menu_mobile .menu__item {
    padding-bottom: 12px;
  }

  .header__aside_icons {
    padding-bottom: 20px;
  }

  .header__search {
    max-width: unset;
    padding-bottom: 25px;
  }

  .catalog__holder {
    min-height: 80vh;
  }

  .catalog__block.row .catalog__scroll {
    height: 80vh;
    min-height: 80vh;
    max-height: 80vh;
  }

  .catalog__block.row .catalog__articles {
    height: 80vh;
    min-height: 80vh;
    max-height: 80vh;
  }

  .catalog__scroll {
    height: 80vh;
    min-height: 80vh;
    max-height: 80vh;
  }

  .catalog__scroll-main {
    max-height: 80vh;
    min-height: 80vh;
    padding-right: 15px;
  }

  .catalog__holder {
    padding: 0 5px 4vh 10px;
  }

  .catalog__top {
    margin-bottom: 0;
  }

  .catalog__section_caption {
    padding: 8px 10px;
  }

  .catalog__holder.open .catalog__scroll-main {
    padding-right: 0;
  }

  .catalog__holder.open .catalog__scroll {
    padding-right: 25px;
  }

  .container_main .wrapper {
    padding-left: 5px;
    padding-right: 5px !important;
  }

  .container_main .slick-list {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .carousel__holder .carousel__item {
    min-height: 265px;
  }

  .carousel__item_inner {
    border-radius: 10px;
    padding: 15px 15px 40px;
  }

  .carousel__item_text h2.text-16.text-white {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 10px;
    line-height: 17px;
  }

  .request__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .request__form_holder {
    padding: 20px;
  }

  .request__form_holder input {
    height: 47px;
  }

  .request__form_holder label:last-of-type {
    margin-bottom: 20px;
  }

  .request__form_holder h2 {
    margin-bottom: 16px;
  }

  form.request__form button {
    margin-top: 30px;
  }

  .cover__container .wrapper {
    grid-template-rows: repeat(3, auto);
    grid-gap: 6px;
  }

  .cover__holder {
    padding: 15px 15px 18px;
  }

  .cover__holder-2 .cover_img {
    max-width: 32%;
  }

  .cover__holder-3 .cover_img {
    max-width: 38%;
  }

  .variety_products__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .variety_products__container {
    margin-top: 32px;
  }

  .variety_products__container br {
    display: none !important;
  }

  .variety_products__container .variety_products__list {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, auto);
    margin-top: 20px;
  }

  .variety_products__container .variety_products__list .variety_products_image {
    border-radius: 10px;
    overflow: hidden;
  }

  .variety_products__container .variety_products__list div[class^='variety_product-'] {
    display: block;
  }

  .variety_products__block-square.variety_product-1 {
    grid-column: 1 / 2;
  }

  .variety_products__block-square.variety_product-3 {
    grid-column: 2 / 3;
  }

  .variety_products__block-square.variety_product-1,
  .variety_products__block-square.variety_product-3 {
    grid-row: 2 / 3;
  }

  .variety_products__block-square.variety_product-2 {
    grid-column: 1 / 2;
  }

  .variety_products__block-square.variety_product-2 {
    grid-column: 2 / 3;
  }

  .variety_products__block-square.variety_product-2,
  .variety_products__block-square.variety_product-4 {
    grid-row: 3 / 4;
  }

  .variety_products__block-square.variety_product-9 {
    grid-column: 1 / 2;
  }

  .variety_products__block-square.variety_product-10 {
    grid-column: 2 / 3;
  }

  .variety_products__block-square.variety_product-9,
  .variety_products__block-square.variety_product-10 {
    grid-row: 6 / 7;
  }

  .variety_products__block-square.variety_product-11 {
    grid-column: 1 / 2;
  }

  .variety_products__block-square.variety_product-12 {
    grid-column: 2 / 3;
  }

  .variety_products__block-square.variety_product-11,
  .variety_products__block-square.variety_product-12 {
    grid-row: 7 / 8;
  }

  .variety_products__block-rectangle.variety_product-5 {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  .variety_products__block-rectangle.variety_product-6 {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
  }

  .variety_products__block-rectangle.variety_product-7 {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
  }

  .variety_products__block-rectangle.variety_product-8 {
    grid-column: 1 / 3;
    grid-row: 8 / 9;
  }

  .variety_products__container .variety_products__list .slick-prev.slick-arrow,
  .variety_products__container .variety_products__list .slick-next.slick-arrow {
    position: absolute;
    transform: none;
    z-index: 100;
    top: 0;
    bottom: 0;
    width: 20%;
    height: 100%;
    color: transparent;
    font-size: 1px;
    line-height: 0;
  }

  .variety_products__container .variety_products__list .slick-prev.slick-arrow {
    left: 0;
  }

  .variety_products__container .variety_products__list .slick-next.slick-arrow {
    right: 0;
  }

  .offer__container {
    padding-top: 30px;
    padding-bottom: 35px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .offer__container h2.caption {
    margin-bottom: 30px;
  }

  .offer__form {
    padding: 15px;
  }

  .offer__contact button {
    margin-top: 20px;
  }

  .offer__li {
    width: 35px;
    height: 35px;
  }

  .busareas__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .busareas__content {
    border-radius: 10px;
  }

  .busareas__block {
    width: 98%;
  }

  .busareas__text span {
    line-height: 120%;
  }

  .busareas__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .busareas__container .wrapper:nth-of-type(2) {
    padding: 0;
  }

  .busareas__block {
    max-height: 125px;
    height: 20vw;
    min-height: 105px;
  }

  .busareas__image,
  .busareas__text {
    padding: 10px;
  }

  .busareas__image {
    min-width: 135px;
    max-height: 150px;
    min-height: unset;
    height: unset;
  }

  .busareas__text {
    height: unset;
  }

  .text-18-title {
    width: 80%;
    font-size: 14px;
    line-height: 100%;
  }

  .delivery_index__container {
    margin-top: 43px;
    margin-bottom: 40px;
  }

  .delivery_index__container .section__caption {
    margin-bottom: 10px;
  }

  .delivery_index__wrapper {
    grid-row-gap: 20px;
  }

  .delivery_index__facts p:nth-of-type(1) span,
  .delivery_index__facts p:nth-of-type(2) span,
  .delivery_index__facts p:nth-of-type(3) span {
    max-width: unset;
  }

  .delivery_index__image {
    height: 200px;
  }

  .choose__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .choose__wrapper {
    padding: 15px;
  }

  .choose__container .button-yellow {
    width: 100%;
  }

  .choose__block_photo {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  .choose__manager {
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: 7.5px;
    margin-right: 7.5px;
    width: calc(100% / 24 * 5 - 15px);
    margin-bottom: 15px;
  }

  .choose__manager_name {
    margin-top: 10px;
  }

  .advantages__container {
    margin-top: 49px;
    margin-bottom: 40px;
  }

  .advantages__item {
    grid-template-columns: 26px auto;
    grid-column-gap: 0;
    padding: 16px 0 14px;
  }

  .advantages__item-1 {
    grid-template-columns: 23px auto;
  }

  .advantages__item-4,
  .advantages__item-5,
  .advantages__item-6 {
    padding: 18px 0 14px;
  }

  .advantages__title,
  .advantages__text {
    grid-column: 2 / 3;
  }

  .reputation__container {
    margin-top: 44px;
    margin-bottom: 40px;
  }

  .reputation__block,
  .reputation__block-rectangle {
    padding: 16px;
  }

  .reputation__title {
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .subscribe__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .subscribe__wrapper {
    padding: 15px;
  }

  .subscribe__wrapper {
    border-radius: 10px;
  }

  .subscribe__caption {
    width: 10em;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0;
  }

  .subscribe__title {
    width: 209px;
  }

  .subscribe__holder {
    padding-top: 54px;
  }

  .subscribe__check span {
    width: 201px;
  }

  .subscribe__check {
    margin-top: 16px;
  }

  .subscribe__social_holder {
    padding: 8px 8px;
  }

  .about_index__container {
    margin-top: 44px;
    margin-bottom: 40px;
  }

  .about_index__content {
    margin-top: -10px;
  }

  .about_index__block {
    padding: 16px;
  }

  .programs__container {
    margin-top: 46px;
    margin-bottom: 40px;
  }

  .programs__container .wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  .programs__container .section__caption,
  .programs__container .slick-list {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .programs__holder .relative:before {
    width: 50px;
    height: 50px;
  }

  .programs__holder .relative .relation {
    padding-top: 106%;
  }

  .programs__video {
    border-radius: 10px;
  }

  .cameras__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .cameras__wrapper .relation {
    padding-top: 69%;
  }

  .cameras__text p {
    line-height: 17px;
  }

  .cameras__text p:first-of-type {
    width: 70%;
  }

  .cameras__text {
    margin-top: -7px;
  }

  .cameras__row {
    grid-column: span 1;
    grid-gap: 10px;
    margin-top: 15px;
  }

  .reviews__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .reviews__content p {
    grid-column: span 1;
  }

  .reviews__content {
    margin-top: -9px;
    grid-template-columns: auto;
    grid-row-gap: 10px;
  }

  .review__wrapper {
    padding: 15px;
  }

  .review__wrapper {
    border-radius: 10px;
    grid-column: span 1;
    grid-column-gap: 0;
    grid-template-columns: auto auto;
  }

  .review__wrapper .review__first {
    grid-column: span 1;
  }

  .review__block-1 {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }

  .review__block-2 {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }

  .contacts_index__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .contact_index___title {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .contact_index___text .mb {
    margin-bottom: 8px;
  }

  .subscribe__wrapper_catalog.subscribe__wrapper {
    padding: 15px;
  }

  .catalog__fbutton span {
    display: none;
  }

  .product__image img {
    max-height: 82%;
  }

  .product__name {
    font-size: 14px;
  }

  .product__options {
    border-radius: 4px;
    grid-template-rows: 40px 40px;
  }

  .product__text {
    padding: 4px 4px 8px 8px;
  }

  .product__buy {
    border-radius: 4px;
  }

  button.button.pagination_arrow {
    width: auto;
    height: auto;
    border-radius: 6px;
  }

  .pagination_arrow div[class^='ico__arrow'] {
    padding: 0;
  }

  .pagination_arrow > span {
    display: none;
  }

  .production__caption h1 {
    width: 90%;
  }

  .production__caption h1 {
    hyphens: auto;
  }

  .production_about__wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-row-gap: 20px;
    margin-bottom: 43px;
  }

  .production__block h2 {
    font-size: 14px;
  }

  .production__image {
    height: 150px;
  }

  .production__image {
    height: 130px;
  }

  #container_production .catalog__fbutton span {
    display: block;
  }

  .suppliers_main__content .caption {
    font-size: 16px;
  }

  .suppliers_steps__container {
    margin-top: 49px;
    margin-bottom: 40px;
  }

  .supplier_form__holder .gr {
    grid-template-columns: 100%;
    grid-column-gap: 0;
    grid-row-gap: 10px;
  }

  .about__video:before {
    width: 50px;
    height: 50px;
  }

  .about__video:after {
    width: 16px;
    height: 16px;
  }

  .contacts_map__container {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0;
    grid-row-gap: 20px;
  }

  .contacts_map__info {
    grid-template-columns: auto;
    grid-template-rows: repeat(4, max-content);
    grid-row-gap: 10px;
  }

  .contact_cameras__row {
    margin-top: 15px;
  }

  .payment_info__image {
    border-radius: 10px;
  }

  .delivery_main__content {
    margin-top: -10px;
  }

  .delivery_main__thesis_title {
    margin-top: 5px;
  }

  .delivery_conditions__container {
    margin-top: 40px;
  }

  .delivery_conditions__blocks {
    margin-top: 20px;
  }

  .delivery_conditions__block {
    grid-column: 1 / 13;
    padding: 16px;
  }

  .delivery_conditions__icon {
    width: 33px;
    height: 33px;
  }

  .delivery_conditions__title {
    font-size: 16px;
  }

  .delivery_camera__holder {
    grid-column: 1 / 13;
  }

  .delivery_camera__text {
    grid-column: 1 / 13;
    grid-row: 1 / 2;
  }

  .delivery_camera__video {
    grid-column: 1 / 13;
    grid-row: 2 / 3;
  }

  .delivery_camera__video:before {
    width: 50px;
    height: 50px;
  }

  .delivery_camera__video:after {
    width: 16px;
    height: 16px;
  }

  .delivery_camera__video .relation_content {
    border-radius: 10px;
  }

  .price__search {
    margin-bottom: 40px;
  }

  .price_section__about {
    padding: 15px 15px 0;
  }

  .price_section__title {
    font-size: 16px;
    line-height: 26px;
  }

  .price_section__item {
    padding: 15px;
  }

  .price__container .mb {
    margin-bottom: 20px;
  }

  .one_image__holder {
    height: 300px;
    margin-bottom: 0;
  }

  .one_image__holder .slick-track {
    top: -10vw;
  }

  .one_image__wrapper,
  .one_image__main.slick-initialized.slick-slider.slick-dotted,
  .one_image__main .slick-list.draggable,
  .one_image__main img {
    height: 300px;
  }

  .one_image__wrapper .slick-track,
  .one_image__main.slick-initialized.slick-slider.slick-dotted .slick-track,
  .one_image__main .slick-list.draggable .slick-track,
  .one_image__main img .slick-track {
    top: -10vw;
  }

  .one_item__delivery {
    display: block;
  }

  .one_item__availability {
    border-top: 1px solid #DDE1E6;
  }

  .similar_carousel__holder .caption {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .similar_carousel__holder .slick-prev.slick-arrow,
  .similar_carousel__holder .slick-next.slick-arrow {
    position: absolute;
    transform: none;
    z-index: 100;
    top: 0;
    bottom: 0;
    width: 20%;
    height: 100%;
    color: transparent;
    font-size: 1px;
    line-height: 0;
  }

  .similar_carousel__holder .slick-prev.slick-arrow {
    left: 0;
  }

  .similar_carousel__holder .slick-next.slick-arrow {
    right: 0;
  }

  #one_item_wrapper .section__caption {
    padding-right: 0;
  }

  .login__container {
    padding: 21px 15px;
  }

  .register__entities {
    grid-column-gap: 8px;
    margin-bottom: 16px;
  }

  .register__form {
    grid-column-gap: 5px;
  }

  .register_agree {
    margin-bottom: 5px;
  }

  .register__form button {
    margin-top: 15px;
  }

  .register__form p.text-small {
    font-size: 10px;
    margin-top: 5px;
  }

  .login_via_social__title {
    font-size: 12px;
    line-height: 120%;
    margin-bottom: 10px;
  }

  .login_via_social__buttons {
    grid-column-gap: 6px;
  }

  .login__container .margin {
    margin-bottom: 10px;
  }

  .login__container .button-yellow {
    height: 48px;
  }

  .login__welcome {
    width: 80%;
  }

  .login__welcome .welcome {
    margin-bottom: 12px;
  }

  .login__welcome .margin {
    margin-bottom: 5px;
  }

  .login__welcome #login_finish {
    min-width: 100%;
    margin-top: 20px;
  }

  .login_welcome__title {
    font-size: 16px;
  }

  #basket_wrapper .section__caption {
    padding-right: 0;
  }

  #basket_wrapper .similar_carousel__holder .slick-prev.slick-arrow,
  #basket_wrapper .similar_carousel__holder .slick-next.slick-arrow {
    position: absolute;
    transform: none;
    z-index: 100;
    top: 0;
    bottom: 0;
    width: 20%;
    height: 100%;
    color: transparent;
    font-size: 1px;
    line-height: 0;
  }

  #basket_wrapper .similar_carousel__holder .slick-prev.slick-arrow {
    left: 0;
  }

  #basket_wrapper .similar_carousel__holder .slick-next.slick-arrow {
    right: 0;
  }

  .basket_order__wrapper .section_block {
    padding: 15px;
  }

  #basket_order_code #order_code_button {
    height: 48px;
  }

  .basket_thanks__holder.last p {
    max-width: 260px;
  }

  .basket_thanks__caption {
    font-size: 16px;
  }

  .basket_product__block {
    padding-bottom: 0;
  }

  .basket_order__out .basket_product__block {
    padding-bottom: 16px;
  }

  .basket_product__block {
    grid-template-columns: 20px 74px repeat(10, auto);
  }

  .basket_product__image {
    min-width: 64px;
    min-height: 64px;
  }

  .amount_important {
    margin-bottom: 16px;
  }

  .address_obtaining__add {
    width: 100%;
  }

  .address_obtaining__add .icon__address_add {
    width: 15px;
    height: 15px;
  }

  .address_obtaining__address {
    grid-column-gap: 10px;
  }

  .basket_address__form {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 16px;
  }

  .add_place__title {
    margin-bottom: 10px;
  }

  .order_info__holder {
    display: none;
  }

  .personal_account__content h1.title {
    margin-bottom: 20px;
  }

  .personal_account__container .section_block {
    padding: 15px;
  }

  .my_account__card .account__title {
    margin-bottom: 5px;
  }

  .account_block__card {
    margin-top: -15px;
    margin-right: -15px;
    margin-bottom: -12px;
  }

  .my_account__info {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 10px;
  }

  .my_account__article {
    height: 100px;
  }

  .my_account__article_text {
    font-size: 12px;
    line-height: 120%;
  }

  .my_account__article_change {
    font-size: 16px;
  }

  .my_account__contact_information .account__title {
    margin-bottom: 16px;
  }

  .my_account__contact_form {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 10px;
  }

  .contact_information__input {
    grid-column: 1 / 2;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  [for='contact_information_numb'] {
    margin-top: 18px;
  }

  .my_account__contact_button button.button-yellow {
    height: 48px;
  }

  .my_account__contact_button {
    grid-column: 1 / 2;
  }

  .orders_info__table {
    padding: 0 20px;
  }

  .orders_info__table_header {
    display: grid;
    grid-template-columns: auto 24px;
    grid-column-gap: 16px;
    grid-template-rows: 24px repeat(2, 14px) 19px;
    grid-row-gap: 8px;
  }

  .orders_info__table_header {
    display: none;
  }

  .orders_col_numb {
    width: 100%;
  }

  .orders_col_numb {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin-bottom: 2px;
  }

  .orders_col_date {
    width: 100%;
  }

  .orders_col_date {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }

  .orders_col_status {
    width: 100%;
  }

  .orders_col_status {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    display: flex;
    justify-content: space-between;
  }

  .orders_col_amount {
    width: 100%;
  }

  .orders_col_amount {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    display: flex;
    justify-content: space-between;
  }

  .orders_col_watch {
    width: 100%;
  }

  .orders_col_watch {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .orders_info__table_row {
    display: grid;
    grid-template-columns: auto 24px;
    grid-column-gap: 16px;
    grid-template-rows: 24px repeat(2, 14px) 19px;
    grid-row-gap: 8px;
  }

  .orders_info__table_row {
    padding: 20px 0;
  }

  .orders_info__table_row .orders_col_numb {
    font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 145%;
  }

  .orders_info__table_row .orders_col_numb span {
    display: inline;
  }

  .orders_info__table_row .block-sm {
    display: inline-block;
    margin-right: auto;
  }

  .orders_info__table_row .orders_col_date,
  .orders_info__table_row .orders_col_status {
    font-size: 12px;
    line-height: 120%;
  }

  .orders_info__table_row .orders_col_amount {
    font-size: 16px;
    line-height: 120%;
  }

  .one_order__container {
    margin-bottom: 20px;
  }

  .one_order_status__radio {
    display: flex;
    flex-direction: column;
  }

  .one_order_status {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .one_order_status span {
    display: inline-block;
    margin-top: 0;
    margin-left: 8px;
    width: auto;
  }

  .one_order_status:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 100;
    top: 12px;
    left: 12px;
    width: 1px;
    height: 100%;
  }

  .one_order_status:last-of-type {
    flex-grow: 0;
    display: flex;
    margin-bottom: 0;
  }

  .one_order_delivery {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 0;
  }

  .one_order_delivery__block {
    grid-column: 1 / 2;
    grid-row: span 1;
  }

  .one_order_details {
    grid-template-columns: 50% 50%;
  }

  .one_order_payment {
    grid-template-columns: 50% 50%;
  }

  .one_order_composition__block {
    padding-bottom: 0;
  }

  .one_order_composition__block {
    grid-template-columns: 64px 1fr 1fr;
    grid-row-gap: 10px;
    align-items: flex-start;
  }

  .one_order_composition__image {
    min-width: 64px;
    min-height: 64px;
  }

  .one_order_composition__title {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
  }

  .one_order_composition__title {
    font-size: 14px;
    line-height: 120%;
  }

  .one_order_composition__amount {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-self: flex-start;
  }

  .one_order_composition__buy {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  .delivery_addresses__title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .address_personal__address {
    grid-column-gap: 10px;
    align-items: flex-start;
  }

  .add_address__title {
    font-size: 18px;
  }

  .payment_info__admin {
    grid-template-columns: 100%;
    grid-template-rows: 20px 36px;
    grid-column-gap: 0;
    grid-row-gap: 10px;
  }

  .payment_info__admin .orders_info__select {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .personal_account__setting .account__title {
    margin-bottom: 10px;
  }

  .personal_account__setting .personal_account__checkbox.mb {
    margin-bottom: 8px;
  }

  .personal_account__setting .title-mt {
    margin-top: 20px;
  }

  .personal_account__bottom {
    padding-bottom: 20px;
  }

  .personal_account__password {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 10px;
  }

  .footer__holder {
    margin-top: 60px;
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .footer__holder .wrapper > div {
    padding-top: 20px;
  }

  .footer__information_holder .footer__logo {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .footer__information > div {
    flex-grow: unset;
    white-space: unset;
    min-width: 70%;
    width: 100%;
    margin-bottom: 10px;
    padding-right: 0;
    padding-left: 0;
  }

  .footer__information {
    margin-bottom: 5px;
  }

  .footer__links_holder {
    display: flex;
    justify-content: flex-start;
  }

  .footer__links_section:first-of-type {
    width: unset;
  }

  .footer__links {
    max-height: unset;
  }

  .footer__links .row_flex-32 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .footer__links .row_flex-32 > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer__link {
    max-width: 195px;
    min-width: 155px;
  }

  .footer_links-1 {
    order: 2;
    min-width: unset;
  }

  .footer_links-1 .footer__link {
    min-width: unset;
  }

  .footer_links-2 {
    order: 1;
    margin-right: 5px;
  }

  .footer__links_holder.row_flex-32 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .footer__links_holder.row_flex-32 > * {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media screen and (max-width: 550px) {
  .basket_product__block {
    align-items: flex-start;
    grid-template-columns: 74px repeat(10, auto) 30px;
    grid-template-rows: auto 19px 46px minmax(18px, auto);
    grid-column-gap: 0;
  }

  .basket_product__image {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    margin-right: 10px;
  }

  .basket_product__checkbox {
    grid-column: 12 / 13;
    grid-row: 1 / 2;
    margin-left: 10px;
  }

  .product__title {
    grid-column: 2 / 12;
  }

  .product__info {
    display: none;
  }

  .basket_product__buy {
    grid-column: 2 / 13;
    grid-row: 2 / 3;
    justify-self: stretch;
    align-self: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .basket__price {
    grid-column: 2 / 7;
    justify-self: flex-start;
  }

  .basket__item {
    grid-column: 7 / 13;
    justify-self: flex-end;
  }

  .basket__amount {
    grid-column: 2 / 13;
    grid-row: 3 / 4;
    align-self: flex-end;
    width: 100%;
    height: 36px;
    padding: 10.5px 43px;
  }

  .amount_important {
    grid-column: 2 / 13;
    grid-row: 4 / 5;
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
  }

  .basket_product__block_out {
    align-items: flex-start;
    grid-template-columns: 74px repeat(10, auto) 30px;
    grid-template-rows: auto 32px;
  }

  .product__another {
    grid-column: 2 / 13;
    grid-row: 2 / 3;
  }

  .one_order_composition__image {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    margin-right: 10px;
  }
}

@media screen and (max-width: 530px) {
  .subscribe__wrapper_catalog.subscribe__wrapper:after {
    width: 11em;
    height: 20em;
    bottom: 15px;
    right: 0;
  }

  .subscribe__wrapper_catalog:after {
    width: 11em;
    height: 20em;
    bottom: 15px;
    right: 0;
  }

  .subscribe__wrapper_catalog .subscribe__caption {
    width: 90%;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0;
  }

  .subscribe__wrapper_catalog .subscribe__title {
    width: 80%;
  }

  .subscribe__wrapper_catalog .subscribe__holder {
    padding-top: 52px;
  }

  .subscribe__wrapper_catalog .subscribe__holder {
    width: 100%;
  }

  .subscribe__wrapper_catalog .subscribe__check {
    margin-top: 16px;
  }

  .subscribe__wrapper_catalog .subscribe__check span {
    width: 80%;
    line-height: 14px;
  }
}

@media screen and (max-width: 525px) {
  .catalog__select + .select2-container {
    min-width: 100%;
  }

  .catalog_main__show {
    display: grid;
    justify-content: unset;
    grid-template-columns: auto 36px;
    grid-template-rows: 36px 40px;
    grid-column-gap: 16px;
    grid-row-gap: 10px;
  }

  .catalog__select_holder-1 {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  .catalog__select_holder-2 {
    grid-column: 1 / 2;
    grid-row: 2 /3;
    margin-left: unset;
  }

  .catalog__fbutton {
    margin-left: 0;
    width: 24px;
  }

  .catalog_main__labels {
    grid-template-columns: auto 120px;
  }

  #container_production .catalog__fbutton {
    width: unset;
  }
}

@media screen and (max-width: 500px) {
  .catalog_main__section {
    grid-template-columns: repeat(2, minmax(139px, auto));
    grid-row-gap: 30px;
  }

  .one_item__parameter_holder {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, 34px);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }

  .one_item__parameter_holder .parameter_pack-1 {
    grid-column: 1 / 3;
  }

  .one_item__parameter_holder .parameter_pack-2 {
    grid-column: 1 / 2;
  }

  .one_item__parameter_holder .parameter_pack-3 {
    grid-column: 2 / 3;
  }
}

@media screen and (max-width: 490px) {
  .one_order_details {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 0;
  }

  .one_order_payment {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0;
  }
}

@media screen and (max-width: 485px) {
  .variety_products__container .wrapper {
    padding-left: 5px;
    padding-right: 5px !important;
  }

  .variety_products__container .slick-list {
    padding-left: 0 !important;
    padding-right: 130px !important;
    margin-left: 25px !important;
  }

  .variety_products__container .section__caption {
    padding-left: 30px;
    margin-left: 23px !important;
  }

  .variety_products__container .variety_products__list > article {
    max-width: 475px;
  }

  .variety_products__container .variety_products__list {
    display: block;
  }

  .variety_products__container .variety_products__list .relation-rectangle,
  .variety_products__container .variety_products__list .relation-square {
    padding-top: 89.8%;
  }

  .variety_products__container .section__caption {
    padding-left: 30px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
  }

  .variety_products__container .section__caption .section-title-text-14 {
    margin-bottom: 6px;
    grid-column: span 1;
    grid-row: 1 / 2;
  }

  .variety_products__container .section__caption .caption {
    grid-column: span 1;
    grid-row: 2 / 3;
  }

  .variety_product-9,
  .variety_product-10,
  .variety_product-11,
  .variety_product-12 {
    margin-top: 0;
  }

  #one_item_wrapper {
    padding-left: 5px;
    padding-right: 5px !important;
  }

  #basket_wrapper {
    padding-left: 5px;
    padding-right: 5px !important;
  }
}

@media screen and (max-width: 475px) {
  .reputation__tile {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: minmax(158px, auto);
    grid-template-areas: "a a" "b c" "d d" "e e" "f f" "j j" "k k";
  }

  .reputation__block-3 {
    grid-area: c;
    grid-column: 2 / 3;
  }

  .reputation__block-1 {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
}

@media screen and (max-width: 470px) {
  html {
    font-size: 10px;
  }

  .wrapper-small {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .wrapper-add-address {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  #black_back.up {
    z-index: 1500;
  }

  h3.caption.text-blue {
    letter-spacing: -0.1px;
  }

  .text-14-small {
    font-size: 12px;
  }

  .section__caption {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    margin-bottom: 18px;
  }

  .section__caption .section-title-text-14 {
    margin-bottom: 6px;
    grid-column: span 1;
    grid-row: 1 / 2;
  }

  .section__caption .caption {
    grid-column: span 1;
    grid-row: 2 / 3;
  }

  .section__caption br {
    display: none;
  }

  .footer__logo .logo__text {
    background-image: url("../images/svg/logo/xs-words.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    flex-shrink: 0;
  }

  .button-blue {
    width: 100%;
    font-size: 13px;
    padding: 16.5px 0;
    white-space: nowrap;
  }

  #container_main .button-yellow {
    max-width: unset;
    width: 100%;
  }

  #container_main .carousel__holder .button-yellow {
    padding: 12.5px 4px;
    max-width: unset;
    width: 150px;
  }

  .select2-container--default .select2-selection--single {
    height: 50px;
  }

  .ico__navigation {
    background-image: url("../images/svg/icons/lk/basket/b-navigation.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
  }

  .welcome {
    height: 49px;
  }

  header .logo__image {
    width: 64px;
    height: 16px;
  }

  header .logo__text {
    display: none;
  }

  footer .logo__text {
    width: 120px;
    height: 44px;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 13.6px;
  }

  .header__main {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header__holder_mobile .header__m_icons {
    margin-left: -7px;
    margin-right: 15px;
  }

  .header__holder_mobile .header__m_icons > * {
    padding-right: 7px;
    padding-left: 7px;
  }

  .carousel__item-1 h1.caption {
    width: 100%;
  }

  .request__image_holder {
    display: none;
  }

  .request__form_holder {
    padding: 15px;
  }

  .cover__caption {
    font-size: 19px;
    line-height: 114%;
    letter-spacing: -0.1px;
  }

  .cover__text {
    max-width: 79%;
  }

  .cover__holder-1 .cover_img {
    max-height: 55%;
  }

  .cover__holder-3 .cover_img {
    max-width: 70.8%;
    bottom: 18.8px;
  }

  .variety_products__block-square {
    /*max-width: 215px;
         max-height: 193px;
      [class^='image__'] {
         max-width: 215px;
         max-height: 193px;
      }*/
  }

  .variety_products__block-rectangle {
    /* max-width: 215px;
         max-height: 193px;
      [class^='image__'] {
         max-width: 215px;
         max-height: 193px;
      }*/
  }

  .variety_product-7,
  .variety_product-8 {
    margin-top: 0;
  }

  .variety_products__container .variety_products__list {
    display: block;
  }

  .variety_products__container .variety_products__list .relation-rectangle,
  .variety_products__container .variety_products__list .relation-square {
    padding-top: 90.8%;
    height: 25px;
    min-height: 25px;
    min-width: 140px;
  }

  .variety_products__container .section__caption {
    padding-left: 0;
  }

  .offer__container {
    padding-top: 28px;
  }

  .offer__container {
    background-image: url("../images/small/offer.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100%;
    height: 100%;
  }

  .offer__container h2.caption {
    line-height: 24.7px;
    letter-spacing: -0.1px;
    margin-bottom: 25.9px;
    margin-left: 12px;
  }

  .offer__container .wrapper.form {
    padding-left: 0;
    padding-right: 0;
  }

  .offer__form {
    border-radius: 10px;
  }

  .offer__li {
    width: 25px;
    height: 25px;
  }

  .offer__label .offer__li {
    margin-right: 8px;
  }

  .offer__select {
    width: 100%;
    margin-left: -28px;
  }

  .offer__container .text-small {
    line-height: 14px;
  }

  .offer__li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 16px;
    height: 16px;
    color: #70798E;
    flex-shrink: 0;
  }

  .offer__title {
    padding-top: 2px;
    padding-left: 28px;
    margin-bottom: 10px;
  }

  .offer__label-select {
    margin-bottom: 8px;
    width: 100%;
  }

  .busareas__container .caption.text-blue br {
    display: none;
  }

  .busareas__image {
    border-radius: 5px;
  }

  .busareas_image-1,
  .busareas_image-2,
  .busareas_image-3,
  .busareas_image-4 {
    border-radius: 5px;
  }

  .busareas__img {
    border-radius: 5px;
  }

  .busareas__container h3.caption.text-blue {
    word-break: break-word;
  }

  .busareas__container h3.caption.text-blue br {
    display: block;
    display: block !important;
  }

  .busareas__container .text-18-title {
    width: 100%;
  }

  .busareas__text {
    padding: 12px;
  }

  .busareas__image {
    padding-right: 10px;
    padding-left: 13px;
  }

  .busareas__text span {
    font-size: 12px;
  }

  .delivery_index__text {
    display: flex;
    flex-direction: column;
  }

  .delivery_index__text p {
    margin-right: 0;
  }

  .delivery_index__text p:first-of-type {
    margin-bottom: 10px;
  }

  .delivery_index__facts {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-left: 26px;
  }

  .delivery_index__facts p {
    line-height: 101%;
  }

  .delivery_index__facts p:not(:last-of-type) {
    padding-bottom: 23px;
  }

  .delivery_index__facts p:after {
    width: 16px;
    height: 16px;
    left: -26px;
    top: 0;
    transform: translateY(-20%);
  }

  .delivery_index__facts p:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 50;
    left: -18.5px;
    top: 4px;
    width: 0;
    border: 1px solid rgba(32, 54, 134, 0.2);
    height: 100%;
  }

  .delivery_index__facts p:last-of-type:before {
    display: none;
  }

  .choose__container .button-yellow {
    height: 41px;
  }

  .choose__manager.first-of-type .choose__manager_image {
    padding-right: 17px;
    padding-top: 6px;
  }

  .advantages__item-2 br,
  .advantages__item-5 br {
    display: none;
  }

  .advantages__title {
    width: 100%;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 6px;
  }

  .advantages__text {
    width: 100%;
    line-height: 125%;
  }

  .reputation__block,
  .reputation__block-rectangle {
    padding: 16px;
  }

  .reputation__block,
  .reputation__block-rectangle {
    padding: 15px;
  }

  .reputation__tile {
    display: grid;
    grid-gap: 0;
    grid-template-rows: minmax(158px, auto);
    grid-template-areas: "a a" "b c" "d d" "e e" "l l" "f f" "j j" "g g" "k k";
  }

  .reputation__tile .watch-more {
    grid-area: l;
    display: flex;
  }

  .reputation__tile .reputation__more {
    grid-area: g;
  }

  .reputation__tile .reputation__more > div {
    margin-bottom: 10px;
  }

  .reputation__tile .reputation__more > div:last-of-type {
    margin-bottom: 0;
  }

  .reputation__block,
  .reputation__block-rectangle {
    margin-bottom: 10px;
  }

  .reputation__block-1 {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  .reputation__block-2 {
    margin-right: 5px;
  }

  .reputation__block-3 {
    grid-area: c;
    grid-column: 2 / 3;
    margin-left: 5px;
  }

  .reputation__block-2 .reputation__text,
  .reputation__block-3 .reputation__text,
  .reputation__block-7 .reputation__text {
    padding-top: 55px;
  }

  .reputation__block-5 .reputation__text {
    padding-top: 38px;
    width: 100%;
  }

  .subscribe__wrapper:after {
    background-image: url("../images/small/hand.webp");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
  }

  .subscribe__wrapper:after {
    width: 11em;
    height: 20em;
    bottom: 15px;
  }

  .subscribe__caption {
    width: 9em;
  }

  .subscribe__holder {
    width: 100%;
  }

  .subscribe__check span {
    line-height: 13px;
  }

  .subscribe__socials {
    grid-template-columns: repeat(3, 40px);
    grid-template-rows: 1fr;
  }

  .subscribe__social_holder {
    grid-column: span 1;
    grid-row: 1 / 2;
  }

  .subscribe__social_holder span {
    display: none;
  }

  .subscribe__social_holder {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
  }

  .subscribe__social_holder div[class^='icon__'] {
    width: 24px;
    height: 24px;
  }

  .about_index__text p:first-of-type {
    margin-bottom: 6px;
  }

  .about_index__facts {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }

  .about_index__block {
    padding: 16px;
  }

  .caption.text-blue.about_index__title {
    font-size: 15px;
  }

  .about_index__span {
    padding-top: 74px;
  }

  .programs__name {
    margin-top: 10px;
    font-size: 17px;
    line-height: 19px;
    width: 91%;
  }

  .cameras__container .watch-more {
    margin-top: 20px;
    display: flex;
  }

  .cameras__wrapper {
    grid-template-rows: 1fr;
  }

  .camera__block {
    margin-bottom: 20px;
  }

  .camera__block:last-of-type {
    margin-bottom: 0;
  }

  .cameras__row {
    grid-template-columns: unset;
    grid-template-rows: unset;
    grid-column: unset;
    grid-row: unset;
    display: block;
  }

  .review__name {
    font-size: 14px;
    line-height: 120%;
  }

  .review__block-1 {
    grid-template-rows: 5px 18px 20px 68px;
    grid-template-columns: 62px 75px auto;
  }

  .review__button {
    width: 100%;
    height: 48px;
  }

  .contacts_index__container .section-title-text-14 {
    display: none;
  }

  .contacts_index__container br {
    display: none;
  }

  .contact_index___map {
    height: 120px;
  }

  .breadcrumbs__container {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  h2.catalog_main__caption {
    margin-bottom: 8px;
    letter-spacing: 0;
  }

  .catalog_main__about {
    margin-bottom: 20px;
  }

  .catalog_main__show {
    margin-bottom: 15px;
  }

  .subscribe__wrapper_catalog.subscribe__wrapper:after {
    background-image: url("../images/small/hand.webp");
  }

  .subscribe__wrapper_catalog .subscribe__socials {
    grid-template-columns: repeat(3, 40px);
    grid-template-rows: 1fr;
  }

  .product__image .relation {
    padding-top: 79%;
  }

  .product__data {
    margin-top: 2px;
  }

  .product__amount {
    padding: 10.5px 4px;
  }

  .filters_mobile__container {
    width: 350px;
  }

  .production__caption {
    padding-bottom: 10px;
  }

  .production__caption h1 {
    width: 100%;
  }

  .suppliers_main__content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    margin-bottom: 18px;
  }

  .suppliers_main__content .section-title-text-14 {
    margin-bottom: 6px;
    grid-column: span 1;
    grid-row: 1 / 2;
  }

  .suppliers_main__content .caption {
    grid-column: span 1;
    grid-row: 2 / 3;
  }

  .suppliers_main__content br {
    display: none;
  }

  .cooperation__text {
    width: 100%;
    line-height: 125%;
  }

  .contacts_map__name {
    font-size: 16px;
  }

  .contacts_camera .watch-more {
    display: block;
  }

  .contact_cameras__row {
    grid-column: 1 / 13;
    display: block;
  }

  .payment_info__line .text-18-title {
    margin-bottom: 5px;
  }

  .payment_info__form {
    padding: 15px;
  }

  .payment_info__form .caption {
    margin-bottom: 15px;
  }

  .payment__form .label-input {
    height: 48px;
  }

  .payment__form .half {
    width: 100%;
  }

  .payment__form {
    width: 100%;
  }

  .delivery_main__text p:first-of-type {
    margin-bottom: 6px;
  }

  .price__caption .button-yellow {
    grid-column: 1 / 13;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .one_image__holder {
    height: 250px;
  }

  .one_item__wrapper .subscribe__wrapper_catalog.subscribe__wrapper {
    padding: 15px;
  }

  .one_image__wrapper,
  .one_image__main.slick-initialized.slick-slider.slick-dotted,
  .one_image__main .slick-list.draggable,
  .one_image__main img {
    height: 250px;
  }

  .one_item__caption {
    font-size: 18px;
  }

  .one_item__purchase_holder button {
    margin-top: 20px;
  }

  .one_item__total {
    margin-bottom: 10px;
  }

  .one_item__buy {
    display: block;
  }

  .one_purchase__title {
    display: none;
  }

  .similar_carousel__holder {
    margin-top: 40px;
  }

  #one_item_wrapper .slick-list {
    padding-left: 0 !important;
    padding-right: 130px !important;
    margin-left: 25px !important;
  }

  #one_item_wrapper .section__caption {
    margin-left: 23px !important;
  }

  .login__title {
    font-size: 16px;
    line-height: 120%;
  }

  .basket__caption_top #amount_basket {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    font-size: 12px;
  }

  #basket_wrapper .slick-list {
    padding-left: 0 !important;
    padding-right: 130px !important;
    margin-left: 25px !important;
  }

  #basket_wrapper .section__caption {
    margin-left: 23px !important;
  }

  #basket_order_code {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 0;
  }

  #basket_order_code label {
    grid-column: 1 / 3;
    width: 100%;
  }

  #basket_order_code #order_code_button {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    width: 100%;
    max-width: unset;
  }

  .basket_thanks__button_holder {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .basket_thanks__button {
    padding-top: 16.5px;
    padding-bottom: 16.5px;
    margin-top: 0;
  }

  .basket_group__title,
  .basket_order_total__title {
    font-size: 18px;
  }

  .product__title {
    max-width: unset;
  }

  .product__title {
    font-size: 14px;
  }

  .product__another .button-another {
    margin-right: 8px;
  }

  .basket_order_total__title {
    margin-bottom: 15px;
  }

  .basket_address__obtaining {
    margin-bottom: 16px;
  }

  .ico__options {
    width: 24px;
    height: 24px;
  }

  .point_options__popup {
    position: fixed;
    z-index: 1600;
    top: calc(100vh - 174px);
    right: 0;
    left: 0;
    height: 174px;
    background-color: white;
    transform: translateY(100%);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
  }

  .point_options__popup.popup {
    transform: translateY(0);
    z-index: 99999;
    transition: all 0.3s ease-in-out;
  }

  .point_options__popup .line_gray {
    display: block;
  }

  .options_popup {
    padding: 27px 15px 15px;
  }

  .options_popup:after {
    display: none;
  }

  .address_obtaining {
    box-shadow: unset;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .address_obtaining .obtaining_map {
    color: #203686;
    margin-bottom: 16px;
  }

  .options_popup__text {
    font-size: 14px;
  }

  .address_obtaining div[class^='ico__'] {
    margin-right: 8px;
  }

  .address_popup__button {
    display: block;
  }

  .add_place_form__holder {
    padding: 20px 15px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .add_place__title {
    margin-bottom: 0;
  }

  .modal[id^='openModal_add_'],
  .modal[id^='openModal_edit_'] {
    top: 0;
    height: 100vh;
  }

  .modal[id^='openModal_add_'] .add_place_map__holder,
  .modal[id^='openModal_edit_'] .add_place_map__holder {
    height: calc(100% - 60px);
  }

  .modal[id^='openModal_add_'] .add_place_form__holder,
  .modal[id^='openModal_edit_'] .add_place_form__holder {
    padding-bottom: 20px;
  }

  .modal[id^='openModal_add_'] .modal__content,
  .modal[id^='openModal_edit_'] .modal__content {
    position: relative;
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }

  .modal[id^='openModal_add_'] .modal__close,
  .modal[id^='openModal_edit_'] .modal__close {
    position: absolute;
    z-index: 3500;
    right: 15px;
    top: 20px;
    width: 20px;
    height: 20px;
  }

  .modal[id^='openModal_add_'] .modal__close .icon__close-w,
  .modal[id^='openModal_edit_'] .modal__close .icon__close-w {
    background-image: url("../images/svg/icons/lk/basket/b-close.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    right: -15px;
    top: -20px;
  }

  .modal[id^='openModal_add_'] .add_place__title,
  .modal[id^='openModal_edit_'] .add_place__title {
    display: none;
  }

  .modal[id^='openModal_add_'] .add_place__title_xs,
  .modal[id^='openModal_edit_'] .add_place__title_xs {
    display: block;
    text-align: center;
    padding: 19px 0;
  }

  .modal[id^='openModal_add_'] .add_place_form__holder,
  .modal[id^='openModal_edit_'] .add_place_form__holder {
    position: absolute;
    z-index: 3000;
    left: 0;
    bottom: 0;
    background-color: white;
  }

  .check_type__holder {
    padding-bottom: 16px;
  }

  .check_info__holder {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .payment_type__item {
    margin-bottom: 10px;
  }

  .payment_type__item:last-of-type {
    margin-bottom: 0;
  }

  .my_account__card {
    margin-bottom: 20px;
  }

  .account_tile__cashback {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-row-gap: 10px;
  }

  .my_account__info {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .my_account__contact_button button.button-cancel {
    width: 100%;
    max-width: unset;
    margin-left: 0;
  }

  .my_account__contact_button button.button-yellow {
    width: 100%;
    max-width: unset;
  }

  .my_account__contact_button {
    display: flex;
    flex-direction: column;
  }

  .orders_info__admin {
    margin-bottom: 10px;
  }

  .orders_info__table {
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .orders_info__table_row {
    padding: 15px 0;
  }

  .one_order_composition__info {
    display: none;
  }

  .delivery_addresses__holder {
    margin-top: 20px;
  }

  .add_address__title {
    display: none;
  }

  .add_address__title_xs {
    display: block;
    padding: 19px 0 19px 20px;
  }

  .payment_info__admin {
    margin-bottom: 10px;
  }

  .payment_info__admin .orders_info__select {
    width: 100%;
  }

  .payment_info__admin .orders_info__select .select2.select2-container.select2-container--default {
    max-width: 100%;
    min-width: 100%;
  }

  .footer__holder {
    margin-top: 40px;
  }

  .footer__information > div {
    min-width: 65%;
  }

  .footer__information {
    margin-right: 0;
    margin-left: 0;
  }

  .footer_links-1 .footer__links {
    min-width: 130px;
  }
}

@media screen and (max-width: 450px) {
  .product__data .block-12 {
    display: block;
  }
}

@media screen and (max-width: 370px) {
  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .col {
    margin-left: 5px;
    margin-right: 5px;
  }

  .section-title-text-14 {
    font-size: 12px;
    line-height: 120%;
  }

  .block-12 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .block-12 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .catalog__top {
    margin-left: -5px;
    margin-right: -5px;
  }

  .catalog__top > div {
    margin-left: 5px;
    margin-right: 5px;
  }

  .catalog__top > div {
    margin-left: 5px;
    margin-right: 5px;
  }

  .container_main .wrapper {
    padding-left: 15px !important;
    padding-right: 5px !important;
  }

  .container_main .slick-list {
    padding-left: 0 !important;
    padding-right: 15px !important;
  }

  .cover__holder-2 .cover_img {
    max-width: 42.7%;
  }

  .variety_products__container .wrapper {
    padding-left: 15px !important;
    padding-right: 5px !important;
  }

  .variety_products__container .slick-list {
    padding-left: 0 !important;
    padding-right: 110px !important;
    margin-left: 15px !important;
  }

  .variety_products__container .section__caption {
    margin-left: 15px !important;
  }

  .offer__form .row > div {
    margin-left: 5px;
    margin-right: 5px;
  }

  .offer__form .row > div {
    margin-left: 5px;
    margin-right: 5px;
  }

  .offer__form .row > div {
    width: calc(100% - 10px);
  }

  .busareas__container .section-title-text-14 br {
    display: none;
  }

  .programs__container .wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  .programs__container .section__caption,
  .programs__container .slick-list {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .review__wrapper .review__first {
    font-size: 14px;
    line-height: 120%;
  }

  .review__block-2 {
    grid-template-columns: 50px auto;
  }

  .contact_index__image-1 {
    background-image: url("../images/small/index-contact/1.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  .contact_index__image-2 {
    background-image: url("../images/small/index-contact/2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  .contact_index__image-3 {
    background-image: url("../images/small/index-contact/3.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  .wrapper-big {
    padding-left: 16px;
    padding-right: 16px;
  }

  .wrapper-big {
    padding-left: 16px;
    padding-right: 16px;
  }

  .filters_mobile__container {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }

  .filters_mobile__holder {
    max-height: calc(100vh - 56.8px - 120px);
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  .caption__wrapper {
    margin-bottom: 20px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  #one_item_wrapper {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  #one_item_wrapper .slick-list {
    padding-left: 0 !important;
    padding-right: 110px !important;
    margin-left: 16px !important;
  }

  #one_item_wrapper .section__caption {
    margin-left: 16px !important;
  }

  #basket_wrapper {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  #basket_wrapper .slick-list {
    padding-left: 0 !important;
    padding-right: 110px !important;
    margin-left: 16px !important;
  }

  #basket_wrapper .section__caption {
    margin-left: 16px !important;
  }

  .product__another .button-another {
    font-size: 13px;
  }

  .orders_info__show {
    justify-self: flex-start;
  }

  .personal_menu__mobile {
    width: 100%;
    height: 100vh;
  }

  .footer__information > div {
    min-width: 90%;
  }

  .footer__link {
    max-width: 145px;
    min-width: 110px;
  }

  .footer_links-1 .footer__links {
    min-width: 100px;
  }
}

@media screen and (max-width: 370px) and screen and (max-width: 768px) {
  .contact_index__image-1 {
    border-radius: 10px;
  }

  .contact_index__image-2 {
    border-radius: 10px;
  }

  .contact_index__image-3 {
    border-radius: 10px;
  }
}

@media screen and (min-width: 890px) and screen and (max-width: 1190px) {
  .programs__container .slick-arrow {
    top: -30px;
  }
}

@media screen and (min-width: 890px) and screen and (max-width: 970px) {
  .programs__container .slick-arrow {
    top: -30px;
  }
}

@media screen and (min-width: 1555px) and screen and (max-width: 1650px) {
  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .wrapper-big {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media screen and (min-width: 1555px) and screen and (max-width: 1235px) {
  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media screen and (min-width: 1555px) and screen and (max-width: 768px) {
  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }
}

@media screen and (min-width: 1555px) and screen and (max-width: 660px) {
  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 1555px) and screen and (max-width: 370px) {
  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 1555px) and screen and (min-width: 1555px) and screen and (max-width: 1650px) {
  #container_one .header__main.wrapper,
  #container_catalog .header__main.wrapper,
  .breadcrumbs__container .wrapper,
  #container_catalog .header__aside .wrapper {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  #container_production .header__main.wrapper,
  #container_production .header__aside .wrapper {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  #container_one .header__main.wrapper,
  #container_one aside .wrapper {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media screen and (min-width: 590px) and screen and (max-width: 1190px) {
  .similar_carousel__holder .slick-arrow {
    top: -30px;
  }

  #basket_wrapper .similar_carousel__holder .slick-arrow {
    top: -30px;
  }
}

@media screen and (min-width: 590px) and screen and (max-width: 970px) {
  .similar_carousel__holder .slick-arrow {
    top: -30px;
  }

  #basket_wrapper .similar_carousel__holder .slick-arrow {
    top: -30px;
  }
}