/*  специализируемся на разных сферах бизнесса   */
.busareas__container {
   .section-margin();
   
   .caption.text-blue {
      width: 85%;
       br:first-of-type {
         .none()
      }
      .middle-block({
         width: 95%;
      });
      .xs-block({
         br {
            .none();
         }
      });
   }
   .caption.text-blue {
   
   }
   .section-title-text-14 {
      .xxs-block({
         br {
            .none();
         }
      })
   }
}
.busareas__content {
   .flex();
   flex-wrap: wrap;
   background-color: @color-white;
   border-radius: 20px;
   .lg-block({
   });
   .middle-block({
      padding: 0;
   });
   .xl-block({
      .column();
   });
   .sm-block({
      border-radius: 10px;
   });
}
.busareas__block {
   .flex();
   width: 50%;
   .xl-block({
      width: 100%;
   });
   .sm-block({
      width: 98%;
   })
}
.busareas__block:nth-of-type(3),
.busareas__block:nth-of-type(4) {
   .flex();
   flex-direction: row-reverse;
   span,
   .text-18-title {
      text-align: left;
   }
}

.busareas__text {
   .column();
   .justify-between();
   flex-grow: 1;
   position: relative;
   max-width: 280px;
   height: 200px;
   padding: 20px;
   &:before,
   &:after {
      .pseudo();
      background-color: @color-gray-light;
      border-radius: 5px;
   }
   .xl-block({
      max-width: unset;
      width: 50%;
   });
   .text-18-title {
      .middle-block({
         width: 80%;
      })
   };
}
.busareas__text span {
   .montserrat-medium();
   font-size: 14px;
   line-height: 20px;
   color: @color-gray;
   .sm-block({
      line-height: 120%;
   })
}


.busareas__image {
   position: relative;
   min-width: 240px;
   width: 240px;
   height: 200px;
   padding: 20px;
   border-radius: 10px;
   &:before,
   &:after {
      .pseudo();
      background-color: @color-gray-light;
      border-radius: 5px;
   }
   .xl-block({
      width: 50%;
   });
   .xs-block({
      border-radius: 5px;
   });
}
.busareas__text:before,
.busareas__image:before {
   top: 20px;
   bottom: 20px;
   width: 1px;
   height: calc( 100% - 40px);
   right: 0;
}
.busareas__text:after,
.busareas__image:after {
   right: 20px;
   left: 20px;
   height: 1px;
   width: calc( 100% - 40px);
   bottom: 0;
}



.busareas_image-1,
.busareas_image-2,
.busareas_image-3,
.busareas_image-4 {
   width: 100%;
   height: 100%;
   border-radius: 10px;
   .xs-block({
      border-radius: 5px;
   });
}
.busareas__img {
   width: 100%;
   height: 100%;
   background-repeat: no-repeat;
   background-size: cover;
   border-radius: 10px;
   .xs-block({
      border-radius: 5px;
   });
   &:nth-of-type(1) {
      background-position: 50% 30%;
   }
   &:nth-of-type(2) {
      background-position: 50% 73%;
   }
   &:nth-of-type(3) {
      background-position: 50% 90%;
   }
   &:nth-of-type(4) {
      background-position: 50% 93%;
   }
}


.busareas__block:nth-of-type(2) .busareas__text,
.busareas__block:nth-of-type(4) .busareas__image {
   &:before {
      display: none;
   }
}
.busareas__block:nth-of-type(3) .busareas__text,
.busareas__block:nth-of-type(3) .busareas__image,
.busareas__block:nth-of-type(4) .busareas__text,
.busareas__block:nth-of-type(4) .busareas__image {
   &:after {
      display: none;
   }
}









@media screen and (max-width: @break_xl) {
   .busareas__content {
      
      padding: 1vw 8vw 2vw;
      box-sizing: border-box;
   }
   .busareas__block:nth-of-type(2) {
      .flex();
      flex-direction: row-reverse;
      span,
      .text-18-title {
         text-align: left;
      }
   }
   .busareas__block:nth-of-type(3) {
      flex-direction: row;
       span,
      .text-18-title {
         text-align: left;
      }
   }
   .busareas__block:nth-of-type(1) {
      .busareas__text:before {
         display: none;
      }
   }
   .busareas__block:nth-of-type(2) {
      .busareas__text:before {
         display: block;
      }
      .busareas__image:before {
         display: none;
      }
   }
   .busareas__block:nth-of-type(3) {
      .busareas__text:before {
         display: none;
      }
   }
   .busareas__block:nth-of-type(4) {
      .busareas__text:after,
      .busareas__image:after {
         display: block;
         top: 0;
         bottom: unset;
      }
   }
}

@media screen and (max-width: @break_lg) {
   .busareas__content {
      padding: 1vw 3vw 2vw;
      box-sizing: border-box;
   }
   .busareas__text:before,
   .busareas__image:before {
      top: 10px;
      bottom: 10px;
      width: 1px;
      height: calc( 100% - 20px);
      right: 0;
   }
   .busareas__text:after,
   .busareas__image:after {
      right: 10px;
      left: 10px;
      height: 1px;
      width: calc( 100% - 20px);
      bottom: 0;
   }

}
@media screen and (max-width: @break_sm) {
   .busareas__content {
      .flex-center();
   }
   .busareas__container .wrapper:nth-of-type(2) {
      padding: 0;
   }
   .busareas__block {
      max-height: 125px;
      height: 20vw;
      min-height: 105px;
   }
   .busareas__image,
   .busareas__text {
      padding: 10px;
   }
   .busareas__image {
      min-width: 135px;
      max-height: 150px;
      min-height: unset;
      height: unset;
   }
   .busareas__text {
      height: unset;
   }
   .text-18-title {
      width: 80%;
      font-size: 14px;
      line-height: 100%;
   }
}
@media screen and (max-width: @break_xs) {
   .busareas__container h3.caption.text-blue {
      word-break: break-word;
      br {
         .block
      }
   }
   .busareas__container .text-18-title {
      width: 100%;
   }
   .busareas__text {
       padding: 12px;
   }
   .busareas__image {
      padding-right: 10px;
      padding-left: 13px;
   }
   .busareas__text span {
      font-size: 12px;
   }
}
