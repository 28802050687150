.request__container {
   margin-top: 84px;
   margin-bottom: 80px;
   
   .sm-block({
      margin-top: 40px;
      margin-bottom: 40px;
   })
}
.request__wrapper {
   .flex();
   .lg-block({
      flex-wrap: wrap;
   });
   border-radius: 20px;
   overflow: hidden;
}
.request__holder {
   min-height: 100%;
   .text-small {
      margin-top: -2px;
      cursor: default;
      .middle-block({
         margin-top: 0;
      })
   }
}
@request_width: 48.5%;
.request__image_holder {
   width: 100% - @request_width;
   .xl-block({
      width: 45%;
   });
   .xs-block({
      display: none;
   });
}
.request__image-1 {
   //.size-cover( 'large', 'request.webp' );
   width: 100%;
   height: 100%;
}
.request_img {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}
.request__form_holder {
   width: @request_width;
   //background-color: @color-white;
   .lines-request();
   padding: 40px;
   box-sizing: border-box;
   
   .xl-block({
      width: 100% - 45%;
   });
   .lg-block({
      width: 100%;
      padding: 30px;
   });
   .middle-block({
      padding-top: 24px;
   });
   .sm-block({
      padding: 20px;
   });
   .xs-block({
      padding: 15px;
   });
   input {
      .sm-block({
         height: 47px;
      });
   }
   label:last-of-type {
      .sm-block({
         margin-bottom: 20px;
      });
   }
   .lg-block({
      .button-yellow {
         width: 100%;
         min-width: 100%;
      }
   })
}
.request__form_holder h2 {
   margin-bottom: 20px;
   .sm-block({
      margin-bottom: 16px;
   })
}
form.request__form {
   button {
      margin-top: 40px;
      .lg-block({
         margin-top: 20px;
      });
      .sm-block({
         margin-top: 30px;
      });
   }
}
