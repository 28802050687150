.catalog_main__products {

}
.product__image {
   .relation {
      padding-top: 78.1%;
      .to( 1825px, {
         padding-top: 73.7%;
      });
      .to( 1000px, {
         padding-top: 93.7%;
      });
      .to(800px, {
         padding-top: 90%;
      });
      .xs-block({
         padding-top: 79%;
      })
   }
   .relation_content {
      z-index: 50;
      .flex-center();
      border-radius: 10px;
      background-color: @color-white;
   }
   
   & img {
      max-width: 92%;
      .to( 1555px, {
         max-width: 92%;
      });
      .to( 1000px, {
         max-width: 95%;
      });
      .to( 770px, {
         max-width: 100%;
         max-height: 79%;
      });
      .sm-block({
         max-height: 82%;
      });
   }
   
   button.icon__like {
      position: absolute;
      z-index: 100;
      width: 24px;
      height: 24px;
      
      top: 10px;
      right: 10px;
      
      .to( 700px, {
         top: 7.8px;
         right: 7px;
      });
   }
}
.product__name {
   .montserrat-semibold();
   font-size: 16px;
   line-height: 120%;
   letter-spacing: .1px;
   margin-top: 8px;
   .middle-block({
      margin-top: 8px;
   });
   .sm-block({
      font-size: 14px;
   });
}
.product__data {
   margin-top: 6px;
   margin-bottom: 8px;
   .to(800px, {
      margin-top: 8px;
   });
   .xs-block({
      margin-top: 2px;
   });
   
   .block-12 {
      max-width: 135px;
      & > * {
         .montserrat-medium();
         font-size: 12px;
         line-height: 120%;
      }
      &:first-of-type {
         .to(800px, {
            margin-bottom: 3px;
         });
      }
      .to(890px, {
         max-width: unset;
         .flex();
         span {
            margin-right: 3px;
         }
      });
      .to(450px, {
         display: block;
      });
   }
}
.product__options {
   border-radius: 10px;
   overflow: hidden;
   .gr();
   grid-template-columns: repeat(3, 33.3334%);
   grid-template-rows: 40px 34px;
   border: 1px solid @color-gray-light;
   margin-bottom: 8px;
   .lg-block({
      *[class^='icon__'] {
          width: 24px;
          height: 24px;
      }
   });
   .middle-block({
      margin-bottom: 9px;
   });
   .sm-block({
      border-radius: 4px;
      grid-template-rows: 40px 40px;
   });
}
.product__options_btn {
   grid-column: span 1;
   grid-row: span 1;
   .flex-center();
   border-right: 1px solid @color-gray-light;
   border-bottom: 1px solid @color-gray-light;
   background-color: @color-white;
}
.opacity.product__options_btn {
   background-color: @color-blue-gray;
}
.product__options .product__wholesale {
   border-right: unset;
}
.product__text {
   grid-column: ~"1 / 4";
   grid-row: ~"2 / 3";
   .montserrat-medium();
   font-size: 12px;
   line-height: 120%;
   padding: 9px 0 11px 9px;
   box-sizing: border-box;
   background-color: @color-white;
   .sm-block({
      padding: 4px 4px 8px 8px;
   });
}
.product__purchase {
   .gr();
      grid-template-columns: auto 48px;
      grid-template-rows: auto auto;
      grid-row-gap: 10px;
    
      .product__amount {
         grid-column: ~"1 / 3";
         grid-row: ~"1 / 2";
      }
      .product__buy {
         grid-column: ~"1 / 2";
         grid-row: ~"2 / 3";
      }
      .product__button {
         grid-column: ~"2 / 3";
         grid-row: ~"2 / 3";
         justify-self: flex-end;
      }
   
   
   
   .md-block({
      .gr();
      grid-template-columns: auto 48px;
      grid-template-rows: 19px 36px;
      grid-column-gap: 8px;
      grid-row-gap: 8px;
      
      
      .product__amount {
         grid-column: ~"1 / 2";
         grid-row: ~"2 / 3";
      }
      .product__buy {
         grid-column: ~"1 / 3";
         grid-row: ~"1 / 2";
      }
      .product__button {
         grid-column: ~"2 / 3";
         grid-row: ~"2 / 3";
      }
      
   });
}
.product__amount {
   background-color: @color-white;
   border-radius: 10px;
   .gr();
   grid-template-columns: 16px auto 16px;
   grid-template-rows: 15px;
   
   padding: 12px 22px;
   .middle-block({
      padding: 12px 22px;
   });
   .xs-block({
      padding: 10.5px 4px;
   });
   
   > button[disabled='true'] {
      background-color: transparent;
      border: none;
   }
}
.product__weight {
   .flex();
   .justify-center();
   .items-end();
   .weight {
      .montserrat-medium();
      font-size: 14px;
      line-height: 110%;
      .to( 1000px, {
         padding-top: 8px;
      });
   }
   span {
      font-size: 14px;
      line-height: 110%;
   }
}
.product__buy {
   .flex();
   .justify-between();
   
   .sm-block({
      border-radius: 4px;
   });
}
.product__price {
   .price {
      .montserrat-semibold();
      font-size: 24px;
      line-height: 110%;
      .md-block({
         font-size: 16px;
      });
   }
   .price-kg {
      .montserrat-medium();
      font-size: 12px;
      line-height: 120%;
   }
   & i {
      display: inline;
      font-style: normal;
   }
   
}
