.about_index__container {
      margin-top: 80px;
      margin-bottom: 80px;
   .lg-block({
      margin-top: 76px;
      margin-bottom: 40px;
   });
   .sm-block({
      margin-top: 44px;
      margin-bottom: 40px;
   });
}
.about_index__content {
   //.section-text-half();
   .gr();
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 32px;
    //grid-row-gap: 40px;
   .middle-block({
      grid-column-gap: 20px;
   });
   .md-block({
      grid-template-columns: repeat(2, 1fr);
   });
   .sm-block({
       margin-top: -10px;
   })
}
.about_index__text {
   grid-row: span 1;
   grid-column: ~"4 / 13";
   margin-top: -20px;
   .flex();
   & p:first-of-type {
      margin-right: 20px;
      .middle-block({
         max-width: 240px;
      });
      .md-block({
         max-width: unset;
         margin-right: 0;
         margin-bottom: 10px;
      });
      .xs-block({
         margin-bottom: 6px;
      });
   }
   & p {
      max-width: 310px;
      line-height: 20px;
      .middle-block({
         line-height: 18px;
         max-width: unset;
      });
   }
   
   .middle-block({
      margin-top: 0;
   });
   .md-block({
      grid-column: ~"1 / 4";
      display: block;
   });
}

.about_index__facts {
   grid-row: span 1;
   grid-column: ~"1 / 13";
   
   .gr();
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: 1fr;
   grid-column-gap: 20px;
   margin-top: 40px;
   .middle-block({
      margin-top: 75px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 20px;
      //margin-top: 20px;
   });
   .md-block({
      grid-column: span 3;
      grid-gap: 10px;
      margin-top: 20px;
   });
   .xs-block({
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
   });
}
.about_index__block {
   .block-white();
   border-radius: 10px;
   grid-column: span 3;
   grid-row: span 1;
   .middle-block({
      grid-column: span 1;
      grid-row: span 1;
   });
}
.caption.text-blue.about_index__title {
   .xs-block({
      font-size: 15px;
   });
}
.about_index__number {
      margin-bottom: 8px;
   .lg-block({
       margin-bottom: 4px;
   });
}

.about_index__span {
   padding-top: 40px;
   margin-top: auto;
   .middle-block({
      padding-top: 30px;
   });
   .xs-block({
      padding-top: 74px;
   });
}
