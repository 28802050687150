.inline {
   display: inline !important;
}
.inline-block {
   display: inline-block !important;
}
.block {
   display: block !important;
}

