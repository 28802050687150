.one_item__caption {
   .montserrat-semibold();
   font-size: 24px;
   line-height: 120%;
   .xs-block({
      font-size: 18px;
   });
}
.one_item__parameter { // del

}
.one_item__parameter_title {
   .montserrat-semibold();
   font-size: 16px;
   margin-bottom: 10px;
}
.one_item__parameter_holder { // тут таблички с выбором объёма покупки
   .montserrat-medium();
   font-size: 14px;
   line-height: 130%;
   .gr();
   grid-template-columns: repeat(2, auto);
   grid-template-rows: repeat(2, 34px);
   grid-column-gap: 5px;
   grid-row-gap: 5px;
   justify-content: start;
   .lg-block({
      grid-template-columns: repeat(3, auto);
      grid-template-rows: none;
      grid-row-gap: 0;
   });
   .to( 500px, {
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(2, 34px);
      grid-column-gap: 5px;
      grid-row-gap: 5px;
   });
   
   .parameter_pack-1 {
      width: 218px;
      grid-column: ~"1 / 3";
      .lg-block({
         grid-column: ~"1 / 2";
      });
      .to( 500px, {
         grid-column: ~"1 / 3";
      });
   }
   .parameter_pack-2 {
      width: 142px;
      grid-column: ~"1 / 2";
      .lg-block({
         grid-column: ~"2 / 3";
      });
      .to( 500px, {
         grid-column: ~"1 / 2";
      });
   }
   .parameter_pack-3 {
      width: 71px;
      grid-column: ~"2 / 3";
      .lg-block({
         grid-column: ~"3 / 4";
      });
      .to( 500px, {
         grid-column: ~"2 / 3";
      });
   }
}
.one_item__parameter .product__amount {
   max-width: 143px;
}
.one_item__parameter_pack {
   .flex-center();
   cursor: pointer;
   width: max-content;
   padding: 8px 12px;
   box-sizing: border-box;
   background-color: white;
   border-radius: 8px;
   border: 1px solid transparent;
   &:first-of-type {
      grid-column: ~"1 / 2";
   }
   &:nth-of-type(2) {
      grid-column: ~"2 / 3";
   }
   &.chosen {
      border-color: @color-blue;
   }
   &:not(.chosen) {
      box-shadow: 1px 1px 2px 0 rgba(37, 37, 37, .15);
   }
}
.one__characteristic {
   width: 100%;
   .u-row-flex(10px);
   padding: 10px 0;
   > div {
      .u-col(10px);
   }
}
.one_characteristic__title {
   .montserrat-medium();
   font-size: 12px;
   line-height: 120%;
   min-width: 120px;
   width: 120px;
   //.u-size(8, 24, 10px);
   
}
.one_characteristic__text {
   .montserrat-medium();
   font-size: 14px;
   line-height: 130%;
   //.u-size(15, 24, 10px);
   width: calc(100% - 120px - 20px);
}
