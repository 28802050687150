.basket_address__contact {
   button {
      width: 200px;
      max-width: unset;
   }
}
.basket_address__obtaining {
   position: relative;
   z-index: 10;
   .flex();
   .items-start();
   .justify-start();
   border-bottom: 1px solid @opacity-blue-20;
   
   margin-bottom: 20px;
   .xs-block({
      margin-bottom: 16px;
   });
}
.address_obtaining__button {
   position: relative;
   z-index: 40;
   padding: 16px;
   border-bottom: 1px solid transparent;
   margin-bottom: -1px;
   .tr();
}
.address_obtaining__button.hidden {
   border-bottom-color: transparent;
}
.address_obtaining__button.chosen {
   border-bottom-color: @color-blue;
}
.basket_address__obtaining .hide {
   display: none;
}
.address_obtaining__point,
.address_obtaining__items label {
   .flex();
}
.address_obtaining__items .label-checkbox {
   margin-right: 12px;
}
.ico__options {
   position: relative;
   z-index: 50;
   width: 32px;
   height: 32px;
   margin-top: 4px;
   
   .middle-block({
      margin-top: 0;
   });
   .xs-block({
      width: 24px;
      height: 24px;
   });
}
.address_obtaining__point {
   position: relative;
   z-index: 50;
}
.point_options__popup {
   position: absolute;
   z-index: 1500;
   top: 40px;
   left: calc( 100% - 16px);
   transform: translateX(-50%);
   display: none;
}
.to( 1190px, {
   .point_options__popup {
      position: absolute;
      z-index: 1501;
      top: 0;
      left: calc( 100% - 36px );
      //transform: translateX(-50%);
      transform: translateX(-100%);
      display: none;
   }
   .address_obtaining {
      &:after {
         .pseudo();
         top: 20%;
         right: -15px;
         left: unset;
         //transform: translateX(-50%);
         transform: rotate(90deg);
         width: 20px;
         height: 12px;
         .options-pointer();
      }
   }
});

.address_obtaining__add {
   .flex();
   .items-center();
   .justify-center();
   width: max-content;
   padding: 15px 15px;
   box-sizing: border-box;
   border-radius: 10px;
   border: 1px solid @color-gray-light;
   white-space: nowrap;
  
   .sm-block({
      width: 100%;
   })
}
.address_obtaining__add .icon__address_add {
   .sm-block({
      width: 15px;
      height: 15px;
   });
}

.address_obtaining__address {
   width: 100%;
   padding: 12px 16px;
   box-sizing: border-box;
   background-color: @color-blue-gray;
   border-radius: 10px;
   
   .gr();
   grid-template-columns: 20px auto 24px;
   grid-column-gap: 12px;
   align-items: center;
   .sm-block({
      grid-column-gap: 10px;
   });
   input {
      grid-column: ~"1 / 2";
   }
   .obtaining_address__text {
       grid-column: ~"2 / 3";
   }
}
.basket_address__form {
   .gr();
   grid-template-columns: repeat(2, 1fr);
   grid-column-gap: 10px;
   .sm-block({
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 16px;
   });
}
.obtaining__select {
   width: 100%;
   + .select2-container {
       min-width: 100%;
   }
   + .select2-container--default .select2-selection--single {
      background-color: @color-blue-gray;
   }
   + .select2-container .select2-selection--single .select2-selection__rendered {
      color: @color-blue;
   }
}
.send_again__popup {
   position: absolute;
   z-index: 100;
   bottom: 0;
   left: 50%;
   transform: translateX(-50%) translateY(100%);
   display: none;
   .middle-block({
      bottom: -12px;
      left: 0;
      transform: translateX(0) translateY(100%);
   });
}
.order_code_again {
   width: 203px;
   .column-center();
   flex-wrap: wrap;
   white-space: normal;
}
.point_options__popup .line_gray {
   width: 100%;
   height: 4px;
   margin-top: 8px;
   .line-gray-popup();
   
   display: none;
}
.obtaining_map {
   color: @color-gray;
   margin-bottom: 10px;
}
.options_popup__text {
   .text-small
}
.address_obtaining {
   div[class^='ico__'] {
      margin-right: 4px;
   }
}
.address_popup__button {
   .flex-center();
   width: 100%;
   height: 48px;
   border-radius: 8px;
   background-color: @color-blue-gray;
   border: 1px solid @color-gray-light;
   cursor: pointer;
   
   color: @color-blue;
   .montserrat-semibold();
   font-size: 14px;
   line-height: 110%;
   
   margin-top: 20px;
   
   display: none;
}
.address_obtaining__items.godown {
   .radio {
      .icon-lk('basket', 'no-radio-back.svg');
      width: 20px;
      height: 20px;
   }
   .checkbox-hidden:checked + .radio {
      .icon-lk('basket', 'yes-radio.svg');
   }
   .ico__options {
      .icon-lk( 'basket', 'options-back.svg');
   }
}
.xs-block({
   .point_options__popup {
      position: fixed;
      z-index: 1600;
      top: calc(100vh - 174px);
      right: 0;
      left: 0;
      height: 174px;
      background-color: white;
      transform: translateY(100%);
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      font-size: 14px;
      
      .tr();
   }
   .point_options__popup.popup {
      transform: translateY(0);
      z-index: 99999;
      .tr();
   }
   .point_options__popup .line_gray {
      display: block;
   }
   .options_popup {
      padding: 27px 15px 15px;
      
      &:after {
         display: none;
      }
   }
   .address_obtaining {
      box-shadow: unset;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      
      .obtaining_map {
         color: @color-blue;
         margin-bottom: 16px;
      }
   }
   .options_popup__text {
      font-size: 14px;
   }
   .address_obtaining {
      div[class^='ico__'] {
         margin-right: 8px;
      }
   }
   .address_popup__button {
      display: block;
   }

   
});

.watch_place__dostavka {
   flex-grow: 999;
   .column();
   .justify-between();
}

.add_place_form__holder,
.add_place_map__holder {
   width: 50%;
   height: 445px;
   .column();
   .lg-block({
      height: 359px;
      width: 100%;
   });
}
.add_place_map__holder {
   & > * {
      min-height: 100%;
   }
}
.add_place_form__holder {
   padding: 20px;
   box-sizing: border-box;
   .xs-block({
      padding: 20px 15px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
   });
}
.obtaining__dostavka {
   flex-grow: 999;
   .label-input,
   input[type='text'] {
      max-height: 60px;
      height: 60px;
      .lg-block({
         height: 48px;
         max-height: 48px;
      });
   }
   .label-input .sup {
      .lg-block({
         font-size: 12px;
      });
   }
   .row_flex-10-m {
      .lg-block({
         margin-left: -2.5px;
         margin-right: -2.5px;
         
         & > * {
            margin-right: 2.5px;
            margin-left: 2.5px;
         }
      });
   }
}
.add_place__title {
   margin-bottom: 20px;
   .sm-block({
      margin-bottom: 10px;
   });
   .xs-block({
      margin-bottom: 0;
   });
}
.dostavka__form {
   min-height: 100%;
   .flex-wrap();
   flex-direction: column;
   .justify-start();
   
   input {
      width: 100%;
   }
}
.dostavka_st {
   width: 100%;
   margin-bottom: 10px;
   .lg-block({
      margin-bottom: 5px;
   })
}
.dostavka_kv {
}
.dostavka_pd {
}
.dostavka_et {
}
.dostavka_ch,
.dostavka_of {
   width: 100%;
   height: 20px;
}
.dostavka_of {
   margin-bottom: 20px;
   .lg-block({
      margin-bottom: 10px;
   });
}
.dostavka_info {
   height: 48px;
}
.dostavka_btn {
   margin-top: auto;
   max-height: 48px;
}


.md-block({
   .modal[id^='openModal_add_'],
   .modal[id^='openModal_edit_'] {
      top: 15vh;
      .add_place_form__holder {
         padding-bottom: 25px;
      }
      .add_place_form__holder {
         height: auto;
      }
      .dostavka_btn {
         margin-top: 20px;
      }
   }
   .modal[id^='openModal_add_'] .modal__content,
   .modal[id^='openModal_edit_'] .modal__content {
      flex-direction: column;
   }
});
.add_place__title_xs {
   .montserrat-semibold();
   font-size: 18px;
   display: none;
}

.xs-block({
   .modal[id^='openModal_add_'],
   .modal[id^='openModal_edit_'] {
      top: 0;
      height: 100vh;

      .add_place_map__holder {
         height: calc(100% - 60px);
      }
      .add_place_form__holder {
          padding-bottom: 20px;
      }
      .modal__content {
         position: relative;
         width: 100%;
         height: 100vh;
         border-radius: 0;
      }
      .modal__close {
         position: absolute;
         z-index: 3500;
         right: 15px;
         top: 20px;
         width: 20px;
         height: 20px;
         .icon__close-w {
            .icon-lk( 'basket', 'b-close.svg');
            width: 20px;
            height: 20px;
            right: -15px;
            top: -20px;
         }
      }
      
      .add_place__title {
         display: none;
      }
      .add_place__title_xs {
         display: block;
         text-align: center;
         padding: 19px 0;
      }
      
      .add_place_form__holder {
         position: absolute;
         z-index: 3000;
         left: 0;
         bottom: 0;
         
         background-color: white;
      }

   }
});


input.obtaining_point_checkbox:checked + .point_options__popup {
   display: block;
}
input.obtaining_point_checkbox:not(:checked) + .point_options__popup {
   display: none;
}
