.logo__holder {
   .items-center();
}
header {
   .logo__image {
      width: 110px;
      height: 30px;
      .xs-block({
         width: 64px;
         height: 16px;
      })
   }
   .logo__text {
      width: 148px;
      height: 25px;
      .xs-block({
         display: none;
      })
   }
}
footer {
   .logo__image {
      width: 125px;
      height: 32px
   }
   .logo__text {
      width: 241px;
      height: 26px;
      .xs-block({
         width: 120px;
         height: 44px;
      })
   }
}
