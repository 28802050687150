.delivery_index__container {
      margin-top: 80px;
      margin-bottom: 80px;
   .middle-block({
      margin-top: 80px;
      margin-bottom: 40px;
   });
   .sm-block({
      margin-top: 43px;
      margin-bottom: 40px;
   });
   
   .relation {
      padding-top: 48.1%;
   }
   .text-18-title {
      .middle-block({
         font-size: 14px;
      });
   }
}
.delivery_index__wrapper {
   margin-top: -20px;
   display: grid;
   grid-template-columns: repeat( 12, 1fr);
   grid-template-rows: repeat( 3, auto);
   grid-column-gap: 32px;
   grid-row-gap: 40px;
   .middle-block({
      margin-top: -10px;
      grid-row-gap: 20px;
   });
   .md-block({
      margin-top: 10px;
      grid-row-gap: 20px;
   });
}

.delivery_index__text {
   grid-column: ~"4 / 13";
   grid-row: ~"1 / 2";
   .flex();
   
   p {
      line-height: 20px;
      margin-right: 10px;
      max-width: 340px;
      .middle-block({
         font-size: 14px;
         line-height: 130%;
      });
   }
   p:first-of-type {
      margin-right: 10px;
      .middle-block({
         max-width: 260px;
         font-size: 14px;
         line-height: 130%;
         margin-right: 7px;
      });
   }
   p:nth-of-type(2) {
      .middle-block({
         max-width: 290px;
         font-size: 14px;
         line-height: 130%;
      });
   }
}
.delivery_index__facts {
   grid-column: ~"4 / 13";
   grid-row: ~"2 / 3";
   
   .flex();
   .justify-between();
   padding-top: 36px;
   
   p {
      position: relative;
      width: 100%;
      //box-sizing: border-box;
      span {
         display: inline-block;
      }
   }
   p:nth-of-type(1) {
      padding-right: 37px;
   }
   p:nth-of-type(2) {
      padding-right: 25px;
   }
   p:nth-of-type(1) span {
      max-width: 187px;
   }
   p:nth-of-type(2) span {
      max-width: 182px;
   }
   p:nth-of-type(3) span {
      max-width: 182px;
   }
}
.delivery_index__image {
   grid-column: ~"4 / 13";
   grid-row: ~"3 / 4";
}

.delivery_index__facts p:after {
   .pseudo();
   z-index: 55;
   .dot-dot();
   
   width: 16px;
   height: 16px;
   top: -36px;
}
.delivery_index__facts p:before {
   .pseudo();
   z-index: 50;
   top: -29px;
   left: 4px;
   height: 0;
   border: 1px solid @opacity-blue-20;
   width: 100%;
}
.delivery_index__facts p:last-of-type:before {
   width: 82%;
}
.delivery_index__img-1 .di-image {
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   width: 100%;
   height: 100%;
}
.delivery_index__image {
   background-color: white;
   border-radius: 20px;
   overflow: hidden;
   .middle-block({
      height: 300px;
      border-radius: 10px;
   });
}

@media screen and (max-width: 760px) {
   
   .delivery_index__wrapper {
      margin-top: 0;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: repeat(3, auto);
      grid-column-gap: 32px;
   }
   
   .delivery_index__text {
      grid-column: span 1;
      grid-row: ~"1 / 2";
      
      .flex();
      p {
         line-height: 20px;
         margin-right: 10px;
      }
   }
   .delivery_index__facts {
      grid-column: span 1;
      grid-row: ~"2 / 3";
      
      .flex();
      .justify-between();
      padding-top: 36px;
      
      p {
         position: relative;
         padding-right: 20px;
      }
   }
   .delivery_index__image {
      grid-column: span 1;
      grid-row: ~"3 / 4";
   }
}
@media screen and (max-width: @break_sm) {
   .delivery_index__container .section__caption {
      margin-bottom: 10px;
   }
   .delivery_index__wrapper {
      grid-row-gap: 20px;
   }
   .delivery_index__facts p:nth-of-type(1) span,
   .delivery_index__facts p:nth-of-type(2) span,
   .delivery_index__facts p:nth-of-type(3) span {
      max-width: unset;
   }
   .delivery_index__image {
      height: 200px;
   }
}
@media screen and (max-width: @break_xs) {
   .delivery_index__text {
      .column();
   }
   .delivery_index__text p {
      margin-right: 0;
   }
   .delivery_index__text p:first-of-type {
      margin-bottom: 10px;
   }
   
   .delivery_index__facts {
      .column();
      padding-top: 0;
      padding-left: 26px;
   }
   .delivery_index__facts p {
          line-height: 101%;
   }
   .delivery_index__facts p:not(:last-of-type) {
      padding-bottom: 23px;
   }
   
   .delivery_index__facts p:after {
      width: 16px;
      height: 16px;
      left: -26px;
      top: 0;
      transform: translateY(-20%);
   }
   .delivery_index__facts p:before {
      .pseudo();
      z-index: 50;
      left: -18.5px;
      top: 4px;
      width: 0;
      border: 1px solid @opacity-blue-20;
      height: 100%;
   }
   .delivery_index__facts p:last-of-type:before {
      display: none;
   }
  
}
