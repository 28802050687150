.icon(@folder, @name) {
   background-image: url("../images/svg/@{folder}/@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}
.icon-i-i(@folder, @name) {
   background-image: url("../images/svg/icons/@{folder}/@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}
.icon-i(@name) {
   background-image: url("../images/svg/icons/@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}
.icon-size() {
   width: 24px;
   height: 24px;
   cursor: pointer;
}

*[class^='icon__'] {
   .icon-size();
   .tr();
}

/*   Social   */
.icon__telegram {
      .icon('social', 't');
}
.icon__whatsapp {
   .icon('social', 'w');
}
.icon__vkontakte {
   .icon('social', 'vk');
}
header {
   .icon__telegram {
      .icon('social', 'b-t');
   }
   .icon__whatsapp {
      .icon('social', 'b-w');
   }
}
footer {
   .icon__telegram {
      .icon('social', 't');
   }
   .icon__whatsapp {
      .icon('social', 'w');
   }
}


/*   Header   */
header {
   .icon__basket {
      .icon-i-i('menu', 'basket');
   }
   .icon__favorites {
      .icon-i-i('menu', 'favorites');
   }
    .icon__user {
       .icon-i-i('menu', 'b-user');
    }
   .icon__search {
      .icon-i-i('menu', 'search');
   }
   .icon__dots {
      .icon-i-i('menu', 'dots');
   }
   .icon__burger {
      .icon-i-i('menu', 'burger'); // del
      width: 20px;
      height: 20px;
   }
   .icon__close-b {
      .icon-i-i('menu', 'b-close'); // del
   }
}
.header__holder_mobile {
   .icon__basket {
      .icon-i-i('menu', 'b-basket');
   }
   .icon__favorites {
      .icon-i-i('menu', 'b-favorites');
   }
    .icon__user {
       .icon-i-i('menu', 'b-user');
    }
   .icon__search {
      .icon-i-i('menu', 'b-search');
   }
}
.icon__search-g {
   .icon-i-i('menu', 'search');
}
.line() {
   .icon-i-i('menu', 'line');
   background-size: contain;
   background-position: center;
}
.line2() {
   .icon-i-i('menu', 'line2');
   background-size: contain;
   background-position: center;
}
@media screen and (max-width: @break_lg) {
   .icon-size() {
      width: 20px;
      height: 20px;
   }
   
   *[class^='icon__'] {
      .icon-size();
   }
    header {
   .icon__basket {
      .icon-i-i('menu', 'b-basket');
   }
   .icon__favorites {
      .icon-i-i('menu', 'b-favorites');
   }
   .icon__dots {
      .icon-i-i('menu', 'b-dots');
   }
   .icon__close-b {
      .icon-i-i('menu', 'b-close'); // del
   }
    footer *[class^='icon__'] {
       width: 24px;
       height: 24px;
   }
}
}
.icon__close {
   .icon-i-i('menu', 'b-close');
}
.icon__close-w {
   .icon-i-i('menu', 'close');
}
.icon__filter {
   .icon-i-i('product', 'filters');
}

/*   checkbox  /  ratio  */
.checkbox {
   .icon-i-i('control', 'no-checkbox');
   width: 20px;
   height: 20px;
}
.checkbox-hidden:checked +.checkbox {
    .icon-i-i('control', 'yes-checkbox')
}
.radio {
   .icon-i-i('control', 'no-radio');
   width: 20px;
   height: 20px;
}
.checkbox-hidden:checked +.radio {
    .icon-i-i('control', 'yes-radio')
}
.icon__angle {
   .icon-i( 'up' );
}
/*   catalog food   */
*[class^='icofish__'] {
   width: 26px;
   height: 26px;
   .tr();
}
*[class^='icofood__'] {
   width: 26px;
   height: 26px;
   .tr();
}
.icofish( @name ) {
   background-image: url("../images/svg/food/fish/@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}
.icofood( @name ) {
   background-image: url("../images/svg/food/@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}
.icofish__sveshemor {
   .icofish( 'svezhemorozhennaya_ryba');
}
.icofish__morejap {
   .icofish( 'moreprodukty_Japan');
}
.icofish__moreprodukty {
   .icofish( 'moreprodukty');
}
.icofish__fileryby {
   .icofish( 'file_i_steyki_ryby');
}
.icofish__fileryby2 {
   .icofish( 'file_i_steyki_ryby');
}

.icofood__polufabrikaty {
   .icofood( '1-polufabrikaty')
}
.icofood__konservy {
   .icofood( '2-konservy')
}
.icofood__molochnaya_produktsiya {
   .icofood( '3-molochnaya_produktsiya')
}
.icofood__gotovaya {
   .icofood( '4-gotovaya')
}
.icofood__izdelie_iz_testa {
   .icofood( '5-izdelie_iz_testa')
}
.icofood__bakaleya {
   .icofood( '6-bakaleya')
}
.icofood__myaso_i_ptitsa {
   .icofood( '7-myaso_i_ptitsa')
}
.icofood__okhlazhdennaya_ryba {
   .icofood( '8-okhlazhdennaya_ryba')
}
.icofood__maslo_rastitelnyoe {
   .icofood( '9-maslo_rastitelnyoe')
}
.icofood__zamorozhennyye_ovoshchi_yagody {
   .icofood( '10-zamorozhennyye_ovoshchi_yagody')
}
.icofood__krasnaya_ikra {
   .icofood( '11-krasnaya_ikra')
}
.icofood__sousy {
   .icofood( '12-sousy')
}
.icofood__packaging{
   .icofood( 'fish_in_packaging')
}

.dot-dot() {
   background-image: url("../images/svg/icons/dot-dot.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}

/*   стрели крутилка   */

.arrow-next {
   .icon-i('arrow-next');
   width: 28px;
   height: 28px;
}
.arrow-prev {
   .icon-i('arrow-prev');
   width: 28px;
   height: 28px;
}
.arrow-next-active {
   .icon-i('arrow-next-active');
   width: 28px;
   height: 28px;
}
.arrow-prev-active {
   .icon-i('arrow-prev-active');
   width: 28px;
   height: 28px;
}
/*  ГЛАВНАЯ блок с рукой */
.ico__checkbox {
   .icon-i-i( 'control', 'yes-checkbox-white');
   width: 20px;
   height: 20px;
}

.play-icon() {
   .icon-i-i( 'control', 'arrow');
   width: 28px;
   height: 28px;
}
.play-icon-small() {
   .icon-i-i( 'control', 'arrow');
   width: 16px;
   height: 16px;
}

/*   ГЛАВНАЯ звезды рейтинг   */
.rating-stars( @name ) {
   background-image: url("../images/svg/icons/review/stars-@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}
.rating-stars-4() {
   .rating-stars( '4');
}
.rating-stars-5() {
   .rating-stars( '5');
}
.user-photo() {
   background-image: url("../images/svg/icons/review/user.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}

/*  CATALOG один продукт  */
.product-one( @name ) {
   background-image: url("../images/svg/icons/product/@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}
.icon__box {
   .product-one('b-box')
}
.icon__scale {
   .product-one('b-scale')
}
.icon__wholesale {
   .product-one('b-wholesale')
}
.opacity {
   .icon__box {
      .product-one('box')
   }
   .icon__scale {
      .product-one('scale')
   }
   .icon__wholesale {
      .product-one('wholesale')
   }
}
.icon__like {
   .product-one('like')
}
.icon__like.like {
   .product-one('b-like')
}
.ico-amount(@name) {
   background-image: url("../images/svg/icons/product/@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}
.ico__minus {
   .ico-amount('b-minus')
}
.ico__plus {
   .ico-amount('b-plus')
}
.ico__minus[disabled='true'] {
   .ico-amount('minus')
}
.ico__plus[disabled='true'] {
   .ico-amount('plus')
}
/*  pagination arrow  */
.pagination_arrow[ disabled='false' ] {
   .ico__arrow_prev {
      .icon-i('b-arr-left');
   }
   .ico__arrow_next {
      .icon-i('b-arr-right');
   }
}
.pagination_arrow[ disabled='true' ] {
   .ico__arrow_prev {
      .icon-i('arr-left');
   }
   .ico__arrow_next {
      .icon-i('arr-right');
   }
}

.ico__del {
   .icon-i('close');
   width: 20px;
   height: 20px;
   .tr();
}


.ico__money {
   .icon-i-i('delivery', 'money')
}
.ico__day {
   .icon-i-i('delivery', 'day')
}
.ico__car {
   .icon-i-i('delivery', 'car')
}
.ico__coins {
   .icon-i-i('delivery', 'coins')
}
.ico__box {
   .icon-i-i('delivery', 'box')
}
.dc-dot {
   background-image: url("../images/svg/icons/delivery/dot.svg");
   background-repeat: no-repeat;
   background-size: 6px;
   background-position: left;
   flex-shrink: 0;
   width: 7px;
   height: 7px;
   padding-top: 9px;
}
.icon__yes {
   .icon-i('yes');
   width: 20px;
   height: 20px;
}
.icon__no {
   .icon-i('no');
   width: 20px;
   height: 20px;
}
.icon__back {
   .icon-i('back');
}
/*  basket  */
.icon-lk(@folder, @name) {
   background-image: url("../images/svg/icons/lk/@{folder}/@{name}");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}
.thanks {
   .icon-lk( 'basket', 'thanks.webp');
   width: 110px;
   height: 110px;
}
.ico__box {
   .icon-lk( 'basket', 'box.svg');
   width: 32px;
   height: 32px;
}
.icon__bin {
   .icon-lk( 'basket', 'bin.svg')
}
.ico__bell {
   .icon-lk( 'basket', 'bell.svg');
   width: 32px;
   height: 32px;
}
.ico__options {
   .icon-lk( 'basket', 'options.svg');
}
.options-pointer() {
   .icon-lk( 'basket', 'pointer.svg');
}
.icon__address_add {
   .ico-amount('b-plus')
}
.ico__navigation {
   .icon-lk( 'basket', 'navigation.svg');
   width: 16px;
   height: 16px;
   .xs-block({
      .icon-lk( 'basket', 'b-navigation.svg');
   })
}
.ico__bin-r {
   .icon-lk( 'basket', 'r-bin.svg');
   width: 16px;
   height: 16px;
}
.ico__del {
   .icon-lk( 'basket', 'del.svg');
   width: 20px;
   height: 20px;
}
.ico__edit {
   .icon-lk( 'basket', 'edit.svg');
   width: 24px;
   height: 24px;
}
.ico__again {
   .icon-lk( 'basket', 'again.svg');
   width: 20px;
   height: 20px;
}
.line-gray-popup() {
   .icon-lk( 'basket', 'line.svg');
}
.radio {
   .icon-lk( 'basket', 'no-radio.svg');
   width: 20px;
   height: 20px;
}
.checkbox-hidden:checked +.radio {
    .icon-lk( 'basket', 'yes-radio.svg');
}

.ico__time {
   .icon-lk( 'basket', 'time.svg');
   width: 48px;
   height: 48px;
}
.ico__point {
   .icon-lk( 'basket', 'point.svg');
   width: 48px;
   height: 48px;
}
.ico__question-circle {
   .icon-lk( 'basket', 'question-circle.svg');
   width: 24px;
   height: 24px;
}

/*  вход login  */
.icon__login_close {
   .icon-i-i( 'login', 'close');
   .sm-block({
      .icon-i-i( 'login', 'b-close');
   })
}
.ico__google {
   .icon-i-i( 'login', 'google');
   width: 20px;
   height: 20px;
}
.ico__eye.no {
   .icon-i-i( 'login', 'eye-hide');
   width: 24px;
   height: 24px;
}
.ico__eye.show {
   .icon-i-i( 'login', 'eye-show');
   width: 24px;
   height: 24px;
}
.welcome {
   background-image: url("../images/svg/icons/login/welcome.webp");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   width: 100%;
   height: 91px;
   .xs-block({
      height: 49px;
   });
}
.register_social( @name ) {
   background-image: url("../images/svg/icons/login/@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
   width: 40px;
   height: 40px;
}
.register_social__vk {
   .register_social( 'vk');
}
.register_social__instagram {
   .register_social( 'instagram');
}
.register_social__facebook {
   .register_social( 'facebook');
}
.register_social__odnoklassniki {
   .register_social( 'odnoklassniki');
}

/*  personal_account  */
.icon-pc( @name) {
   background-image: url("../images/svg/icons/lk/personal_account/@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   flex-shrink: 0;
}
.ico__confirmed {
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   width: 24px;
   height: 24px;
}
[data-confirmed='false'] {
   .ico__confirmed {
      background-image: url("../images/svg/icons/lk/personal_account/no.svg");
   }
}
[data-confirmed='true'] {
   .ico__confirmed {
      background-image: url("../images/svg/icons/lk/personal_account/yes.svg");
   }
}
.ico__eye {
   .icon-pc( 'eye');
   width: 24px;
   height: 24px;
}

.radio_checked {
   .icon-pc( 'b-check-circle');
   width: 24px;
   height: 24px;
}
.radio_false {
   .icon-pc( 'check-circle');
   width: 24px;
   height: 24px;
}
.star {
   .icon-pc( 'star');
   width: 16px;
   height: 16px;
}
.star.fill {
   .icon-pc( 'star-fill');
   width: 16px;
   height: 16px;
}
/* личный кабинет - доставка */
.lk-delivery() {
   width: 48px;
   height: 48px;
   .md-block({
      width: 38px;
      height: 38px;
   });
   .md-block({
      width: 38px;
      height: 38px;
   });
}
.ico__pin {
   .icon-pc( 'pin');
   .lk-delivery();
}
.ico__day {
   .icon-pc( 'day');
   .lk-delivery();
}
.ico__time {
   .icon-pc( 'time');
   .lk-delivery();
}
.ico__person {
   .icon-pc( 'men');
   .lk-delivery();
}
.ico__phone {
   .icon-pc( 'tel');
   .lk-delivery();
}
.ico__car {
   .icon-pc( 'car');
   .lk-delivery();
}
.ico__wallet {
   .icon-pc( 'money');
   .lk-delivery();
}
.ico__cheque {
   .icon-pc( 'cheque');
   .lk-delivery();
}
.ico__close {
   .icon-pc( 'close');
    width: 20px;
   height: 20px;
}

.ico__load {
   .icon-i( 'load' );
    width: 20px;
   height: 20px;
}

.price_item__purchase .ico__minus[disabled='true'] {
   .icon-i('minus')
}
//
//.search {
//    background-image: url(../images/svg/icons/menu/search.svg);
//    background-repeat: no-repeat;
//    background-size: contain;
//    background-position: center;
//    width: 20px;
//    height: 20px;
//    cursor: pointer;
//}
