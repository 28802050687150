.header__menu_mobile {
   position: absolute;
   top: 74px;
   
   .xl-block({
      top: 60px
   });
   
   z-index: 2500;
   width: 100%;
   
   background-color: @color-back-main;
   
   padding-top: 40px;
   padding-bottom: 42px;
   
   display: none;
   
   .lg-block({
      padding-top: 38px;
   });
   .sm-block({
      top: 52px;
      padding-top: 30px;
   });
}
.header__menu_mobile .column.start {
   width: 100%;
}

.header__menu_mobile .menu__holder {
   padding-bottom: 60px;
}
.header__menu_mobile .menu__item {
   padding-bottom: 20px;
}
.header__menu_mobile .header__contact {
   padding-bottom: 20px;
   margin-top: auto;
}
.header__menu_mobile .header__social [class^='icon__'] {
   width: 40px;
   height: 40px;
}
.header__holder_mobile {
   display: none;
   .lg-block({
      display: flex;
   })
}
.header__holder_mobile .header__m_search {
   display: none;
}
.header__holder_mobile .header__m_icons {
   .flex();
   .items-center();
   margin-right: 20px;
   .amount {
      right: 0;
   };
   .xs-block({
      margin-left: -7px;
      margin-right: 15px;
      
      & > * {
         padding-right: 7px;
         padding-left: 7px;
      }
   });
}

@media screen and (max-width: @break_lg) {
   .header__aside_icons {
      width: 100%;
      flex-direction: column;
      .items-start();
      padding-bottom: 35px;
   }
    .header__to-catalog > span,
    .header__section > span,
    .header__section .login {
       display: block;
      color: @color-blue;
   }
   .header__to-catalog,
   .header__section {
      width: 100%;
      padding-top: 18px;
      padding-bottom: 18px;
      border-bottom: 1px solid rgba(32, 54, 134, 0.2);
   }
   .header__to-catalog {
      border-top: 1px solid rgba(32, 54, 134, 0.2);
   }
   .header__search {
      width: 100%;
      max-width: unset;
      padding-bottom: 40px;
   }
   .header__section:last-of-type {
      order: -1;
   }
}
@media screen and (max-width: @break_sm){
    .header__to-catalog > span,
    .header__section > span,
    .header__section .login {
   
   }
   .header__menu_mobile .menu__item {
      padding-bottom: 12px;
   }
   .header__aside_icons {
      padding-bottom: 20px;
   }
   .header__search {
      max-width: unset;
      padding-bottom: 25px;
   }
}

