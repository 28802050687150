.footer__holder {
   overflow: hidden;
   background-color: @color-blue;
   padding-top: 30px;
   padding-bottom: 60px;
   
   margin-top: 80px;
   .lg-block({
      padding-top: 10px;
      padding-bottom: 40px;
   });
   .sm-block({
      margin-top: 60px;
      padding-top: 10px;
      padding-bottom: 30px;
   });
   .xs-block({
      margin-top: 40px;
   });
}
.footer__holder .wrapper {
   .justify-between();
   flex-wrap: wrap;
   > div {
      padding-top: 30px;
      flex-grow: 1;
      .lg-block({
         padding-top: 30px;
      });
      .sm-block({
         padding-top: 20px;
      });
   }
   
   .xl-block({
      .column();
   });
   
   .row_flex-16 {
      margin-right: 0;
      margin-left: 0;
   }
}

.footer__information_holder {
   flex-basis: 43%;
   .footer__logo {
      margin-bottom: 32px;
      .middle-block({
         margin-bottom: 20px;
      });
      .sm-block({
         .items-start();
         margin-bottom: 20px;
      })
   }
}
.footer__information {
   
   > div {
      
      .middle-block({
         min-width: 209px;
      });
      margin-bottom: 32px;
      .sm-block({
         flex-grow: unset;
         white-space: unset;
         min-width: 70%;
         width: 100%;
         margin-bottom: 10px;
         padding-right: 0;
         padding-left: 0;
      });
      .xs-block({
         min-width: 65%;
      });
      .xxs-block({
         min-width: 90%;
      });
   }
   > div:last-of-type {
      margin-bottom: 0;
   }
   .contact__number {
      margin-bottom: 32px;
      font-size: 24px;
      color: @color-white
   }
   
   .xxl-block({
      .flex-wrap();
      margin-right: -10px;
      margin-left: -10px;
      > div {
         margin-bottom: 20px;
         flex-basis: 30%;
         min-width: 215px;
         width: 33%;
         flex-grow: 1;
         padding-right: 10px;
         padding-left: 10px;
         font-size: 14px;
      }
      .contact__number {
         min-width: 217px;
         margin-bottom: 10px;
         font-size: 18px;
      }
   });
   .sm-block({
      margin-bottom: 5px;
   });
   .xs-block({
      margin-right: 0;
      margin-left: 0;
   })
}
.footer__information_section {
   .montserrat-regular();
   color: rgba(255, 255, 255, 0.8);
   font-size: 16px;
   line-height: 145%;
}
.footer__links_holder {
   .flex();
   //min-width: 50%;
   height: 100%;
   flex-basis: 50%;
   min-width: 669px;
   
   .lg-block({
      min-width: unset;
   });
   .middle-block({
      //.justify-between()
   });
   .sm-block({
      .justify-start();
   });
}
.footer__links_section:first-of-type {
   width: 193px;
   .sm-block({
      width: unset;
   })
}
.footer__links_caption {
   font-size: 16px;
   line-height: 145%;
   color: @color-blue-gray;
   margin-bottom: 8px; // 24 - 16
   opacity: .7;
   .xl-block({
      margin-bottom: 0; // 16 - 10
   });
}
.footer__links {
   .column();
   flex-wrap: wrap;
   max-height: 262px; // влияет на длинну списка
   .sm-block({
      max-height: unset;
      //max-width: 180px;
   })
}
.footer__links .row_flex-32{
   .sm-block({
      margin-left: -15px;
      margin-right: -15px;
      > div {
         padding-left: 15px;
         padding-right: 15px;
      }
   })
}

.footer__link {
   display: inline-block;
   min-width: 160px;
   max-width: 216px;
   //white-space: nowrap;
   margin-top: 18px;
   font-size: 16px;
   line-height: 22px;
   color: @color-white;
   
   .xl-block({
      margin-top: 10px; // 16 - 10
      font-size: 14px;
   });
   
   .middle-block({
      margin-top: 7px;
      max-width: 173px;
      min-width: unset;
   });
   
   .sm-block({
      max-width: 195px;
      min-width: 155px;
   });
   .xxs-block({
       max-width: 145px;
      min-width: 110px;
   });
}

.footer_links-1 {
   .sm-block({
      order: 2;
      min-width: unset;
   });
   .footer__links {
       .xs-block({
         min-width: 130px;
      });
      .xxs-block({
         min-width: 100px;
      })
   }
   .footer__link {
      .sm-block({
         min-width: unset;
      });
   }
}
.footer_links-2 {
   .sm-block({
      order: 1;
      margin-right: 5px;
   });
}

.footer__links_holder.row_flex-32 {
   .xxl-block({
      margin-left: -20px;
      margin-right: -20px;
      
      & > * {
         padding-right: 20px;
         padding-left: 20px;
      }
   });
   .sm-block({
      margin-left: -5px;
      margin-right: -5px;
      
      & > * {
         padding-right: 5px;
         padding-left: 5px;
      }
   })
}
