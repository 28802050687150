/* ГЛАВНАЯ index "обложка" */
.cover__container {
   margin-top: 10px;
   .lg-block({
      margin-top: 20px;
   });
   
   div[class^='cover_holder-'] {
      .column();
      width: 100%;
      height: 100%;
   }
   .cover_img {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
   }
}
.cover__container .wrapper {
   display: grid;
   grid-gap: 10px;
   grid-template-columns: repeat(3, 1fr);
   grid-template-rows: repeat(2, 1fr);
   grid-auto-rows: minmax(290px, auto);
   .xl-block({
      grid-template-columns: repeat(2, 1fr);
   });
   .md-block({
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
   });
   .sm-block({
      grid-template-rows: repeat(3, auto);
      grid-gap: 6px;
   });
}
.cover__holder {
   position: relative;
   .column();
   grid-row: ~"1 / 3";
   align-self: stretch;
   background-color: @color-white;
   border-radius: 20px;
   padding: 20px;
   box-sizing: border-box;
   
   min-height: 220px;
   .from(1220px, {
      height: auto;
   });
   .middle-block({
      border-radius: 10px;
      
   });
   .md-block({
      min-height: 206px;
       .cover_img {
         max-height: 65%;
      }
   });
   .sm-block({
      padding: 15px 15px 18px;
   });
   
   .cover_img {
      display: block;
      position: absolute;
      z-index: 50;
      width: 80%;
      height: 80%;
      right: 20px;
   }
   a{
      position: relative;
      z-index: 60;
   }
}

.cover__holder-1 {
   .cover_img {
      max-width: 32%;
      background-size: contain;
      bottom: 20px;
   }
   .xl-block({
      min-height: 402px;
      grid-row: ~"1 / 3";
      grid-column: ~"1 / 2";
      
      .cover_img {
         max-width: 32%;
         background-size: contain;
         bottom: 20px;
      }
   });
   .md-block({
      grid-column: ~"1 / 2";
      grid-row: ~"1 / 2";
      
      .cover_img {
         max-height: 65%;
         bottom: 0;
      }
   });
}
.cover__holder-2 {
   padding-bottom: 24px;
   .xl-block({
      grid-column: ~"2 / 3";
      grid-row: ~"1 / 2";
   
      .cover_img {
         max-width: 32%;
         background-size: contain;
      }
   });
   .middle-block({
      .cover_img {
         max-width: 43%;
      }
   });
   .md-block({
      .cover_img {
         max-width: 55%;
      }
   });
   .sm-block({
      .cover_img {
         max-width: 32%;
      }
   });
   .md-block({
      grid-column: ~"1 / 2";
      grid-row: ~"2 / 3";
   });
}
.cover__holder-3 {
   .xl-block({
      min-height: 196px;
      grid-column: ~"2 / 3";
      grid-row: ~"2 / 3";
      
      .cover_img {
         max-height: 38%;
         background-size: contain;
         bottom: 20px;
      }
   });
   .middle-block({
      .cover_img {
         max-width: 58%;
         height: 80%;
         max-height: unset;
         right: 5px;
      }
   });
   .sm-block({
      .cover_img {
         max-width: 38%;
      }
   });
   .md-block({
      grid-column: ~"1 / 2";
      grid-row: ~"3 / 4";
   });
}
.cover__caption {
   position: relative;
   z-index: 60;
   color: @color-blue;
   margin-bottom: 6px;
   .middle-block({
      line-height: 105%;
   });
   .xs-block({
      font-size: 19px;
      line-height: 114%;
      letter-spacing: -0.1px;
   });
}
.cover__text {
   position: relative;
   z-index: 60;
   .montserrat-medium();
   font-size: 14px;
   line-height: 130%;
   color: @color-blue;
   max-width: 61%;
   .xl-block({
      max-width: 65%;
   });
   .middle-block({
      max-width: 70%;
   });
   .xs-block({
      max-width: 79%;
   });
}
.cover__holder .btn {
   padding-top: 10px;
}
.badge_yellow {
   position: relative;
   z-index: 60;
   .flex-center();
   background-color: @color-yellow-2;
   width: 126px;
   padding: 5px 12px;
   border-radius: 12px;
   box-sizing: border-box;
   
   
   span {
      display: inline-block;
      .montserrat-medium();
      font-size: 14px;
      line-height: 130%;
      color: @color-blue;
   }
}

.cover__holder-1 {
   .cover__text {
      margin-bottom: 12px;
   }
}
.cover__holder-1 .cover_img {
   background-size: 107px auto;
   
   bottom: 0;
   .md-block({
      right: 15px;
   });
   .xs-block({
       max-height: 55%;
   });
}
.cover__holder-2 .cover_img {
   max-width: 49%;
   background-size: contain;
   bottom: 13px;
   .md-block({
      max-height: unset;
      max-width: 55%;
      right: 12px;
      bottom: 10px;
   });
   .xxs-block({
       max-width: 42.7%;
   });
}
.cover__holder-3 .cover_img {
   bottom: 23px;
   width: 75%;
   
   .md-block({
      right: 7px;
      bottom: 16px;
   });
   .xs-block({
      max-width: 70.8%;
      bottom: 18.8px;
   })
}
