.offer__container {
   margin-top: 80px;
   margin-bottom: 80px;
   padding-top: 60px;
   padding-bottom: 115px;
   .lg-block({
      padding-top: 40px;
      padding-bottom: 45px;
   });
   .sm-block({
      padding-top: 30px;
      padding-bottom: 35px;
      margin-top: 40px;
      margin-bottom: 40px;
   });
   .xs-block({
      padding-top: 28px;
   });
   .image-offer();
   
   h2.caption {
      margin-bottom: 40px;
      .lg-block({
         margin-bottom: 26px;
      });
      .middle-block({
         width: 90%;
      });
      .sm-block({
         margin-bottom: 30px;
      });
      .xs-block({
         line-height: 24.7px;
         letter-spacing: -0.1px;
         margin-bottom: 25.9px;
         margin-left: 12px;
      });
   }
   .wrapper.form {
      .xs-block({
         padding-left: 0;
         padding-right: 0;
      })
   }
}
.offer__form {
   background-color: @color-blue-gray;
   box-sizing: border-box;
   border-radius: 20px;
   padding: 40px;
   .lg-block({
      padding: 30px;
   });
   .sm-block({
      padding: 15px;
   });
   .xs-block({
      border-radius: 10px;
   });
}
.offer__form .row {
   .justify-center();
}
.offer__form .row > div {
   .col();
   .size(12);
   .size-xxl(12);
   .size-lg(24);
   .size-middle(24);
   .size-md(24);
   .size-xxs(24);
}
.offer__info {
   padding-top: 17px;
   .lg-block({
      padding-top: 0;
   })
}
.offer__label-select {
   margin-bottom: 10px;
   width: 100%;
}
.offer__label {
   .flex();
}
.offer__label .offer__li {
   margin-right: 16px;
}
.offer__li {
   .column-center();
   width: 48px;
   height: 48px;
   padding: 10px;
   border: 1px solid @color-gray-light;
   box-sizing: border-box;
   border-radius: 50px;
   background-color: transparent;
   color: @color-gray;
   flex-shrink: 0;
   .tr();
}
.offer__select {
   width: 100%;
}
.offer__li {
   .column-center();
   width: 48px;
   height: 48px;
   padding: 10px;
   border: 1px solid @color-gray-light;
   box-sizing: border-box;
   border-radius: 50px;
   background-color: transparent;
   color: @color-gray;
   flex-shrink: 0;
   .tr();
}
.offer__select {
   width: 100%;
}
.offer__title {
   color: @color-gray;
   .montserrat-medium();
   font-size: 14px;
   line-height: 130%;
   margin-bottom: 4px;
   .tr();
}
select.offer__select { // del

}
.offer__info .focus {
   .offer__li {
      border: 1px solid @color-blue;
      background-color: @color-blue;
      color: @color-blue-gray;
      text-decoration: underline;
      .tr();
   }
   
   .offer__title {
      color: @color-blue;
      .tr();
   }
}

.offer__contact {
   .lg-block({
      margin-top: 20px;
   });
   .middle-block({
      margin-top: 10px;
   });
   h3.title {
      .middle-block({
         .montserrat-semibold();
         font-size: 16px;
      })
   }
}
.offer__contact button {
   margin-top: 30px;
   .sm-block({
      margin-top: 20px;
   })
}
.offer__contact .label-input input {
   background-color: @color-white;
}


@media screen and (max-width: @break_sm) {
   .offer__li {
      width: 35px;
      height: 35px;
   }
}
@media screen and (max-width: @break_xs) {
   .offer__li {
      width: 25px;
      height: 25px;
   }
   .offer__label .offer__li {
      margin-right: 8px;
   }
   
   .offer__select {
      width: 100%;
      margin-left: -28px;
   }
}
@media screen and (max-width: @break_xs) {
   .offer__container {
      .text-small {
         line-height: 14px;
      }
   }
   .offer__li {
      .column-center();
      width: 16px;
      height: 16px;
      color: @color-gray;
      flex-shrink: 0;
   }
   .offer__title {
      padding-top: 2px;
      padding-left: 28px;
      margin-bottom: 10px;
   }
   
   .offer__label-select {
      margin-bottom: 8px;
      width: 100%;
   }
}
