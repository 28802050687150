.cameras__container {
      margin-top: 80px;
      margin-bottom: 80px;
   .lg-block({
      margin-top: 78px;
      margin-bottom: 40px;
   });
   .sm-block({
      margin-top: 40px;
      margin-bottom: 40px;
   });
   
   //.wrapper {
   //   //.sm-block({
   //   //   padding-left: 30px !important;
   //   //   padding-right: 0;
   //   //});
   //   .xxs-block({
   //       padding-left: 15px !important;
   //      padding-right: 0 !important;
   //   });
   //}
   //.slick-list {
   //   .sm-block({
   //      //padding-left: 15px !important;
   //      padding-right: 60px !important;
   //   });
   //}
   //
   //.section__caption {
   //   .sm-block({
   //      padding-left: 15px !important;
   //      padding-right: 15px !important;
   //   });
   //}
   
   .watch-more {
      .xs-block({
         margin-top: 20px;
         display: flex;
      });
   }
}
.cameras__wrapper {
   .gr();
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: repeat(2, auto);
   grid-column-gap: 32px;
   
   margin-top: -20px;
   .middle-block({
      grid-column-gap: 20px;
      margin-top: 0;
   });
   .md-block({
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
   });
   .xs-block({
      grid-template-rows: 1fr;
   });
   .relation {
      padding-top: 56.8%;
      .lg-block({
         padding-top: 55.8%;
      });
      .sm-block({
         padding-top: 69%;
      });
   }
}
.cameras__text {
   grid-column: ~"4 / 13";
   grid-row: span 1;
   .flex();
   & p {
      max-width: 300px;
      line-height: 20px;
      .middle-block({
         max-width: 224px;
         line-height: 19px;
      });
      .md-block({
         max-width: unset;
         line-height: 16px;
      });
      .sm-block({
         line-height: 17px;
      });
   }
   & p:first-of-type {
      margin-right: 10px;
      .md-block({
         margin-right: 0;
         margin-bottom: 8px;
      });
      .sm-block({
         width: 70%;
      });
   }
   .md-block({
      grid-column:span 1;
      display: block;
   });
   .sm-block({
      margin-top: -7px;
   });
}
.camera__block .relative {
   z-index: 10;
   &:after,
   &:before {
      .pseudo();
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
   }
   &:before {
      z-index: 20;
      width: 40px;
      height: 40px;
      background-color: @color-yellow-2;
      border-radius: 100px;
   }
   &:after {
      z-index: 30;
      .pseudo();
      .play-icon-small();
      transform: translateX(-33%) translateY(-50%);
   }
  
}
.camera__block {
   .xs-block({
      margin-bottom: 20px;
   });
   &:last-of-type {
      .xs-block({
         margin-bottom: 0;
      });
   }
}

.cameras__row {
      grid-column: ~"4 / 13";
      grid-row: span 1;
      margin-top: 40px;
   
      .gr();
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: 1fr;
      grid-gap: 20px;
      .lg-block({
         margin-top: 30px;
         grid-column: ~"1 / 13";
      });
      .sm-block({
         grid-column: span 1;
         grid-gap: 10px;
         margin-top: 15px;
      });
   
  
   .xs-block({
      grid-template-columns: unset;
      grid-template-rows: unset;
      grid-column: unset;
      grid-row: unset;
      
      display: block;
   });
}


.camera__block-1 {
   grid-column: ~"1 / 4";
}
.camera__block-2 {
   grid-column: ~"4 / 7";
}
.camera__block-3 {
   grid-column: ~"7 / 10";
}
  
   
.camera__block {
   
   .middle-block({
      //grid-column: ~"1 / 25";
      grid-row: ~"1 / 2";
   });
}
.cameras__video-1 { .camera-img-1() }
.cameras__video-2 { .camera-img-2() }
.cameras__video-3 { .camera-img-3() }

.camera__name {
   margin-top: 10px;
   .middle-block({
      font-size: 14px;
      //margin-top: 14px;
   })
}
