.delivery_main__content {
   width: 100%;
     .gr();
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: auto auto;
   grid-column-gap: 32px;
    grid-row-gap: 40px;
   .middle-block({
      grid-column-gap: 20px;
   });
   .md-block({
      grid-template-columns: repeat(2, 1fr);
   });
   .sm-block({
       margin-top: -10px;
   });
   
   .relation {
      padding-top: 61.1%;
      .md-block({
         padding-top: 44.8%;
      });
   }
}

.delivery_main__text {
   grid-row: span 1;
   grid-column: ~"4 / 13";
   margin-top: -20px;
   .flex();
   
   & p:first-of-type {
      min-width: 340px;
      margin-right: 20px;
      .ll-block({
         min-width: unset;
      });
      .middle-block({
         max-width: 240px;
      });
      .md-block({
         max-width: unset;
         margin-right: 0;
         margin-bottom: 10px;
      });
      .xs-block({
         margin-bottom: 6px;
      });
   }
   
   & p {
      max-width: 310px;
      line-height: 20px;
      .middle-block({
         line-height: 18px;
         max-width: unset;
      });
   }
   
   .middle-block({
      margin-top: 0;
   });
   .md-block({
      grid-column: ~"1 / 13";
      display: block;
   });
}
.delivery_main__theses {
   .gr();
   grid-column: ~"1 / 13";
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: auto auto;
   grid-column-gap: 20px;
   grid-row-gap: 0;
   .md-block({
      grid-template-rows: auto auto auto;
      grid-column-gap: 0;
      grid-row-gap: 20px;
   });
}
.delivery_main__thesis {
   grid-row: span 1;
   .md-block({
      grid-column: ~"1 / 13";
   })
}
.delivery_thesis-1 {
   grid-column: ~"1 / 5";
}
.delivery_thesis-2 {
   grid-column: ~"5 / 9";
}
.delivery_thesis-3 {
   grid-column: ~"9 / 13";
}
.delivery_main__thesis_title {
   .montserrat-semibold();
   width: 83%;
   font-size: 18px;
   line-height: 120%;
   margin-top: 10px;
   .lg-block({
      width: 90%;
      font-size: 14px;
   });
   .md-block({
      width: 100%;
   });
   .sm-block({
      margin-top: 5px;
   });
}
.delivery_main__thesis_image {
   border-radius: 10px;
   overflow: hidden;
}
.delivery__image {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}

.delivery_conditions__container {
   margin-top: 80px;
   .sm-block({
      margin-top: 40px;
   });
}
.delivery_conditions__blocks {
   .gr();
   grid-column: ~"4 / 13";
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: repeat(5, auto);
   grid-column-gap: 32px;
   grid-row-gap: 20px;
   margin-top: 40px;
   .lg-block({
      grid-column-gap: 20px;
      grid-row-gap: 10px;
      margin-top: 24px;
   });
   .sm-block({
      margin-top: 20px;
   });
}
.delivery_conditions__block {
   grid-column: ~"4 / 13";
   .flex();
   background-color: white;
   border-radius: 10px;
   padding: 20px;
   box-sizing: border-box;
   .sm-block({
      grid-column: ~"1 / 13";
      padding: 16px;
   });
}
.delivery_conditions__icon {
   margin-right: 16px;
   width: 44px;
   height: 44px;
   .sm-block({
      width: 33px;
      height: 33px;
   });
}

.delivery_conditions__content {
}
.delivery_conditions__title {
   .montserrat-semibold();
   font-size: 18px;
   line-height: 24px;
   .sm-block({
      font-size: 16px;
   })
}
.condition-text .dc-dot {
   margin-right: 8px;
}
.delivery_conditions__text {
   max-width: 600px;
}
.condition-text {
   margin-right: 8px;
}
.delivery_conditions__text {
   .xl-block({
      .block();
   });
}
.delivery_conditions__text_big {
   .column();
   width: calc(50% - 2px);
   box-sizing: border-box;
   .xl-block({
      width: 100%;
   });
   
   &:first-of-type {
      border-right: 1px solid @color-gray-light;
      padding-right: 10px;
      .xl-block({
         border-right: unset;
         padding-right: 0;
      });
   }
   &:last-of-type {
      border-left: 1px solid @color-gray-light;
      padding-left: 10px;
      .xl-block({
         border-left: unset;
         padding-left: 0;
      });
   }
}
.delivery_camera__holder {
   .gr();
   grid-column: ~"4 / 13";
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: auto;
   grid-column-gap: 32px;
   .lg-block({
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: auto auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
   });
   .sm-block({
      grid-column: ~"1 / 13";
   });
}
.delivery_camera__text {
   grid-column: ~"10 / 13";
   grid-row: ~"1 / 2";
   .lg-block({
      grid-column: ~"4 / 13";
      grid-row: ~"1 / 2";
      grid-column-gap: 20px;
   });
   .sm-block({
      grid-column: ~"1 / 13";
      grid-row: ~"1 / 2";
   });
}
.delivery_camera__video {
   grid-column: ~"4 / 10";
   grid-row: ~"1 / 2";
   .lg-block({
      grid-column: ~"4 / 13";
      grid-row: ~"2 / 3";
   });
   .sm-block({
      grid-column: ~"1 / 13";
      grid-row: ~"2 / 3";
   });
   .video();
   .relation {
      padding-top: 59.3%;
   }
   .relation_content {
      border-right: 20px;
      overflow: hidden;
      .sm-block({
         border-radius: 10px;
      })
   }
}
.delivery_camera {
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
}
