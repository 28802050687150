.button-yellow {
   .flex-center();
   cursor: pointer;
   width: 288px;
   max-width: 288px;
   height: 48px;
   border-radius: 10px;
   background-color: @color-yellow-2;
   color: @color-blue;
   
   padding: 12.5px 24px;
   flex-grow: 1;
   .montserrat-semibold();
   font-size: 14px;
   .lg-block({
      width: 223px;
   });
   .sm-block({
      width: 150px;
      height: 38px;
   });
   border: 1px solid @color-yellow-2;
   box-sizing: border-box;
   .tr();
}
.button-gray {
   .flex-center();
   cursor: pointer;
   width: 288px;
   max-width: 288px;
   height: 48px;
   border-radius: 10px;
   background-color: transparent;
   color: @color-gray-search;
   
   padding: 12.5px 24px;
   flex-grow: 1;
   .montserrat-semibold();
   font-size: 14px;
   .lg-block({
      width: 223px;
   });
   .sm-block({
      width: 150px;
      height: 38px;
   });
   border: 1px solid transparent;
   box-sizing: border-box;
   .tr();
}
.button-yellow:hover,
.button-yellow:active {
   background-color: @color-yellow-light;
   border: 1px solid @color-yellow-light;
   .tr();
}
a.button-yellow[disabled='true'],
button[disabled='true'] {
   background-color: @color-gray-light;
   border: 1px solid @color-gray-light;
   .tr();
   pointer-events: none;
  cursor: default;
}
#catalog_filters_submit[disabled] {
   background-color: @color-gray-light;
   border: 1px solid @color-gray-light;
   .tr();
   pointer-events: none;
   cursor: default;
}
#payment_btn[disabled],
#supplier_btn[disabled],
#reset_password_button[disabled] {
   background-color: @color-gray-light;
   border: 1px solid @color-gray-light;
   .tr();
   pointer-events: none;
   cursor: default;
}
.button-yellow:focus,
.button-yellow:target {
   background-color: white;
   border: 1px solid @color-blue;
   .tr();
}
.button-gray:hover {
   .tr();
   color: @opacity-blue-30;
}
.button-gray:focus,
.button-gray:target {
   .tr();
   color: @color-gray;
}
.button-long {
   width: 100%;
   max-width: 100%;
   .lg-block({
      width: 100%;
      max-width: 100%;
   });
   .sm-block({
      width: 100%;
      max-width: 100%;
      height: 48px;
   });
}
.button-small {
   width: 235px;
   .sm-block({
      width: 260px;
   });
}
.button-blue() {
   .montserrat-semibold();
   .flex-center();
   font-size: 14px;
   line-height: 110%;
   border-radius: 10px;
   color: @color-blue;
   background-color: @color-blue-gray;
   padding: 16.5px 39px;
   
   box-sizing: border-box;
   .tr();
   .xs-block({
      width: 100%;
      font-size: 13px;
      padding: 16.5px 0;
      white-space: nowrap;
   });
   
    &:hover {
      color: @opacity-blue-90;
      .tr();
   }
   &:focus,
   &:target,
   &:target-within {
   
   }
}
.button-blue {
   .button-blue();
}
.button-hollow() {
   .montserrat-semibold();
   font-size: 14px;
   line-height: 110%;
   border-radius: 10px;
   background-color: transparent;
   color: @color-blue;
   border: 1px solid @color-gray-light;
   padding: 12.5px 24px;
   flex-grow: 1;
   
   box-sizing: border-box;
   .tr();
   &:hover {
      color: @opacity-blue-90;
      .tr();
   }
   &:focus,
   &:target,
   &:target-within {
   
   }
}
.button-hollow {
   .button-hollow();
}
.button-more() {
   width: 100%;
   height: 48px;
   border-radius: 10px;
   background-color: @color-blue-gray;
   cursor: pointer;
   .watch-inner {
      .column-center();
      width: 100%;
      height: 100%;
   }
   span {
      color: @color-blue;
      .montserrat-semibold();
      font-size: 14px;
      line-height: 110%;
   }
    &:hover span {
      color: @opacity-blue-90;
      .tr();
   }
   &:focus,
   &:target,
   &:target-within {
   
   }
   
}
.watch-more {
   .button-more();
   display: unset;
}


.button-buy {
   .flex-center();
   cursor: pointer;
   border-radius: 4px;
   background-color: @color-yellow-2;
   width: 40px;
   height: 40px;
   
   background-image: url("../images/svg/icons/product/buy.svg");
   background-repeat: no-repeat;
   background-size: 24px 24px;
   background-position: center;
   flex-shrink: 0;
   .md-block({
      width: 48px;
      height: 36px;
   });
   
}


#container_main .button-yellow {
   .xs-block({
      max-width: unset;
      width: 100%
   });
}
#container_main .carousel__holder .button-yellow {
   .xs-block({
      padding: 12.5px 4px;
      max-width: unset;
      width: 150px
   });
}
.ico__minus[disabled='true'],
.ico__plus[disabled='true'] {
   background-color: transparent;
   border-color: transparent;
}
