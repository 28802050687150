.svg-b(@folder1, @folder2, @name) {
   background-image: url("../data/@{folder1}/@{folder2}/@{name}.svg");
   background-repeat: no-repeat;
}
.svg(@folder, @name) {
   background-image: url("../data/@{folder}/@{name}.svg");
   background-repeat: no-repeat;
}
.size-contain(@size, @name) {
    background-image: url("../images/@{size}/@{name}");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
}
.size-cover(@size, @name) {
    background-image: url("../images/@{size}/@{name}");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}


.logo( @name) {
   background-image: url("../images/svg/logo/@{name}.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: left;
   flex-shrink: 0;
}
.header__logo {
   .logo__image {
      .logo('b-logo');
   }
   .logo__text {
      .logo('b-words');
      margin-left: 10px;
   }
}
.footer__logo {
   .logo__image {
      .logo('logo');
   }
   .logo__text {
      .logo('words');
      margin-left: 16px;
      .xs-block({
         .logo('xs-words');
      })
   }
}

/* line */
.lines() {
    background-image: url("../images/svg/bg/lines.png");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}
.lines2() {
    background-image: url("../images/svg/bg/lines2.png");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}
.lines-request() {
    background-image: url("../images/svg/bg/request.jpg");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}

///* main карусель на главной */            ВНЕШНЯЯ ЗАГРУЗКА
/*.carousel-little-img( @numb, @size ) {
   background-image: url("../images/@{size}/carousel/index-@{numb}.webp");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: bottom right;
}
.carousel__item-1-img() {
   .carousel-little-img( 1, 'large' );
   .lg-block({
      .carousel-little-img( 1, 'middle' );
   });
   .sm-block({
      .carousel-little-img( 1, 'small' );
   });
}
.carousel__item-2-img() {
   .carousel-little-img( 2, 'large' );
   .lg-block({
      .carousel-little-img( 2, 'middle' );
   });
   .sm-block({
      .carousel-little-img( 2, 'small' );
   });
}
.carousel__item-3-img() {
   .carousel-little-img( 3, 'large' );
   .lg-block({
      .carousel-little-img( 3, 'middle' );
      background-position: top right;
   });
   .middle-block({
      background-position: bottom right;
   });
   .sm-block({
      .carousel-little-img( 3, 'small' );
   });
}*/
.request() {
   .size-cover( 'large', 'request.webp' );
   .lg-block({
      .size-cover( 'middle', 'request.webp' );
   });
   .sm-block({
      .size-cover( 'small', 'request.webp' );
   })
}


/* главная - cover, белые прямоугольники  */
.cover-img( @numb, @size ) {
    background-image: url("../images/@{size}/cover/cover-@{numb}");
   background-repeat: no-repeat;
   //background-size: contain;
   background-position: bottom right;
}
.cover-block-1() {
   .cover-img( '1.png', 'large');
   .xl-block({
      .cover-img( '1.png', 'middle');
   });
   .md-block({
      .cover-img( '1.png', 'large');
   })
}
.cover-block-2() {
   .cover-img( '2.svg', 'large');
}
.cover-block-3() {
   .cover-img( '3.svg', 'large');
}

/* ГЛАВНАЯ разная продукция */
/*
.variety_products( @size, @name ) {
    background-image: url("../images/@{size}/product/@{name}.webp");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   width: 100%;
   height: 100%;
}
.to485( @name ) {
   .to( 485px, {
      background-image: url("../images/small/product/@{name}.webp");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
   });
   
}
.image__sveshemor {
   .variety_products( 'large', 'sveshemor' );
   .to485('sveshemor');
}
.image__okhlazhdennaya_ryba {
   .variety_products( 'large', 'okhlazhdennaya_ryba' );
      .to485('okhlazhdennaya_ryba');
}
.image__myaso_i_ptitsa {
   .variety_products( 'large', 'myaso_i_ptitsa' );
      .to485('myaso_i_ptitsa');
}
.image__gotovaya {
   .variety_products( 'large', 'gotovaya' );
      .to485('gotovaya');
}
.image__moreprodukty {
   .variety_products( 'large', 'moreprodukty' );
   .to485( 'moreprodukty');
}
.image__fileryby {
   .variety_products( 'large', 'fileryby' );
   .ll-block({
       .variety_products( 'middle', 'fileryby' );
   });
   .to485( 'fileryby');
}
.image__fish_in_packaging {
   .variety_products( 'large', 'fish_in_packaging' );
   .ll-block({
       .variety_products( 'middle', 'fish_in_packaging' );
   });
   .to485( 'fish_in_packaging');
}
.image__zamorozhennyye-ovoshchi-yagody {
   .variety_products( 'large', 'zamorozhennyye-ovoshchi-yagody' );
   .to485( 'zamorozhennyye-ovoshchi-yagody');
}
.image__molochnaya-produktsiya {
   .variety_products( 'large', 'molochnaya-produktsiya' );
      .to485('molochnaya-produktsiya');
}
.image__bakaleya {
   .variety_products( 'large', 'bakaleya' );
      .to485('bakaleya');
}
.image__maslo-rastitelnyoe {
   .variety_products( 'large', 'maslo-rastitelnyoe' );
      .to485('maslo-rastitelnyoe');
}
.image__sousy {
   .variety_products( 'large', 'sousy' );
      .to485('sousy');
}
*/

/* индивидуальное предложение  */
.index-offer( @size ) {
   background-image: url("../images/@{size}/offer.webp");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: right;
   width: 100%;
   height: 100%;
}
.image-offer() {
   .index-offer( 'large');
   .lg-block({
      .index-offer( 'middle')
   });
   .xs-block({
      .index-offer( 'small')
   });
}

/* поставки в разные сферы бизнесса  */
.busareas-image( @size, @num ) {
   background-image: url("../images/@{size}/busareas/@{num}.webp");
   background-repeat: no-repeat;
   background-size: cover;
   border-radius: 10px;
   .xs-block({
      border-radius: 5px;
   });
}
.busareas-img-1() {
   .busareas-image( 'large', '01');
       background-position: 50% 30%;
   .lg-block({
      .r1( .busareas-image( 'small', '01'));
   });
   .r2( .busareas-image( 'large', '01'));
   .sm-block({
      .busareas-image( 'small', '01');
   })
}
.busareas-img-2() {
   .busareas-image( 'large', '02');
       background-position: 50% 73%;
   .lg-block({
      .r1( .busareas-image( 'small', '02'));
   });
   .r2( .busareas-image( 'large', '02'));
   .sm-block({
      .busareas-image( 'small', '02');
   })
}
.busareas-img-3() {
   .busareas-image( 'large', '03');
       background-position: 50% 90%;
   .lg-block({
      .r1( .busareas-image( 'small', '03'));
   });
   .r2( .busareas-image( 'large', '03'));
   .sm-block({
      .busareas-image( 'small', '03');
   })
}
.busareas-img-4() {
   .busareas-image( 'large', '04');
       background-position: 50% 93%;
   .lg-block({
      .r1( .busareas-image( 'small', '04'));
   });
   .r2( .busareas-image( 'large', '04'));
   .sm-block({
      .busareas-image( 'small', '04');
   })
}
/* ГЛАВНАЯ эффективная доставка */
.delivery-index-img( @size ) {
   background-image: url("../images/@{size}/delivery-index.webp");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   width: 100%;
   height: 100%;
}
/*.delivery-index() {                    ВНЕШНЯЯ ЗАГРУЗКА
   .delivery-index-img( 'large' );
    .lg-block({
      .delivery-index-img( 'middle' );
   });
   .middle-block({
      .r2({
         .delivery-index-img('middle');
      });
      .r1( .delivery-index-img( 'small'));
   });
   .sm-block({
      .delivery-index-img( 'small');
   })
}*/
/* ГЛАВНАЯ рука */
.hand-index( @size ) {
   background-image: url("../images/@{size}/hand.webp");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: bottom right;
}
.hand-index-img() {
   background-image: url("../images/large/hang-2.webp");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: bottom right;
   .lg-block({
      .hand-index( 'middle');
   });
   .xs-block({
      .hand-index( 'small');
   });
}

/* ГЛАВНОЕ видео и камера */
.video( @size, @numb ) {
   background-image: url("../images/@{size}/video/poster-@{numb}.webp");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   border-radius: 20px;
   width: 100%;
   height: 100%;
}
.video-img-1() {
   .video( 'middle', '1' );
   .middle-block({
      .video( 'small', '1' );
   })
}
.video-img-2() {
   .video( 'middle', '2' );
   .middle-block({
      .video( 'small', '2' );
   })
}

.camera( @numb ) {
   background-image: url("../images/middle/camera/@{numb}.webp");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   border-radius: 10px;
   width: 100%;
   height: 100%;
}
.camera-img-1() {
   .camera( '1' );
}
.camera-img-2() {
   .camera( '2' );
}
.camera-img-3() {
   .camera( '3' );
}
.camera-img-4() {
   background-image: url("../images/middle/camera/2.jpg");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   border-radius: 10px;
   width: 100%;
   height: 100%;
}
/* ГЛАВНАЯ контакты карта */
.index-map( @size, @numb ) {
   background-image: url("../images/@{size}/index-contact/@{numb}.webp");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   border-radius: 20px;
   width: 100%;
   height: 100%;
   
   .middle-block({
      border-radius: 10px;
   });
}
.index-map-1() {
   .index-map( 'large', '1');
   .lg-block({
      .index-map( 'middle', '1');
   });
   .md-block({
      .index-map( 'large', '1');
   });
   .xxs-block({
      .index-map( 'small', '1');
   });
}
.index-map-2() {
   .index-map( 'large', '2');
   .lg-block({
      .index-map( 'middle', '2');
   });
   .md-block({
      .index-map( 'large', '2');
   });
   .xxs-block({
      .index-map( 'small', '2');
   });
}
.index-map-3() {
   .index-map( 'large', '3');
   .lg-block({
      .index-map( 'middle', '3');
   });
   .md-block({
      .index-map( 'large', '3');
   });
   .xxs-block({
      .index-map( 'small', '3');
   });
}

/* рамка у менеджера */
.stroke-img() {
    background-image: url("../images/middle/managers/stroke.svg");
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
}
