html {
   font-size: 15px;
   .middle( font-size, 12px );
   .xs( font-size, 10px );
}
body {
   .montserrat-medium();
   background-color: @color-back-main;
   min-width: 280px;
   flex-direction: column;
   display: flex;
   min-height: 100vh;
}
.container {
   overflow-x: hidden;
   position: relative;
   margin-bottom: auto;
}
.wrapper {
   .wrapper();
}
.wrapper-small {
   max-width: 862px;
   .xs-block({
      padding-right: 0 !important;
      padding-left: 0 !important;
   });
}
.wrapper-add-address {
   max-width: 630px;
   .xs-block({
      padding-right: 0 !important;
      padding-left: 0 !important;
   });
}
.row() {
   .row-flex();
}
.row {
   .row-flex();
}
.col {
   .col();
}

a {
   display: block;
   text-decoration: none;
   cursor: pointer;
   
   color: inherit;
   .tr();
}

input {
   .un-input();
   width: 90%;
   height: 90%;
   color: @color-blue;
   font-size: 14px;
   line-height: 110%;
}
input:focus {
   color: @color-gray;
}
textarea {
   .un-input();
   width: 90%;
   height: 90%;
   color: @color-blue;
   font-size: 14px;
   line-height: 110%;
}
textarea:focus {
   color: @color-gray;
}
button {
   .un-button();
   cursor: pointer;
}

menu {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

input::-moz-placeholder { color: @color-gray-search; font-size: 14px; .montserrat-medium();}
input::-webkit-input-placeholder { color: @color-gray-search; font-size: 14px; .montserrat-medium();}
input:-ms-input-placeholder { color: @color-gray-search; font-size: 14px; .montserrat-medium();}
input::-ms-input-placeholder { color: @color-gray-search; font-size: 14px; .montserrat-medium();}
input::placeholder { color: @color-gray-search; font-size: 14px; .montserrat-medium();}



#black_back {
   position: fixed;
   z-index: 490;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background-color: rgba(32, 54, 134, 0.6);
   //opacity: 0;
   transition: opacity .2s;
   
   display: none;
}
#black_back.up {
   z-index: 1900;
   .xs-block({
      z-index: 1500;
   })
}
.absolute-img {
   width: 100%;
   height: 100%;
}
.dot {
   width: 4px;
   height: 4px;
   background-color: @color-blue;
   border-radius: 50px;
}
