/* О КОМПАНИИ */
.caption__wrapper {
   margin-top: 40px;
   margin-bottom: 40px;
   .lg-block({
      margin-top: 20px;
   });
   .xxs-block({
      margin-bottom: 20px;
   });
}
.about__video {
   position: relative;
   .video();
   //margin-top: 40px;
   border-radius: 20px;
   overflow: hidden;
   .lg-block({
      //margin-top: 20px;
   })
}
