.label-checkbox {
   position: relative;
   z-index: 50;
}
.checkbox,
.radio {
   display: block;
}
.checkbox-hidden {
   position: absolute;
   left: -99999px;
   width: 0;
   height: 0;
   opacity: 0;
}

.label-input {
   position: relative;
   z-index: 50;
   .flex();
   .items-center();
   background: @color-blue-gray;
   
   border-radius: 10px;
}
.label-input input {
   position: relative;
   z-index: 60;
   background-color: transparent;
}
.label-input .sup {
   position: absolute;
   z-index: 55;
   left: 16px;
   .montserrat-medium();
   font-size: 14px;
   line-height: 130%;
   color: @color-gray;
   .tr();
}
.label-input input:focus + .sup{
   font-size: 12px;
   line-height: 120%;
   color: rgba(60, 60, 67, 0.3);
   .tr();
   margin-bottom: 36px;
}
.label-tel,
.label-date {
 input::-moz-placeholder { color: transparent; .tr();}
 input::-webkit-input-placeholder { color: transparent; .tr(); }
 input:-ms-input-placeholder { color: transparent; .tr(); }
 input::-ms-input-placeholder { color: transparent; .tr(); }
 input::placeholder { color: transparent; .tr(); }
}
.label-tel input:focus,
.label-date input:focus {
 &::-moz-placeholder { color: @color-gray; .tr(); }
 &::-webkit-input-placeholder { color: @color-gray; .tr(); }
 &:-ms-input-placeholder { color: @color-gray; .tr(); }
 &::-ms-input-placeholder { color: @color-gray; .tr(); }
 &::placeholder { color: @color-gray; .tr(); }
}

textarea {
   resize: none;
   background-color: transparent;
   padding: 18px 16px;
   box-sizing: border-box;
   border-radius: 10px;
   border: 1px solid #DDE1E6;
   min-width: 100%;
   width: 100%;
   max-height: 120px;
   height: 120px;
}
.label-area .sup {
   top: 18px;
}
.label-area textarea:focus + .sup {
   top: 3px;
   font-size: 12px;
   line-height: 120%;
   color: rgba(60, 60, 67, 0.3);
   .tr();
   margin-bottom: 36px;
}
