.contacts_index__container {
      margin-top: 80px;
      margin-bottom: 80px;
   .lg-block({
      margin-top: 40px;
      margin-bottom: 40px;
   });
   .sm-block({
      margin-top: 40px;
      margin-bottom: 40px;
   });
   .section-title-text-14 {
      .xs-block({
         .none();
      });
   }
   .xs-block({
      br {
         .none();
      }
   });
}

.contact_index___item {
   display: grid;
   grid-template-columns: repeat( 24, 1fr);
   padding: 16px 0;
   border-top: 1px solid @opacity-blue-20;
   grid-column-gap: 32px;
   .middle-block({
      grid-column-gap: 20px;
   });
}
.contact_index___title {
   grid-column: ~"1 / 7";
   .middle-block({
      font-size: 16px;
   });
   .sm-block({
      font-size: 16px;
      margin-bottom: 15px;
   });
}
.contact_index___text {
   grid-column: ~"7 / 14";
   padding-right: 15px;
   .lg-block({
      padding-right: 0;
      grid-column: ~"7 / 17";
   });
   .middle-block({
      grid-column: ~"7 / 17";
   });
   .sm-block({
      .mb {
         margin-bottom: 8px;
      }
   });
}
.contact_index___map {
   .ci_img {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 20px;
      width: 100%;
      height: 100%;
      
      .middle-block({
         border-radius: 10px;
      });
   }
   grid-column: ~"14 / 25";
   & div {
      width: 100%;
      border-radius: 20px;
      .lg-block({
         border-radius: 10px;
      });
   }
   .lg-block({
      grid-column: ~"17 / 25";
   });
   .middle-block({
      grid-column: ~"17 / 25";
   });
}

.contact_index__item-3 {
   border-bottom: 1px solid @opacity-blue-20;
}

.contact_index___map {
   height: 220px;
   
   .lg-block({
      height: 120px;
   });
   .md-block({
      height: 180px;
   });
   .xs-block({
      height: 120px;
   });
}
.contact_index__image-1 { .index-map-1()}
.contact_index__image-2 { .index-map-2()}
.contact_index__image-3 { .index-map-3()}


@media screen and (max-width: @break_xl) {
   .contact_index___item {
      padding: 16px 0;
   }
}
@media screen and (max-width: @break_lg) {
   .contact_index___item {
      grid-column-gap: 20px;
   }
}
@media screen and (max-width: @break_md) {

   .contact_index___item {
      display: grid;
      grid-template-columns: auto;
      padding: 16px 0;
   }
   .contact_index___title {
      grid-column: span 1;
   }
   .contact_index___text {
      grid-column: span 1;
   }
   .contact_index___map {
      grid-column: span 1;
      margin-left: 0;
   }
   .contact_index__image-3 {
      background-position: 50% 100%;
   }
}
