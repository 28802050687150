@break_first: 1555px;
@break_second: 1095px;
#container_one .header__main.wrapper,
#container_catalog .header__main.wrapper,
.breadcrumbs__container .wrapper,
#container_catalog .header__aside .wrapper {
   .from( @break_first, {
      .wrapper-big();
   });
   .wrapper();
}
#container_catalog .header__aside .header__form  {
   .from( @break_first, {
      height: 40px;
      max-width: 520px;
      width: 520px;
      margin-right: auto;
   });
   .to( 1825px, {
      height: 40px;
      max-width: 520px;
      width: auto;
      margin-right: auto;
   });
}
#container_catalog #catalog_trigger {
   .from( @break_first, {
      width: 311px;
      margin-right: auto;
   });
}


.catalog_main__container {
   position: relative;
   z-index: 100;
   
   .catalog__block.catalog__block-aside > .scroll-wrapper {
      overflow: visible !important;
   }
   .catalog__block.catalog__block-aside > .scroll-wrapper > .scroll-content {
      overflow: visible !important;
   }
}
.wrapper-big {
   .wrapper();
   .from( @break_first, {
      .wrapper-big();
   });
}
.catalog_main__wrapper {
   .gr();
   grid-template-columns: 252px auto;
   grid-template-rows: auto;
   grid-column-gap: 50px;
   .to(@break_first, {
      .gr();
      grid-template-columns: minmax(210px, 256px) auto;
      grid-column-gap: 32px;
   });
   .to(@break_second, {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-column-gap: 0;
      grid-row-gap: 16px;
   });
}
.catalog_main__section {
   grid-column: ~"2 / 3";
   grid-row: ~"1 / 2";
   margin-left: auto;
   .to(@break_second, {
      grid-column: ~"1 / 2";
   });
   
   .gr();
   width: 100%;
   grid-template-columns: repeat(auto-fill, minmax(214.67px, auto));
   grid-template-rows: auto;
   grid-column-gap: 51px;
   grid-row-gap: 50px;
   .to( 1825px, {
      grid-column-gap: 20px;
      grid-row-gap: 40px;
   });
   .to( @break_second, {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
   });
   .to(700px, {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 40px;
   });
   .to(500px, {
      grid-template-columns: repeat(2, minmax(139px, auto));
      grid-row-gap: 30px;
   });
}
.catalog_main__content { // del

}

.catalog_main__products {
   min-width: 214.67px;
   flex-grow: 1;
   max-width: 345px;
   .to( @break_second, {
      min-width: 200px;
   });
   .middle-block({
      min-width: 140px;
   });
}
.catalog_main__filter {
   grid-column: ~"1 / 2";
   grid-row: ~"1 / 2";
   
   .to(@break_second, {
      .none();
   });
}

h2.catalog_main__caption {
   margin-bottom: 20px;
   .xs-block({
      margin-bottom: 8px;
      letter-spacing: 0;
   })
}
.catalog_main__about {
   .montserrat-medium();
   font-size: 14px;
   line-height: 20px;
   
   margin-bottom: 40px;
   .xs-block({
      margin-bottom: 20px;
   });
}
.catalog_main__show {
   .flex();
   .justify-end();
   
   min-height: 36px;
   margin-bottom: 20px;
   .to( 525px, {
      .gr();
      justify-content: unset;
      grid-template-columns: auto 36px;
      grid-template-rows: 36px 40px;
      grid-column-gap: 16px;
      grid-row-gap: 10px;
   });
   .xs-block({
      margin-bottom: 15px;
   })
}
.catalog__select_holder-1 {
   .to( 525px, {
      grid-column: ~"1 / 3";
      grid-row: ~"1 / 2";
   });
}
.catalog__select_holder-2 {
   margin-left: auto;
   .to( 525px, {
      grid-column: ~"1 / 2";
      grid-row: ~"2 /3";
      margin-left: unset;
   });
}

.subscribe__wrapper_catalog {
   &.subscribe__wrapper {
      grid-column: ~"1 / 4";
      grid-row: ~"3 / 4";
      margin-top: 0;
      margin-bottom: 0;
      
      .to(768px, {
         grid-column: ~"1 / 4";
         grid-row: ~"3 / 4";
      });
      .to(700px, {
         grid-column: ~"1 / 3";
         grid-row: ~"3 / 4";
      });
      
      
      
      
      position: relative;
      z-index: 50;
      box-sizing: border-box;
      padding: 55px 50px 50px;
         .to( @break_first, {
            padding: 20px;
         });
         .sm-block({
            padding: 15px;
         });
      .column();
      background-color: @color-gray;
      border-radius: 10px;
      overflow: hidden;
      &:before {
         .pseudo();
         z-index: 55;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 100%;
         .lines();
      }
      &:after {
         .pseudo();
         z-index: 58;
         right: 0;
         bottom: 0;
         width: 100%;
         height: 80%;
         
         background-image: url("../images/middle/hand-catalog.webp");
         background-repeat: no-repeat;
         background-size: contain;
         background-position: bottom right;
         .xs-block({
             background-image: url("../images/small/hand.webp");
         });
         .to( @break_first, {
            width: 100%;
            height: 95%;
            right: -30px;
            bottom: -10px;
         });
         
         .lg-block({
            width: 50%;
            height: 80%;
         });
         .middle-block({
            width: 70%;
            height: 100%;
            
            right: -8px;
         });
         .to( 530px, {
            width: 11em;
            height: 20em;
            bottom: 15px;
            right: 0;
         });
      }
      > * {
         position: relative;
         z-index: 60;
      }
   }
   .subscribe__caption {
      .montserrat-semibold();
      color: @color-white;
      font-size: 40px;
      line-height: 100%;
      margin-bottom: 10px;
      width: 13em;
         .to( @break_first, {
            width: 15em;
         });
      .middle-block({
         width: 14em;
         font-size: 32px;
         line-height: 120%;
         letter-spacing: -.6px;
         margin-bottom: 10px;
      });
      
   }
   .subscribe__title {
       .montserrat-medium();
      color: @color-white;
      font-size: 13px;
      line-height: 135%;
      width: 28em;
      letter-spacing: 0.2px;
      .middle-block({
         width: 20em;
         letter-spacing: 0;
         font-size: 14px;
         line-height: 130%;
      });
     
   }
   .subscribe__holder {
      width: 60%;
      margin-top: auto;
      padding-top: 120px;
         .to( @break_first, {
            padding-top: 40px;
         });
     
   }
   
   .subscribe__socials {
      .lg-block({
         grid-template-columns: repeat(3, 1fr);
         grid-template-rows: auto;
      });
      
      .xs-block({
         grid-template-columns: repeat(3, 40px);
         grid-template-rows: 1fr;
      })
   }
   .subscribe__social_holder {
      .middle-block({
         padding: 7.5px 18px;
      });
      span {
          .middle-block({
            font-size: 12px;
             line-height: 120%;
         });
      }
      *[class^='icon__'] {
          width: 24px;
          height: 24px;
      }
   }
   .subscribe__check {
      .middle-block({
         margin-top: 10px;
      });
      span {
         .middle-block({
            width: 300px;
         });
      }
   }
   
   
   .to( 530px, {
      &:after {
          width: 11em;
          height: 20em;
          bottom: 15px;
         right: 0;
      }
      
      .subscribe__caption {
         width: 90%;
         font-size: 24px;
         line-height: 110%;
         letter-spacing: 0;
      }
      .subscribe__title {
         width: 80%;
      }
      .subscribe__holder {
         padding-top: 52px;
      }
      .subscribe__holder {
         width: 100%;
      }
      .subscribe__check {
         margin-top: 16px;
         span {
            width: 80%;
            line-height: 14px;
         }
      }
   });
   
}
.catalog__fbutton {
   margin-left: 16px;
   background-color: @color-white;
   border-radius: 8px;
   box-sizing: border-box;
   padding: 8px 9.5px;
   height: 40px;
   min-width: 36px;
   display: none;
   .to( @break_second, {
      .flex-center();
   });
   
   span {
      .montserrat-semibold();
      font-size: 14px;
      line-height: 145%;
      margin-left: 8px;
      .sm-block({
         display: none;
      });
   }
   .to( 525px, {
      margin-left: 0;
      width: 24px;
   });
}
/*#container_catalog .catalog_main__labels {
   .gr();
   grid-template-columns: auto;
   grid-column-gap: 0;
   .to( 525px, {
      grid-template-columns: auto;
   });
}*/
