.header__menu {
   margin-right: auto;
}
.menu__holder {
    .flex();
   flex-direction: row;
   
   .xl-block({
      flex-direction: column;
   })
}
.menu__item { // del
}
.menu__item_link {
   display: inline-block;
   .montserrat-semibold();
   color: @color-blue;
   font-size: 14px;
   line-height: 20px;
   .tr();
}

