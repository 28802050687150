.subscribe__container {
      margin-top: 80px;
      margin-bottom: 80px;
   .lg-block({
      margin-top: 76px;
      margin-bottom: 40px;
   });
   .sm-block({
      margin-top: 40px;
      margin-bottom: 40px;
   });
}
.subscribe__wrapper {
   position: relative;
   z-index: 50;
   .section-padding-403015();
   .column();
   background-color: @color-gray;
   border-radius: 20px;
   overflow: hidden;
   &:before {
      .pseudo();
      z-index: 55;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .lines();
   }
   &:after {
      .pseudo();
      z-index: 58;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      .hand-index-img();
      
      .lg-block({
         width: 50%;
         height: 80%;
      });
      .middle-block({
         width: 50%;
         height: 86%;
      });
      .xs-block({
         width: 11em;
         height: 20em;
         bottom: 15px;
      });
   }
   > * {
      position: relative;
      z-index: 60;
   }
   .sm-block({
      border-radius: 10px;
   })
}
.subscribe__caption {
   .montserrat-semibold();
   color: @color-white;
   font-size: 60px;
   line-height: 90%;
   margin-bottom: 20px;
   width: 10em;
   .lg-block({
      width: 14em;
      font-size: 30px;
      line-height: 110%;
      margin-bottom: 10px;
   });
   .middle-block({
      letter-spacing: -0.7px;
   });
   .sm-block({
      width: 10em;
      font-size: 24px;
      line-height: 110%;
      letter-spacing: 0;
   });
   .xs-block({
      width: 9em;
   })
}
.subscribe__title {
    .montserrat-medium();
   color: @color-white;
   font-size: 18px;
   line-height: 120%;
   width: 18em;
   letter-spacing: 0.2px;
   .lg-block({
      font-size: 14px;
      line-height: 130%;
   });
   .middle-block({
      width: 20em;
      letter-spacing: -0.1px;
   });
   .sm-block({
      width: 209px;
   });
}
.subscribe__holder {
   width: 60%;
   margin-top: auto;
   padding-top: 100px;
   .lg-block({
      padding-top: 64px;
   });
   .sm-block({
      padding-top: 54px;
   });
   .xs-block({
      width: 100%;
   });
}
.subscribe__check {
   .flex();
   .items-start();
   margin-top: 20px;
   span {
      width: 353px;
      .sm-block({
         width: 201px;
      });
      .xs-block({
         line-height: 13px;
      });
   }
   .sm-block({
      margin-top: 16px;
   })
}

.subscribe__socials {
   display: grid;
   //grid-template-columns: minmax(160px, 264px);
   grid-template-columns: repeat(3, 1fr);
   grid-template-rows: 1fr;
   grid-gap: 10px;
   .lg-block({
      grid-template-columns: 244px;
      grid-template-rows: repeat(3, 1fr);
   });
   
   .xs-block({
      grid-template-columns: repeat(3, 40px);
      grid-template-rows: 1fr;
   })
}
.subscribe__social_holder {
   cursor: pointer;
   .flex-center();
   //height: 60px;
   padding: 18px 25px;
   border-radius: 10px;
   .lg-block({
      //height: 40px;
      padding: 8px 25px;
   });
   .md-block({
      //height: 40px;
      padding: 8px 16px;
   });
   .sm-block({
      //height: 40px;
      padding: 8px 8px;
   });
   grid-column: span 1;
   grid-row: ~"1 / 2";
   
   .lg-block({
      grid-column: span 1;
      grid-row: span 1;
   });
   .xs-block({
      grid-column: span 1;
      grid-row: ~"1 / 2";
   });
   span {
      display: inline-block;
      margin-left: 8px;
      .montserrat-semibold();
      font-size: 16px;
      line-height: 24px;
      
      .xs-block({
         display: none;
      });
   }
}
.subscribe__social-whatsapp {
   background-color: #4ADD47;
   
}
.subscribe__social-telegram {
   background: linear-gradient(180deg, #2AABEE 0%, #229ED9 99.26%);
}
.subscribe__social-vkontakte {
   background-color: #1877F2;
}
.subscribe__social_input {
   display: none;
}


.subscribe__social_holder {
   .xs-block({
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      div[class^='icon__'] {
          width: 24px;
         height: 24px;
      }
   });
   input {
      color: @color-white
   }

input::-moz-placeholder { color: @color-white; font-size: 14px; .montserrat-medium();}
input::-webkit-input-placeholder { color: @color-white; font-size: 14px; .montserrat-medium();}
input:-ms-input-placeholder { color: @color-white; font-size: 14px; .montserrat-medium();}
input::-ms-input-placeholder { color: @color-white; font-size: 14px; .montserrat-medium();}
input::placeholder { color: @color-white; font-size: 14px; .montserrat-medium();}

}
.subscribe__social_holder input {
   width: 140px;
   margin-left: 10px;
   display: none;
}
.subscribe__social_holder input:focus {
   display: block;
   + span {
      display: none;
   }
}
