.one_order__container {
   .gr();
   grid-row-gap: 20px;
   margin-bottom: 40px;
   .middle-block({
      grid-row-gap: 10px;
   });
   .sm-block({
      margin-bottom: 20px;
   });
}
.one_order__assessment .star {
   cursor: pointer;
}
.one_order_status__radio {
   .flex();
   .justify-between();
   margin-bottom: 20px;
}
.one_order_status {
   position: relative;
   z-index: 100;
   .flex();
   flex-wrap: wrap;
   flex-grow: 1;
   &:after {
      .pseudo();
      z-index: 100;
      top: 12px;
      left: 12px;
      
      width: 100%;
      height: 1px;
      background-color: @color-gray-light;
   }

   span {
      display: inline-block;
      margin-top: 8px;
      width: 100%;
   }
   &:last-of-type {
      flex-grow: 0;
      .gr();

      &:after {
         display: none;
      }
   }
   .radio_checked,
   .radio_false {
      position: relative;
      z-index: 500;
   }
}
.one_order_status__button {
   margin-bottom: 40px;
   .middle-block({
      margin-bottom: 20px;
   });
}
.sm-block({
   .one_order_status__radio {
      .column()
   }
   .one_order_status {
      position: relative;
      z-index: 100;
      .flex();
      .items-center();
      margin-bottom: 10px;
      
      span {
         display: inline-block;
         margin-top: 0;
         margin-left: 8px;
         width: auto;
      }
   
      &:after {
         .pseudo();
         z-index: 100;
         top: 12px;
         left: 12px;
      
         width: 1px;
         height: 100%;
      }
      &:last-of-type {
         flex-grow: 0;
         .flex();
         margin-bottom: 0;
      }
   }
});


.one_order_delivery {
   .gr();
   grid-template-columns: repeat(3, 1fr);
   grid-template-rows: repeat(2, auto);
   grid-column-gap: 10px;
   
   .to( 750px, {
      grid-template-columns: 60% 40%;
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
   });
   .sm-block({
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      grid-column-gap: 0;
   });
}
.one_order_delivery__block {
   .flex();
   grid-column: span 1;
   grid-row: span 1;
   
   .to( 750px, {
      &:first-of-type {
         grid-column: ~"1 / 2";
         grid-row: ~"1 / 3";
      }
   });
   .sm-block({
      grid-column: ~"1 / 2";
      grid-row: span 1;
   });
}
.delivery__info,
.details__info,
.payment__info{
   margin-left: 16px;
   .middle-block({
      margin-left: 10px;
   })
}


.one_order_details {
   .gr();
   grid-template-columns: repeat(3, 1fr);
   grid-template-rows: repeat(2, auto);
   grid-column-gap: 10px;
   .to( 750px, {
      grid-template-columns: 60% 40%;
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
   });
   
   .to( 590px, {
      grid-template-columns: 50% 50%;
   });
   
   .to( 490px, {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      grid-column-gap: 0;
   });
}
.one_order_details__block {
   .flex();
   grid-column: span 1;
   grid-row: span 1;
}

.one_order_payment {
   .gr();
   grid-template-columns: repeat(2, 1fr);
   grid-template-rows: 1fr;
   grid-column-gap: 10px;
   
   .to( 750px, {
      grid-template-columns: 60% 40%;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
   });
   
   .to( 590px, {
      grid-template-columns: 50% 50%;
   });
   
   .to( 490px, {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 0;
   });
}
.one_order_payment__block {
   .flex();
   grid-column: span 1;
   grid-row: span 1;
}


.one_order__information {
   .gr();
   grid-template-columns: repeat(4, max-content);
   grid-template-rows: 1fr;
   grid-column-gap: 20px;
   align-items: center;
   .md-block({
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, max-content);
      grid-column-gap: 5px;
      grid-row-gap: 5px;
      align-items: center;
   });
}
.one_order_information__status {
   width: min-content;
   border-radius: 12px;
   border: 1px solid;
   padding: 5px 12px;
   .md-block({
      justify-self: flex-end;
      grid-column: ~"2 / 3";
      grid-row: ~"1 / 3";
   });
}
.one_order_information__text {
   .md-block({
      grid-column: ~"1 / 2";
      line-height: 100%;
   });
}
.md-block({
   .information_text-1 {
      grid-row: ~"1 / 2";
   }
   .information_text-2 {
      grid-row: ~"2 / 3";
   }
   .information_text-3 {
      margin-top: 5px;
      grid-column: ~"1 / 3";
      grid-row: ~"3 / 4";
   }
   
});




.one_order_composition__block {
   padding: 16px 0 16px;
   border-top: 1px solid @color-gray-light;
   box-sizing: border-box;
   .to( 590px, {
      padding-bottom: 0;
   });
   .gr();
   grid-template-columns: 120px minmax(150px, 260px) repeat(2, 1fr);
   grid-template-rows: auto 40px;
   grid-column-gap: 16px;
   grid-row-gap: 0;
   align-items: center;
   .to( 590px, {
      grid-template-columns: 64px 1fr 1fr;
      grid-row-gap: 10px;
      align-items: flex-start;
   });
   &:first-of-type {
      border-top: 1px solid transparent;
      padding-top: 0;
   }
}
.one_order_composition__image {
   grid-column: ~"1 / 2";
   grid-row: ~"1 / 4";
   min-width: 120px;
   min-height: 120px;
   background-color: @color-blue-gray;
   border-radius: 10px;
   .to( 590px, {
      min-width: 64px;
      min-height: 64px;
   });
   .to( 550px, {
      grid-column: ~"1 / 2";
      grid-row: ~"1 / 3";
      margin-right: 10px;
   });
}
.one_order_composition__title {
   grid-column: ~"2 / 3";
   grid-row: ~"1 / 2";
   .montserrat-semibold();
   font-size: 16px;
   line-height: 120%;
   .to( 590px, {
      grid-column: ~"2 / 4";
      grid-row: ~"1 / 2";
   });
   .sm-block({
      font-size: 14px;
      line-height: 120%;
   })
}
.one_order_composition__info {
   grid-column: ~"2 / 3";
   grid-row: ~"2 / 4";
   .xs-block({
      display: none;
   });
}
.one_order_composition__amount,
.one_order_composition__buy {
   margin-right: 60px;
   justify-self: flex-end;
   .md-block({
      margin-right: 0;
   });
}
.one_order_composition__amount {
   grid-column: ~"3 / 4";
   grid-row: ~"1 / 4";
   .middle-block({
      margin-right: 20px;
   });
   
   .to( 590px, {
      grid-column: ~"2 / 3";
      grid-row: ~"2 / 3";
       justify-self: flex-start;
   });
}
.one_order_composition__buy {
   grid-column: ~"4 / 5";
   grid-row: ~"1 / 4";
   
   .to( 590px, {
      grid-column: ~"3 / 4";
      grid-row: ~"2 / 3";
   });
}
