@import "breadcrumbs";

@columns-big: 30;
@atom-big: (100% / @columns-big);


@break_xxl-big: 1650px;

@offset-big: 51px;
@offset-big_xxl: 20px;

@offset-big_one_side: (@offset-big / 2);
@offset-big_xxl_one_side: (@offset-big_xxl / 2);


@fields-big: 60px;
@fields-big_xxl: 30px;


.big-xxl(@name, @value){
    @media screen and (max-width: @break_xxl-big){
        @{name}: @value;
    }
}

.big-xxl-block(@content){
    @media screen and (max-width: @break_xxl-big){
        @content();
    }
}
.wrapper-big(){
    max-width: 1756px !important;
    margin: 0 auto;
    
    padding-left: @fields-big !important;
    padding-right: @fields-big !important;;

    .big-xxl-block({
        padding-left: @fields-big_xxl !important;;
        padding-right: @fields-big_xxl !important;;
    });
}


@import "main";
@import "filters";
@import "filters_list";
@import "product";
@import "pagination";
@import "mobile_filters";
