#container_one .header__main.wrapper,
#container_one aside .wrapper {
   .from( @break_first, {
      .wrapper-big();
   });
   .wrapper();
}
.one_item__wrapper {
   .gr();
   grid-template-columns: 256px auto;
   grid-template-rows: auto auto auto;
   grid-column-gap: 50px;
   .to(1640px, {
      .gr();
      grid-template-columns: auto;
      grid-column-gap: 32px;
   });
   .to(@break_second, {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-column-gap: 0;
      grid-row-gap: 16px;
   });
}
.one_item__filter {
   .to(1640px, {.none()});
}
.one_item__content { // тут фото, конфигурации, блок покупки, описание
   .gr();
   grid-template-columns: 553px minmax(300px, 450px) minmax(255px, 460px);
   grid-template-rows: auto 1fr;
   grid-column-gap: 50px;
   grid-row-gap: 50px;
   .to(1640px, {
      .gr();
      grid-template-columns: 448px minmax(353px, 465px) minmax(255px, 460px);
   });
   .to(@break_first, {
      grid-column-gap: 32px;
      grid-row-gap: 40px;
   });
   .xxl-block({
      grid-template-columns: 448px auto auto;
   });
   .lg-block({ // 1095px
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-column-gap: 0;
      grid-row-gap: 20px;
   });
}

.one_image__holder {
   grid-column: ~"1 / 2";
   grid-row: ~"1 / 3";
   max-width: 553px;
   .lg-block({
      width: 100%;
      max-width: unset;
      margin-bottom: 12px;
      grid-column: ~"1 / 4";
      grid-row: ~"1 / 2";
   });
   .middle-block({
      height: 400px;
      border-radius: 10px;
      overflow: hidden;
      .slick-track {
         top: -10vw;
      }
   });
   .sm-block({
      height: 300px;
      margin-bottom: 0;
      .slick-track {
         top: -10vw;
      }
   });
   .xs-block({
      height: 250px;
   })
}
.one_data__holder {
   .gr();
   grid-column: ~"2 / 3";
   grid-row: ~"1 / 3";
   grid-template-columns: 353px;
   grid-template-rows: repeat(4, min-content);
   grid-row-gap: 20px;
   .xxl-block({
      grid-template-columns: auto;
      grid-column: ~"2 / 4";
      grid-row: ~"2 / 3";
   });
   .lg-block({
      grid-column: ~"1 / 4";
      grid-row: ~"2 / 3";
      grid-template-rows: repeat(3, min-content);
   })
}
.one_item__characteristic {
   grid-column: ~"1 / 2";
   grid-row: ~"4 / 5";
}
.one_purchase__holder {
   grid-column: ~"3 / 4";
   grid-row: ~"1 / 2";
   .xxl-block({
      grid-column: ~"2 / 4";
      grid-row: ~"1 / 2";
   });
   .lg-block({
      grid-column: ~"1 / 4";
      grid-row: ~"3 / 4";
   })
}
.one_about__holder {
   grid-column: ~"3 / 4";
   .to(@break_first, {
      grid-column: ~"1 / 3";
      grid-row: ~"3 / 4";
   });
   .xxl-block({
      grid-column: ~"1 / 4";
      grid-row: ~"3 / 4";
   });
   .lg-block({
      grid-column: ~"1 / 4";
      grid-row: ~"4 / 5";
   })
}
.one_item__wrapper .subscribe__wrapper_catalog.subscribe__wrapper {
   grid-column: ~"1 / 4";
   grid-row: ~"3 / 4";
   padding: 50px;
   .to(@break_first, {
      grid-column: ~"1 / 3";
      grid-row: ~"4 / 5";
   });
   .xxl-block({
      grid-column: ~"1 / 4";
      grid-row: ~"4 / 5";
      padding: 30px;
   });
   .lg-block({
      grid-column: ~"1 / 4";
      grid-row: ~"5 / 6";
      padding: 20px;
   });
   .xs-block({
      padding: 15px;
   });
   
   .subscribe__holder {
      padding-top: 64px;
   }
   &:after {
      height: 94%;
   }
}
