/*
@media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi) {
  .example {
    background-image: url(img.png);
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .example {
    background-image: url(img@2x.png);
  }
}
*/


.r1(@content) {  // Уменьшенная версия
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi) {
         @content();
    }
}
.r2(@content) { // Качество выше
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
         @content();
    }
}

.none1190() {
    .to( 1190px, {
        display: none;
    });
}
.none890() {
    .lg-block({
        display: none;
    });
}



.section-margin() {
      margin-top: 80px;
      margin-bottom: 80px;
   .lg-block({
      margin-top: 40px;
      margin-bottom: 40px;
   });
   .sm-block({
      margin-top: 40px;
      margin-bottom: 40px;
   });
}
.section-padding-403015() {
   padding: 40px;
   .lg-block({
      padding: 30px;
   });
   .sm-block({
      padding: 15px;
   });
}
.block-white() {
   .column();
   .justify-between();
   padding: 20px;
   box-sizing: border-box;
   border-radius: 20px;
   background-color: @color-white;
   .sm-block({
      padding: 16px;
   });
   .xs-block({
      padding: 16px;
   });
}
.section-text-half() {
   .gr();
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: repeat(2, auto);
   .gap2040();
   .middle-block({
      grid-template-rows: repeat(3, auto);
      grid-row-gap: 5px;
   });
   .sm-block({
       grid-template-columns: auto;
      grid-row-gap: 5px;
   });
   
   
   .section_part_text {
      grid-row: ~"1 / 2";
      //max-width: 345px;
      .sm-block({
          grid-column: span 1;
      })
   }
   .section_part_text:first-of-type {
      grid-column: ~"4 / 8";
      .middle-block({
         grid-column: ~"1 / 25";
         grid-row: ~"1 / 2";
      });
      .sm-block({
          grid-column: span 1;
      })
   }
   .section_part_text:nth-of-type(2) {
      grid-column: ~"8 / 13";
      .middle-block({
         grid-column: ~"1 / 25";
         grid-row: ~"2 / 3";
      });
      .sm-block({
          grid-column: span 1;
      })
   }
   .sm-block({
      hyphens: auto;
   });
}

.good-friendly-arrows() {
   .slick-prev.slick-arrow,
   .slick-next.slick-arrow {
      position: absolute;
            transform: none;
      z-index: 100;
      top: 0;
      bottom: 0;
      width: 20%;
      height: 100%;
      color: transparent;
      font-size: 1px;
      line-height: 0;
   }
   .slick-prev.slick-arrow {
      left: 0;
   }
   .slick-next.slick-arrow {
      right: 0;
   }

}

.video() {
   z-index: 10;
   &:after,
   &:before {
      .pseudo();
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
   }
   &:before {
      z-index: 20;
      width: 70px;
      height: 70px;
      background-color: @color-yellow-2;
      border-radius: 100px;
      .sm-block({
         width: 50px;
         height: 50px;
      });
   }
   &:after {
      z-index: 30;
      .pseudo();
      .play-icon();
      transform: translateX(-35%) translateY(-50%);
      .sm-block({
         width: 16px;
         height: 16px;
      });
   }
}
