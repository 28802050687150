.wrapper.flex {
   .flex();
   //.items-center();
}
.grid-wrapper { // del
   display: grid;
}
.slick-slide { // del
  //margin: 0 5px; /* Измените отступы между слайдами на 20 пикселей */
}
.slick-list .slick-track { // del
}
.flex {
   .flex();
}
.column {
   .column();
}
.center {
   .items-center()
}
.start {
   .items-start();
}
.hide { // del
   display: none;
}
.dis { // del
   opacity: 0;
}
.element {
   padding: 0 !important;
   color: inherit;
}
.relative {
   position: relative;
}
.relation {
   height: 0;
}
.relation-square {
   height: 0;
   padding-top: 100%;
}
.relation-rectangle {
   height: 0;
   padding-top: 48.5%;
}
.relation_content {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
}
.form_pass .ico__eye {
   position: absolute;
   z-index: 1000;
   right: 16px;
}
.row_flex-10 {
   margin-left: -5px;
   margin-right: -5px;
   
   & > * {
      padding-right: 5px;
      padding-left: 5px;
   }
}
.quarter { // del
   width: 25%;
}
.third { // del
   width: 33%;
}
.half {
   width: 50%;
}
.two-thirds { // del
   width: 66%;
}
.full {
   width: 100%;
}
h1.caption {
   .montserrat-semibold();
   font-size: 50px;
   line-height: 100%;
   .xl-block({
      font-size: 32px;
      line-height: 120%;
   });
   .sm-block({
      font-size: 24px;
      line-height: 110%;
   });
}
h1.title,
h3.caption,
h2.caption {
   .montserrat-semibold();
   font-size: 40px;
   line-height: 100%;
   .xl-block({
      font-size: 30px;
      line-height: 110%;
      letter-spacing: -0.6px;
   });
   .sm-block({
      font-size: 24px;
   });
}

h2.title,
h3.title {
   .montserrat-semibold();
   font-size: 24px;
   
   .sm-block({
      font-size: 18px;
   });
}
h3.caption.text-blue {
   .xs-block({
      letter-spacing: -0.1px;
   })
}
.text-18-title {
   .montserrat-semibold();
   font-size: 18px;
   line-height: 120%;
   color: @color-blue;
   .sm-block({
      font-size: 16px;
   });
}
.text-18 {
   font-size: 18px;
   line-height: 120%;
}
.text-16 {
   .montserrat-medium();
   font-size: 16px;
   line-height: 120%;
   .sm-block({
      font-size: 14px;
      line-height: 130%;
   })
}
.text-16-big {
   .montserrat-semibold();
   font-size: 16px;
   line-height: 120%;
}
.text-14 {
   .montserrat-medium();
   font-size: 14px;
   line-height: 120%;
}
.text-14-small {
   .montserrat-medium();
   font-size: 14px;
   line-height: 120%;
   .xs-block({
      font-size: 12px;
   })
}
.text-14-big {
   .montserrat-semibold();
   font-size: 14px;
   line-height: 110%;
}
.section-title-text-14 {
   .montserrat-medium();
   font-size: 14px;
   line-height: 130%;
   color: @color-gray;
   .xxs-block({
      font-size: 12px;
      line-height: 120%;
   })
}
.text-small {
   .montserrat-medium();
   font-size: 12px;
   line-height: 16px;
}
.text-12-bold {
   .montserrat-semibold();
   font-size: 12px;
   line-height: 16px;
}
.row_flex-8 {
   .flex();
   margin-left: -4px;
   margin-right: -4px;
   
   & > * {
      padding-right: 4px;
      padding-left: 4px;
   }
}
.row_flex-10-m {
   .flex();
   margin-left: -5px;
   margin-right: -5px;
   
   & > * {
      margin-right: 5px;
      margin-left: 5px;
   }
}
.row_flex-12 {
   .flex();
   margin-left: -6px;
   margin-right: -6px;
   
   & > * {
      padding-right: 6px;
      padding-left: 6px;
   }
}
.row_flex-16 {
   .flex();
   margin-left: -8px;
   margin-right: -8px;
   
   & > * {
      padding-right: 8px;
      padding-left: 8px;
   }
}
.row_flex-20 {
   .flex();
   margin-left: -10px;
   margin-right: -10px;
   
   & > * {
      padding-right: 10px;
      padding-left: 10px;
   }
}
.row_flex-32 {
   .flex();
   margin-left: -16px;
   margin-right: -16px;
   
   & > * {
      padding-right: 16px;
      padding-left: 16px;
   }
}
.row_flex-80-media { // del
   margin-left: -40px;
   margin-right: -40px;
   
   & > * {
      padding-right: 40px;
      padding-left: 40px;
   }
   .md-block({
      margin-left: 0;
      margin-right: 0;
      & > * {
         padding-right: 0;
         padding-left: 0;
      }
   });
}




.text-white {
   color: @color-white
}
.text-blue {
   color: @color-blue;
}
.text-gray {
   color: @color-blue-gray;
}
.text-opacity {
   color: @opacity-blue-30;
}
.text-dark {
   color: @color-gray;
}
.text-red {
   color: @color-red;
}
.text-green {
   color: @color-green;
}
.status-green {
   border-color: @color-green;
   color: @color-green;
}
.status-red {
   border-color: @color-red;
   color: @color-red;
}
.status-blue {
   border-color: @color-blue;
   color: @color-blue;
}
.status-gray {
   border-color: @color-gray;
   color: @color-gray;
}
.back-blue { // del
   background-color: @color-blue-gray;
}
.text-center {
   text-align: center;
}




.mtauto {
   margin-top: auto;
}
.mlauto {
   margin-left: auto;
}
.mb {
   margin-bottom: 6px;
}
.mr {
   margin-right: 6px;
}
.mt4 {
   margin-top: 4px;
}
.mr4 {
   margin-right: 4px;
}
.mb4 {
   margin-bottom: 4px;
}
.mr6 {
   margin-right: 6px;
}
.mb6 {
   margin-bottom: 6px;
}
.mr8 {
   margin-right: 8px;
}
.mb8 {
   margin-bottom: 8px;
}
.mb10 {
   margin-bottom: 10px;
}
.mr10 {
   margin-right: 10px;
}
.mb12 {
   margin-bottom: 12px;
}
.mt10 {
   margin-top: 10px;
}
.mt16 {
   margin-top: 16px;
}
.mr16 {
   margin-right: 16px;
}
.mb16 {
   margin-bottom: 16px;
}
.mt20 {
   margin-top: 20px;
}
.mb20 {
   margin-bottom: 20px;
}
.mr20 {
   margin-right: 20px;
}
.mb25 {
   margin-bottom: 25px;
}
.mb30 {
   margin-bottom: 30px;
}
.mt40 {
   margin-top: 40px;
}
.pt20 { // del
   padding-top: 20px;
}
.p20 {
   padding: 20px;
}
.block {
   display: block !important;
}
.block-12 {
   .col();
   .size(12);
   .size-xxl(12);
   .size-lg(24);
   .size-middle(24);
   .size-md(24);
}
.section__caption {
   display: grid;
   grid-template-columns: repeat( 12, 1fr);
   grid-column-gap: 32px;
   margin-bottom: 40px;
   
   
   .section-title-text-14 {
      grid-column: ~"1 / 4";
   }
   .caption {
      grid-column: ~"4 / 13";
   }
    
    .ll-block({
      margin-bottom: 30px;
      grid-template-columns: repeat( 12, 1fr);
      .section-title-text-14 {
         grid-column: ~"1 / 4";
         word-break: break-word;
      }
      .caption {
         grid-column: ~"4 / 13";
      }
    });
   .middle-block({
      margin-bottom: 20px;
      grid-column-gap: 20px;
   });
    .xs-block({
      display: grid;
      
      grid-template-columns: auto;
      grid-template-rows: repeat( 2, auto);
       margin-bottom: 18px;
      .section-title-text-14 {
         margin-bottom: 6px;
         grid-column: span 1;
         grid-row: ~"1 / 2";
      }
      .caption {
         grid-column: span 1;
         grid-row: ~"2 / 3";
      }
       br {
          .none();
       }
    })
}

.text-bold {
   font-family: 'Montserrat-Bold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif !important;
   font-weight: 700 !important;
}
.text-big{
   font-family: 'Montserrat-SemiBold', Proxima Nova, Gilroy, Arial, Helvetica, sans-serif !important;
   font-weight: 600 !important;
}
.pointer {
   cursor: pointer;
}
.default {
   cursor: default;
}
