
.breadcrumbs__container {
   .flex-wrap();
   .justify-start();
   .items-end();
   height: 20px;
   margin-top: 25px;
   margin-bottom: 29px;
   .middle-block({
      //height: 30px;
      margin-top: 10px;
      margin-bottom: 20px;
   });
   .xs-block({
      margin-top: 20px;
      margin-bottom: 30px;
   });
   .icon__back {
      margin-right: 12px;
   }
}
.breadcrumbs__link {
   position: relative;
   .montserrat-medium();
   font-size: 12px;
   line-height: 14px;
   color: @color-gray;
   margin-right: 24px;
   margin-left: 1px;
}
.breadcrumbs__link:after {
   content: '/';
   display: block;
   position: absolute;
   font-size: 14px;
   line-height: 17px;
   width: 5px;
   top: -1px;
   bottom: -2px;
   right: -10px;
   transform: translateX(100%);
}
.breadcrumbs__active {
   .montserrat-medium();
   display: inline-block;
   font-size: 12px;
   line-height: 14px;
   color: @color-blue;
   margin-left: 1px;
   margin-top: 2px;
   .xxs-block({
      //width: 1;
   })
}
.breadcrumbs__button {
   position: relative;
   .montserrat-medium();
   font-size: 12px;
   line-height: 14px;
   color: @color-gray;
   margin-right: 24px;
}
